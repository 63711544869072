import axios from "axios";
import { logout } from "../store/auth/actions";
import { setSnackbar } from "../store/general/actions";
import store from "../store/store";

const agent = axios.create({
  // Development
  // baseURL: "https://api-dev-warehouse-ibbr.assist.id/api",
  // Staging
  // baseURL: "https://api-uat-warehouse-ibbr.assist.id/api",
  // Production
  // baseURL: "https://api-warehouse.reqaid.com/api", old API
  baseURL: "https://api-warehouse-ibbr.assist.id/api",
});

agent.interceptors.request.use(function (config) {
  config.headers.Authorization = store.getState().auth.token;

  return config;
});

agent.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response) {
      if (store.getState().auth.isLoggedIn && error.response.status === 401) {
        store.dispatch(logout());
        store.dispatch(setSnackbar("error", "Error! Silakan login kembali"));
      } else {
        store.dispatch(
          setSnackbar("error", error.response.data.error?.message)
        );
      }
    }
    return Promise.reject(error);
  }
);

export default agent;
