import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import debounce from "awesome-debounce-promise";

import {
  makeStyles,
  Typography,
  Grid,
  ButtonGroup,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
} from "@material-ui/core";

import routes from "../../constants/routes";

import validations from "../../utils/validations";
import formatting from "../../utils/formatting";
import useDisclosure from "../../utils/useDisclosure";

import CustomTextField from "../custom/CustomTextField";
import CustomSelect from "../custom/CustomSelect";
import CustomAutocomplete from "../custom/CustomAutocomplete";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";

import { getListCustomer } from "../../services/CustomerService";
import { generateROCOde, addRO } from "../../services/RequestOrderService";
import { getListInventory } from "../../services/InventoryService";

import { getPlaceId } from "../../store/auth/selector";
import { setSnackbar } from "../../store/general/actions";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  header: {
    lineHeight: "60px",
    fontWeight: "bold",
  },
  inputField: {
    width: "100%",
  },
  itemGridLeft: {
    paddingRight: 12,
  },
  itemGridRight: {
    paddingLeft: 12,
  },
  buttonGroup: {
    marginBottom: 27,
  },
  buttonGroupSelected: {
    backgroundColor: theme.palette.lightBlue,
    borderColor: theme.palette.lightBlue,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.lightBlue,
      borderColor: theme.palette.lightBlue,
      color: theme.palette.white,
    },
  },
  select: {
    width: "100%",
  },
  inputWithSelect: {
    paddingLeft: 10,
  },
  table: {
    marginBottom: 30,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  grandTotal: {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.whiteBlue,
      color: theme.palette.titleText,
      fontWeight: "bold",
    },
  },
}));

const units = [
  { value: "FIXED", label: "Rp" },
  { value: "PERCENTAGE", label: "%" },
];

const debounceGetListCustomer = debounce(getListCustomer, 500);
const debounceGetListInventory = debounce(getListInventory, 500);

const AddNewOrder = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    open: isSubmitting,
    onOpen: setSubmit,
    onClose: setSubmitted,
  } = useDisclosure();

  const [searchClient, setSearchClient] = useState("");
  const [clients, setClients] = useState([]);
  const [searchItem, setSearchItem] = useState({ type: "Obat", name: "" });
  const [items, setItems] = useState([]);

  const [error, setError] = useState({});
  const [data, setData] = useState({});

  const [code, setCode] = useState();
  const [currentClient, setCurrentClient] = useState({});
  const [currentItem, setCurrentItem] = useState({ discountUnit: "FIXED" });
  const [orderDetailTable, setOrderDetailTable] = useState([]);

  const [subTotal, setSubTotal] = useState("");
  const [totalDiscount, setTotalDiscount] = useState("");
  const [ppnPercent, setPpnPercent] = useState(0);
  const [ppnFixed, setPpnFixed] = useState(0);
  const [grandTotal, setGrandTotal] = useState("");

  useEffect(() => {
    generateROCOde().then((res) => setCode(res.data.code));
  }, []);

  useEffect(() => {
    const subtotal = calculateSubTotal(orderDetailTable);
    setGrandTotal(subtotal + Number(ppnFixed));
    calculateTotalDiscount(orderDetailTable);
  }, [orderDetailTable, ppnFixed]);

  useEffect(() => {
    debounceGetListCustomer(searchClient).then((res) =>
      setClients(res.data.items)
    );
  }, [searchClient]);

  useEffect(() => {
    debounceGetListInventory(
      searchItem.name,
      undefined,
      searchItem.type
    ).then((res) => setItems(res.data.items));
  }, [searchItem]);

  const setCurrentItemState = (newValue, details) => {
    if (details) {
      setCurrentItem({
        ...details.option,
        sellPrice: formatting.currencyFormat(details.option.sellFee),
        itemName: newValue,
        discountUnit: "FIXED",
        discount: 0,
      });
    }
  };

  const setCurrentClientState = (newValue, details) => {
    if (details) {
      setCurrentClient({ ...details.option, discountUnit: "FIXED" });
      setData({
        ...data,
        clientName: newValue,
        clientPic: details.option.name,
        clientPhone: details.option.hp,
      });
    }
  };

  const resetItem = () => {
    setCurrentItem({
      category: "",
      discountUnit: "FIXED",
      bigStock: "",
      sellPrice: "",
      buyTotal: "",
      discount: "",
      itemName: "",
    });
    setSearchItem({ ...searchItem, name: "" });
  };
  const validateAddItem = () => {
    const valid = {
      clientName: validations.isEmpty(data.clientName)
        ? "Nama Client tidak boleh kosong"
        : undefined,
      itemName: validations.isEmpty(currentItem._id)
        ? "Nama item tidak boleh kosong"
        : undefined,
      buyTotal: validations.isEmpty(currentItem.buyTotal)
        ? "Jumlah yang akan dibeli tidak boleh kosong"
        : validations.lessThanZero(currentItem.buyTotal)
        ? "Jumlah yang akan dibeli harus lebih besar dari 0"
        : undefined,
      discount: validations.isEmpty(currentItem.discount)
        ? "Diskon tidak boleh kosong"
        : validations.lessThanZero(currentItem.discount)
        ? "Diskon harus lebih besar dari 0"
        : undefined,
    };
    setError(valid);
    return Object.values(valid).every((error) => !error);
  };
  const calculateDiscount = () => {
    return (currentItem.discount / 100) * currentItem.sellFee;
  };
  const calculateSubTotal = (orderDetails) => {
    const subTotal = orderDetails.reduce(
      (previousValue, currentValue) => previousValue + currentValue.total,
      0
    );
    setSubTotal(subTotal);
    return subTotal;
  };
  const calculateTotalDiscount = (orderDetails) => {
    console.log(orderDetails);
    let totalDiscountFix = 0;
    let totalDiscountPercent = 0;
    totalDiscountFix = orderDetails
      .filter((f) => f.discountUnit === "FIXED")
      .reduce(
        (previousValue, currentValue) =>
          previousValue + currentValue.discount * currentValue.qty,
        0
      );
    totalDiscountPercent = orderDetails
      .filter((f) => f.discountUnit === "PERCENTAGE")
      .reduce(
        (previousValue, currentValue) =>
          previousValue +
          (currentValue.discount / 100) *
            currentValue.sellFee *
            currentValue.qty,
        0
      );
    const totalDiscount = totalDiscountFix + totalDiscountPercent;
    setTotalDiscount(totalDiscount);
  };
  const handleAddItem = () => {
    if (validateAddItem()) {
      const orderDetails = orderDetailTable.filter(
        (value) => value.name !== currentItem.name
      );
      orderDetails.push({
        id: currentItem["_id"],
        name: currentItem.name,
        qty: parseInt(currentItem.buyTotal, 10),
        price: currentItem.sellFee,
        discountUnit: currentItem.discountUnit,
        originalDiscount: currentItem.discount,
        discount: currentItem.discount * 1,
        total:
          currentItem.buyTotal *
          (currentItem.sellFee -
            (currentItem.discountUnit === "FIXED"
              ? currentItem.discount
              : calculateDiscount())),
        unit: currentItem.bigUnit,
        sellFee: currentItem.sellFee,
      });
      setOrderDetailTable(orderDetails);
      calculateTotalDiscount(orderDetails);
      calculateSubTotal(orderDetails);
      resetItem();
    }
  };
  const resetOrder = () => {
    resetItem();
    setOrderDetailTable([]);
    setSubTotal("");
    setTotalDiscount("");
    setPpnFixed("");
    setPpnPercent("");
    setGrandTotal("");
  };

  const validateOrder = () => {
    const valid = {
      ppn:
        validations.isEmpty(ppnFixed) || validations.isEmpty(ppnPercent)
          ? "PPN tidak boleh kosong"
          : validations.lessThanZero(ppnFixed) ||
            validations.lessThanZero(ppnPercent)
          ? "PPN harus lebih besar dari 0"
          : undefined,
      clientName: validations.isEmpty(data.clientName)
        ? "Nama Client tidak boleh kosong"
        : undefined,
      items:
        orderDetailTable.length === 0 ? "Items tidak boleh kosng" : undefined,
    };
    setError(valid);
    return Object.values(valid).every((error) => !error);
  };

  const handleOrder = () => {
    if (validateOrder()) {
      setSubmit();
      addRO({
        customerId: currentClient.id,
        placeId: getPlaceId(),
        notes: data.notes,
        code: code,
        subTotalFee: subTotal,
        discountRp: totalDiscount,
        discountPercent: parseFloat(totalDiscount / (subTotal + totalDiscount) * 100).toFixed(2),
        ppnPercent,
        ppnRp: ppnFixed,
        totalFee: grandTotal,
        items: orderDetailTable.map((value) => {
          return {
            itemId: value.id,
            name: value.name,
            sellFee: value.sellFee,
            quantity: parseInt(value.qty, 10),
            bigUnit: value.unit,
            discountRp:
              value.discountUnit === "FIXED"
                ? parseInt(value.originalDiscount, 10).toFixed(2)
                : ((parseInt(value.originalDiscount, 10) / 100) * value.sellFee).toFixed(2),
            discountPercent:
              value.discountUnit === "PERCENTAGE"
                ? value.originalDiscount.toFixed(2)
                : ((parseInt(value.originalDiscount, 10) / value.sellFee) * 100).toFixed(2),
          };
        }),
      })
        .then((res) => {
          console.log(res);
          if (res.data.data === "the code exists, please edit the code!") {
            dispatch(
              setSnackbar(
                "warning",
                "Maaf! Nomor request order telah digunakan"
              )
            );
          } else {
            dispatch(
              setSnackbar(
                "success",
                "Sukses! Request order berhasil dilakukan"
              )
            );
            history.push(routes.REQUEST_PROCESSING);
          }
          setSubmitted();
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            setSnackbar("error", "Error! Terjadi kesalahan. Coba beberapa saat lagi")
          );
          setSubmitted();
        });
    }
  };

  const deleteRow = (index) => {
    let items = [...orderDetailTable];
    items.splice(index, 1);

    setOrderDetailTable(items);
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName="Tambah Request Order Baru"
        previousPageName="Request Order"
        previousPageLink={routes.REQUEST_NEW}
      />
      <Grid item xs={12}>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className={`${classes.header} ${classes.borderBottom}`}
            >
              Order
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              className={`${classes.header} ${classes.borderBottom}`}
            >
              Detail Order
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6} className={classes.itemGridLeft}>
                <CustomAutocomplete
                  options={clients}
                  onInputChange={(event, newInputValue) =>
                    setSearchClient(newInputValue)
                  }
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue, reason, details) => {
                    setCurrentClientState(newValue, details);
                    setError({ ...error, clientName: undefined });
                  }}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  inputValue={searchClient}
                  error={error.clientName}
                  helperText={error.clientName}
                  label="Nama Client"
                  getOptionListLabel={(option) => option.name}
                  requiredField
                />
              </Grid>
              <Grid item xs={6} className={classes.itemGridRight}>
                <CustomTextField
                  label="PIC RO Client"
                  className={classes.inputField}
                  value={data.clientPic}
                  style={{ color: "black" }}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.itemGridLeft}>
                <CustomTextField
                  label="No. Whatsapp Client"
                  className={classes.inputField}
                  value={data.clientPhone}
                  style={{ color: "black" }}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.itemGridRight}>
                <CustomTextField
                  label="Nomer Request Order"
                  className={classes.inputField}
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  placeholder="Masukan alamat lengkap"
                  label="Alamat"
                  className={classes.inputField}
                  value={currentClient.address}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Keterangan"
                  className={classes.inputField}
                  value={data.notes}
                  onChange={(event) =>
                    setData({ ...data, notes: event.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonGroup color="primary" className={classes.buttonGroup}>
                  <Button
                    className={clsx({
                      [classes.buttonGroupSelected]: searchItem.type === "Obat",
                    })}
                    onClick={() =>
                      setSearchItem({ ...searchItem, type: "Obat" })
                    }
                  >
                    Obat
                  </Button>
                  <Button
                    className={clsx({
                      [classes.buttonGroupSelected]: searchItem.type === "BHP",
                    })}
                    onClick={() =>
                      setSearchItem({ ...searchItem, type: "BHP" })
                    }
                  >
                    Bahan Habis Pakai
                  </Button>
                  <Button
                    className={clsx({
                      [classes.buttonGroupSelected]:
                        searchItem.type === "Logistik",
                    })}
                    onClick={() =>
                      setSearchItem({ ...searchItem, type: "Logistik" })
                    }
                  >
                    Logistik
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <CustomAutocomplete
                  options={items}
                  onInputChange={(event, newInputValue) =>
                    setSearchItem({ ...searchItem, name: newInputValue })
                  }
                  getOptionLabel={(option) => option.code + " - " + option.name}
                  onChange={(event, newValue, reason, details) => {
                    setCurrentItemState(newValue, details);
                    setError({ ...error, itemName: undefined });
                  }}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  inputValue={searchItem.name}
                  value={currentItem.itemName}
                  error={error.itemName}
                  helperText={error.itemName}
                  label="Nama Item"
                  getOptionListLabel={(option) =>
                    option.code +
                    " - " +
                    option.name +
                    " (" +
                    option.bigStock +
                    ")"
                  }
                  requiredField
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Sub Kategori"
                  className={classes.inputField}
                  value={currentItem.category}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.itemGridLeft}>
                <CustomTextField
                  label="Jumlah Saat Ini"
                  className={classes.inputField}
                  value={currentItem.bigStock}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.itemGridRight}>
                <CustomTextField
                  label="Jumlah Yang Akan Dibeli"
                  type="number"
                  className={classes.inputField}
                  value={currentItem.buyTotal}
                  onChange={(event) => {
                    setCurrentItem({
                      ...currentItem,
                      buyTotal: event.target.value,
                    });
                    setError({ ...error, buyTotal: undefined });
                  }}
                  error={error.buyTotal}
                  helperText={error.buyTotal}
                  requiredField
                />
              </Grid>
              <Grid item xs={6} className={classes.itemGridLeft}>
                <CustomTextField
                  label="Harga Jual"
                  className={classes.inputField}
                  value={currentItem.sellPrice}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.itemGridRight}>
                <Grid container alignItems="flex-end">
                  <Grid item xs={4}>
                    <CustomSelect
                      value={currentItem.discountUnit}
                      onChange={(event) =>
                        setCurrentItem({
                          ...currentItem,
                          discountUnit: event.target.value,
                          discount: 0,
                        })
                      }
                      label="Diskon (Rp | %)"
                      className={classes.select}
                      requiredField
                    >
                      {units.map((data) => (
                        <MenuItem key={data.value} value={data.value}>
                          {data.label}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </Grid>
                  <Grid item xs={8} className={classes.inputWithSelect}>
                    <CustomTextField
                      className={classes.inputField}
                      value={currentItem.discount}
                      currency={currentItem.discountUnit === "FIXED"}
                      onChange={(event) => {
                        setCurrentItem({
                          ...currentItem,
                          discount:
                            currentItem.discountUnit === "FIXED"
                              ? event.target.value !== ""
                                ? event.target.value > currentItem.sellFee
                                  ? currentItem.sellFee
                                  : parseInt(event.target.value, 10)
                                : 0
                              : event.target.value > 100
                              ? 100
                              : event.target.value === ""
                              ? 0
                              : parseInt(event.target.value, 10),
                        });
                        setError({ ...error, discount: undefined });
                      }}
                      error={error.discount}
                      helperText={error.discount}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="flex-end">
                  <Button
                    disableElevation
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={resetItem}
                  >
                    Reset
                  </Button>
                  <Button
                    disableElevation
                    variant="contained"
                    size="large"
                    onClick={handleAddItem}
                  >
                    Tambah {">"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.headerLeft}>
                        Obat/BHP/Logistik
                      </TableCell>
                      <TableCell>Jumlah</TableCell>
                      <TableCell>Harga</TableCell>
                      <TableCell>Diskon</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell className={classes.headerRight}>
                        Total
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderDetailTable.map((row, index) => (
                      <TableRow key={row["_id"]}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          {formatting.numberFormat(row.qty) + " " + row.unit}
                        </TableCell>
                        <TableCell>
                          {"@" + formatting.currencyFormat(row.price)}
                        </TableCell>
                        <TableCell>
                          {row.discountUnit === "FIXED"
                            ? "@" + formatting.currencyFormat(row.discount)
                            : "@" + (row.discount + "%")}
                        </TableCell>
                        <TableCell>
                          {formatting.currencyFormat(row.total)}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="secondary"
                            className={classes.button}
                            onClick={() => deleteRow(index)}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={6} className={classes.itemGridLeft}>
                <CustomTextField
                  label="Sub Total"
                  className={classes.inputField}
                  value={formatting.currencyFormat(subTotal)}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.itemGridRight}>
                <CustomTextField
                  label="Total Diskon"
                  className={classes.inputField}
                  value={formatting.currencyFormat(totalDiscount)}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.itemGridLeft}>
                <Grid container alignItems="flex-end">
                  <Grid item xs={6}>
                    <CustomTextField
                      className={classes.inputField}
                      value={ppnFixed}
                      currency
                      onChange={(event) => {
                        const v = event.target.value;
                        setPpnPercent((v / subTotal) * 100);
                        setPpnFixed(v > subTotal ? subTotal : v);
                        setError({ ...error, ppn: undefined });
                      }}
                      error={error.ppn}
                      label="PPN Fix"
                      helperText={error.ppn}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.inputWithSelect}>
                    <CustomTextField
                      className={classes.inputField}
                      value={ppnPercent}
                      label="PPN Persen"
                      onChange={(event) => {
                        const v = event.target.value;
                        setPpnPercent(v > 100 ? 100 : v);
                        setPpnFixed(subTotal * (v / 100));
                        setError({ ...error, ppn: undefined });
                      }}
                      error={error.ppn}
                      helperText={error.ppn}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} className={classes.itemGridRight} />
              <Grid item xs={6} className={classes.itemGridLeft}>
                <CustomTextField
                  label="Total Seluruhnya"
                  className={`${classes.inputField} ${classes.grandTotal}`}
                  value={formatting.currencyFormat(grandTotal)}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="flex-end">
                  <Button
                    disableElevation
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={resetOrder}
                  >
                    Reset
                  </Button>
                  <Button
                    disableElevation
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={() => history.push("/request/new")}
                  >
                    Batal
                  </Button>
                  <Button
                    disableElevation
                    variant="contained"
                    size="large"
                    onClick={isSubmitting ? null : handleOrder}
                  >
                    Pesan
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddNewOrder;
