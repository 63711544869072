import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import MenuItem from "@material-ui/core/MenuItem";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { palette } from "../../theme";
import CustomPagination from "../custom/CustomPagination";
import CustomDropdownButton from "../custom/CustomDropdownButton";
import CustomSelect from "../custom/CustomSelect";
import { getStockCard } from "../../services/InventoryService";
import CustomRangeDatePicker from "../custom/CustomRangeDatePicker";

import debounce from "awesome-debounce-promise";
import get from "lodash.get";
import { useParams } from "react-router-dom";
import {
  paramDateFormat,
  dateFormat,
  typeFormat,
} from "../../utils/formatting";
import exportXL from "../../utils/exportXL";
import Print from "../../utils/Print";

import moment from "moment";
import useCekHakAkses from "../../utils/cekHakAkses";

const STATUS_SELECT = [
  { key: "Semuanya", label: "Semuanya" },
  { key: "request", label: "Request" },
  { key: "purchase", label: "Purchase" },
  { key: 'return', label: 'Return' },
];

const useStyles = makeStyles((theme) => ({
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  editButton: {
    float: "right",
    marginLeft: 5,
    color: palette.blue,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  topGrid: {
    display: "flex",
  },
  alignRight: {
    textAlign: "right",
  },
  marginContainer: {
    marginLeft: 20,
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debouncedGetStockCard = debounce(getStockCard, 500);

const KartuStock = () => {
  const classes = useStyles();
  const [searchForm, setSearchForm] = useState({
    startDate: moment().subtract(30, "days").toDate(),
    endDate: new Date(),
    type: ["Semuanya"],
  });
  const [pagination, setPagination] = useState(paginationInitialData);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);

  const cekRole = useCekHakAkses();

  const { itemID } = useParams();

  useEffect(() => {
    setIsFetchingData(true);
    debouncedGetStockCard(
      itemID,
      paramDateFormat(searchForm.startDate),
      paramDateFormat(searchForm.endDate),
      searchForm.type.find(a => a === "Semuanya") ? [] : searchForm.type,
      pagination.page,
      pagination.rowsPerPage,
    )
      .then((res) => {
        setTotalData(res.data.count);
        setTableData(res.data.items);
        setExportData([]);
      })
      .finally(() => setIsFetchingData(false));
  }, [searchForm, pagination, itemID]);

  const getDataExport = async () => {
    let page = 0;
    let skip = 0;
    const limit = 10;
    const fetch = async () => {
      const result = await getStockCard(
        itemID,
        paramDateFormat(searchForm.startDate),
        paramDateFormat(searchForm.endDate),
        searchForm.type.find(a => a === "Semuanya") ? [] : searchForm.type,
        page,
        limit,
      );
      exportData.splice(skip * limit, limit, ...result.data.items);
      setExportData(exportData);
    };
    if (exportData.length >= totalData) return;
    while (skip < totalData) {
      await fetch();
      page += 1;
      skip += limit;
    }
  };

  const handleExport = () => {
    setIsFetching(true);
    try {
      getDataExport().then(() => {
        const hasil = exportData.map((val) => ({
          Tanggal: dateFormat(val.createdDate),
          "Jenis Transaksi": (typeFormat(val.isEdit && val.type === 'request') ? 'Perubahan Delivery Order' : 
          (val.isEdit && val.type === 'purchase')  ?
          'Perubahan Purchase' : val.type),
          Kode: val.PurchaseData
            ? val.PurchaseData.code
            : val.RequestData
            ? val.RequestData.code
            : "-",
          "Supplier/Customer": val.PurchaseData
            ? val.PurchaseData.picRoSupplierName
            : val.RequestData
            ? val.RequestData.hospitalName
              ? val.RequestData.hospitalName
              : val.RequestData.picRoClientName
            : "-",
          Catatan: val.PurchaseData
            ? val.PurchaseData.notes
            : val.RequestData
            ? val.RequestData.notes
            : val.notes,
          Depot: val.depotName || "-",
          "Stok Sebelumnya": `${val.stockBefore} ${val.bigUnit}`,
          "Stok Perubahan": `${val.stockAfter < val.stockBefore ? "-" : ""} ${
            val.quantity
          } ${val.bigUnit}`,
          "Total Stok": `${val.stockAfter} ${val.bigUnit}`,
          "Penanggung Jawab": val.picName,
        }));
        exportXL("Export Kartu Stok", hasil);
        setIsFetching(false);
      });
    } catch (e) {
      console.error(e);
      setIsFetching(false);
    }
  };

  const handlePrint = () => {
    setIsFetching(true);
    try {
      getDataExport()
        .then(() => {
          setIsPrint(true);
          window.print();
          setIsFetching(false);
        })
        .finally(() => {
          setIsPrint(false);
          setExportData([]);
          setIsFetching(false);
        })
        .catch((e) => {
          console.error(e);
          setIsPrint(false);
          setIsFetching(false);
        });
    } catch (e) {
      console.error(e);
      setIsPrint(false);
      setIsFetching(false);
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid
          item
          sm={9}
          style={{ paddingTop: 10 }}
          className={classes.topGrid}
        >
          <div>
            <CustomRangeDatePicker
              disabled={isFetching}
              startDate={searchForm.startDate}
              endDate={searchForm.endDate}
              onChange={([start, end]) =>
                setSearchForm({ ...searchForm, startDate: start, endDate: end })
              }
              placeholder="Berdasarkan Tanggal"
            />
          </div>
          <div className={classes.marginContainer}>
            <CustomSelect
              value={searchForm.type}
              onChange={(event) =>
                setSearchForm({ ...searchForm, type: [event.target.value] })
              }
              className={classes.select}
            >
              {STATUS_SELECT.map((data) => (
                <MenuItem key={data.key} value={data.key}>
                  {data.label}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        </Grid>
        <Grid item sm={3} className={classes.alignRight}>
          <div>
            {isFetching ? (
              <CircularProgress />
            ) : (
              <CustomDropdownButton label="Export">
                {cekRole("inventory.f21") && (
                  <MenuItem value={1} onClick={handleExport} selected>
                    Export
                  </MenuItem>
                )}
                {cekRole("inventory.f22") && (
                  <MenuItem value={2} onClick={handlePrint}>
                    Print
                  </MenuItem>
                )}
              </CustomDropdownButton>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Print style={{ overflow: "auto" }}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerLeft}>Tanggal</TableCell>
                  <TableCell>Jenis Transaksi</TableCell>
                  <TableCell>Detail</TableCell>
                  <TableCell>Stok Sebelumnya</TableCell>
                  <TableCell>Stok Perubahan</TableCell>
                  <TableCell>Total Stok</TableCell>
                  <TableCell className={classes.headerRight}>
                    Penanggung Jawab
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetchingData ? (
                  <TableRow>
                    <TableCell colSpan={7} align={"center"}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : !isPrint && tableData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align={"center"}>
                      <p>Tidak ada data yang bisa ditampilkan.</p>
                    </TableCell>
                  </TableRow>
                ) : (
                  !isPrint &&
                  tableData.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell>{dateFormat(row.createdDate)}</TableCell>
                      <TableCell>{typeFormat((row.isEdit && row.type === 'request') ? 'Perubahan Delivery Order' : 
                      (row.isEdit && row.type === 'purchase')  ?
                      'Perubahan Purchase' : row.type)}</TableCell>
                      <TableCell>
                        {row.PurchaseData ? (
                          <div style={{ display: "grid" }}>
                            <span>
                              Kode PO : {row.PurchaseData.code || "-"}
                            </span>
                            <span>
                              Supplier :{" "}
                              {row.PurchaseData.picRoSupplierName || "-"}
                            </span>
                            <span>
                              Catatan : {row.PurchaseData.notes || "-"}
                            </span>
                            <span>Depot : {row.depotName || "-"}</span>
                          </div>
                        ) : row.RequestData ? (
                          <div style={{ display: "grid" }}>
                            <span>Kode RO : {row.RequestData.code || "-"}</span>
                            <span>
                              Customer :{" "}
                              {row.RequestData.hospitalName
                                ? row.RequestData.hospitalName
                                : row.RequestData.picRoClientName
                                ? row.RequestData.picRoClientName
                                : "-"}
                            </span>
                            <span>
                              Catatan : {row.RequestData.notes || "-"}
                            </span>
                            <span>Depot : {row.depotName || "-"}</span>
                          </div>
                        ) : (
                          <div style={{ display: "grid" }}>
                            <span>Catatan : {row.notes || "-"}</span>
                            <span>Depot : {row.depotName || "-"}</span>
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        {row.stockBefore + " " + row.bigUnit}
                      </TableCell>
                      <TableCell>
                        {row.stockAfter < row.stockBefore
                          ? "-" + row.quantity + " " + row.bigUnit
                          : row.quantity + " " + row.bigUnit}
                      </TableCell>
                      <TableCell>
                        {row.stockAfter + " " + row.bigUnit}
                      </TableCell>
                      <TableCell>
                        {get(row, "ReturnRequestData.status") === "received"
                          ? row.ReturnRequestData.receivedName
                          : row.picName}
                      </TableCell>
                    </TableRow>
                  ))
                )}
                {isPrint &&
                  exportData.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell>{dateFormat(row.createdDate)}</TableCell>
                      <TableCell>{typeFormat((row.isEdit && row.type === 'request') ? 'Perubahan Delivery Order' : 
                      (row.isEdit && row.type === 'purchase')  ?
                      'Perubahan Purchase' : row.type)}</TableCell>
                      <TableCell>
                        {row.PurchaseData ? (
                          <div style={{ display: "grid" }}>
                            <span>
                              Kode PO : {row.PurchaseData.code || "-"}
                            </span>
                            <span>
                              Supplier :{" "}
                              {row.PurchaseData.picRoSupplierName || "-"}
                            </span>
                            <span>
                              Catatan : {row.PurchaseData.notes || "-"}
                            </span>
                            <span>Depot : {row.depotName || "-"}</span>
                          </div>
                        ) : row.RequestData ? (
                          <div style={{ display: "grid" }}>
                            <span>Kode RO : {row.RequestData.code || "-"}</span>
                            <span>
                              Customer :{" "}
                              {row.RequestData.hospitalName
                                ? row.RequestData.hospitalName
                                : row.RequestData.picRoClientName
                                ? row.RequestData.picRoClientName
                                : "-"}
                            </span>
                            <span>
                              Catatan : {row.RequestData.notes || "-"}
                            </span>
                            <span>Depot : {row.depotName || "-"}</span>
                          </div>
                        ) : (
                          <div style={{ display: "grid" }}>
                            <span>Catatan : {row.notes || "-"}</span>
                            <span>Depot : {row.depotName || "-"}</span>
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        {row.stockBefore + " " + row.bigUnit}
                      </TableCell>
                      <TableCell>{row.quantity + " " + row.bigUnit}</TableCell>
                      <TableCell>
                        {row.stockAfter + " " + row.bigUnit}
                      </TableCell>
                      <TableCell>{row.picName}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Print>
          <CustomPagination
            page={pagination.page}
            onChangePage={(event, newPage) =>
              setPagination({ ...pagination, page: newPage })
            }
            rowsPerPage={pagination.rowsPerPage}
            onChangeRowsPerPage={(event) =>
              setPagination({
                ...pagination,
                rowsPerPage: parseInt(event.target.value, 10),
              })
            }
            labelDisplayedRows={({ from, to, count }) =>
              from + "-" + to + " dari " + count + " Data"
            }
            count={totalData}
          />
        </Grid>
      </Grid>
    </div>
  );
};

KartuStock.propTypes = {};

export default KartuStock;
