import React, { useEffect } from "react";

import {
  makeStyles,
  Grid,
  Button,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup
} from "@material-ui/core";

import validations from "../../utils/validations";
import CustomTextField from "../custom/CustomTextField";

import { palette } from "../../theme";

import CustomSelect from "../custom/CustomSelect";

const useStyles = makeStyles((theme) => ({
  editGrid: {
    display: "flex",
  },
  marginContainerLeft: {
    marginLeft: 20,
  },
  buttonGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  detailGrid: {
    marginTop: 20,
  },
  unitTextField: {
    width: 240,
  },
  equal: {
    width: 40,
    marginLeft: 8,
  },
  equal2: {
    width: 40,
    marginLeft: 8,
    padding: 0,
    fontSize: 48,
  },
  grey: {
    color: palette.disabledTextfield,
  },
  normalTextField: {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.normalTextfield,
    },
  },
}));

const tipeSelect = ["BHP", "Obat", "Logistik"];

const subCategorySelect = [
  "Aksesoris",
  "Clinpro",
  "General",
  "Member",
  "Rontgen",
];

const FormInventory = ({
  data: { sellFee, hnaPPN, ...data },
  setData,
  ...props
}) => {
  const classes = useStyles();

  const handleChange = (name) => (event) => {
    setData((d) => ({ ...d, [name]: event.target.checked }));
  };

  const handleTambahBarang = (name) => (event) => {
      const { value } = event.target;
      setData((d) => ({ ...d, [name]: value }));
  };

  useEffect(() => {
    if (sellFee && hnaPPN) {
      setData((d) => ({
        ...d,
        marginRp: sellFee - hnaPPN,
        marginPercent: ((sellFee - hnaPPN) / hnaPPN) * 100,
      }));
    }
  }, [sellFee, hnaPPN, setData]);

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.detailGrid}
      >
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            {/* <CustomTextField label="Kode" value={props.code} disabled /> */}
            <CustomTextField
              label="Kode Barang"
              value={data.code}
              onChange={handleTambahBarang("code")}
              requiredField
              error={props.error.code && validations.isEmpty(data.code)}
              helperText={
                props.error.code && validations.isEmpty(data.code)
                  ? "Kode Barang tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Nama Barang"
              value={data.name}
              onChange={handleTambahBarang("name")}
              requiredField
              error={props.error.name && validations.isEmpty(data.name)}
              helperText={
                props.error.name && validations.isEmpty(data.name)
                  ? "Nama Barang tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomSelect
              style={{ marginRight: 20 }}
              value={data.type}
              label="Tipe"
              onChange={handleTambahBarang("type")}
              requiredField
              error={props.error.type && validations.isEmpty(data.type)}
              helperText={
                props.error.type && validations.isEmpty(data.type)
                  ? "Tipe tidak boleh kosong"
                  : " "
              }
            >
              {tipeSelect.map((tipe) => (
                <MenuItem key={tipe} value={tipe}>
                  {tipe}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            <CustomSelect
              value={data.category}
              requiredField
              label="Kategori"
              onChange={handleTambahBarang("category")}
              error={props.error.category && validations.isEmpty(data.category)}
              helperText={
                props.error.category && validations.isEmpty(data.category)
                  ? "Kategori tidak boleh kosong"
                  : " "
              }
            >
              {props.categories?.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomSelect
              value={data.subCategory}
              requiredField
              label="Sub - Category"
              onChange={handleTambahBarang("subCategory")}
              error={props.error.subCategory && validations.isEmpty(data.subCategory)}
              helperText={
                props.error.subCategory && validations.isEmpty(data.subCategory)
                  ? "Sub - Category tidak boleh kosong"
                  : " "
              }
            >
              {subCategorySelect.map((subCategory) => (
                <MenuItem key={subCategory} value={subCategory}>
                  {subCategory}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
          {props.fromImport ? (
            <div className={classes.marginContainerLeft}>
              <CustomSelect
                style={{ marginRight: 20 }}
                value={data.rack}
                label="Rak"
                onChange={handleTambahBarang("rack")}
                error={props.error.rack && validations.isEmpty(data.rack)}
                helperText={
                  props.error.rack && validations.isEmpty(data.rack)
                    ? "Rak tidak boleh kosong"
                    : " "
                }
                requiredField
              >
                {props.racks.map((racks) => (
                  <MenuItem key={racks} value={racks}>
                    {racks}
                  </MenuItem>
                ))}
              </CustomSelect>
            </div>
          ) : undefined}
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            <InputLabel required>{"Unit Besar"}</InputLabel>
            <OutlinedInput
              value={data.bigUnit}
              onChange={handleTambahBarang("bigUnit")}
              error={props.error.bigUnit && validations.isEmpty(data.bigUnit)}
              className={classes.unitTextField}
            />
            <FormHelperText
              error={props.error.bigUnit && validations.isEmpty(data.bigUnit)}
              style={{ paddingLeft: 0 }}
            >
              {props.error.bigUnit && validations.isEmpty(data.bigUnit)
                ? "Unit Besar tidak boleh kosong"
                : " "}
            </FormHelperText>
          </div>
          <div>
            <InputLabel style={{ height: 12 }}></InputLabel>
            <OutlinedInput
              value="="
              disabled
              className={classes.equal}
              classes={{
                input: classes.equal2,
              }}
            />
            <FormHelperText> </FormHelperText>
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Presisi Unit"
              requiredField
              value={data.unitPrecision}
              type="number"
              onChange={handleTambahBarang("unitPrecision")}
              error={
                props.error.unitPrecision &&
                validations.isEmpty(data.unitPrecision)
              }
              helperText={
                props.error.unitPrecision &&
                validations.isEmpty(data.unitPrecision)
                  ? "Presisi Unit tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Unit Kecil"
              requiredField
              value={data.smallUnit}
              onChange={handleTambahBarang("smallUnit")}
              error={
                props.error.smallUnit && validations.isEmpty(data.smallUnit)
              }
              helperText={
                props.error.smallUnit && validations.isEmpty(data.smallUnit)
                  ? "Unit Kecil tidak boleh kosong"
                  : " "
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            <CustomTextField
              label="Jumlah Stok Satuan Besar"
              requiredField
              value={data.stock}
              onChange={handleTambahBarang("stock")}
              endIcon={data.bigUnit}
              error={props.error.stock && validations.isEmpty(data.stock)}
              helperText={
                props.error.stock && validations.isEmpty(data.stock)
                  ? "Jumlah tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Jumlah Stok Satuan Kecil"
              requiredField
              value={data.stock * data.unitPrecision}
              disabled
              endIcon={data.smallUnit}
              className={classes.normalTextField}
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            <CustomTextField
              label="Minimum Stok"
              value={data.stockWarning}
              requiredField
              onChange={handleTambahBarang("stockWarning")}
              error={
                props.error.stockWarning &&
                validations.isEmpty(data.stockWarning)
              }
              helperText={
                props.error.stockWarning &&
                validations.isEmpty(data.stockWarning)
                  ? "Minimum Stock tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Buffer Stok"
              value={data.stockBuffer}
              requiredField
              onChange={handleTambahBarang("stockBuffer")}
              error={
                props.error.stockBuffer && validations.isEmpty(data.stockBuffer)
              }
              helperText={
                props.error.stockBuffer && validations.isEmpty(data.stockBuffer)
                  ? "Stock Buffer tidak boleh kosong"
                  : " "
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            <CustomTextField
              label="Harga Dasar"
              requiredField
              value={data.basicFee}
              onChange={handleTambahBarang("basicFee")}
              currency
              error={props.error.basicFee && validations.isEmpty(data.basicFee)}
              helperText={
                props.error.basicFee && validations.isEmpty(data.basicFee)
                  ? "Harga dasar tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Harga Beli"
              requiredField
              value={data.buyFee}
              onChange={handleTambahBarang("buyFee")}
              currency
              error={props.error.buyFee && validations.isEmpty(data.buyFee)}
              helperText={
                props.error.buyFee && validations.isEmpty(data.buyFee)
                  ? "Harga beli tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomSelect
              value={data.isActive}
              label="Status Aktif"
              onChange={handleTambahBarang("isActive")}
            >
              <MenuItem value={true}>Aktif</MenuItem>
              <MenuItem value={false}>Tidak Aktif</MenuItem>
            </CustomSelect>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            <CustomTextField
              label="HNA"
              requiredField
              value={data.hna}
              onChange={handleTambahBarang("hna")}
              currency
              error={props.error.hna && validations.isEmpty(data.hna)}
              helperText={
                props.error.hna && validations.isEmpty(data.hna)
                  ? "HNA tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="HNA + PPN"
              requiredField
              value={hnaPPN}
              onChange={handleTambahBarang("hnaPPN")}
              currency
              error={props.error.hnaPPN && validations.isEmpty(data.hnaPPN)}
              helperText={
                props.error.hnaPPN && validations.isEmpty(data.hnaPPN)
                  ? "HNA + PPN tidak boleh kosong"
                  : " "
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            <CustomTextField
              label="Harga Jual"
              requiredField
              value={sellFee}
              onChange={handleTambahBarang("sellFee")}
              currency
              error={props.error.sellFee && validations.isEmpty(data.sellFee)}
              helperText={
                props.error.sellFee && validations.isEmpty(data.sellFee)
                  ? "Harga Jual tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Margin Rp"
              requiredField
              value={data.marginRp}
              onChange={handleTambahBarang("marginRp")}
              currency
              error={props.error.marginRp && validations.isEmpty(data.marginRp)}
              helperText={
                props.error.marginRp && validations.isEmpty(data.marginRp)
                  ? "Margin Rp tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Margin %"
              requiredField
              value={data.marginPercent}
              onChange={handleTambahBarang("marginPercent")}
              error={props.error.marginPercent && validations.isEmpty(data.marginPercent)}
              helperText={
                props.error.marginPercent && validations.isEmpty(data.marginPercent)
                  ? "Margin % tidak boleh kosong"
                  : " "
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
        <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  value={data.isClinicAdd}
                  checked={data.isClinicAdd}
                  onChange={handleChange("isClinicAdd")}
                  name="isClinicAdd"
                />
              }
              label="Connect To Clinic"
            />
          </FormGroup>
        </Grid>
        {props.fromImport ? (
          <Grid item xs={12} className={classes.editGrid}>
            <Typography variant="body2">
              -(<p style={{ color: "red", display: "inline" }}>*</p>) Data tidak
              boleh kosong
            </Typography>
          </Grid>
        ) : undefined}

        <Grid item xs={12} className={classes.buttonGrid}>
          <div>
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={props.handleCancel}
            >
              {"Batalkan"}
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              onClick={props.handleAddItems}
            >
              {"Simpan"}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default FormInventory;
