import { SET_KODE } from "./action-types";

const initialState = {
  kode: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_KODE:
      return {
        ...state,
        kode: action.payload.kode,
      };

    default:
      return state;
  }
}
