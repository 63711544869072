import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { palette } from "../../theme";
import { Table, TableBody, TableCell, TableRow, TableHead } from '@material-ui/core';
import formatting from "../../utils/formatting";
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from "moment";

const CustomTableCell = withStyles({
  head: {
    backgroundColor: '#f2f2f2',
    fontSize: 14,
    fontclassName: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.28,
    letterSpacing: 'normal',
    textAlign: 'left',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: 0,
    paddingLeft: 3,
    paddingRight: 0,
    paddingTop: 0,
    // width: 150,
  },
  body: {
    fontSize: 14,
    fontclassName: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.28,
    letterSpacing: 'normal',
    textAlign: 'left',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: 0,
    paddingLeft: 3,
    paddingRight: 0,
    paddingTop: 0,
    // width: 150,
  },
})(TableCell);

const useStyle = makeStyles({
  page: {
    padding: 20,
  },
  title: {
    fontSize: 17,
    fontWeight: "bold",
    fontFamily: "Roboto",
    marginBottom: 20,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    backgroundColor: palette.whiteBlue,
    marginBottom: 10,
  },
  header: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 5,
  },
  headerTextLabel: {
    fontFamily: "Roboto",
    fontSize: 10,
    flexDirection: "column",
    color: palette.secondaryText,
    width: 50,
    marginBottom: 5,
  },
  headerTextValue: {
    color: palette.black,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 10,
    flexDirection: "column",
    marginBottom: 5,
    marginRight: 0,
  },
  headerLeftWidth: {
    width: 150,
  },
  tableHeaderText: {
    backgroundColor: palette.disabledTextfield,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 10,
    paddingTop: 15,
    paddingBottom: 15,
    borderColor: palette.disabledTextfield,
    paddingLeft: 5,
  },
  tableHeaderLeft: {
    borderTopLeftRadius: 10,
  },
  tableHeaderRight: {
    borderTopRightRadius: 10,
  },
  tableDataText: {
    fontFamily: "Roboto",
    fontSize: 8,
    paddingTop: 5,
    paddingBottom: 5,
    borderColor: palette.disabledTextfield,
    borderTopColor: palette.disabledTextfield,
    paddingLeft: 5,
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    marginTop: 20,
    marginLeft: 400,
  },
  footerSignatureName: {
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    color: palette.black,
    width: 100,
    marginBottom: 40,
    textAlign: "center",
    marginRight: 130,
  },
  footerSignature: {
    color: palette.black,
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    width: 100,
    marginRight: 130,
  },
  blackFill: {
    backgroundColor: palette.black,
  },
});

const InvoiceDocument = (props) => {
  console.log(props);
  const classes = useStyle();
  moment.locale("id");
  return (
    <div>
        <div>
          <div className={classes.title}>Sales Invoice {props.data?.codeRo}</div>
        </div>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <div className={classes.headerTextLabel}> Waktu</div>
            <div className={classes.headerTextLabel}> Customer</div>
            <div className={classes.headerTextLabel}> Alamat</div>
            <div className={classes.headerTextLabel}> Telephone</div>
          </div>
          <div className={classes.header}>
            <div className={classes.headerTextValue}>
              {moment(props.data.createdDate).format("dddd, DD MMMM YYYY")}
            </div>
            <div className={classes.headerTextValue}>
              {props.data?.Customers?.name}
            </div>
            <div className={classes.headerTextValue}>
              {props.data?.Customers?.address}
            </div>
            <div className={classes.headerTextValue}>
              {props.data?.Customers?.hp}
            </div>
          </div>
        </div>

        <Table data={props.items}>
          <TableHead>
            <CustomTableCell
              className={[classes.tableHeaderText, classes.tableHeaderLeft]}
              weighting={0.05}
            >
              No
            </CustomTableCell>
            <CustomTableCell className={classes.tableHeaderText} weighting={0.2}>
              Kode Item
            </CustomTableCell>
            <CustomTableCell className={classes.tableHeaderText} weighting={0.25}>
              Nama Item
            </CustomTableCell>
            <CustomTableCell className={classes.tableHeaderText} weighting={0.1}>
              Qty Ship
            </CustomTableCell>
            <CustomTableCell className={classes.tableHeaderText} weighting={0.1}>
              Unit Price
            </CustomTableCell>
            <CustomTableCell className={classes.tableHeaderText} weighting={0.1}>
              Disc
            </CustomTableCell>
            <CustomTableCell
              className={[classes.tableHeaderText, classes.tableHeaderRight]}
              weighting={0.2}
            >
              Amount
            </CustomTableCell>
          </TableHead>
          <TableBody>
            {props.items.map((d) => (
            <TableRow>
            <CustomTableCell className={classes.tableDataText} weighting={0.05}>{d.no}</CustomTableCell>
            <CustomTableCell className={classes.tableDataText} weighting={0.2}>{d.code}</CustomTableCell>
            <CustomTableCell className={classes.tableDataText} weighting={0.25}>{d.name}</CustomTableCell>
            <CustomTableCell className={classes.tableDataText} weighting={0.1}>{d.quantity}</CustomTableCell>
            <CustomTableCell className={classes.tableDataText} weighting={0.1}>{formatting.currencyFormat(d.baseFee)}</CustomTableCell>
            <CustomTableCell className={classes.tableDataText} weighting={0.1}>{formatting.currencyFormat(d.discountRp)}</CustomTableCell>
            <CustomTableCell className={classes.tableDataText} weighting={0.2}>{formatting.currencyFormat(d.baseFee * d.quantity)}</CustomTableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.footerContainer}>
          <div className={classes.header}>
            <div className={classes.headerTextLabel}> Amount</div>
            <div className={classes.headerTextLabel}> Disc</div>
            <div className={classes.headerTextLabel}> DPP</div>
            <div className={classes.headerTextLabel}> PPn</div>
            <div className={classes.headerTextLabel}> Total</div>
          </div>
          <div className={classes.header}>
            <div className={classes.headerTextValue}>
              {formatting.currencyFormat(props.data?.subTotalFee + props.data?.discountRp)}
            </div>
            <div className={classes.headerTextValue}>
              {formatting.currencyFormat(props.data?.discountRp)}
            </div>
            <div className={classes.headerTextValue}>
              {formatting.currencyFormat(props.data?.totalFee - props.data?.ppnRp)}
            </div>
            <div className={classes.headerTextValue}>
              {formatting.currencyFormat(props.data?.ppnRp)}
            </div>
            <div className={classes.headerTextValue}>
              {formatting.currencyFormat(props.data?.totalFee)}
            </div>
          </div>
        </div>
    </div>
  );
};

export default InvoiceDocument;
