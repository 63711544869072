import { getPlaceId } from "../store/auth/selector";
import agent from "./agent";

export const getListBilling = (search, dateStart, dateEnd, skip, limit) => {
  return agent.get("/transactions/getListBilling", {
    params: {
      filter: { placeId: getPlaceId(), search, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};

export const getDetailBilling = (id) => {
  return agent.get(`/transactions/${id}/getDetailBilling`);
};

export const payBilling = (id, metode, alat, nominal, dibayarOleh) => {
  return agent.put(`/transactions/${id}/payBilling`, {
    paymentMethod: metode,
    paymentTools: alat,
    paymentNominal: nominal,
    paymentBy: dibayarOleh,
  });
};

export const changeInvoice = (id) => {
  return agent.get(`/transactions/${id}/changeInvoice`, { params: { id } });
};
