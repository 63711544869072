import React from "react";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import omit from "lodash.omit";
import NumberFormat from "react-number-format";

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      isNumericString
      prefix="Rp. "
      decimalSeparator=","
      decimalScale={2}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CustomTextField = React.forwardRef(({ allowNegative, currency, fullWidth, ...props }, ref) => {
  const inputProps = omit(props, [
    "label",
    "helperText",
    "startIcon",
    "endIcon",
    "requiredField",
  ]);

  return (
    <div className={props.parentClass}>
      <InputLabel required={props.requiredField}>{props.label}</InputLabel>
      <OutlinedInput
        {...inputProps}
        startAdornment={props.startIcon}
        endAdornment={props.endIcon}
        inputComponent={currency ? NumberFormatCustom : undefined}
        style={fullWidth ? { width: "100%" } : {}}
        inputProps={currency ? { allowNegative } : {}}
        ref={ref}
      />
      <FormHelperText error={props.error} style={{ paddingLeft: 0 }}>
        {props.helperText}
      </FormHelperText>
    </div>
  );
});

CustomTextField.defaultProps = {
  helperText: " ",
  label: "",
  startIcon: null,
  endIcon: null,
  currency: false,
  allowNegative: false,
};

CustomTextField.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  currency: PropTypes.bool,
  allowNegative: PropTypes.bool,
};

export default CustomTextField;
