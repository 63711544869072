import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import omit from "lodash.omit";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    width: "inherit !important",
    marginLeft: 8,
  },
}));

const CustomSelect = ({ fullWidth, ...props }) => {
  const classes = useStyles();
  const selectProps = omit(props, [
    "label",
    "helperText",
    "startIcon",
    "endIcon",
    "children",
  ]);

  return (
    <div>
      <InputLabel required={props.requiredField}>{props.label}</InputLabel>
      <Select
        {...selectProps}
        variant="outlined"
        startAdornment={props.startIcon}
        style={fullWidth ? { width: "100%" } : {}}
        endAdornment={props.endIcon}
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          getContentAnchorEl: null,
          classes: {
            paper: classes.menuPaper,
          },
        }}
        defaultValue=""
      >
        {props.children}
      </Select>
      <FormHelperText error={props.error} style={{ paddingLeft: 0 }}>
        {props.helperText}
      </FormHelperText>
    </div>
  );
};

CustomSelect.defaultProps = {
  helperText: " ",
  label: "",
  startIcon: null,
  endIcon: null,
};

CustomSelect.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  children: PropTypes.any,
};

export default CustomSelect;
