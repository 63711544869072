import React from "react";
import { palette } from "../../theme";
import formatting from "../../utils/formatting";
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from "moment";

const useStyle = makeStyles({
  page: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    height: 500,
    margin: 0,
  },
  kwitansi: {
    display: "flex",
    flexDirection: "row",
    marginTop: 40,
    marginLeft: 0,
  },
  title: {
    fontSize: 17,
    fontWeight: "bold",
    fontFamily: "Roboto",
    marginBottom: 20,
    textAlign: "center",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: palette.whiteBlue,
    border: "1px solid black",
    padding: 20,
    width: 275,
    marginRight: 2,
  },
  leftValue: {
    color: palette.black,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 14,
    marginBottom: 10,
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: palette.whiteBlue,
    padding: 20,
    width: 500,
    marginLeft: 20,
  },
  top: {
    color: palette.black,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 14,
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
  },
  topValue: {
    color: palette.black,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 14,
    marginLeft: 145,
  },
  mid: {
    marginBottom: 10,
  },
  midValue: {
    color: palette.black,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 14,
    marginBottom: 10,
  },
  bottom: {
    display: "flex",
    flexDirection: "row",
  },
  bottomValue: {
    color: palette.black,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 10,
    marginBottom: 10,
    marginLeft: 20,
    width: 150,
    height: 50,
    paddingTop: 35,
    paddingLeft: 5,
  },
  currencyValue: {
    color: palette.black,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 14,
    marginBottom: 10,
    width: 150,
    height: 50,
    paddingTop: 15,
    paddingLeft: 5,
    backgroundColor: "lightgrey",
  },
});

const BillingKwitansi = (props) => {
  const classes = useStyle();
  moment.locale("id");
  return (
    <div>
        <div>
          <div className={classes.title}>Kwitansi Pembayaran</div>
        </div>
        <div className={classes.kwitansi}>
          <div className={classes.leftContainer}>
            <div>
              <div className={classes.leftValue}>No : {props.data?.codePO}</div>
              <div className={classes.leftValue}>
                Tanggal : {moment().format("dddd, DD MMMM YYYY")}
              </div>
              <div className={classes.leftValue}>Terima Dari :</div>
              <div className={classes.leftValue}>
                Jumlah : {formatting.currencyFormat(props.data?.totalFee)}
              </div>
              <div className={classes.leftValue}>Untuk Pembayaran :</div>
              <div className={classes.leftValue}>
                Pembayaran {props.data?.codePO}
              </div>
            </div>
          </div>
          <div className={classes.rightContainer}>
            <div className={classes.top}>
              <div>No : {props.data?.codePO}</div>
              <div className={classes.topValue}>
                Tanggal : {moment().format("dddd, DD MMMM YYYY")}
              </div>
            </div>
            <div className={classes.mid}>
              <div className={classes.midValue}>Terima Dari :</div>
              <div className={classes.midValue}>
                Untuk Pembayaran : Purchase Order {props.data?.codePO}
              </div>
            </div>
            <div className={classes.bottom}>
              <div className={classes.currencyValue}>
                {formatting.currencyFormat(props.data?.totalFee)}
              </div>
              <div className={classes.bottomValue}>Tanda Tangan Penerima</div>
              <div className={classes.bottomValue}>Tanda Tangan Penyetor</div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default BillingKwitansi;
