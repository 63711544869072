import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CustomTextField from "../custom/CustomTextField";
import SearchIcon from "@material-ui/icons/Search";
// import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import routesConst from "../../constants/routes";
import routes from "../../constants/routes";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import CustomPagination from "../custom/CustomPagination";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import SyncOutlinedIcon from "@material-ui/icons/SyncOutlined";
import { getListAccount } from "../../services/AccountService";
import debounce from "awesome-debounce-promise";
import CircularProgress from "@material-ui/core/CircularProgress";
import cekHakAkses from "../../utils/cekHakAkses";
import CustomDropdownButton from "../custom/CustomDropdownButton";
import { MenuItem } from "@material-ui/core";
import moment from "moment";
import exportXL from "../../utils/exportXL";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  headerTitle: {
    color: theme.palette.blue,
  },
  searchBarGrid: {
    height: 100,
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debounceGetListAccount = debounce(getListAccount, 500);

const StaffManagement = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = cekHakAkses();

  const [searchStaff, setSearchStaff] = useState();
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState(paginationInitialData);
  const [totalData, setTotalData] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [buttonMenuSelect] = useState();
  const [exportData, setExportData] = useState([]);

  useEffect(() => {
    setIsFetching(true);
    debounceGetListAccount(
      searchStaff,
      pagination.page,
      pagination.rowsPerPage
    ).then((res) => {
      setTableData(res.data.items);
      setTotalData(res.data.count);
      setExportData([]);
    }).finally(() => setIsFetching(false));
  }, [pagination, searchStaff]);

  const getDataExport = async () => {
    let page = 0;
    let skip = 0;
    const limit = 5000;
    const fetch = async () => {
      const result = await getListAccount(searchStaff, page, limit);
      exportData.splice(skip * limit, limit, ...result.data.items);
      setExportData(exportData);
    };
    if (exportData.length >= totalData) return;
    while (skip < totalData) {
      await fetch();
      page += 1;
      skip += limit;
    }
  }

  const handleExport = () => {
    try {
      getDataExport().then(() => {
        const hasil = exportData.map((val => ({
          "Nama": val?.name ?? '-',
          "Status": val?.isActive ? "Aktif" : "Non Aktif" ?? '-',
          "Tipe Akses": val?.RoleActions?.name ?? '-',
          "Nomor Hp": val?.Confidentials?.hp ?? '-',
          "Login Email": val?.Confidentials?.email ?? '-',
        })))
        exportXL(`Export Staff (diekspor pada ${moment(new Date()).format('DD-MM-YYYY, HH:mm')})`, hasil)
      })
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid item xs={6} style={{ paddingTop: 10 }}>
          <CustomTextField
            label="Cari Staff"
            placeholder="Nama Staff"
            value={searchStaff}
            onChange={(event) => setSearchStaff(event.target.value)}
            startIcon={<SearchIcon />}
          />
        </Grid>
        <Grid container item xs={6} alignItems="center" justify="flex-end">
          {/* {cekRole('setting.i4') && <Button
              disableElevation
              variant="contained"
              size="large"
              className={classes.button}
              onClick={() => history.push(routesConst.ADD_NEW_STAFF)}
          >
            Tambah Staff
          </Button>} */}
          {(cekRole('setting.i4') || cekRole("setting.i40")) && (
            <CustomDropdownButton
              label={
                cekRole("setting.i4") ? "Tambah Staff" :
                cekRole("setting.i40") ? "Export" : ""}
                disableElevation
                variant="contained"
                size="large"
                side
                onClick={
                  cekRole("setting.i4")
                  ? () => history.push(routesConst.ADD_NEW_STAFF)
                  :
                  cekRole("setting.i40")
                  ? handleExport
                  : ""
                }
            >
              {cekRole("setting.i4") && (
                <MenuItem
                  key="Tambah Staff"
                  value={1}
                  onClick={() => history.push(routesConst.ADD_NEW_STAFF)}
                  selected={1 === buttonMenuSelect}
                >
                  Tambah Staff
                </MenuItem>
              )}
              {cekRole("setting.i40") && (
                <MenuItem
                  key="Export"
                  value={2}
                  onClick={handleExport}
                  selected={2 === buttonMenuSelect}
                >
                  Export
                </MenuItem>
              )}
            </CustomDropdownButton>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerLeft}>Nama</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Tipe Akses</TableCell>
              <TableCell>Nomor Hp</TableCell>
              <TableCell>Login Email</TableCell>
              <TableCell className={classes.headerRight} />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              isFetching
                ? <TableRow>
                  <TableCell colSpan={6} align={'center'}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
                : tableData.length === 0
                ? <TableRow>
                  <TableCell colSpan={6} align={'center'}>
                    <p>Tidak ada data yang bisa ditampilkan.</p>
                  </TableCell>
                </TableRow>
                : tableData.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.isActive ? "Aktif" : "Non Aktif"}</TableCell>
                    <TableCell>{row.RoleActions?.name}</TableCell>
                    <TableCell>{row.Confidentials?.hp}</TableCell>
                    <TableCell>{row.Confidentials?.email}</TableCell>
                    <TableCell>
                      {cekRole('setting.i6') && 
                      <Tooltip title="Sinkronisasi" placement="center" id="warehouse-content">
                      <IconButton color="primary" className={classes.button}>
                        <SyncOutlinedIcon/>
                      </IconButton>
                      </Tooltip>}
                      {cekRole('setting.i5') && 
                      <Tooltip title="Edit Staff" placement="center" id="warehouse-content">
                      <IconButton
                          color="primary"
                          className={classes.button}
                          onClick={() =>
                              history.push(routes.EDIT_STAFF + `/${row.id}`)
                          }
                      >
                        <CreateOutlinedIcon/>
                      </IconButton>
                      </Tooltip>}
                    </TableCell>
                  </TableRow>
                ))
            }
          </TableBody>
        </Table>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
    </div>
  );
};

export default StaffManagement;
