import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import routes, { adjustmentStokRoutes } from "../../constants/routes";
import useCekHakAkses from "../../utils/cekHakAkses";

import AdjustmentStokLayout from "./AdjustmentStokLayout";
import AdjustmentStok from "./AdjustmentStok";
import HistoryRepair from "./HistoryRepair";
import TambahDepot from "./TambahDepot";
import RepairStok from "./RepairStok";
import EditStok from "./EditStok";

const getRoute = (route) => {
  return routes.ADJUSTMENT + route;
};

const AdjustmentStokRoute = () => {
  const cekRole = useCekHakAkses();

  return (
    <Switch>
      <Route path={getRoute(adjustmentStokRoutes.ADJUSTMENT_STOK)} exact>
        <AdjustmentStokLayout>
          <AdjustmentStok />
        </AdjustmentStokLayout>
      </Route>
      <Route path={getRoute(adjustmentStokRoutes.HISTORY_REPAIR)} exact>
        <AdjustmentStokLayout>
          <HistoryRepair />
        </AdjustmentStokLayout>
      </Route>
      <Route path={getRoute(adjustmentStokRoutes.REPAIR_STOK + "/:id")} exact>
        <AdjustmentStokLayout>
          <RepairStok />
        </AdjustmentStokLayout>
      </Route>
      <Route
        path={getRoute(
          adjustmentStokRoutes.REPAIR_STOK + "/:id/:itemId/:stockDepotId"
        )}
        exact
      >
        <AdjustmentStokLayout>
          <EditStok />
        </AdjustmentStokLayout>
      </Route>
      <Route path={getRoute(adjustmentStokRoutes.TAMBAH_DEPOT)} exact>
        <TambahDepot />
      </Route>

      <Redirect
        to={getRoute(
          cekRole("adjustmentStock.g2")
            ? adjustmentStokRoutes.ADJUSTMENT_STOK
            : adjustmentStokRoutes.HISTORY_REPAIR
        )}
      />
    </Switch>
  );
};

export default AdjustmentStokRoute;
