import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import routes, { inventoryRoutes } from "../../constants/routes";
import { useHistory, useLocation } from "react-router-dom";
import useCekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
}));

const tabs = [
  { label: "Data Barang", path: inventoryRoutes.DATA_BARANG },
  { label: "Data Barang Details", path: inventoryRoutes.DATA_BARANG_DETAILS },
  { label: "Warning Kadaluarsa", path: inventoryRoutes.WARNING_KADALUARSA },
  { label: "Warning Stok", path: inventoryRoutes.WARNING_STOK },
  { label: "Barang Per-Supplier", path: inventoryRoutes.BARANG_PER_SUPPLIER },
  { label: "Barang Rusak", path: inventoryRoutes.BARANG_RUSAK },
  { label: "Barang Rusak Detail", path: inventoryRoutes.BARANG_RUSAK_DETAIL },
];

const Inventory = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [tab, setTab] = React.useState(0);
  const cekRole = useCekHakAkses();

  const filteredTabs = tabs.filter(tab => {
    switch(tab.label) {
      case 'Data Barang':
        return cekRole('inventory.f2');
      case 'Data Barang Details':
        return cekRole('inventory.f23');
      case 'Warning Kadaluarsa':
        return cekRole('inventory.f37');
      case 'Warning Stok':
        return cekRole('inventory.f42');
      case 'Barang Per-Supplier':
        return cekRole('inventory.f47');
      case 'Barang Rusak':
        return cekRole('inventory.f52');
      case 'Barang Rusak Detail':
        return cekRole('inventory.f55');
      default:
        return true;
    }
  });

  useEffect(() => {
    const path = location.pathname.replace(routes.INVENTORY, "");
    let selectedTab = filteredTabs.findIndex((tab) => tab.path === path);
    if (selectedTab === -1) selectedTab = 0;
    setTab(selectedTab);
  }, [location.pathname, filteredTabs]);

  const handleTabChange = (event, newValue) => {
    const newRoute = routes.INVENTORY + filteredTabs[newValue].path;
    history.push(newRoute);
  };

  return (
    <div className={classes.root}>
      <Tabs
        textColor="primary"
        value={tab}
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleTabChange}
      >
        {filteredTabs.map((tabObj) => (
          <Tab key={tabObj.label} label={tabObj.label} />
        ))}
      </Tabs>
      {props.children}
    </div>
  );
};

export default Inventory;
