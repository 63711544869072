import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CustomTextField from "../custom/CustomTextField";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import routes from "../../constants/routes";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CustomPagination from "../custom/CustomPagination";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
// import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
// import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import debounce from "awesome-debounce-promise";
import {
  deleteAccessRight,
  getAccessRightList,
} from "../../services/AccessRightService";
import CustomDialog from "../custom/CustomDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import cekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  searchBarGrid: {
    height: 100,
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const featuresMap = {
  a1: "Buka menu Purchase Order",
  a2: "Melakukan tambah baru Purchase Order (PO)",
  a3: "Melihat detail Purchase Order (PO)",
  a4: "Melakukan print pada data PO",
  a5: "Melakukan edit pada data PO",
  b1: "Buka menu Penerimaan Barang",
  b2: "Bisa melakukan proses penerimaan barang",
  b3: "Bisa melakukan retur barang",
  b4: "Bisa melihat detail",
  b5: "Bisa melakukan print",
  b6: "Bisa melakukan delete data",
  b7: "Penerimaan barang PO",
  b8: "Penerimaan barang Return",
  b9: "Bisa melakukan proses penerimaan barang",
  b10: "Bisa melihat detail",
  b11: "Bisa melakukan print",
  c1: "Buka Menu Billing",
  c2: "Bisa melakukan pembayaran",
  c3: "Bisa melihat detail",
  c4: "Bisa melakukan tukar faktur",
  c5: "Bisa melakukan print bukti pembayaran",
  d1: "Buka menu Request Order",
  d2: "New Order",
  d3: "Bisa melakukan tambah RO baru",
  d4: "Bisa melakukan Approve RO dari client",
  d5: "Bisa melakukan delete data RO",
  d6: "Processing",
  d7: "Bisa melakukan proses pada RO",
  d8: "Bisa melakukan delete data Processing",
  d9: "Shipping",
  d10: "Bisa melakukan cetak detail",
  d11: "Bisa melakukan cetak biasa",
  d12: "Bisa melakukan print berita acara RO",
  d13: "Bisa melakukan edit shipping",
  e1: "Buka menu Sales",
  e2: "Bisa melakukan pembayaran",
  e3: "Bisa cetak invoice",
  f1: "Buka menu Inventory Barang",
  f2: "Data Barang",
  f3: "Bisa tambah barang",
  f4: "Bisa melihat detail barang",
  f5: "Bisa mengedit detail barang",
  f6: "Bisa melihat harga beli per supplier",
  // f7: "Bisa export, import & print",
  f8: "Hanya export",
  // f9: "Hanya import",
  f10: "Hanya print",
  f11: "Bisa melihat depot stok",
  f12: "Bisa tambah depot",
  f13: "Bisa Mutasi barang",
  f14: "Bisa export & print",
  f15: "Hanya export",
  f16: "Hanya print",
  f17: "Kadaluarsa Item",
  f18: "Bisa tambah kaldaluarsa item",
  f19: "Kartu Stok",
  f20: "Bisa export & print",
  f21: "Hanya export",
  f22: "Hanya print",
  f23: "Data Barang Detail",
  f24: "Bisa tambah barang",
  f25: "Bisa melihat detail barang",
  f26: "Bisa mengedit detail barang",
  f27: "Bisa melihat harga beli per supplier",
  f28: "Bisa export, import & print",
  f29: "Hanya export",
  f30: "Hanya import",
  f31: "Hanya print",
  f32: "Bisa melihat depot stok",
  f33: "Bisa tambah depot",
  f34: "Bisa export & print",
  f35: "Hanya export",
  f36: "Hanya print",
  f37: "Warning Kadaluarsa",
  // f37: "Bisa melihat warning kadaluarsa",
  f39: "Bisa export & print",
  f40: "Hanya export",
  f41: "Hanya print",
  f42: "Warning Stok",
  // f42: "Bisa melihat warning stok",
  f44: "Bisa export & print",
  f45: "Hanya export",
  f46: "Hanya print",
  f47: "Barang per Supplier",
  // f47: "Bisa melihat barang per supplier",
  f49: "Bisa export & print",
  f50: "Hanya export",
  f51: "Hanya print",
  g1: "Buka menu Adjustment Stok",
  g2: "Adjustment Stock",
  g3: "Bisa melakukan tambah depot",
  g4: "Bisa melakukan Repair Stock",
  g5: "Bisa export & print",
  g6: "Hanya export",
  g7: "Hanya print",
  // g8: "History Repair Stock",
  // g9: "Bisa melihat History Repair Stock",
  // g10: "Bisa export & print",
  // g11: "Hanya export",
  // g12: "Hanya print",
  h1: "Buka menu Laporan",
  h2: "Bisa export & print",
  h3: "Hanya export",
  h4: "Hanya print",
  h5: "Melihat laporan penjualan barang",
  h6: "Melihat laporan penerimaan barang",
  h7: "Melihat laporan data stok barang",
  h8: "Melihat laporan selisih RO dari klinik ke warehouse",
  h9: "Melihat laporan selisih dari PO ke supplier",
  h10: "Melihat laporan adjustment stock",
  h11: "Melihat laporan faktur jatuh tempo",
  h12: "Melihat laporan delivery order (DO)",
  h13: "Laporan kekayaan barang",
  h14: "Melihat laporan PO barang",
  h15: "Melihat laporan DO barang",
  h16: "Melihat laporan RO barang",
  h17: "Melihat laporan fast moving, slow moving",
  h18: "Melihat laporan return barang",
  i1: "Buka menu Setting",
  i2: "General Setting",
  i3: "Management Staff",
  i4: "Tambah staff baru",
  i5: "Edit data staff",
  i6: "Reset password staff",
  i7: "Hak Akses",
  i8: "Tambah hak akses baru",
  i9: "Melihat detail hak akses",
  i10: "Edit hak akses",
  i11: "Hapus hak akses",
  i12: "Kategori Barang",
  i13: "Bisa export & print",
  i14: "Hanya export",
  i15: "Hanya print",
  i16: "Tambah kategori baru",
  i17: "Edit kategori",
  i18: "Hapus kategori",
  i19: "Rak Penyimpanan",
  i20: "Tambah rak baru",
  i21: "Edit rak penyimpanan",
  i22: "Hapus rak penyimpanan",
  i23: "Bisa export & print",
  i24: "Hanya export",
  i25: "Hanya print",
  i26: "Supplier",
  i27: "Tambah supplier baru",
  i28: "Edit supplier",
  i29: "Hapus supplier",
  i30: "Bisa export & print",
  i31: "Hanya export",
  i32: "Hanya print",
  i33: "Client",
  i34: "Tambah client baru",
  i35: "Edit client",
  i36: "Hapus client",
  i37: "Bisa export & print",
  i38: "Hanya export",
  i39: "Hanya print",
  i40: "Hanya export",
};

const debounceGetAccessRightList = debounce(getAccessRightList, 500);

const AccessRight = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = cekHakAkses();

  const [searchAccessRight, setSearchAccessRight] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState(paginationInitialData);
  const [totalData, setTotalData] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState({ open: false });
  const [isFetching, setIsFetching] = useState(false);

  const confirmDelete = () => {
    deleteAccessRight(deleteDialog.id).then(() => {
      setDeleteDialog({ ...deleteDialog, open: false });
      getAccessRightList(
        searchAccessRight,
        pagination.page,
        pagination.rowsPerPage
      ).then((res) => {
        setTableData(res.data.items);
        setTotalData(res.data.count);
      });
    });
  };

  useEffect(() => {
    setIsFetching(true);
    debounceGetAccessRightList(
      searchAccessRight,
      pagination.page,
      pagination.rowsPerPage
    ).then((res) => {
      setTableData(res.data.items);
      setTotalData(res.data.count);
      setIsFetching(false);
    });
  }, [pagination, searchAccessRight]);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid item xs={6} style={{ paddingTop: 10 }}>
          <CustomTextField
            label="Cari Tipe Akses"
            placeholder="Nama tipe akses"
            value={searchAccessRight}
            onChange={(event) => setSearchAccessRight(event.target.value)}
            startIcon={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={6}>
          {cekRole("setting.i8") && (
            <Button
              disableElevation
              variant="contained"
              size="large"
              className={classes.button}
              onClick={() => history.push(routes.ADD_ACCESS_RIGHT)}
            >
              + Tipe Akses
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerLeft}>
                Nama Tipe Akses
              </TableCell>
              <TableCell>Fitur</TableCell>
              <TableCell className={classes.headerRight} />
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={3} align={"center"}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : tableData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} align={"center"}>
                  <p>Tidak ada data yang bisa ditampilkan</p>
                </TableCell>
              </TableRow>
            ) : (
              tableData.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {
                      Array.isArray(row.action)
                        ? row.action
                            .map((item) => featuresMap[item]).join(", ")
                        : Object.values(row.action)
                            .flatMap(act => Object.keys(act))
                            .map((item) => featuresMap[item]).join(", ")
                    }
                  </TableCell>
                  <TableCell>
                    {cekRole("setting.i11") && (
                      <Tooltip title="Delete Hak Akses" placement="center" id="warehouse-content">
                      <IconButton
                        color="secondary"
                        className={classes.button}
                        onClick={() =>
                          setDeleteDialog({
                            ...deleteDialog,
                            open: true,
                            name: row.name,
                            id: row.id,
                          })
                        }
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                      </Tooltip>
                    )}
                    {cekRole("setting.i10") && (
                      <Tooltip title="Edit Hak Akses" placement="center" id="warehouse-content">
                      <IconButton
                        color="primary"
                        className={classes.button}
                        onClick={() =>
                          history.push(routes.EDIT_ACCESS_RIGHT + `/${row.id}`)
                        }
                      >
                        <CreateOutlinedIcon />
                      </IconButton>
                      </Tooltip>
                    )}
                    {/*{cekRole("i8") && (*/}
                    {/*  <Tooltip title="Detail Hak Akses" placement="center">*/}
                    {/*  <IconButton color="primary" className={classes.button}>*/}
                    {/*    <InsertDriveFileOutlinedIcon />*/}
                    {/*  </IconButton>*/}
                    {/*  </Tooltip>*/}
                    {/*)}*/}
                    {/*{cekRole("i12") && (*/}
                    {/*  <Tooltip title="Print Hak Akses" placement="center">*/}
                    {/*  <IconButton color="primary" className={classes.button}>*/}
                    {/*    <PrintOutlinedIcon />*/}
                    {/*  </IconButton>*/}
                    {/*  </Tooltip>*/}
                    {/*)}*/}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
      <CustomDialog
        open={deleteDialog.open}
        handleClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
        title="Anda akan menghapus hak akses"
        body={
          "Anda akan menghapus hak akses " + deleteDialog.name + ", setuju?"
        }
        onCancel={() => setDeleteDialog({ ...deleteDialog, open: false })}
        cancelButtonText="Batalkan"
        onAccept={confirmDelete}
        acceptButtonText="Hapus Data"
      />
    </div>
  );
};

export default AccessRight;
