import { useState, useCallback } from 'react';

export default function usePdfPrint() {
  const [printPdfProps, setPrintPdfProps] = useState({});

  const setPdfProps = useCallback((value, callback = () => {}) => {
    setPrintPdfProps(value);
    setTimeout(() => {
      callback();
      window.print();
      setPrintPdfProps({});
    }, 1000);
  }, []);

  return { printPdfProps, setPdfProps }
}
