import { getPlaceId } from "../store/auth/selector";
import agent from "./agent";

export const getListInventory = (
  search,
  category,
  type,
  skip = 0,
  limit = 20,
  status
) => {
  return agent.get("/inventories/getList", {
    params: {
      filter: { placeId: getPlaceId(), search, category, type, status },
      skip: skip * limit,
      limit,
    },
  });
};
export const getListExportInventory = (
  search,
  category,
  type,
  skip,
  limit,
  status
) => {
  return agent.get("/inventories/getList", {
    params: {
      filter: { placeId: getPlaceId(), search, category, type, status },
      skip: skip * limit,
      limit,
    },
  });
};

export const getWarningStock = (search, type, category, status, skip = 0, limit = 20) => {
  return agent.get("/inventories/getWarningStock", {
    params: {
      filter: { placeId: getPlaceId(), search, type, category, status },
      skip: skip * limit,
      limit,
    },
  });
};
export const getStockExpires = (search, status, skip = 0, limit = 20) => {
  return agent.get("/stockExpires/getListAll", {
    params: {
      filter: { placeId: getPlaceId(), search, status },
      skip: skip * limit,
      limit,
    },
  });
};
export const getItemPerSupplier = (
  search,
  dateStart,
  dateEnd,
  skip = 0,
  limit = 20,
) => {
  return agent.get("/inventories/getItemPerSupplier", {
    params: {
      filter: { placeId: getPlaceId(), search },
      dateStart,
      dateEnd,
      skip: skip * limit,
      limit,
    },
  });
};
export const getDepotStock = (id, skip = 0, limit = 20) => {
  return agent.get("/depotInventories/getList", {
    params: {
      filter: { placeId: getPlaceId(), itemId: id },
      skip: skip * limit,
      limit,
    },
  });
};
export const getSpecificStockExpires = (id, skip = 0, limit = 20) => {
  return agent.get("/stockExpires/getListSpecific", {
    params: {
      filter: { placeId: getPlaceId(), itemId: id },
      skip: skip * limit,
      limit,
    },
  });
};
export const getStockCard = (id, dateStart, dateEnd, type, skip = 0, limit = 20) => {
  return agent.get("/inventories/getStockCard", {
    params: {
      filter: { placeId: getPlaceId(), itemId: id, dateStart, dateEnd, type},
      skip: skip * limit,
      limit,
    },
  });
};
export const getSupplierPrice = (
  search,
  id,
  dateStart,
  dateEnd,
  skip,
  limit
) => {
  return agent.get("inventories/getSupplierPrice", {
    params: {
      filter: { placeId: getPlaceId(), itemId: id, search, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};
export const getGenerateCode = () => {
  return agent.get("inventories/generateCode", {
    params: {
      filter: { placeId: getPlaceId() },
    },
  });
};

export const addItems = (data) => {
  return agent.post("/inventories/add", { ...data, placeId: getPlaceId() });
};

export const getItemDetails = (id) => {
  return agent.get(`/inventories/${id}/getDetail`);
};

export const getLastBuyHistory = (id) => {
  return agent.get(`/inventories/${id}/getLastBuyHistory`, {});
};

export const addStockExpires = (data) => {
  return agent.post("/stockExpires/add", { ...data, placeId: getPlaceId() });
};

export const addDepot = (data) => {
  return agent.post("/depotInventories/add", {
    ...data,
    placeId: getPlaceId(),
  });
};

export const editInventory = (data, itemId) => {
  return agent.put(`/inventories/${itemId}/edit`, data);
};

export const mutasiDepot = (id, data) => {
  return agent.put(`/depotInventories/${id}/mutate`, data);
};

export const getDepotInventoriesDetail = (id) => {
  return agent.get(`/depotInventories/${id}/getDetail`);
};

export const checkEmptyWarning = (id) => {
  return agent.get(`/inventories/${id}/checkEmptyWarning`);
}

export const getDetailKadaluarsaItem = (id) => {
  return agent.get(`/stockExpires/${id}/getDetail`);
}

export const editKadaluarsaItem = (id, payload) => {
  return agent.put(`/stockExpires/${id}/editBatchNoAndExpirationDate`, payload);
}

export const getTotalStockExpiresByDepotId = (stockDepotId) => {
  return agent.get("stockExpires/countTotalStockExpiresByDepotId", {
    params: {
      stockDepotId,
    },
  });
};

export const checkBatch = (batchNo, itemId) => {
  return agent.get("stockExpires/", {
    params: {
      filter: `{"where": {"batchNo": "${batchNo}", "itemId": "${itemId}"}}`,
    },
  });
};

export const getByMinStockOrLessForPurchaseOrderV2 = (idSupplier) => {
  return agent.get("purchases/getListItemPOBySupplier", {
    params: {
      supplierId: idSupplier,
      placeId: getPlaceId(),
    },
  });
};



export const getListBarangRusak = (
  search,
  category,
  type,
  skip = 0,
  limit = 20,
  status
) => {
  return agent.get("/inventories/getListDamage", {
    params: {
      filter: { placeId: getPlaceId(), search, category, type, status },
      skip: skip * limit,
      limit,
    },
  });
};
export const getListExportBarangRusak = (
  search,
  category,
  type,
  skip,
  limit,
  status
) => {
  return agent.get("/inventories/getListDamage", {
    params: {
      filter: { placeId: getPlaceId(), search, category, type, status },
      skip: skip * limit,
      limit,
    },
  });
};

