import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import debounce from "awesome-debounce-promise";
import { makeStyles } from "@material-ui/core/styles";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import CustomAutocomplete from "../custom/CustomAutocomplete";
import routes from "../../constants/routes";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import CustomTextField from "../custom/CustomTextField";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import CustomSelect from "../custom/CustomSelect";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Radio from "@material-ui/core/Radio";
import { useParams } from "react-router-dom";
import DeletePODialog from "./DeletePODialog";
import { getDetailDO, 
  editPurchaseOrder, 
  deleteRowPO,
  unlockPurchaseOrder,
  addItemPOWhenEdit,
  unlockReceiveForEditing,
 } from "../../services/PurchaseOrderService";
import { getDetailStockDepot } from "../../services/StockDepotService";
import { getItemDetails, getListInventory, getLastBuyHistory } from "../../services/InventoryService";
import { getListInventoryDepot } from "../../services/DepotInventoriesService";
import { setSnackbar } from "../../store/general/actions";
import formatting from "../../utils/formatting";
import { adjust } from "../../utils/fp-helpers";
import keyBy from "lodash.keyby";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  header: {
    lineHeight: "60px",
    fontWeight: "bold",
    color: theme.palette.blue,
  },
  title: {
    lineHeight: "60px",
    fontWeight: "bold",
  },
  itemGridLeft: {
    paddingRight: 12,
  },
  itemGridRight: {
    paddingLeft: 12,
  },
  inputField: {
    width: "100%",
  },
  buttonGroup: {
    marginBottom: 27,
  },
  buttonGroupSelected: {
    backgroundColor: theme.palette.lightBlue,
    borderColor: theme.palette.lightBlue,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.lightBlue,
      borderColor: theme.palette.lightBlue,
      color: theme.palette.white,
    },
  },
  select: {
    width: "100%",
  },
  inputWithSelect: {
    paddingLeft: 10,
  },
  table: {
    marginBottom: 30,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  grandTotal: {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.whiteBlue,
      color: theme.palette.titleText,
      fontWeight: "bold",
    },
  },
  paragraph: {
    margin: 0,
    borderRight: "2px solid",
    borderRightColor: theme.palette.whiteBlue,
  },
  hiddenRadioButton: {
    visibility: "hidden",
  },
  selectRow: {
    "&:hover": {
      color: theme.palette.blue,
      cursor: "pointer",
    },
  },
  expand: {
    float: "right",
    border: "none",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  collapseTitle: {
    display: "inline-block",
    marginTop: 10,
    fontFamily: theme.typography.fontFamily,
  },
  collapse: {
    marginBottom: 27,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.blue,
  },
}));

const units = [
  { value: "FIXED", label: "Rp" },
  { value: "PERCENTAGE", label: "%" },
];

const debounceGetListInventory = debounce(getListInventory, 500);

const EditPurchaseOrder = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const history = useHistory();
  const { id } = useParams();

  const [data, setData] = useState({
    items: [{}],
    Suppliers: {},
  });
  const [dataTambah, setDataTambah] = useState({
    items: [],
    Suppliers: {},
  });
  const [dataDetail, setDataDetail] = useState({
    items: [],
    Suppliers: {},
  });
  const [selected, setSelected] = useState(0);
  const [depots, setDepots] = useState({});
  const [items, setItems] = useState({});
  const [searchItem, setSearchItem] = useState({ type: "Obat", name: "" });
  const [openEdit,setOpenEdit] = useState(false);
  const [error, setError] = useState({});
  const [itemName, setItemName] = useState({});
  const [item, setItem] = useState({
    discountType: "FIXED",
    quantityOrder: 0,
    bigStock: "",
    sellFee: 0,
    category: "",
    depotDestination: "",
    remarks: "",
  });
  const [expanded, setExpanded] = useState(true);
  const [lastBuyHistory, setLastBuyHistory] = useState({ supplier: "" });
  const [normalBuyingPrice, setNormalBuyingPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [newBuyingPrice, setNewBuyingPrice] = useState(0);
  const [ppn] = useState(0);
  const [depotsNew, setDepotsNew] = useState([]);
  const [detailOrder, setDetailOrder] = useState({ items: [] });
  const [openDeleteDialog, setOpenDeleteDialog] = useState({ open: false });

  const handleDeleteDialog = (name, id, itemId) => {
    if (dataDetail.items.length === 1) {
      dispatch(
        setSnackbar("warning", "Maaf! tidak bisa menghapus semua item")
      );
      return;
    } else {
    setOpenDeleteDialog({ ...openDeleteDialog, open: true, name, id, itemId });
    }
  };

  const handleChangeTab = (name) => {
    if (name === 'true') {
    setOpenEdit(true);
    } else {
      setOpenEdit(false);
      setItemName({});
      fetchData();
      setSelected(0);
    }
    resetItem();
  };

  useEffect(() => {
    getDetailDO(id).then((res) => {
      // ini untuk nampung data edit
      setData({
        ...res.data.item,
        items: res.data.item.TxPurchaseItems,
      });
      // ini untuk nampung data tambah edit
      setDataTambah({
        ...res.data.item,
        items: [],
      });
      // ini untuk nampilin data detail sudah edit / tambah 
      setDataDetail({
        ...res.data.item,
        items: res.data.item.TxPurchaseItems,
      });

      const inventoryPromises = [];
      const depotPromises = [];
      res.data.item.TxPurchaseItems.forEach((value) => {
        depotPromises.push(getDetailStockDepot(value.stockDepotId));
        inventoryPromises.push(getItemDetails(value.itemId));
      });

      Promise.all(depotPromises).then((values) => {
        setDepots(keyBy(values, (data) => data.data.item.id));
      });

      Promise.all(inventoryPromises).then((values) => {
        setItems(keyBy(values, (data) => data.data.item.id));
      });
    });
    // component unmount
    return () => {
      unlockPurchaseOrder({
        poId: id,
      });
      unlockReceiveForEditing({
        poId: id,
      });
    }
  }, [id]);
 // hit kembali api ini ketika aksi edit dan tambah
  const fetchDetail = () => {
    getDetailDO(id).then((res) => {
      setDataDetail({
        ...res.data.item,
        items: res.data.item.TxPurchaseItems,
      }); 
    });
  };

  const fetchData = () => {
    getDetailDO(id).then((res) => {
      setData({
        ...res.data.item,
        items: res.data.item.TxPurchaseItems,
      }); 
    });
  };

  useEffect(() => {
    if (normalBuyingPrice) {
      let newPrice = normalBuyingPrice;
      setNewBuyingPrice(Math.round(newPrice));
    }
  }, [normalBuyingPrice]);

  useEffect(() => {
    debounceGetListInventory(
      searchItem.name,
      undefined,
      searchItem.type
    ).then((res) => setItems(res.data.items));
  }, [searchItem]);

  const setCurrentItemState = (newValue, details) => {
    if (details) {
      setItemName({ name: newValue, id: details.option["_id"] });
      setItem({
        ...details.option,
        discountType: "FIXED",
        quantityOrder: 0,
      });
    }
  };

  const resetItem = () => {
    setSearchItem({ ...searchItem, name: "" });
    setLastBuyHistory({ supplier: "" });
    setItem({
      discountType: "FIXED",
      bigStock: "",
      quantityOrder: 0,
      sellFee: 0,
      category: "",
      depotDestination: "",
      remarks: "",
    });
    setNormalBuyingPrice(0);
    setDiscount(0);
  };

  useEffect(() => {
    if (detailOrder.items) {
      let subTotal = 0;
      let totalDiscount = 0;
      detailOrder.items.forEach((value) => {
        subTotal += value.price * value.qty;
        totalDiscount += Number(value.discount) * value.qty;
      });

      const totalPpn = (subTotal - totalDiscount) * (ppn / 100);
      setDetailOrder((d) => ({
        items: d.items,
        subTotal,
        totalDiscount,
        totalPpn,
        grandTotal: subTotal - totalDiscount + totalPpn,
        notes: d.notes,
      }));
    }
  }, [detailOrder.items, ppn]);

  useEffect(() => {
    if (itemName.id) {
      getLastBuyHistory(itemName.id).then((res) => {
        setLastBuyHistory(res.data.item);
        setNormalBuyingPrice(Number(res.data.item.buyFee));
        setDiscount(
          isNaN(res.data.item.discount) ? 0 : Number(res.data.item.discount)
        );
      });
      getListInventoryDepot(itemName.id).then((res) =>
        setDepotsNew(res.data.items)
      );
    }
  }, [itemName]);

  const addItem = async (dataTambahbaru) => {
    if (item["_id"] === undefined) {
      dispatch(
        setSnackbar("warning", "Maaf! Silakan pilih item terlebih dahulu")
      );
      return;
    }
    if (item.depotDestination === undefined) {
      dispatch(
        setSnackbar(
          "warning",
          "Maaf! Silakan pilih tujuan depot terlebih dahulu"
        )
      );
      return;
    }
    if (item.quantityOrder <= 0) {
      dispatch(
        setSnackbar(
          "warning",
          "Maaf! Silakan isi quantity order terlebih dahulu"
        )
      );
      return;
    }
    setDetailOrder(({ items, ...d }) => ({
      ...d,
      notes: '',
      items: [
        ...items,
        {
          itemId: item["_id"],
          name: item.name,
          oldSellFee: lastBuyHistory.sellFee,
          oldBuyFee: lastBuyHistory.buyFee,
          quantityBefore: parseInt(item.bigStock.match(/\d+/)[0], 10) || parseInt(item.stock.match(/\d+/)[0], 10),
          quantityOrder: parseInt(item.quantityOrder, 10),
          bigUnit: item.bigUnit,
          buyFee: parseFloat(parseFloat(newBuyingPrice, 10).toFixed(2), 10),
          discountRp:
          discount === null
            ? 0
            : item.discountType === "FIXED"
            ? parseFloat(parseFloat(discount, 10).toFixed(2), 10)
            : Math.round(newBuyingPrice * (discount / 100)),
         discountPercent:
          discount === null
            ? 0
            : item.discountType === "PERCENTAGE"
            ? discount
            : (discount / newBuyingPrice) * 100,
        ppnRp: (newBuyingPrice - discount) * (data.ppnPercent / 100),
        ppnPercent: data.ppnPercent,
        unitBuyFinalFee: newBuyingPrice - discount,
        stockDepotId: item.depotDestination["_id"],
        sellFee: parseFloat(parseFloat(item.sellFee, 10).toFixed(2), 10),
        notes: item.remarks ? item.remarks : '',
        totalFee: (item.quantityOrder * newBuyingPrice) -
        (item.quantityOrder * item.discountRp),
        },
      ],
    })
    );
    const convert = {
      itemId: item["_id"],
      name: item.name,
      oldSellFee: lastBuyHistory.sellFee,
      oldBuyFee: lastBuyHistory.buyFee,
      quantityBefore: parseInt(item.bigStock.match(/\d+/)[0], 10) || parseInt(item.stock.match(/\d+/)[0], 10),
      quantityOrder: parseInt(item.quantityOrder, 10),
      bigUnit: item.bigUnit,
      buyFee: parseFloat(parseFloat(newBuyingPrice, 10).toFixed(2), 10),
      discountRp:
      discount === null
        ? 0
        : item.discountType === "FIXED"
        ? parseFloat(parseFloat(discount, 10).toFixed(2), 10)
        : Math.round(newBuyingPrice * (discount / 100)),
     discountPercent:
      discount === null
        ? 0
        : item.discountType === "PERCENTAGE"
        ? discount
        : (discount / newBuyingPrice) * 100,
    ppnRp: (newBuyingPrice - discount) * (data.ppnPercent / 100),
    ppnPercent: data.ppnPercent,
    unitBuyFinalFee: newBuyingPrice - discount,
    stockDepotId: item.depotDestination["_id"],
    sellFee: parseFloat(parseFloat(item.sellFee, 10).toFixed(2), 10),
    notes: item.remarks ? item.remarks : '',
    totalFee: item.quantityOrder * (newBuyingPrice - discount),
  }
    resetItem();
    return { ...dataTambah, items: dataTambah.items.concat(convert) };
  };
  
  const submitEditPO = () => {
    const NewSubTotalFee = data.items.map(val => val.buyFee * val.quantityOrder);
    data.subTotalFee = NewSubTotalFee.reduce((a, b) => a + b, 0);

    const NewDiscountRp = data.items.map(val => val.discountRp * val.quantityOrder);
    data.discountRp = NewDiscountRp.reduce((a, b) => a + b, 0);
    data.ppnRp = parseInt((data.subTotalFee - data.discountRp) * (data.ppnPercent / 100));
    
    const NewtotalFee = Number(data.subTotalFee - data.discountRp) +
    parseInt((data.subTotalFee - data.discountRp) * (data.ppnPercent / 100));
    data.totalFee = NewtotalFee;
    const dataEdit = {
      id: data.id,
      code: data.code,
      supplierId: data.supplierId,
      placeId: data.placeId,
      picRoSupplierName: data.picRoSupplierName,
      picRoSupplierHp: data.picRoSupplierHp,
      subTotalFee: data.subTotalFee,
      discountRp: data.discountRp,
      discountPercent: data.discountRp / data.subTotalFee,
      ppnRp: data.ppnRp,
      ppnPercent: data.ppnPercent,
      totalFee: data.totalFee,
      notes: "",
      items: data.items.map((value) => {
        return {
          id: value.id,
          batchItems: value.batchItems,
          itemId: value.itemId,
          name: value.name,
          oldSellFee: value.oldSellFee,
          oldBuyFee: value.oldBuyFee,
          quantityBefore: value.quantityBefore,
          quantityOrder: value.quantityOrder,
          bigUnit: value.bigUnit,
          buyFee: value.buyFee,
          discountType: value.discountType,
          discountRp: value.discountRp,
          discountPercent: value.discountPercent,
          ppnRp: (value.buyFee - value.discountRp) * (data.ppnPercent / 100),
          ppnPercent: data.ppnPercent,
          unitBuyFinalFee: value.buyFee - value.discountRp,
          stockDepotId: value.stockDepotId,
          sellFee: value.sellFee,
          notes: value.notes || "",
        };
      }),
    }
    editPurchaseOrder(dataEdit).then(() => {
      dispatch(
        setSnackbar("success", "Sukses! Purchase order berhasil dilakukan")
      );
      unlockPurchaseOrder({
        poId: data.id,
      }); 
      unlockReceiveForEditing({
        poId: data.id,
      });     
      fetchDetail();
    }).catch(() => 
    dispatch(
      setSnackbar("warning", "Maaf! gagal")
    )
    );
  };
  const submitAddItemPO = async => {
    addItem().then((dataTambahbaru) => {
      const NewSubTotalFee = dataTambahbaru.items.map(val => val.buyFee * val.quantityOrder);
      dataTambahbaru.subTotalFee = NewSubTotalFee.reduce((a, b) => a + b, 0);
      
      const NewDiscountRp = dataTambahbaru.items.map(val => val.discountRp * val.quantityOrder);
      dataTambahbaru.discountRp = NewDiscountRp.reduce((a, b) => a + b, 0);
      dataTambahbaru.ppnRp = parseInt((dataTambahbaru.subTotalFee - dataTambahbaru.discountRp) * (dataTambahbaru.ppnPercent / 100));
      
      const NewtotalFee = Number(dataTambahbaru.subTotalFee - dataTambahbaru.discountRp) +
      parseInt((dataTambahbaru.subTotalFee - dataTambahbaru.discountRp) * (dataTambahbaru.ppnPercent / 100));
      dataTambahbaru.totalFee = NewtotalFee;
    
      delete dataTambahbaru.TxPurchaseItems;
      addItemPOWhenEdit(dataTambahbaru).then(() => {
        dispatch(
          setSnackbar("success", "Sukses! Tambah items purchase order berhasil dilakukan")
        );
        unlockPurchaseOrder({
          poId: data.id,
        });   
        unlockReceiveForEditing({
          poId: data.id,
        });      
        fetchDetail();
      });
    }).catch(() =>
    dispatch(
      setSnackbar("warning", "Maaf! gagal")
    )); 
};
  const handlePo = (idx, name) => (event) => {
    const { value } = event.target;
    if (name === 'discountRp') {
    const resultValue = value === "" ? 0 : parseInt(value);
      const dataNew = adjust(idx, item => ({
        ...item,
        [name]: resultValue,
        'totalFee': ((item.quantityOrder * item.buyFee) - (item.quantityOrder * parseInt(resultValue))),
      }), data.items);
    setData({ ...data, items: dataNew, TxPurchaseItems: dataNew });
    } else if (name === 'quantityOrder') {
      const resultValue = value === "" ? 0 : parseInt(value);
      const dataNewQO = adjust(idx, item => ({
        ...item,
        [name]: resultValue,
        'totalFee': parseInt(resultValue * item.buyFee) - parseInt(resultValue * item.discountRp),
      }), data.items);
    setData({ ...data, items: dataNewQO, TxPurchaseItems: dataNewQO });
    } else if (name === 'buyFee') {
      const resultValue = value === "" ? 0 : parseInt(value);
      const dataNewBuy = adjust(idx, item => ({
        ...item,
        [name]: resultValue,
        'totalFee': ((item.quantityOrder * parseInt(resultValue)) - (item.quantityOrder * item.discountRp)),
      }), data.items);
    setData({ ...data, items: dataNewBuy, TxPurchaseItems: dataNewBuy });
    }
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={`Edit Purchase Order ${data.code}`}
        previousPageName="Purchase Order"
        previousPageLink={routes.PURCHASE}
      />
      <Grid container item xs={12} className={classes.headerGrid}>
        <Typography variant="body1" className={`${classes.header}`}>
          Edit Purchase Order
        </Typography>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            className={`${classes.title} ${classes.borderBottom}`}
          >
            Order
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            className={`${classes.title} ${classes.borderBottom}`}
          >
            Detail Order
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
          <Grid item xs={12}>
              <ButtonGroup color="primary" className={classes.buttonGroup}>
                <Button
                  className={clsx({
                    [classes.buttonGroupSelected]: openEdit === false,
                  })}
                  onClick={() => handleChangeTab('false')} 
                >
                  Edit
                </Button>
                <Button
                  className={clsx({
                    [classes.buttonGroupSelected]:
                    openEdit === true,
                  })}
                  onClick={() => handleChangeTab('true')} 
                >
                  Tambah
                </Button>
              </ButtonGroup>
            </Grid>
            
            {openEdit &&
            <Grid item xs={12}>
              <ButtonGroup color="primary" className={classes.buttonGroup}>
                <Button
                  className={clsx({
                    [classes.buttonGroupSelected]: searchItem.type === "Obat",
                  })}
                  onClick={() => setSearchItem({ ...searchItem, type: "Obat" })}
                >
                  Obat
                </Button>
                <Button
                  className={clsx({
                    [classes.buttonGroupSelected]: searchItem.type === "BHP",
                  })}
                  onClick={() => setSearchItem({ ...searchItem, type: "BHP" })}
                >
                  Bahan Habis Pakai
                </Button>
                <Button
                  className={clsx({
                    [classes.buttonGroupSelected]:
                      searchItem.type === "Logistik",
                  })}
                  onClick={() =>
                    setSearchItem({ ...searchItem, type: "Logistik" })
                  }
                >
                  Logistik
                </Button>
              </ButtonGroup>
              <CustomAutocomplete
                options={items}
                onInputChange={(event, newInputValue) =>
                  setSearchItem({ ...searchItem, name: newInputValue })
                }
                getOptionLabel={(option) => option.code + " - " + option.name}
                onChange={(event, newValue, reason, details) => {
                  setCurrentItemState(newValue, details);
                  setError({ ...error, itemName: undefined });
                }}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                inputValue={searchItem.name}
                value={itemName.name}
                error={error.itemName}
                helperText={error.itemName}
                label="Nama Item"
                getOptionListLabel={(option) =>
                  `${option.code} - ${option.name}( ${
                    option.bigStock || `0 ${option.bigUnit}`
                  } )`
                }
              />
            <Grid container>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Obat Saat Ini"
                className={classes.lighterBackgroundColor}
                value={item.bigStock || item.stock}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
           <CustomTextField
                label="Jumlah Obat Dipesan"
                className={classes.inputField}
                onChange={(event) => 
                  setItem({
                    ...item,
                    quantityOrder: isNaN(event.target.value)
                      ? 0
                      : event.target.value,
                  })
              }
                value={item.quantityOrder}
              />
            </Grid>
            </Grid>
            {/* informasi po */}
            <Grid
              item
              xs={12}
              className={classes.collapse}
              alignItems="center"
              alignContent="center"
            >
              <Typography
                variant="body1"
                color="primary"
                className={classes.collapseTitle}
              >
                Informasi PO
              </Typography>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={() => setExpanded(!expanded)}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Collapse
                in={expanded}
                timeout="auto"
                unmountOnExit
                className={`${classes.borderBottom} ${classes.buttonGroup}`}
              >
                <Grid container>
                  <Grid item xs={6} className={classes.itemGridLeft}>
                    <CustomTextField
                      label="Tanggal Order Terakhir"
                      className={classes.inputField}
                      value={
                        lastBuyHistory.buyDate && lastBuyHistory.buyDate !== "-"
                          ? moment(new Date(lastBuyHistory.buyDate)).format(
                              "DD/MM/YYYY"
                            )
                          : ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridRight}>
                    <CustomTextField
                      label="Supplier Terakhir"
                      className={classes.inputField}
                      value={lastBuyHistory.supplier}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridLeft}>
                    <CustomTextField
                      label="Harga Dasar"
                      className={classes.inputField}
                      value={formatting.currencyFormat(lastBuyHistory.basicFee)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridRight}>
                    <CustomTextField
                      label="Harga Jual Lama"
                      className={classes.inputField}
                      value={formatting.currencyFormat(lastBuyHistory.sellFee)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridLeft}>
                    <CustomTextField
                      label="Harga Beli Lama"
                      className={classes.inputField}
                      value={formatting.currencyFormat(lastBuyHistory.buyFee)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridRight}>
                    <CustomTextField
                      label="Quantity Beli Lama"
                      className={classes.inputField}
                      value={lastBuyHistory.quantity}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridLeft}>
                    <CustomTextField
                      label="Diskon Beli Lama"
                      className={classes.inputField}
                      value={formatting.currencyFormat(lastBuyHistory.discount)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridRight}>
                    <CustomTextField
                      label="Harga Beli Lama + PPN"
                      className={classes.inputField}
                      value={formatting.currencyFormat(
                        lastBuyHistory.buyPpnFee
                      )}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            {expanded ? (
              <Grid container>
                <Grid item xs={6} className={classes.itemGridLeft}>
                  <Grid container alignItems="flex-end">
                    <Grid item xs={4}>
                      <CustomSelect
                        value={item.discountType}
                        onChange={(event) =>
                          setItem({ ...item, discountType: event.target.value })
                        }
                        label="Diskon"
                        className={classes.select}
                      >
                        {units.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.label}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </Grid>
                    <Grid item xs={8} className={classes.inputWithSelect}>
                      <CustomTextField
                        className={classes.inputField}
                        onChange={(event) =>
                          isNaN(event.target.value) || event.target.value === ""
                            ? 0
                            : setDiscount(
                                event.target.value > item.basicFee &&
                                  item.discountType === "FIXED"
                                  ? item.basicFee
                                  : event.target.value
                              )
                        }
                        value={discount}
                        currency={item.discountType === "FIXED"}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6} className={classes.itemGridRight}>
                  <CustomTextField
                    label="Harga Beli Normal"
                    className={classes.inputField}
                    onChange={(event) =>
                      setNormalBuyingPrice(event.target.value)
                    }
                    value={normalBuyingPrice}
                    currency
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={6} className={classes.itemGridLeft}>
                  <CustomTextField
                    label="Harga Beli Normal"
                    className={classes.inputField}
                    onChange={(event) =>
                      setNormalBuyingPrice(event.target.value)
                    }
                    value={normalBuyingPrice}
                  />
                </Grid>
                <Grid item xs={6} className={classes.itemGridRight}>
                  <Grid container alignItems="flex-end">
                    <Grid item xs={4}>
                      <CustomSelect
                        value={item.discountType}
                        onChange={(event) =>
                          setItem({ ...item, discountType: event.target.value })
                        }
                        label="Diskon"
                        className={classes.select}
                      >
                        {units.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.label}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </Grid>
                    <Grid item xs={8} className={classes.inputWithSelect}>
                      <CustomTextField
                        className={classes.inputField}
                        onChange={(event) =>
                          isNaN(event.target.value)
                            ? 0
                            : setDiscount(event.target.value)
                        }
                        value={discount}
                        currency={item.discountType === "FIXED"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid container>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Harga Jual Baru"
                className={classes.inputField}
                onChange={(event) =>
                  setItem({
                    ...item,
                    sellFee: isNaN(event.target.value) ? 0 : event.target.value,
                  })
                }
                value={item.sellFee}
                currency
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Harga Beli Baru"
                className={classes.inputField}
                onChange={(event) =>
                  isNaN(event.target.value)
                    ? 0
                    : setNewBuyingPrice(event.target.value)
                }
                value={newBuyingPrice}
                currency
              />
            </Grid>
            </Grid>
            <Grid container>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomSelect
                value={item.depotDestination}
                onChange={(event) =>
                  setItem({ ...item, depotDestination: event.target.value })
                  }
                label="Tujuan Depot"
                className={classes.select}
              >
                {depotsNew?.map((data) => (
                  <MenuItem key={data["_id"]} value={data}>
                    {`${data.depotName} (${data.rackName})`}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Sub Kategori"
                className={classes.inputField}
                value={item.category}
                disabled
              />
            </Grid>
            </Grid>
            <Grid item xs={12} className={classes.itemGridLeft}>
              <CustomTextField
                label="Keterangan"
                className={classes.inputField}
                onChange={(event) =>
                  setItem({ ...item, remarks: event.target.value })
                }
                value={item.remarks}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Button
                  disableElevation
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => resetItem()}
                >
                  Reset
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  size="large"
                  onClick={async () => submitAddItemPO()}
                >
                  Tambah Barang
                </Button>
              </Grid>
            </Grid>
            </Grid>}
            {!openEdit &&
            <Grid container>
            <Grid item xs={12}>
              <CustomTextField
                label="Nama Item"
                className={classes.inputField}
                value={data.items[selected].name}
                startIcon={<SearchIcon />}
                placeholder="Cari Nama Obat"
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Jumlah Obat Dipesan"
                className={classes.inputField}
                value={data.items[selected].quantityOrder}
                onChange={handlePo(selected, 'quantityOrder')}
              />
            </Grid>
            <Grid item xs={6} />
            
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Harga Dasar"
                className={classes.inputField}
                value={
                  data.items[selected]?.Inventories?.basicFee
                }
                currency
                onChange={handlePo(selected, 'basicFee')}
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Harga Beli"
                className={classes.inputField}
                value={data.items[selected].buyFee}
                currency
                onChange={handlePo(selected, 'buyFee')}
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <Grid container alignItems="flex-end">
                <Grid item xs={4}>
                  <CustomSelect
                    value="FIXED"
                    label="Diskon"
                    className={classes.select}
                  >
                    {units.map((data) => (
                      <MenuItem key={data.value} value={data.value}>
                        {data.label}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </Grid>
                <Grid item xs={8} className={classes.inputWithSelect}>
                  <CustomTextField
                    className={classes.inputField}
                    value={data.items[selected].discountRp}
                    onChange={handlePo(selected, 'discountRp')}
                    currency
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Harga Jual"
                className={classes.inputField}
                value={data.items[selected].sellFee}
                onChange={handlePo(selected, 'sellFee')}
                currency
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Tujuan Depot"
                className={classes.inputField}
                value={
                  depots[data.items[selected].stockDepotId]?.data?.item?.Depots
                    ?.name
                }
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Sub Kategori"
                className={classes.inputField}
                value={
                  items[data.items[selected].itemId]?.data?.item?.subCategory
                }
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Keterangan"
                className={classes.inputField}
                value={data.notes}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Button
                  disableElevation
                  variant="contained"
                  size="large"
                  onClick={() => submitEditPO()}
                >
                  Simpan Edit
                </Button>
              </Grid>
            </Grid>
            </Grid>}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerLeft}></TableCell>
                    <TableCell>No</TableCell>
                    <TableCell>Obat</TableCell>
                    <TableCell>Jumlah</TableCell>
                    <TableCell>Harga</TableCell>
                    <TableCell>Diskon</TableCell>
                    <TableCell className={classes.headerRight}>
                      Total Harga
                    </TableCell>

                    <TableCell>Aksi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataDetail.items.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell>
                        <Radio
                          color="primary"
                          checked
                          className={clsx({
                            [classes.hiddenRadioButton]: index !== selected,
                          })}
                        />
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell
                        className={classes.selectRow}
                        onClick={() => setSelected(index)}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell>
                        {row.quantityOrderOld ? row.quantityOrderOld : row.quantityOrder} {row.bigUnit}
                      </TableCell>
                      <TableCell>
                        <p className={classes.paragraph}>
                          {"@" + formatting.currencyFormat(row.oldBuyFee ? row.oldBuyFee : row.buyFee)}
                        </p>
                      </TableCell>
                      <TableCell>
                        {formatting.currencyFormat(row.discountRpOld ? row.discountRpOld : row.discountRp)}
                      </TableCell>
                      <TableCell>
                      {formatting.currencyFormat((((row.oldBuyFee ? row.oldBuyFee : row.buyFee) - 
                      (row.discountRpOld ? row.discountRpOld : row.discountRp)) * (row.quantityOrderOld ? row.quantityOrderOld : row.quantityOrder)))}
                      </TableCell>
                      <TableCell>
                      <IconButton
                          color="secondary"
                          className={classes.button}
                          onClick={() => handleDeleteDialog(row.name, row.id, row.Inventories.id)}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Sub Total"
                className={classes.inputField}
                value={formatting.currencyFormat(dataDetail.subTotalFee)}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Total PPN"
                className={classes.inputField}
                value={formatting.currencyFormat(dataDetail.ppnRp)}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Total Diskon"
                className={classes.inputField}
                value={formatting.currencyFormat(dataDetail.discountRp)}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Total Seluruhnya"
                className={`${classes.grandTotal} ${classes.inputField}`}
                value={formatting.currencyFormat(dataDetail.totalFee)}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    <DeletePODialog
        open={openDeleteDialog.open}
        handleClose={() => {
          setOpenDeleteDialog({ ...openDeleteDialog, open: false });
        }}
        roNumber={openDeleteDialog.name}
        handleAccept={() => {
          deleteRowPO(id, openDeleteDialog.id, openDeleteDialog.itemId).then(() => {
            setOpenDeleteDialog({ ...openDeleteDialog, open: false });
            setSnackbar("success", "Sukses! Hapus barang berhasil dilakukan");
            setSelected(0);
            fetchDetail();
            fetchData();
          });
        }}
      />
    </div>
  );
};

export default EditPurchaseOrder;
