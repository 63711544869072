import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import CustomTextField from "../custom/CustomTextField";
import SearchIcon from "@material-ui/icons/Search";
import CustomDropdownButton from "../custom/CustomDropdownButton";
import routes from "../../constants/routes";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CustomPagination from "../custom/CustomPagination";
import CustomDialog from "../custom/CustomDialog";
import { deleteRack, getListRacks } from "../../services/RackService";
import debounce from "awesome-debounce-promise";
import exportXL from "../../utils/exportXL";
import Print from "../../utils/Print";
import CircularProgress from "@material-ui/core/CircularProgress";
import cekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  headerTitle: {
    color: theme.palette.blue,
  },
  searchBarGrid: {
    height: 100,
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debounceGetList = debounce(getListRacks, 500);

const StorageRack = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = cekHakAkses();

  const [searchRack, setSearchRack] = useState("");
  const [buttonMenuSelect] = useState();
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState(paginationInitialData);
  const [deleteDialog, setDeleteDialog] = useState({ open: false });
  const [totalData, setTotalData] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    debounceGetList(searchRack, pagination.page, pagination.rowsPerPage)
      .then((res) => {
        setTableData(res.data.items);
        setTotalData(res.data.count);
        setExportData([]);
      })
      .finally(() => setIsFetching(false));
  }, [pagination, searchRack]);

  const confirmDeleteRack = () => {
    deleteRack(deleteDialog.id).then(() => {
      setDeleteDialog({ ...deleteDialog, open: false });
      setIsFetching(true);
      getListRacks(searchRack, pagination.page, pagination.rowsPerPage)
        .then((res) => {
          setTableData(res.data.items);
          setTotalData(res.data.count);
        })
        .then(() => setIsFetching(false));
    });
  };

  const getDataExport = async () => {
    let page = 0;
    let skip = 0;
    const limit = 10;
    const fetch = async () => {
      const result = await getListRacks(searchRack, page, limit);
      exportData.splice(skip * limit, limit, ...result.data.items);
      setExportData(exportData);
    };
    if (exportData.length >= totalData) return;
    while (skip < totalData) {
      await fetch();
      page += 1;
      skip += limit;
    }
  };

  const handleExport = () => {
    try {
      getDataExport().then(() => {
        const hasil = exportData.map((val) => ({
          "Nama Rak Penyimpanan": val.name,
        }));
        exportXL("Export Rak Penyimpanan", hasil);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handlePrint = () => {
    try {
      getDataExport()
        .then(() => {
          setIsPrint(true);
          window.print();
        })
        .finally(() => {
          setIsPrint(false);
          setExportData([]);
        })
        .catch((e) => {
          console.error(e);
          setIsPrint(false);
        });
    } catch (e) {
      console.error(e);
      setIsPrint(false);
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid item xs={6} style={{ paddingTop: 10 }}>
          <CustomTextField
            label="Cari di Rak Penyimpanan"
            placeholder="Nama rak penyimpanan"
            value={searchRack}
            onChange={(event) => setSearchRack(event.target.value)}
            startIcon={<SearchIcon />}
          />
        </Grid>
        <Grid container item xs={6} alignItems="center" justify="flex-end">
          {(cekRole("setting.i20") || cekRole("setting.i24") || cekRole("setting.i25")) && (
            <CustomDropdownButton
              label={cekRole("setting.i20") ? "+ Rak Baru" : 
              cekRole("setting.i24") ? "Export" :
              cekRole("setting.i25") ? "Print" : ""}
              disableElevation
              variant="contained"
              size="large"
              side
              onClick={
                cekRole("setting.i20")
                  ? () => history.push(routes.ADD_STORAGE_RACK)
                  : 
                cekRole("setting.i24")
                  ? handleExport
                  :
                cekRole("setting.i25")
                  ? handlePrint
                  : ""
              }
            >
              {cekRole("setting.i20") && (
                <MenuItem
                  key="+ Rak Baru"
                  value={1}
                  onClick={() => history.push(routes.ADD_STORAGE_RACK)}
                  selected={1 === buttonMenuSelect}
                >
                  + Rak Baru
                </MenuItem>
              )}
              {(cekRole("setting.i24")) && (
                <MenuItem
                  key="export"
                  value={2}
                  onClick={handleExport}
                  selected={2 === buttonMenuSelect}
                >
                  Export
                </MenuItem>
              )}
              {(cekRole("setting.i25")) && (
                <MenuItem
                  key="print"
                  value={3}
                  onClick={handlePrint}
                  selected={3 === buttonMenuSelect}
                >
                  Print
                </MenuItem>
              )}
            </CustomDropdownButton>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Print>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerLeft}>Nama</TableCell>
                <TableCell className={classes.headerRight} />
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={2} align={"center"}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : !isPrint && tableData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={2} align={"center"}>
                    <p>Tidak ada data yang bisa ditampilkan.</p>
                  </TableCell>
                </TableRow>
              ) : (
                !isPrint &&
                tableData.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      <Tooltip title="Delete Rak Penyimpanan" placement="center" id="warehouse-content">
                      <IconButton
                        color="secondary"
                        className={classes.button}
                        size="small"
                        onClick={() =>
                          setDeleteDialog({
                            ...deleteDialog,
                            open: true,
                            name: row.name,
                            id: row.id,
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Rak Penyimpanan" placement="center" id="warehouse-content">
                      <IconButton
                        color="primary"
                        className={classes.button}
                        onClick={() =>
                          history.push(routes.EDIT_STORAGE_RACK + `/${row.id}`)
                        }
                      >
                        <CreateOutlinedIcon />
                      </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
              {isPrint &&
                exportData.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>{row.name}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Print>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
      <CustomDialog
        open={deleteDialog.open}
        handleClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
        title="Anda akan menghapus rak penyimpanan"
        body={
          "Anda akan menghapus rak penyimpanan " +
          deleteDialog.name +
          ", setuju?"
        }
        onCancel={() => setDeleteDialog({ ...deleteDialog, open: false })}
        cancelButtonText="Batalkan"
        onAccept={confirmDeleteRack}
        acceptButtonText="Hapus Data"
      />
    </div>
  );
};

export default StorageRack;
