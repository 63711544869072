import { getConfigId } from "../store/auth/selector";
import agent from "./agent";

export const getDetailConfig = () => {
  return agent.get(`/Configs/${getConfigId()}/getDetail`, {});
};

export const editDetailConfig = (body) => {
  return agent.put(`/Configs/${getConfigId()}/edit`, body);
};
