import { getPlaceId } from "../store/auth/selector";
import agent from "./agent";

export const getListCategory = (name, skip = 0, limit = 20) => {
  return agent.get("/categories/getList", {
    params: {
      filter: { placeId: getPlaceId(), name },
      skip: skip * limit,
      limit,
    },
  });
};

export const getDetailCategory = (id) => {
  return agent.get(`/categories/${id}/getDetail`);
};

export const deleteCategory = (id) => {
  return agent.put(`/categories/${id}/delete`);
};

export const editCategory = (id, name) => {
  return agent.put(`/categories/${id}/edit`, { name });
};

export const addCategory = (name) => {
  return agent.post(`/categories/add`, { placeId: getPlaceId(), name });
};
