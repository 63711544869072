import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import routes from "../../constants/routes";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import CustomTextField from "../custom/CustomTextField";
import {
  getDetailCategory,
  editCategory,
  addCategory,
} from "../../services/CategoryService";
import useSnackbar from "../../utils/useSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
}));

const AddNewCategory = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { setWarning } = useSnackbar();

  const [data, setData] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (props.edit) {
      getDetailCategory(id).then((res) => setData(res.data.item.name));
    }
  }, [id, props.edit]);

  const saveCategory = () => {
    if (!data) {
      setWarning("Maaf! Silakan isi nama kategori terlebih dahulu");
      return;
    }
    if (props.edit) {
      setIsFetching(true);
      editCategory(id, data).then(() => history.push(routes.ITEM_CATEGORY));
    } else {
      setIsFetching(true);
      addCategory(data).then(() => history.push(routes.ITEM_CATEGORY));
    }
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={props.edit ? "Edit Kategori" : "Tambah Kategori"}
        previousPageName="Setting"
        previousPageLink={routes.ITEM_CATEGORY}
      />
      <Grid container style={{ marginTop: 20 }}>
        <Grid item xs={3}>
          <CustomTextField
            label="Nama"
            className={classes.inputField}
            value={data}
            onChange={(event) => setData(event.target.value)}
            placeholder="Isi nama kategori"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => history.push(routes.ITEM_CATEGORY)}
            >
              Batalkan
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              onClick={isFetching ? null : saveCategory}
            >
              {isFetching ? <CircularProgress /> : "Simpan"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddNewCategory;
