import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import debounce from "awesome-debounce-promise";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { palette } from "../../theme";
import routes, { adjustmentStokRoutes } from "../../constants/routes";

import CustomTextField from "../custom/CustomTextField";
import CustomPagination from "../custom/CustomPagination";
import CustomDropdownButton from "../custom/CustomDropdownButton";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";

import exportXL from "../../utils/exportXL";
import Print from "../../utils/Print";
import useCekHakAkses from "../../utils/cekHakAkses";

import { getListForAdjustment } from "../../services/DepotInventoriesService";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  editButton: {
    float: "right",
    marginLeft: 5,
    color: palette.blue,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  topGrid: {
    display: "flex",
  },
  marginContainer: {
    marginLeft: 20,
  },
  alignRight: {
    textAlign: "right",
  },
  buttonGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  totalGudangType: {
    width: 125,
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debounceGetList = debounce(getListForAdjustment, 500);

const RepairStok = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const cekRole = useCekHakAkses();

  const [searchForm, setSearchForm] = useState("");
  const [pagination, setPagination] = useState(paginationInitialData);
  const [totalData, setTotalData] = useState(0);
  const [data, setData] = useState([]);
  const [buttonMenuSelect] = useState();
  const [exportData, setExportData] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    debounceGetList(id, searchForm, pagination.page, pagination.rowsPerPage)
      .then((res) => {
        setData(res.data.items);
        setTotalData(res.data.count);
        setExportData([]);
      })
      .finally(() => setIsFetching(false));
  }, [id, pagination, searchForm]);

  const getDataExport = async () => {
    let page = 0;
    let skip = 0;
    const limit = 10;
    const fetch = async () => {
      const result = await getListForAdjustment(id, searchForm, page, limit);
      exportData.splice(skip * limit, limit, ...result.data.items);
      setExportData(exportData);
    };
    while (skip < totalData) {
      await fetch();
      page += 1;
      skip += limit;
    }
  };

  const editStock = (id, itemId, stockDepotId) => {
    history.push(
      routes.ADJUSTMENT +
        adjustmentStokRoutes.REPAIR_STOK +
        `/${id}/${itemId}/${stockDepotId}`
    );
  };

  const handleExport = () => {
    try {
      getDataExport().then(() => {
        const hasil = exportData.map((val) => ({
          "Nama Obat": val.name,
          "Total di Sistem": val.stock,
          Rak: val.rackName,
        }));
        exportXL(`Export Adjustment Stok Depot ${data[0]?.depotName}`, hasil);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handlePrint = () => {
    try {
      getDataExport()
        .then(() => {
          setIsPrint(true);
          window.print();
        })
        .finally(() => {
          setIsPrint(false);
          setExportData([]);
        })
        .catch((e) => {
          console.error(e);
          setIsPrint(false);
        });
    } catch (e) {
      console.error(e);
      setIsPrint(false);
    }
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={data[0]?.depotName}
        previousPageName="Adjustment Stok"
        previousPageLink={routes.ADJUSTMENT}
      />
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid
          item
          xs={9}
          style={{ paddingTop: 10 }}
          className={classes.topGrid}
        >
          <div>
            <CustomTextField
              label="Cari Data"
              placeholder="Nama Obat"
              startIcon={<SearchIcon />}
              value={searchForm}
              onChange={(event) => setSearchForm(event.target.value)}
            />
          </div>
        </Grid>
        <Grid item xs={3} className={classes.alignRight}>
          {(cekRole("adjustmentStock.g6") || cekRole("adjustmentStock.g7")) && (
            <CustomDropdownButton
              disableElevation
              variant="contained"
              size="large"
              label={(cekRole("adjustmentStock.g6")) ? "Export" : "Print"}
              endIcon={<ExpandMoreIcon />}
            >
              {(cekRole("adjustmentStock.g6")) && (
                <MenuItem
                  key="export"
                  value={1}
                  onClick={handleExport}
                  selected={1 === buttonMenuSelect}
                >
                  Export
                </MenuItem>
              )}
              {(cekRole("adjustmentStock.g7")) && (
                <MenuItem
                  key="print"
                  value={2}
                  onClick={handlePrint}
                  selected={2 === buttonMenuSelect}
                >
                  Print
                </MenuItem>
              )}
            </CustomDropdownButton>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Print>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerLeft}>Nama Obat</TableCell>
                <TableCell>Total di Sistem</TableCell>
                <TableCell>Rak</TableCell>
                <TableCell className={classes.headerRight} />
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={7} align={"center"}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : !isPrint && data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align={"center"}>
                    <p>Tidak ada data yang bisa ditampilkan.</p>
                  </TableCell>
                </TableRow>
              ) : (
                !isPrint &&
                data.map((row, index) => (
                  <TableRow key={row["_id"]}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.stock + " " + row?.bigUnit}</TableCell>
                    <TableCell>{row.rackName}</TableCell>
                    <TableCell>
                      <Button
                        disableElevation
                        variant="outlined"
                        size="large"
                        className={classes.button}
                        onClick={() => editStock(id, row.itemId, row["_id"])}
                        startIcon={<SaveOutlinedIcon />}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
              {isPrint &&
                exportData.map((row, index) => (
                  <TableRow key={row["_id"]}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.stock + " " + row?.bigUnit}</TableCell>
                    <TableCell>{row.rackName}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Print>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
    </div>
  );
};

export default RepairStok;
