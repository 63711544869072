import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  MenuItem,
  Button,
  Modal,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  IconButton,
  Avatar,
  Menu,
  TextField
} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import { Close, Visibility, VisibilityOff } from "@material-ui/icons";
import { connect, useSelector, useDispatch } from "react-redux";

import get from 'lodash.get';

import { logout } from "../store/auth/actions";
import { setSnackbar } from "../store/general/actions";
import { getListAccount } from "../services/AccountService";
import { changePasswordInsideNew } from "../services/AuthService";
import validations from "../utils/validations";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    top: 0,
    width: "100%",
    borderBottom: "2px solid",
    borderBottomColor: theme.palette.normalTextfield,
    background: "white",
    zIndex: 2,
  },
  grid: {
    height: 80,
  },
  gridLeft: {
    paddingRight: 40,
    height: 80,
    borderRight: "2px solid",
    borderRightColor: theme.palette.normalTextfield,
  },
  clickable: {
    cursor: "pointer",
  },
  inputField: {
    width: "100%",
  },
  IconButton: {
    height: 0.1,
    width: 0.1,
  },
  paper: {
    left: "50%",
    transform: "translate(-50%, 0)",
    position: "absolute",
    width: 500,
    outline: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    borderRadius: 8,
    margin: `${theme.spacing.unit * 4}px 0`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    border: "none",
  },
  name: {
    margin: 0,
    color: '#2699fb',
    fontWeight: 600,
  },
  detail: {
    color: '#707070',
  },
  textField: {
    height: 62,
  },
  submitButton: {
    justifyContent: 'center',
    marginRight: 20,
    maxWidth: "288px",
    width: "100%",
    borderRadius: "8px",
  },
}));

function DialogEditAccount({
  onAdd,
  onCloseButton,
  user,
  old,
  baru,
  ulang,
  setPasswordBaru,
  setPasswordLama,
  setPasswordUlang,
  error,
  handleSubmit,
  showPassword,
  onClickShowPassword,
  onClickOffPassword,
  ...props
}) {
  const classes = useStyles();
  return (
    <Modal
      open={props.open}
      classes={{
        root: classes.modalRoot,
      }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <Paper className={classes.paper}>
        <DialogTitle>
          <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onCloseButton}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List>
            <p className={classes.name}>{user && user.name}</p>
            <p className={classes.detail}>{user && user.RoleActions.name}</p>
          </List>
          <List>
          <p>
            <TextField
              label="No. Hp"
              className={classes.textField}
              value={user && user.Confidentials.hp}
              fullWidth
              disabled
            />
          </p>
          <p>
            <TextField
              label="Email"
              className={classes.textField}
              value={user && user.Confidentials.email}
              fullWidth
              disabled
            />
          </p>
          <p>
          <TextField
            className={classes.textField}
            label="Password lama"
            type={showPassword ? "text" : "password"}
            value={old}
            fullWidth
            onChange={(event) => setPasswordLama(event.target.value)}
            error={error.old && validations.isEmpty(old)}
            helperText={
              error.old && validations.isEmpty(old)
                ? "Password tidak boleh kosong"
                : " "
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  id="eyeBtn"
                  aria-label="Toggle password visibility"
                  className={classes.IconButton}
                  onClick={showPassword === false ? onClickShowPassword : onClickOffPassword}
                  >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>,
            }}
          />
            </p>
            <p>
            <TextField
            className={classes.textField}
            label="Password Baru"
            type={showPassword ? "text" : "password"}
            value={baru}
            fullWidth
            onChange={(event) => setPasswordBaru(event.target.value)}
            error={error.baru && validations.isEmpty(baru)}
            helperText={
              error.baru && validations.isEmpty(baru)
                ? "Password tidak boleh kosong"
                : " "
            }
             InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  id="eyeBtn"
                  aria-label="Toggle password visibility"
                  className={classes.IconButton}
                  onClick={showPassword === false ? onClickShowPassword : onClickOffPassword}
                  >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>,
            }}
          />
              </p>
              <p>
            <TextField
            className={classes.textField}
            label="Konfirmasi Password"
            type={showPassword ? "text" : "password"}
            value={ulang}
            fullWidth
            onChange={(event) => setPasswordUlang(event.target.value)}
            error={error.ulang && validations.isEmpty(ulang)}
            helperText={
              error.ulang && validations.isEmpty(ulang)
                ? "Password tidak boleh kosong"
                : " "
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  id="eyeBtn"
                  aria-label="Toggle password visibility"
                  className={classes.IconButton}
                  onClick={showPassword === false ? onClickShowPassword : onClickOffPassword}
                  >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>,
            }}
          />
              </p>
          </List>
        </DialogContent>
        <DialogActions>
        <Button
          disableElevation
          variant="contained"
          size="large"
          color="blue"
          onClick={handleSubmit}
        >
          Submit
        </Button>
        </DialogActions>
      </Paper>
    </Modal>
  );
}

const Header = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const userId = useSelector(({ auth }) => get(auth, 'user.userId'));
  const [user, setUser] = useState(undefined);
  const [openChangeAccount, setOpenChangeAccount] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [old, setPasswordLama] = useState("");
  const [baru, setPasswordBaru] = useState("");
  const [ulang, setPasswordUlang] = useState("");
  const [error, setError] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const result = await getListAccount('', 0, 99999);
      setUser(result.data.items.find(val => userId === val.accountId));
    })();
  }, [userId]);

  const handleLogout = () => {
    props.logout();
  };

  const handleChangeAccount = () => {
    setOpenChangeAccount(true);
  };

  const handleCloseDialogAccount = () => {
    setOpenChangeAccount(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(true);
  };

  const handleClickOffPassword = () => {
    setShowPassword(false);
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const checkError = () => {
    const passBaruError = validations.isEmpty(baru);
    const passLamaError = validations.isEmpty(old);
    const passUlangError = validations.isEmpty(ulang);
    setError({
      baru: passBaruError,
      old: passLamaError,
      ulang: passUlangError,
    });
    return !!passBaruError || !!passLamaError || !!passUlangError;
  };

  const handleSubmit = () => {
    if (checkError()) {
      return;
    }
    if (old.length < 6) {
      dispatch(
        setSnackbar("warning", "Maaf! Password Lama kurang dari 6 digit")
      );
      return;
    }
    if (old.length < 6) {
      dispatch(
        setSnackbar("warning", "Maaf! Password Lama kurang dari 6 digit")
      );
      return;
    }
    if (baru.length < 6) {
      dispatch(
        setSnackbar("warning", "Maaf! Password Baru Minimal 6 digit")
      );
      return;
    }
    if (ulang.length < 6) {
      dispatch(
        setSnackbar("warning", "Maaf! Konfirmasi Password kurang dari 6 digit")
      );
      return;
    }
    if (ulang !== baru) {
      dispatch(
        setSnackbar("warning", "Maaf! Konfirmasi Password tidak sama dengan Password Baru")
      );
      return;
    }
    
    changePasswordInsideNew(old, baru)
    .then((res) => {
      if (res.data.status === 'wrong password') {
        dispatch(
          setSnackbar("warning", "Maaf! Password Lama tidak sesuai")
        );
      return;
      } else {
      console.log(res);
      dispatch(
      setSnackbar("success", "Sukses! Ganti password berhasil dilakukan")
      );
      setOpenChangeAccount(false);
    }})
  };


  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          className={classes.gridLeft}
          item
          xs={9}
          md={11}
          container
          justify="flex-end"
          alignItems="center"
        >
          <Typography variant="h2" color="primary">
            Warehouse
          </Typography>
        </Grid>
        <Grid
          className={classes.grid}
          item
          xs={3}
          md={1}
          container
          justify="center"
          alignItems="center"
        >
          <Avatar className={classes.clickable} onClick={openMenu}>
            WH
          </Avatar>
        </Grid>
      </Grid>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        getContentAnchorEl={null}
      >
        {user && <MenuItem onClick={handleChangeAccount}>{user.name}</MenuItem>}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <DialogEditAccount
        open={openChangeAccount}
        onCloseButton={handleCloseDialogAccount}
        handleSubmit={handleSubmit}
        user={user}
        error={error}
        setPasswordLama={setPasswordLama}
        setPasswordBaru={setPasswordBaru}
        setPasswordUlang={setPasswordUlang}
        old={old}
        baru={baru}
        ulang={ulang}
        showPassword={showPassword}
        onClickShowPassword={handleClickShowPassword}
        onClickOffPassword={handleClickOffPassword}
      />
    </div>
  );
};

export default connect(null, { logout })(Header);
