import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  makeStyles,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Modal,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  IconButton,
  ListItem,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import CustomTextField from "../custom/CustomTextField";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import CustomSelect from "../custom/CustomSelect";
import CustomDialog from "../custom/CustomDialog";
import CustomDatePicker from "../custom/CustomDatePicker";
import CustomPagination from "../custom/CustomPagination";
import CustomAutocomplete from "../custom/CustomAutocomplete";

import set from "lodash.set";
import omit from "lodash.omit";
import moment from 'moment';

import debounce from "awesome-debounce-promise";

import validations from "../../utils/validations";
import { formatPayloadInt } from "../../utils/formatting";
import useDisclosure from '../../utils/useDisclosure';

import routes from "../../constants/routes";
import { palette } from "../../theme";

import { setSnackbar } from "../../store/general/actions";
import {
  getPenerimaanDetail,
  processPenerimaan,
} from "../../services/PenerimaanService";
import { getDepotStock } from "../../services/InventoryService";
import { getListSpecificExpires } from "../../services/StockExpires";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  inputField: {
    width: "100%",
  },
  paper: {
    left: "50%",
    transform: "translate(-50%, 0)",
    position: "absolute",
    width: 900,
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    borderRadius: 8,
    margin: `${theme.spacing.unit * 4}px 0`,
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  header: {
    lineHeight: "60px",
    fontWeight: "bold",
  },
  headerGrid: {
    marginBottom: 20,
  },
  numbering: {
    backgroundColor: palette.lightBlue,
    color: palette.white,
    fontWeight: "normal",
    borderRadius: "100%",
    padding: 4,
    paddingLeft: 7,
    paddingRight: 7,
    fontSize: 10,
    marginRight: 10,
  },
  select: {
    width: "100%",
  },
  inputWithSelect: {
    paddingLeft: 10,
  },
  grandTotal: {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.whiteBlue,
      color: theme.palette.titleText,
      fontWeight: "bold",
    },
  },
  textButton: {
    color: palette.button,
    borderRadius: 50,
    textTransform: "none",
    fontWeight: "bold",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    border: "none",
  },
}));

const debounceGetSpecific = debounce(getListSpecificExpires, 500);

function DialogBatch({
  onAdd,
  onDelete,
  onClose,
  onCloseButton,
  onAddBatches,
  onAddNewBatches,
  onChangeQuantity,
  onChangeDate,
  items,
  batches,
  setSearchBatches,
  searchBatches,
  ...props
}) {
  const classes = useStyles();
  return (
    <Modal
      open={props.open}
      classes={{
        root: classes.modalRoot,
      }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <Paper className={classes.paper}>
        <DialogTitle>
          Setup Batch
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onCloseButton}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ overflow: items.length > 3 ? "auto" : "visible", height: items.length > 5 ? "500px" : "auto" }}>
          <List>
            {items.map((item, i) => (
              <ListItem key={i}>
                <Grid
                  container
                  spacing={2}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item md={3}>
                    <CustomAutocomplete
                      disabled
                      options={batches}
                      onInputChange={(event, newInputValue) =>
                        setSearchBatches((s) => {
                          const copy = [...s];
                          copy[i] = newInputValue;
                          return copy;
                        })
                      }
                      getOptionLabel={(option) => option.batchNo}
                      getOptionSelected={(option, value) =>
                        option.batchNo === value.batchNo
                      }
                      inputValue={item.batchNo || searchBatches[i]}
                      label="Nomor Batch"
                      getOptionListLabel={(option) => option.batchNo}
                      onChange={(event, newValue, reason, details) => {
                        if (typeof newValue === "string") {
                          onAddBatches(details, i);
                        } else if (newValue && newValue.inputValue) {
                          onAddNewBatches(newValue, i);
                        } else {
                          onAddBatches(details, i);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = [...options];
                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            batchNo: params.inputValue,
                          });
                        }
                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label="Batch Stock"
                      value={item.stock}
                      disabled
                      InputLabelProps={{ shrink: true }}
                      id="batchStock"
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      fullWidth
                      label="Jumlah"
                      id="quantityReceive"
                      onChange={onChangeQuantity(i)}
                      value={item.quantityReceive}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <CustomDatePicker
                      disabled={item.isOld}
                      label="Tanggal Kadaluarsa"
                      date={new Date(item.expireDate)}
                      placeholder="Set Tanggal"
                      onChange={onChangeDate(i)}
                    />
                  </Grid>
                  {/* <Grid item md={1}>
                    <IconButton
                      onClick={() => onDelete(i)}
                      color="secondary"
                      size="small"
                    >
                      <Delete />
                    </IconButton>
                  </Grid> */}
                </Grid>
              </ListItem>
            ))}
          </List>
          {/* <Button onClick={onAdd}>Tambah</Button> */}
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            variant="contained"
            size="large"
            onClick={onClose}
          >
            Simpan
          </Button>
        </DialogActions>
      </Paper>
    </Modal>
  );
}

const Item = (props) => {
  const classes = useStyles();
  const { item, setData, data } = props;
  const [depots, setDepots] = useState([]);
  const [batches, setBatches] = useState([]);
  const [searchBatches, setSearchBatches] = useState(
    item.batchItems.map((a) => a.batchNo)
  );
  const [usePercent, setUsePercent] = useState(
    item.discountType === 'PERCENTAGE' ? true : false
  );
  const [openBatch, setOpenBatch] = useState(false);

  useEffect(() => {
    getDepotStock(item.itemId, 0, 100).then((res) => {
      setDepots(
        res.data.items.map((e) => ({
          label: `${e.depotName} (${e.rackName})`,
          value: e._id,
        }))
      );
    });
  }, [item.itemId]);

  useEffect(() => {
    debounceGetSpecific(item.itemId).then((res) => {
      setBatches(res.data.items);
    });
  }, [searchBatches, item.itemId]);

  if (!item) {
    return;
  }

  const calculateTotal = () => {
    let discount;
    if (usePercent) {
      discount = parseInt(item.buyFee, 10) - calculateDiscountPercentToRp();
    } else {
      discount = parseInt(item.buyFee, 10) - parseInt(item.discountRp || 0, 10);
    }
    return discount;
  };

  const calculateDiscountPercentToRp = (discount) => {
    let discountToUse = discount || item.discountPercent;
    const percent =
      parseInt(item.buyFee, 10) * 0.01 * parseInt(discountToUse || 0, 10);
    return percent;
  };

  const calculateDiscountRpToPercent = (discount) => {
    let discountToUse = discount || item.discountRp;
    const buyFee = parseInt(item.buyFee, 10);
    const discountRp = parseInt(discountToUse || 0, 10);
    return (discountRp / buyFee) * 100;
  };

  const handleChangePercent = (event) => {
    const { value } = event.target;
    if (usePercent) {
      props.onChangeData("discountPercent", value);
      props.onChangeData("discountRp", calculateDiscountPercentToRp(value));
    } else {
      props.onChangeData(
        "discountPercent",
        calculateDiscountRpToPercent(value)
      );
      props.onChangeData("discountRp", value);
    }
  };

  const handleBatches = (details, i) => {
    if (details) {
      props.onChangeBatches(
        i,
        "batchNo"
      )({ target: { value: details.option.batchNo } });
      props.onChangeBatches(
        i,
        "expireDate"
      )({ target: { value: details.option.expiredDate } });
    } else {
      props.onChangeBatches(i, "batchNo")({ target: { value: undefined } });
    }
  };

  const handleNewBatches = (value, i) => {
    props.onChangeBatches(
      i,
      "batchNo"
    )({ target: { value: value.inputValue } });
  };

  const handleChangeQuantity = (i) => (event) => {
    const value = event.target.value;

    props.onChangeBatches(i, "quantityReceive")({ target: { value: value } });
  };

  const handleChangeDate = (i) => (value) => {
    props.onChangeBatches(i, "expireDate")({ target: { value: value } });
  };

  const handleOpen = (i) => {
    setOpenBatch(true);
    props.setIndexOpened(i);
  };
  return (
    <div>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          className={`${classes.borderBottom} ${classes.headerGrid}`}
        >
          <Typography variant="body2" className={classes.header}>
            <b className={classes.numbering}>{props.index + 1}</b>
            {item.name}
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Jumlah Diterima"
              value={item.quantityReceive}
              type="number"
              className={classes.inputField}
              onChange={(event) => {
                if (+event.target.value >= props.item.quantityPO) {
                  props.onChangeData("quantityReceive", +props.item.quantityPO);
                  setData({ ...data, ppnRp: ((parseInt(data.ppnPercent || 0, 10) / 100) * props.countSubTotal()),
                  ppnPercent: parseInt(data.ppnPercent || 0, 10), });
                  return;
                }
                props.onChangeData("quantityReceive", +event.target.value);
                setData({ ...data, ppnRp: ((parseInt(data.ppnPercent || 0, 10) / 100) * props.countSubTotal()),
                ppnPercent: parseInt(data.ppnPercent || 0, 10), });
              }}
              placeholder={item.quantityPO}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Jumlah PO"
              value={item.quantityPO}
              className={classes.inputField}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Satuan"
              value={item.bigUnit}
              className={classes.inputField}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Sub Kategori"
              value={item.subCategory}
              className={classes.inputField}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Harga Beli"
              value={item.buyFee}
              currency
              className={classes.inputField}
              onChange={(event) =>
                props.onChangeData("buyFee", event.target.value)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Grid container alignItems="flex-end">
              <Grid item xs={4}>
                <CustomSelect
                  value={usePercent}
                  label="Diskon (%/Rp)"
                  className={classes.select}
                  onChange={(event) => setUsePercent(event.target.value)}
                >
                  <MenuItem value={true}>%</MenuItem>
                  <MenuItem value={false}>Rp</MenuItem>
                </CustomSelect>
              </Grid>
              <Grid item xs={8} className={classes.inputWithSelect}>
                <CustomTextField
                  className={classes.inputField}
                  value={usePercent ? item.discountPercent : item.discountRp}
                  onChange={handleChangePercent}
                  type={usePercent ? "number" : "text"}
                  currency={!usePercent}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Harga Beli Total"
              value={calculateTotal()}
              className={classes.inputField}
              currency
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Harga Jual"
              value={item.sellFee}
              className={classes.inputField}
              currency
              onChange={(event) =>
                props.onChangeData("sellFee", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomSelect
              value={item.stockDepotId}
              label="Tujuan Depot"
              className={classes.select}
              onChange={(event) =>
                props.onChangeData("stockDepotId", event.target.value)
              }
            >
              {depots.map((data) => (
                <MenuItem key={data.value} value={data.value}>
                  {data.label}
                </MenuItem>
              ))}
            </CustomSelect>
          </Grid>
          <Grid item xs={3} style={{ paddingTop: "2em" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: 150 }}
              onClick={() => handleOpen(props.index)}
            >
              Setup Batch
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DialogBatch
        open={openBatch}
        onClose={props.onClose(() => setOpenBatch(false), props.index)}
        onCloseButton={props.onCloseButton(() => setOpenBatch(false), props.index)}
        onAdd={props.onAdd}
        onDelete={props.onDelete}
        onAddBatches={handleBatches}
        onAddNewBatches={handleNewBatches}
        onChangeQuantity={handleChangeQuantity}
        onChangeDate={handleChangeDate}
        items={props.item.batchItems}
        batches={batches}
        setSearchBatches={setSearchBatches}
        searchBatches={searchBatches}
      />
    </div>
    
  );
};


const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const EditPenerimaanBarang = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    open: isSubmitting,
    onOpen: setSubmitting,
    onClose: setSubmitted,
  } = useDisclosure();
  const { receiveId } = useParams();

  const [data, setData] = useState({
    code: "",
  });
  const [pagination, setPagination] = useState(paginationInitialData);
  const [totalData, setTotalData] = useState(0);
  const [indexOpened, setIndexOpened] = useState(-1);
  const [usePercent, setUsePercent] = useState(true);
  const [openDialog, setOpenDialog] = useState({
    open: false,
    isHalfReceive: false,
  });

  // const disableTerimaSebagian = useMemo(() => {
  //   if (!data.item) return false;
  //   return data.item.every((item) => +item.quantityReceive >= item.quantityPO);
  // }, [data]);

  useEffect(() => {
    async function fetchRO() {
      const {
        data: { item: { code , ...item }}
      } = await getPenerimaanDetail(receiveId);
      const expires = await Promise.all(
        item.item.map((item) => getListSpecificExpires(item.itemId))
      ).then((items) => Promise.resolve(items.map((i) => i.data)));
      if (item.dueDate) {
        item.dueDate = moment(item.dueDate).add(+item.termOfPayment, 'd').toDate();
      }
      const dt = {
        ...item,
        item: item.item.map(i => {
          const newData = { ...i };
          delete newData.batchNo;
          return newData;
        }).map((i, index) => ({
          ...i,
          batches: expires[index].items,
        })),
      };
      setData(dt);
      setTotalData(item?.item.length);
    }
    fetchRO();
  }, [receiveId]);

  const deleteBatchItem = (batchIndex) => {
    setData(({ item, ...rest }) => {
      item[indexOpened].batchItems.splice(batchIndex, 1);
      return {
        ...rest,
        item,
      };
    });
  };

  const changeBatchItem = useCallback(
    (batchIndex, propName) => {
      return (evt) => {
        const value = evt.target.value;
        if (!value) return;
        setData(({ item, ...res }) => {
          if (propName === "batchNo") {
            const cek = item[indexOpened].batchItems.find(
              (val) => val.batchNo === value
            );
            const opt = item[indexOpened].batches.find(
              (val) => val.batchNo === value
            );
            if (cek) {
              dispatch(
                setSnackbar("warning", "Maaf! Silakan memilih batch yang berbeda")
              );
            } else if (opt !== undefined) {
              item[indexOpened].batchItems[batchIndex][propName] = value;
              item[indexOpened].batchItems[batchIndex].depotName =
                opt.depotName;
              item[indexOpened].batchItems[batchIndex].expireDate = new Date(
                opt.expiredDate
              );
              item[indexOpened].batchItems[batchIndex].expireId = opt._id;
              item[indexOpened].batchItems[batchIndex].rackName = opt.rackName;
              item[indexOpened].batchItems[batchIndex].stock = opt.stock;
              item[indexOpened].batchItems[batchIndex].isOld = true;
            } else {
              item[indexOpened].batchItems[batchIndex][propName] = value;
              item[indexOpened].batchItems[batchIndex].isOld = false;
            }
          }

          if (propName === "quantityReceive") {
            const parsedValue = value < 0 ? 0 : +value;
            item[indexOpened].batchItems[batchIndex][propName] = parsedValue;
            // if (
            //   parsedValue > item[indexOpened].batchItems[batchIndex].stock &&
            //   item[indexOpened].batchItems[batchIndex].isOld
            // ) {
            //   dispatch(
            //     setSnackbar(
            //       "warning",
            //       "Jumlah tidak boleh melebihi jumlah stok batch!"
            //     )
            //   );
            //   item[indexOpened].batchItems[batchIndex][propName] = 0;
            // }
          }
          if (propName === "expireDate") {
            item[indexOpened].batchItems[batchIndex][propName] = new Date(
              value
            );
          }

          return { ...res, item };
        });
      };
    },
    [dispatch, indexOpened]
  );

  const handleCloseDialogBatch = (callbackClose, index) => () => {
    const cumulativeItemValue = data.item[index].batchItems.reduce(
      (totalBatch, current) => totalBatch + current.quantityReceive,
      0
    );
    const cek = data.item[index].batchItems.find(
      (val) => val.expireDate === null
    );
    const cekReceived = data.item[index].batchItems.find(
      (val) => val.quantityReceive === 0
    );
    if (+cumulativeItemValue > data.item[index].quantityReceive) {
      dispatch(setSnackbar("warning", "Maaf! Jumlah tidak boleh melebihi jumlah yang diterima"));
    } else if (cek) {
      dispatch(setSnackbar("warning", "Maaf! Silakan isi tanggal kadaluarsa terlebih dahulu"));
    } else if (cekReceived) {
      dispatch(setSnackbar("warning", "Maaf! Jumlah yang diterima tidak boleh 0"));
    }  else {
      callbackClose();
      setIndexOpened(-1);
    }
  };

  const handleCloseDialogBatchButton = (callbackClose, index) => () => {
    const cumulativeItemValue = data.item[index].batchItems.reduce(
      (totalBatch, current) => totalBatch + current.quantityReceive,
      0
    );
    const cek = data.item[index].batchItems.find(
      (val) => val.expireDate === null
    );
    const cekReceived = data.item[index].batchItems.find(
      (val) => val.quantityReceive === 0
    );
    if (+cumulativeItemValue > data.item[index].quantityReceive) {
      setData(({ item, ...rest }) => {
        const quantity = data.item[index].batchItems.splice(index);
        set(item, `[${index}].item`, quantity)
        return { ...rest, item };
      });
    callbackClose();
    } else if (cek) {
      setData(({ item, ...rest }) => {
        const batchItemBaru = data.item[index].batchItems.filter((val) => val.expireDate !== null)
        set(item, `[${index}].batchItems`, batchItemBaru)
        return { ...rest, item };
      });
    callbackClose();
    } else if (cekReceived) {
      setData(({ item, ...rest }) => {
        const quantityCek = data.item[index].batchItems.filter((val) => val.quantityReceive !== 0)
        set(item, `[${index}].batchItems`, quantityCek)
        return { ...rest, item };
      }); 
    callbackClose();
    } else {
      callbackClose();
      setIndexOpened(-1);
    }
  };

  const countSubTotal = () => {
    const total = data.item?.reduce(
      (a, b) => a + parseInt(b.buyFee, 10) * b.quantityReceive,
      0
    );
    return total - countDiscount();
  };

  const countDiscount = () => {
    return data.item?.reduce(
      (a, b) => a + parseInt(b.discountRp, 10) * b.quantityReceive,
      0
    );
  };

  const countDiscountToPercent = () => {
    const total = data.item?.reduce((a, b) => a + parseInt(b.buyFee, 10), 0);
    const discount = countDiscount();

    return (discount / total) * 100;
  };

  const countTotal = () => {
    const total = countSubTotal();
    if (usePercent) {
      return total + total * (parseInt(data.ppnPercent || 0, 10) / 100);
    } else {
      return total + parseInt(data.ppnRp || 0, 10);
    }
  };

  const addBatchItem = () => {
    setData(({ item, ...rest }) => {
      item[indexOpened].batchItems.push({
        batchNo: null,
        depotName: null,
        quantityReceive: 0,
        expireDate: new Date(),
        expireId: null,
        rackName: "",
        stock: "",
        isOld: false,
      });
      return {
        ...rest,
        item,
      };
    });
  };

  const handleSave = (isHalfReceive) => () => {
    if (validations.isEmpty(data.invoiceNo)) {
      dispatch(
        setSnackbar("warning", "Maaf! Silahkan isi kode faktur terlebih dahulu")
      );
      return;
    }
    setSubmitting();
    const formattedData = formatPayloadInt(
      {
        ...data,
        invoiceNo: data.invoiceNo,
        items: [],
        subTotalFee: countSubTotal(),
        totalFee: countTotal() % 2 ? parseFloat(countTotal().toFixed(2)) : parseInt(countTotal()),
        discountRp: countDiscount(),
        discountPercent: countDiscountToPercent(),
        totalDiscountFee: countDiscount(),
        isHalfReceive,
        ppnRp: (data.ppnRp === 0 && data.ppnPercent !== 0) ? (parseInt(data.ppnPercent || 0, 10) / 100) * countSubTotal() :
        parseInt(data.ppnRp) || 0,
        isEdit: true,
      },
      [
        "ppnPercent",
        "discountRp",
        "discountPercent",
        "subTotalFee",
        "totalDiscountFee",
      ]
    );
    formattedData.item &&
      formattedData.item.forEach((item) => {
        item.ppnPercent = data.ppnPercent;
        item.ppnRp = (item.buyFee - item.discountRp) * (data.ppnPercent / 100);
        formattedData.items.push(
          formatPayloadInt(item, [
            "buyFee",
            "discountPercent",
            "sellFee",
            "quantityReceive",
            "discountRp",
            "ppnPercent",
            "ppnRp",
          ])
        );
      });

    const noZeroAll = formattedData.item.every(
      (f) => f.batchItems.length !== 0
    );
    const emptyBatch = formattedData.item.every((f) =>
      f.batchItems.every((g) => g.batchNo === null || g.expireDate === null)
    );
    if (noZeroAll && !emptyBatch) {
      processPenerimaan(receiveId, omit(formattedData, ["item", "code"]))
        .then((res) => {
          if (res.data.status === "success") {
            dispatch(
              setSnackbar(
                "success",
                "Sukses! Edit Penerimaan barang berhasil dilakukan"
              )
            );
            history.push(routes.PENERIMAAN);
          } else {
            dispatch(
              setSnackbar("error", "Error! Terjadi kesalahan. Coba beberapa saat lagi")
            );
          }
          setSubmitted();
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            setSnackbar("error", "Error! Terjadi kesalahan. Coba beberapa saat lagi")
          );
          setSubmitted();
        });
    } else {
      dispatch(
        setSnackbar(
          "warning",
          "Maaf! Silakan isi nomor batch / tanggal kadaluarsa terlebih dahulu"
        )
      );
      setSubmitted();
      return;
    }
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={`Edit Penerimaan Barang ${data.code || data.invoiceNo || ""}`}
        previousPageName="Penerimaan Barang"
        previousPageLink={routes.PENERIMAAN}
      />
      <Grid container>
        <Grid
          container
          item
          xs={12}
          className={`${classes.borderBottom} ${classes.headerGrid}`}
        >
          <Typography variant="body2" className={classes.header}>
            Data Pesanan & Penerimaan
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Nomor Faktur"
              className={classes.inputField}
              value={data.invoiceNo}
              onChange={(e) => {
                const newData = { ...data };
                newData.invoiceNo = e.target.value;
              setData(newData);
              }}
              error={validations.isEmpty(data.invoiceNo)}
              helperText={
                validations.isEmpty(data.invoiceNo)
                  ? "Nomor Faktur Wajib Diisi!"
                  : " "
              }
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Kode PO"
              value={data.poCode}
              className={classes.inputField}
              disabled
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Nama Pembuat PO"
              value={data.poPIC}
              className={classes.inputField}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Nama Penerima Barang"
              value={data.receivePIC}
              className={classes.inputField}
              disabled
            />
            <Grid item xs={3} />
            <Grid item xs={3} />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Nama Supplier"
              value={data.supplierName}
              className={classes.inputField}
              disabled
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>
      </Grid>
      {data.item?.map((value, index) => (
        <Item
          key={index}
          item={value}
          index={index}
          onChangeData={(property, value) => {
            const newData = { ...data };
            set(newData, `item[${index}].${property}`, value);
            setData(newData);
          }}
          onClose={handleCloseDialogBatch}
          onCloseButton={handleCloseDialogBatchButton}
          onAdd={addBatchItem}
          onDelete={deleteBatchItem}
          onChangeBatches={changeBatchItem}
          setIndexOpened={setIndexOpened}
          countSubTotal={countSubTotal}
          setData={setData}
          data={data}
          indexOpened={indexOpened}
        />
      ))}
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={`${classes.borderBottom} ${classes.headerGrid}`}
      >
        <Grid container item md={6} sm={8}>
          <CustomPagination
            page={pagination.page}
            onChangePage={(event, newPage) =>
              setPagination({ ...pagination, page: newPage })
            }
            rowsPerPage={pagination.rowsPerPage}
            onChangeRowsPerPage={(event) =>
              setPagination({
                ...pagination,
                rowsPerPage: parseInt(event.target.value, 10),
              })
            }
            labelDisplayedRows={({ from, to, count }) =>
              from + "-" + to + " dari " + count + " Item"
            }
            count={totalData}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Sub Total"
              value={countSubTotal()}
              className={classes.inputField}
              disabled
              currency
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Total Diskon Didapatkan"
              value={countDiscount()}
              className={classes.inputField}
              disabled
              currency
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <Grid container alignItems="flex-end">
              <Grid item xs={4}>
                <CustomSelect
                  value={usePercent}
                  label="Pajak (%/Rp)"
                  className={classes.select}
                  onChange={(event) =>
                    setUsePercent(event.target.value)}
                >
                  <MenuItem value={true}>%</MenuItem>
                  <MenuItem value={false}>Rp</MenuItem>
                </CustomSelect>
              </Grid>
              <Grid item xs={8} className={classes.inputWithSelect}>
                <CustomTextField
                  className={classes.inputField}
                  value={usePercent ? data.ppnPercent : data.ppnRp}
                  onChange={(event) => {
                    if (usePercent) {
                      const newData = { ...data };
                      newData.ppnPercent = event.target.value;
                      newData.ppnRp = (parseInt(newData.ppnPercent || 0, 10) / 100) * (data.subTotalFee - data.discountRp);
                      setData(newData);
                    } else {
                      const newData = { ...data };
                      newData.ppnRp = event.target.value;
                      newData.ppnPercent = ((parseInt(newData.ppnRp || 0, 10) / (data.subTotalFee - data.discountRp)) * 100)
                      setData(newData);
                    }
                  }}
                  // onChange={(event) =>
                  //   setData({
                  //     ...data,
                  //     [usePercent ? "ppnPercent" : "ppnRp"]: event.target.value,
                  //   })
                  // }
                  type={usePercent ? "number" : "text"}
                  currency={!usePercent}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <CustomDatePicker
              label="Tanggal Jatuh Tempo"
              date={data.dueDate}
              placeholder="Set Tanggal Jatuh Tempo"
              onChange={(date) => setData({ ...data, dueDate: date })}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Total Seluruhnya"
              value={countTotal() % 2 ? countTotal().toFixed(2) : countTotal()}
              className={`${classes.inputField} ${classes.grandTotal}`}
              currency
              disabled
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => history.push(routes.PENERIMAAN)}
            >
              Batalkan
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              onClick={() => {
                setOpenDialog({
                  open: true,
                  isHalfReceive: false,
                });
              }}
            >
              Barang Diterima
            </Button>
            {/* <Button
              disableElevation
              variant="text"
              disabled={disableTerimaSebagian}
              size="large"
              className={classes.textButton}
              onClick={() => {
                setOpenDialog({
                  open: true,
                  isHalfReceive: true,
                });
              }}
            >
              Barang Diterima Sebagian
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
      {openDialog.open && (
        <CustomDialog
          open={openDialog.open}
          handleClose={() =>
            setOpenDialog({ open: false, isHalfReceive: false })
          }
          title={`Penerimaan Barang ${
            openDialog.isHalfReceive ? "(Sebagian)" : ""
          }`}
          body={"Apakah anda yakin akan melakukan penerimaan barang tersebut?"}
          onCancel={() => setOpenDialog({ open: false, isHalfReceive: false })}
          cancelButtonText="Batalkan"
          onAccept={isSubmitting ? null : handleSave(openDialog.isHalfReceive)}
          acceptButtonText={"Terima"}
          acceptButtonColor="green"
        />
      )}
    </div>
  );
};

export default EditPenerimaanBarang;
