import { createMuiTheme } from "@material-ui/core";

export const palette = {
  primary: {
    main: "#0A70C7",
  },
  blue: "#0A70C7",
  black: "#25282B",
  white: "#FFFFFF",
  red: "#EB5757",
  yellow: "#FFBE3B",
  green: "#31B057",
  whiteBlue: "#EDF4FA",
  lightBlue: "#2598FA",
  primaryText: "#25282B",
  secondaryText: "#666666",
  placeholderText: "rgba(37,40,43,0.2)",
  titleText: "#0A70C7",
  normalTextfield: "#F4F4F4",
  filledTextfield: "",
  typingTextfield: "#edf4fa",
  disabledTextfield: "#D6D6D6",
  errorTextfield: "#FFEEF3",
  button: "#0A70C7",
  buttonPressed: "#085599",
  buttonPressedSecondary: "#F4F4F4",
};

export default createMuiTheme({
  palette: palette,
  typography: {
    fontFamily: "Roboto",
  },
  overrides: {
    MuiButton: {
      root: {
        height: 40,
      },
      contained: {
        color: palette.white,
        backgroundColor: palette.button,
        borderRadius: 50,
        textTransform: "none",
        "&:hover": { backgroundColor: palette.buttonPressed },
        fontWeight: "bold",
      },
      containedPrimary: {
        color: palette.button,
        backgroundColor: palette.white,
        "&:hover": { backgroundColor: palette.whiteBlue },
      },
      containedSecondary: {
        color: palette.secondaryText,
        backgroundColor: "transparent !important",
        "&:hover": {
          backgroundColor: `${palette.buttonPressedSecondary} !important`,
        },
      },
      textSizeLarge: {
        minWidth: 168,
        maxWidth: 300,
        height: 43,
        fontSize: 17,
        lineHeight: "18px",
      },
      containedSizeLarge: {
        minWidth: 168,
        maxWidth: 200,
        height: 43,
        fontSize: 17,
        lineHeight: "18px",
      },
      containedSizeSmall: {
        minWidth: 0,
        maxWidth: "none",
        height: 38,
        fontSize: 12,
        lineHeight: "13px",
        padding: "0px 16px",
        border: "2px solid",
        borderColor: palette.button,
      },
      outlined: {
        color: palette.button,
        borderColor: palette.button,
        backgroundColor: palette.white,
        borderRadius: 50,
        textTransform: "none",
        "&:hover": { backgroundColor: palette.buttonPressedSecondary },
        fontWeight: "bold",
        border: "2px solid",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
        backgroundColor: palette.normalTextfield,
        border: "none",
        width: 288,
        height: 40,
        caretColor: palette.blue,
        fontSize: 14,
        "&$disabled": { backgroundColor: palette.disabledTextfield },
        "&$focused": { backgroundColor: palette.typingTextfield },
        "&$error": { backgroundColor: palette.errorTextfield },
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          {
            "-webkit-appearance": "none",
            margin: 0,
          },
      },
      input: {
        padding: 0,
        paddingLeft: 16,
        paddingRight: 16,
      },
      notchedOutline: {
        border: "none",
      },
      adornedStart: {
        color: palette.secondaryText,
      },
      adornedEnd: {
        color: palette.secondaryText,
      },
      inputAdornedStart: {
        paddingLeft: 8,
      },
    },
    MuiInputLabel: {
      root: {
        color: palette.secondaryText,
        fontSize: 12,
        marginBottom: 8,
      },
      asterisk: {
        color: palette.red,
        paddingLeft: 2,
      },
    },
    MuiFormHelperText: {
      root: {
        paddingLeft: 16,
        marginTop: 8,
        fontSize: 12,
        "&$error": { color: palette.red },
      },
    },
    MuiIconButton: {
      root: {
        width: 40,
        height: 40,
        border: "2px solid",
        "& .MuiSvgIcon-root": {
          width: 22,
        },
        "&.Mui-disabled": {
          borderColor: palette.disabledTextfield,
        },
      },
      colorPrimary: {
        borderColor: palette.button,
        color: palette.button,
      },
      colorSecondary: {
        borderColor: palette.red,
        color: palette.red,
      },
    },
    MuiTypography: {
      h1: { fontSize: 30, lineHeight: "40px", fontWeight: "bold" },
      h2: { fontSize: 22, lineHeight: "30px", fontWeight: "bold" },
      body1: { fontSize: 17, lineHeight: "24px" },
      body2: { fontSize: 14, lineHeight: "24px" },
      subtitle1: { fontSize: 12, lineHeight: "24px" },
      colorTextPrimary: { color: palette.primaryText },
      colorTextSecondary: { color: palette.secondaryText },
      colorPrimary: {
        color: palette.titleText,
        fontFamily: "Playfair",
        fontWeight: "bold",
      },
    },
    MuiTableCell: {
      root: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      head: {
        backgroundColor: palette.whiteBlue,
        color: palette.titleText,
        fontWeight: "bold",
        fontSize: 14,
      },
      body: {
        borderBottomWidth: 4,
        borderBottomColor: palette.whiteBlue,
        fontSize: 14,
        paddingTop: 10,
        paddingBottom: 10,
      },
      footer: {
        fontSize: 14,
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "white",
      },
    },
    MuiTab: {
      root: {
        fontWeight: "bold",
        padding: 0,
        textTransform: "none",
        fontSize: 16,
        minWidth: "0px !important",
        paddingRight: 40,
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      wrapper: {
        alignItems: "flex-start",
      },
    },
    MuiTablePagination: {
      selectRoot: {
        marginRight: 15,
      },
      select: {
        fontWeight: "bold",
      },
      selectIcon: {
        color: palette.primaryText,
      },
    },
    MuiCheckbox: {
      root: {
        border: "none",
        "& .MuiSvgIcon-root": {
          width: 30,
          height: 30,
        },
      },
    },
    MuiSelect: {
      select: {
        height: 40,
        lineHeight: "40px",
        backgroundColor: "transparent",
        "&:focus": { backgroundColor: "transparent" },
        borderRadius: 50,
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 0,
      },
      list: {
        padding: 0,
      },
    },
    MuiMenuItem: {
      root: {
        "&:hover": { backgroundColor: palette.whiteBlue },
        "&$selected": {
          backgroundColor: palette.whiteBlue,
          "&:hover": { backgroundColor: palette.whiteBlue },
        },
      },
    },
    MuiButtonGroup: {
      grouped: {
        textTransform: "none",
        paddingRight: 30,
        paddingLeft: 30,
        borderColor: palette.disabledTextfield,
        color: palette.disabledTextfield,
        borderRadius: 10,
      },
    },
    MuiAutocomplete: {
      endAdornment: {
        display: "none",
      },
      inputRoot: {
        width: "100%",
        '&&[class*="MuiOutlinedInput-root"] $input': {
          padding: 0,
        },
      },
      paper: {
        width: "50%",
      },
      option: {
        fontSize: 12,
        color: palette.secondaryText,
        paddingTop: 15,
        paddingBottom: 15,
        "&:hover": {
          backgroundColor: palette.typingTextfield,
        },
      },
    },
    MuiDialog: {
      paper: {
        width: 475,
        borderRadius: 10,
      },
    },
    MuiRadio: {
      root: {
        color: palette.blue,
        "&.MuiIconButton-root": {
          border: "none",
          width: 0,
          height: 0,
        },
        "& .MuiSvgIcon-root": {
          width: 20,
          height: 20,
        },
      },
      colorPrimary: {
        color: palette.blue,
      },
    },
    MuiSwitch: {
      root: {
        "& .MuiIconButton-root": {
          border: "none",
          width: 24,
          height: 24,
        },
      },
    },
  },
});
