import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import Print from '../../utils/Print';


export default function PrintReturnBarang({
  itemsReturn, itemsDemage
}) {
  if (itemsDemage.length === 0) {
    return (
      <Print isHidden>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nama barang</TableCell>
              <TableCell>Jumlah Return</TableCell>
              <TableCell>Nomor Batch</TableCell>
              <TableCell>Depot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemsReturn.map((itemReturn) => (
              <TableRow>
                <TableCell>{itemReturn.name}</TableCell>
                <TableCell>{itemReturn.quantityRequest}</TableCell>
                <TableCell>
                  {itemReturn.batchNo}
                </TableCell>
                <TableCell>
                  {itemReturn.depotName || '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Print>
    );
  } else {
    return (
      <Print isHidden>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nama barang</TableCell>
              <TableCell>Jumlah Return Rusak</TableCell>
              <TableCell>Nomor Batch</TableCell>
              <TableCell>Depot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemsDemage.map((itemsDemage) => (
              <TableRow>
                <TableCell>{itemsDemage.name}</TableCell>
                <TableCell>{itemsDemage.quantityRequest}</TableCell>
                <TableCell>
                  {itemsDemage.batchNo}
                </TableCell>
                <TableCell>
                  {itemsDemage.depotName || '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Print>
    );
  }
}
