import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import routes, { billingRoutes } from "../../constants/routes";

import Billing from "./Billing";
import BillingDetail from "./BillingDetails";
import Payment from "./Payment";

const getRoute = (route) => {
  return routes.BILLING + route;
};

const BillingRoute = () => {
  return (
    <Switch>
      <Route path={getRoute(billingRoutes.BILLING)} exact>
        <Billing />
      </Route>
      <Route
        path={getRoute(billingRoutes.BILLING_DETAIL) + "/:invoiceId"}
        exact
      >
        <BillingDetail />
      </Route>
      <Route path={getRoute(billingRoutes.PAYMENT) + "/:invoiceId"} exact>
        <Payment />
      </Route>

      <Redirect to={getRoute(billingRoutes.BILLING)} />
    </Switch>
  );
};

export default BillingRoute;
