import { getPlaceId } from "../store/auth/selector";
import agent from "./agent";

export const getListSpecificExpires = (itemId) => {
  return agent.get("/stockExpires/getListSpecific", {
    params: {
      filter: { placeId: getPlaceId(), itemId },
    },
  });
};

export const getListSpecificByDepotId = (
  itemId,
  stockDepotId,
  batchNo,
  skip,
  limit
) => {
  return agent.get("stockExpires/getListSpecificByDepotId", {
    params: {
      filter: { placeId: getPlaceId(), itemId, stockDepotId, batchNo },
      skip: skip * limit,
      limit,
    },
  });
};
