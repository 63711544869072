import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { palette } from "../../theme";
import CustomPagination from "../custom/CustomPagination";
import { getSpecificStockExpires } from "../../services/InventoryService";
import CircularProgress from "@material-ui/core/CircularProgress";

import debounce from "awesome-debounce-promise";
import moment from "moment";

// import moment from "moment";
import routes, { inventoryRoutes } from "../../constants/routes";
import useCekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {},
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  editButton: {
    float: "right",
    marginLeft: 5,
    color: palette.blue,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  topGrid: {
    display: "flex",
  },
  marginContainer: {
    marginLeft: 20,
  },
  alignRight: {
    textAlign: "right",
  },
  buttonKadaluarsa: {
    maxWidth: 210,
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debouncedSpecificStock = debounce(getSpecificStockExpires, 500);

const KadaluarsaItem = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = useCekHakAkses();

  const { itemID } = useParams();

  const { search } = useLocation();
  const q = new URLSearchParams(search);
  const source = q.get("source");

  const [searchForm] = useState({});
  const [pagination, setPagination] = useState(paginationInitialData);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [isFetchingData, setIsFetchingData] = useState(false);

  useEffect(() => {
    setIsFetchingData(true);
    debouncedSpecificStock(
      itemID,
      pagination.page,
      pagination.rowsPerPage
    ).then((res) => {
      setTotalData(res.data.count);
      setTableData(res.data.items);
    }).finally(() => setIsFetchingData(false));
  }, [searchForm, pagination, itemID]);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        {cekRole('inventory.f17') &&
          <Grid item xs={12} className={classes.alignRight}>
            <div style={{ paddingTop: 20 }}>
              <Button
                disableElevation
                variant="contained"
                size="large"
                className={classes.button}
                onClick={() =>
                  history.push(
                    routes.INVENTORY +
                      inventoryRoutes.DETAIL_BARANG +
                      `/${itemID}` +
                      inventoryRoutes.TAMBAH_KADALUARSA +
                      `?source=${source}`
                  )
                }
              >
                + Tambah Kadaluarsa
              </Button>
            </div>
          </Grid>
        }
        <Grid item xs={12} style={{ overflow: "auto" }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerLeft}>No. Batch</TableCell>
                <TableCell>Tanggal Kadaluarsa</TableCell>
                <TableCell>Jumlah Stok</TableCell>
                <TableCell>Nama Depot</TableCell>
                <TableCell className={classes.headerRight} />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                isFetchingData
                  ? <TableRow>
                    <TableCell colSpan={5} align={'center'}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                  : tableData.length === 0
                  ? <TableRow>
                    <TableCell colSpan={5} align={'center'}>
                      <p>Tidak ada data yang bisa di tampilkan.</p>
                    </TableCell>
                  </TableRow>
                  : tableData
                    .map((row) => (
                    <TableRow key={row._id}>
                      <TableCell>{row.batchNo}</TableCell>
                      <TableCell>
                      {moment(row.expiredDate).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{row.stock}</TableCell>
                      <TableCell>{row.depotName}</TableCell>
                      <TableCell>
                        <Tooltip title="Edit Kadaluarsa Item" placement="bottom" id="warehouse-content">
                          <IconButton
                            color="primary"
                            className={classes.button}
                            onClick={() =>
                              history.push(
                                routes.INVENTORY +
                                inventoryRoutes.DETAIL_BARANG +
                                `/${itemID}` +
                                inventoryRoutes.EDIT_KADALUARSA +
                                `/${row._id}` +
                                `?source=${source}`
                              )
                            }
                          >
                            <CreateOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
              }
            </TableBody>
          </Table>
          <CustomPagination
            page={pagination.page}
            onChangePage={(event, newPage) =>
              setPagination({ ...pagination, page: newPage })
            }
            rowsPerPage={pagination.rowsPerPage}
            onChangeRowsPerPage={(event) =>
              setPagination({
                ...pagination,
                rowsPerPage: parseInt(event.target.value, 10),
              })
            }
            labelDisplayedRows={({ from, to, count }) =>
              from + "-" + to + " dari " + count + " Data"
            }
            count={totalData}
          />
        </Grid>
      </Grid>
    </div>
  );
};

KadaluarsaItem.propTypes = {};

export default KadaluarsaItem;
