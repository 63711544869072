import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import HttpsIcon from "@material-ui/icons/Https";
import { useHistory, useParams } from "react-router-dom";

import { palette } from "../../theme";
import CustomTextField from "../custom/CustomTextField";
import validations from "../../utils/validations";
import routes from "../../constants/routes";
import { updatePassword } from "../../services/AuthService";
import { setSnackbar } from "../../store/general/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: palette.titleText,
  },
  secondText: {
    color: palette.placeholderText,
  },
  changePassContainer: {
    padding: "0 25px 0 130px",
    width: "100%",
  },
  textField: {
    maxWidth: "288px",
    width: "100%",
  },
  textFieldContainer: {
    paddingTop: 40,
  },
  submitButton: {
    marginRight: 20,
    maxWidth: "288px",
    width: "100%",
    borderRadius: "8px",
  },
}));

const ChangePassword = (props) => {
  const classes = useStyles();
  const [passwordBaru, setPasswordBaru] = useState("");
  const [konfirmasiPass, setKonfirmasiPass] = useState("");
  const [error, setError] = useState({});
  const { token } = useParams();
  const history = useHistory();

  const checkError = () => {
    const passBaruError = validations.isEmpty(passwordBaru);
    const konfirmasiPassError = validations.isEmpty(konfirmasiPass);
    setError({
      passwordBaru: passBaruError,
      konfirmasiPass: konfirmasiPassError,
    });
    return !!passBaruError || !!konfirmasiPassError;
  };

  const handleSubmit = () => {
    if (checkError()) {
      return;
    }
    const newToken = token.substring(1);
    updatePassword(newToken, passwordBaru, konfirmasiPass).then((res) => {
      props.setSnackbar("success", "Sukses! Ganti password berhasil dilakukan");
      history.push(routes.LOGIN);
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.changePassContainer}>
        <Typography variant="h2" color="textPrimary" className={classes.title}>
          Ganti Password{" "}
        </Typography>
        <div className={classes.textFieldContainer}>
          <CustomTextField
            className={classes.textField}
            label="Password Baru"
            type="password"
            value={passwordBaru}
            onChange={(event) => setPasswordBaru(event.target.value)}
            error={error.passwordBaru && validations.isEmpty(passwordBaru)}
            helperText={
              error.passwordBaru && validations.isEmpty(passwordBaru)
                ? "Password tidak boleh kosong"
                : " "
            }
            startIcon={<HttpsIcon />}
          />
          <CustomTextField
            className={classes.textField}
            label="Konfirmasi Password"
            type="password"
            value={konfirmasiPass}
            onChange={(event) => setKonfirmasiPass(event.target.value)}
            error={error.konfirmasiPass && validations.isEmpty(konfirmasiPass)}
            helperText={
              error.konfirmasiPass && validations.isEmpty(konfirmasiPass)
                ? "Password tidak boleh kosong"
                : " "
            }
            startIcon={<HttpsIcon />}
          />
        </div>
        <Button
          disableElevation
          variant="contained"
          size="large"
          className={classes.submitButton}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

ChangePassword.propTypes = {};

export default connect(null, { setSnackbar })(ChangePassword);
