import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomTextField from "../custom/CustomTextField";
import CustomSelect from "../custom/CustomSelect";
import MenuItem from "@material-ui/core/MenuItem";
import {
  editDetailConfig,
  getDetailConfig,
} from "../../services/ConfigService";
import { getListDepots } from "../../services/DepotService";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  collapseTitle: {
    display: "inline-block",
    marginTop: 10,
    fontFamily: theme.typography.fontFamily,
  },
  collapse: {
    marginBottom: 15,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.blue,
  },
  expand: {
    float: "right",
    border: "none",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  collapseItem: {
    paddingLeft: 15,
  },
}));

const collapseInitialState = {
  expiryWarning: true,
  depotLocation: true,
  itemCodeFormat: true,
  customerFormat: true,
  supplierFormat: true,
  poCodeFormat: true,
  roCodeFormat: true,
  doCodeFormat: true,
  itemRetrievalFormat: true,
};

const GeneralSettings = (props) => {
  const classes = useStyles();
  const [depots, setDepots] = useState([]);

  const [expiryWarning, setExpiryWarning] = useState(0);
  const [collapse, setCollapse] = useState(collapseInitialState);
  const [depotLocation, setDepotLocation] = useState({
    selling: "",
    restock: "",
  });
  const [itemCodeFormat, setItemCodeFormat] = useState({});
  const [customerFormat, setCustomerFormat] = useState({});
  const [supplierFormat, setSupplierFormat] = useState({});
  const [poCodeFormat, setPoCodeFormat] = useState({});
  const [roCodeFormat, setRoCodeFormat] = useState({});
  const [doCodeFormat, setDoCodeFormat] = useState({});
  const [itemRetrievalFormat, setItemRetrievalFormat] = useState({});

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    getListDepots(undefined).then((res) => {
      setDepots(res.data.items);
    });
    getDetailSetting();
  }, []);

  const updateExpiryWarning = () => {
    editDetailConfig({
      warningExpireDate: {
        type: "number",
        default: expiryWarning,
      },
    }).then(getDetailSetting);
  };

  const updateRODepot = (id) => {
    editDetailConfig({
      depot: {
        doDepotId: depotLocation.selling,
        doDepotName: depots.find((value) => value.id === depotLocation.selling)
          ?.name,
        roDepotId: id,
        roDepotName: depots.find((value) => value.id === id)?.name,
      },
    }).then(getDetailConfig);
  };

  const updateDODepot = (id) => {
    editDetailConfig({
      depot: {
        doDepotId: id,
        doDepotName: depots.find((value) => value.id === id)?.name,
        roDepotId: depotLocation.restock,
        roDepotName: depots.find((value) => value.id === depotLocation.restock)
          ?.name,
      },
    }).then(getDetailConfig);
  };

  const updateFormat = (field, state) => {
    editDetailConfig({
      [field]: {
        prefixType: "constant",
        suffixType: "constant",
        bodyType: "increment",
        prefixFormat: state.prefix,
        bodyDigit: Number(state.numberOfDigit),
        suffixFormat: state.postfix,
      },
    }).then(getDetailConfig);
  };

  const getDetailSetting = () => {
    setIsFetching(true);
    getDetailConfig()
      .then((res) => {
        setExpiryWarning(res.data.item?.warningExpireDate?.default);
        setDepotLocation({
          selling: res.data.item?.depot?.doDepotId,
          restock: res.data.item?.depot?.roDepotId,
        });
        setItemCodeFormat({
          prefix: res.data.item?.formatItem.prefixFormat,
          postfix: res.data.item?.formatItem.suffixFormat,
          numberOfDigit: res.data.item?.formatItem.bodyDigit,
        });
        setCustomerFormat({
          prefix: res.data.item?.formatCustomer.prefixFormat,
          postfix: res.data.item?.formatCustomer.suffixFormat,
          numberOfDigit: res.data.item?.formatCustomer.bodyDigit,
        });
        setSupplierFormat({
          prefix: res.data.item?.formatSupplier.prefixFormat,
          postfix: res.data.item?.formatSupplier.suffixFormat,
          numberOfDigit: res.data.item?.formatSupplier.bodyDigit,
        });
        setPoCodeFormat({
          prefix: res.data.item?.formatPO.prefixFormat,
          postfix: res.data.item?.formatPO.suffixFormat,
          numberOfDigit: res.data.item?.formatPO.bodyDigit,
        });
        setRoCodeFormat({
          prefix: res.data.item?.formatRO.prefixFormat,
          postfix: res.data.item?.formatRO.suffixFormat,
          numberOfDigit: res.data.item?.formatRO.bodyDigit,
        });
        setDoCodeFormat({
          prefix: res.data.item?.formatDO.prefixFormat,
          postfix: res.data.item?.formatDO.suffixFormat,
          numberOfDigit: res.data.item?.formatDO.bodyDigit,
        });
        setItemRetrievalFormat({
          prefix: res.data.item?.formatReceive.prefixFormat,
          postfix: res.data.item?.formatReceive.suffixFormat,
          numberOfDigit: res.data.item?.formatReceive.bodyDigit,
        });
      })
      .finally(() => setIsFetching(false));
  };
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          item
          xs={9}
          className={classes.collapse}
          alignItems="center"
          alignContent="center"
        >
          <Typography
            variant="body1"
            color="primary"
            className={classes.collapseTitle}
          >
            Warning Kadaluarsa
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: collapse.expiryWarning,
            })}
            onClick={() =>
              setCollapse({
                ...collapse,
                expiryWarning: !collapse.expiryWarning,
              })
            }
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={collapse.expiryWarning} timeout="auto" unmountOnExit>
            <Grid container className={classes.collapseItem}>
              <Grid item xs={12}>
                <CustomTextField
                  label="Sisa Bulan"
                  helperText="Warning akan dimunculkan jika tanggal kadaluarsa kurang dari data di atas (dalam hitungan bulan)"
                  onChange={(event) =>
                    setExpiryWarning(
                      isNaN(event.target.value) ? 1 : event.target.value
                    )
                  }
                  onBlur={updateExpiryWarning}
                  value={expiryWarning}
                  requiredField
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid
          item
          xs={9}
          className={classes.collapse}
          alignItems="center"
          alignContent="center"
        >
          <Typography
            variant="body1"
            color="primary"
            className={classes.collapseTitle}
          >
            Lokasi Depot Obat
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: collapse.depotLocation,
            })}
            onClick={() =>
              setCollapse({
                ...collapse,
                depotLocation: !collapse.depotLocation,
              })
            }
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={collapse.depotLocation} timeout="auto" unmountOnExit>
            <Grid container className={classes.collapseItem} spacing={2}>
              <Grid item xs={12}>
                <CustomSelect
                  value={depotLocation.restock}
                  onChange={(event) => {
                    setDepotLocation({
                      ...depotLocation,
                      restock: event.target.value,
                    });
                    updateRODepot(event.target.value);
                  }}
                  label="Depot Restock dan Return"
                  helperText="Depot tempat keluar dan masuk obat dari proses restock dan return"
                >
                  {depots.map((depot) => (
                    <MenuItem key={depot.id} value={depot.id}>
                      {depot.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item xs={12}>
                <CustomSelect
                  value={depotLocation.selling}
                  onChange={(event) => {
                    setDepotLocation({
                      ...depotLocation,
                      selling: event.target.value,
                    });
                    updateDODepot(event.target.value);
                  }}
                  label="Depot Penjualan dan Resep"
                  helperText="Depot tempat keluar obat dari proses pembayaran di kasir"
                >
                  {depots.map((depot) => (
                    <MenuItem key={depot.id} value={depot.id}>
                      {depot.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid
          item
          xs={9}
          className={classes.collapse}
          alignItems="center"
          alignContent="center"
        >
          <Typography
            variant="body1"
            color="primary"
            className={classes.collapseTitle}
          >
            Format Barang
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: collapse.itemCodeFormat,
            })}
            onClick={() =>
              setCollapse({
                ...collapse,
                itemCodeFormat: !collapse.itemCodeFormat,
              })
            }
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={collapse.itemCodeFormat} timeout="auto" unmountOnExit>
            <Grid container className={classes.collapseItem}>
              <Grid item xs={6}>
                <CustomTextField
                  label="Format Depan Obat"
                  onChange={(event) =>
                    setItemCodeFormat({
                      ...itemCodeFormat,
                      prefix: event.target.value,
                    })
                  }
                  value={itemCodeFormat.prefix}
                  onBlur={() => updateFormat("formatItem", itemCodeFormat)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" color="textPrimary">
                  Contoh:
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  {isFetching ? "" :
                    `${itemCodeFormat.prefix}${"012345678901234567890123456789".substr(0, itemCodeFormat.numberOfDigit)}${itemCodeFormat.postfix}`
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Format Belakang Obat"
                  onChange={(event) =>
                    setItemCodeFormat({
                      ...itemCodeFormat,
                      postfix: event.target.value,
                    })
                  }
                  value={itemCodeFormat.postfix}
                  onBlur={() => updateFormat("formatItem", itemCodeFormat)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Jumlah Digit Obat"
                  onChange={(event) =>
                    setItemCodeFormat({
                      ...itemCodeFormat,
                      numberOfDigit: isNaN(event.target.value)
                        ? 1
                        : event.target.value,
                    })
                  }
                  value={itemCodeFormat.numberOfDigit}
                  onBlur={() => updateFormat("formatItem", itemCodeFormat)}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid
          item
          xs={9}
          className={classes.collapse}
          alignItems="center"
          alignContent="center"
        >
          <Typography
            variant="body1"
            color="primary"
            className={classes.collapseTitle}
          >
            Format Customer
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: collapse.customerFormat,
            })}
            onClick={() =>
              setCollapse({
                ...collapse,
                customerFormat: !collapse.customerFormat,
              })
            }
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={collapse.customerFormat} timeout="auto" unmountOnExit>
            <Grid container className={classes.collapseItem}>
              <Grid item xs={6}>
                <CustomTextField
                  label="Nama Depan Customer"
                  onChange={(event) =>
                    setCustomerFormat({
                      ...customerFormat,
                      prefix: event.target.value,
                    })
                  }
                  value={customerFormat.prefix}
                  onBlur={() => updateFormat("formatCustomer", customerFormat)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" color="textPrimary">
                  Contoh:
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  {isFetching ? "" :
                    `${customerFormat.prefix}${"012345678901234567890123456789".substr(0, customerFormat.numberOfDigit)}${customerFormat.postfix}`
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Nama Belakang Customer"
                  onChange={(event) =>
                    setCustomerFormat({
                      ...customerFormat,
                      postfix: event.target.value,
                    })
                  }
                  value={customerFormat.postfix}
                  onBlur={() => updateFormat("formatCustomer", customerFormat)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Jumlah Digit Obat"
                  onChange={(event) =>
                    setCustomerFormat({
                      ...customerFormat,
                      numberOfDigit: isNaN(event.target.value)
                        ? 1
                        : event.target.value,
                    })
                  }
                  value={customerFormat.numberOfDigit}
                  onBlur={() => updateFormat("formatCustomer", customerFormat)}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid
          item
          xs={9}
          className={classes.collapse}
          alignItems="center"
          alignContent="center"
        >
          <Typography
            variant="body1"
            color="primary"
            className={classes.collapseTitle}
          >
            Format Supplier
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: collapse.supplierFormat,
            })}
            onClick={() =>
              setCollapse({
                ...collapse,
                supplierFormat: !collapse.supplierFormat,
              })
            }
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={collapse.supplierFormat} timeout="auto" unmountOnExit>
            <Grid container className={classes.collapseItem}>
              <Grid item xs={6}>
                <CustomTextField
                  label="Nama Depan Supplier"
                  onChange={(event) =>
                    setSupplierFormat({
                      ...supplierFormat,
                      prefix: event.target.value,
                    })
                  }
                  value={supplierFormat.prefix}
                  onBlur={() => updateFormat("formatSupplier", supplierFormat)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" color="textPrimary">
                  Contoh:
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  {isFetching ? "" :
                    `${supplierFormat.prefix}${"012345678901234567890123456789".substr(0, supplierFormat.numberOfDigit)}${supplierFormat.postfix}`
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Nama Belakang Supplier"
                  onChange={(event) =>
                    setSupplierFormat({
                      ...supplierFormat,
                      postfix: event.target.value,
                    })
                  }
                  value={supplierFormat.postfix}
                  onBlur={() => updateFormat("formatSupplier", supplierFormat)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Jumlah Digit Supplier"
                  onChange={(event) =>
                    setSupplierFormat({
                      ...supplierFormat,
                      numberOfDigit: isNaN(event.target.value)
                        ? 1
                        : event.target.value,
                    })
                  }
                  value={supplierFormat.numberOfDigit}
                  onBlur={() => updateFormat("formatSupplier", supplierFormat)}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid
          item
          xs={9}
          className={classes.collapse}
          alignItems="center"
          alignContent="center"
        >
          <Typography
            variant="body1"
            color="primary"
            className={classes.collapseTitle}
          >
            Format Kode PO
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: collapse.poCodeFormat,
            })}
            onClick={() =>
              setCollapse({ ...collapse, poCodeFormat: !collapse.poCodeFormat })
            }
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={collapse.poCodeFormat} timeout="auto" unmountOnExit>
            <Grid container className={classes.collapseItem}>
              <Grid item xs={6}>
                <CustomTextField
                  label="Format Depan PO"
                  onChange={(event) =>
                    setPoCodeFormat({
                      ...poCodeFormat,
                      prefix: event.target.value,
                    })
                  }
                  value={poCodeFormat.prefix}
                  onBlur={() => updateFormat("formatPO", poCodeFormat)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" color="textPrimary">
                  Contoh:
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  {isFetching ? "" :
                    `${poCodeFormat.prefix}${"012345678901234567890123456789".substr(0, poCodeFormat.numberOfDigit)}${poCodeFormat.postfix}`
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Format Belakang PO"
                  onChange={(event) =>
                    setPoCodeFormat({
                      ...poCodeFormat,
                      postfix: event.target.value,
                    })
                  }
                  value={poCodeFormat.postfix}
                  onBlur={() => updateFormat("formatPO", poCodeFormat)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Jumlah Digit PO"
                  onChange={(event) =>
                    setPoCodeFormat({
                      ...poCodeFormat,
                      numberOfDigit: isNaN(event.target.value)
                        ? 1
                        : event.target.value,
                    })
                  }
                  value={poCodeFormat.numberOfDigit}
                  onBlur={() => updateFormat("formatPO", poCodeFormat)}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid
          item
          xs={9}
          className={classes.collapse}
          alignItems="center"
          alignContent="center"
        >
          <Typography
            variant="body1"
            color="primary"
            className={classes.collapseTitle}
          >
            Format Kode RO
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: collapse.roCodeFormat,
            })}
            onClick={() =>
              setCollapse({ ...collapse, roCodeFormat: !collapse.roCodeFormat })
            }
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={collapse.roCodeFormat} timeout="auto" unmountOnExit>
            <Grid container className={classes.collapseItem}>
              <Grid item xs={6}>
                <CustomTextField
                  label="Format Depan RO"
                  onChange={(event) =>
                    setRoCodeFormat({
                      ...roCodeFormat,
                      prefix: event.target.value,
                    })
                  }
                  value={roCodeFormat.prefix}
                  onBlur={() => updateFormat("formatRO", roCodeFormat)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" color="textPrimary">
                  Contoh:
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  {isFetching ? "" :
                    `${roCodeFormat.prefix}${"012345678901234567890123456789".substr(0, roCodeFormat.numberOfDigit)}${roCodeFormat.postfix}`
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Format Belakang RO"
                  onChange={(event) =>
                    setRoCodeFormat({
                      ...roCodeFormat,
                      postfix: event.target.value,
                    })
                  }
                  value={roCodeFormat.postfix}
                  onBlur={() => updateFormat("formatRO", roCodeFormat)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Jumlah Digit RO"
                  onChange={(event) =>
                    setRoCodeFormat({
                      ...roCodeFormat,
                      numberOfDigit: isNaN(event.target.value)
                        ? 1
                        : event.target.value,
                    })
                  }
                  value={roCodeFormat.numberOfDigit}
                  onBlur={() => updateFormat("formatRO", roCodeFormat)}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid
          item
          xs={9}
          className={classes.collapse}
          alignItems="center"
          alignContent="center"
        >
          <Typography
            variant="body1"
            color="primary"
            className={classes.collapseTitle}
          >
            Format Kode DO
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: collapse.doCodeFormat,
            })}
            onClick={() =>
              setCollapse({ ...collapse, doCodeFormat: !collapse.doCodeFormat })
            }
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={collapse.doCodeFormat} timeout="auto" unmountOnExit>
            <Grid container className={classes.collapseItem}>
              <Grid item xs={6}>
                <CustomTextField
                  label="Format Depan DO"
                  onChange={(event) =>
                    setDoCodeFormat({
                      ...doCodeFormat,
                      prefix: event.target.value,
                    })
                  }
                  value={doCodeFormat.prefix}
                  onBlur={() => updateFormat("formatDO", doCodeFormat)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" color="textPrimary">
                  Contoh:
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  {isFetching ? "" :
                    `${doCodeFormat.prefix}${"012345678901234567890123456789".substr(0, doCodeFormat.numberOfDigit)}${doCodeFormat.postfix}`
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Format Belakang DO"
                  onChange={(event) =>
                    setDoCodeFormat({
                      ...doCodeFormat,
                      postfix: event.target.value,
                    })
                  }
                  value={doCodeFormat.postfix}
                  onBlur={() => updateFormat("formatDO", doCodeFormat)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Jumlah Digit DO"
                  onChange={(event) =>
                    setDoCodeFormat({
                      ...doCodeFormat,
                      numberOfDigit: isNaN(event.target.value)
                        ? 1
                        : event.target.value,
                    })
                  }
                  value={doCodeFormat.numberOfDigit}
                  onBlur={() => updateFormat("formatDO", doCodeFormat)}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid
          item
          xs={9}
          className={classes.collapse}
          alignItems="center"
          alignContent="center"
        >
          <Typography
            variant="body1"
            color="primary"
            className={classes.collapseTitle}
          >
            Format Penerimaan Barang
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: collapse.itemRetrievalFormat,
            })}
            onClick={() =>
              setCollapse({
                ...collapse,
                itemRetrievalFormat: !collapse.itemRetrievalFormat,
              })
            }
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Collapse
            in={collapse.itemRetrievalFormat}
            timeout="auto"
            unmountOnExit
          >
            <Grid container className={classes.collapseItem}>
              <Grid item xs={6}>
                <CustomTextField
                  label="Format Depan DO"
                  onChange={(event) =>
                    setItemRetrievalFormat({
                      ...itemRetrievalFormat,
                      prefix: event.target.value,
                    })
                  }
                  value={itemRetrievalFormat.prefix}
                  onBlur={() =>
                    updateFormat("formatReceive", itemRetrievalFormat)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" color="textPrimary">
                  Contoh:
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  {isFetching ? "" :
                    `${itemRetrievalFormat.prefix}${"012345678901234567890123456789".substr(0, itemRetrievalFormat.numberOfDigit)}${itemRetrievalFormat.postfix}`
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Format Belakang DO"
                  onChange={(event) =>
                    setItemRetrievalFormat({
                      ...itemRetrievalFormat,
                      postfix: event.target.value,
                    })
                  }
                  value={itemRetrievalFormat.postfix}
                  onBlur={() =>
                    updateFormat("formatReceive", itemRetrievalFormat)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Jumlah Digit DO"
                  onChange={(event) =>
                    setItemRetrievalFormat({
                      ...itemRetrievalFormat,
                      numberOfDigit: isNaN(event.target.value)
                        ? 1
                        : event.target.value,
                    })
                  }
                  value={itemRetrievalFormat.numberOfDigit}
                  onBlur={() =>
                    updateFormat("formatReceive", itemRetrievalFormat)
                  }
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </div>
  );
};

export default GeneralSettings;
