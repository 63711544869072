import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import omit from "lodash.omit";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "100%",
  },
}));

const CustomAutocomplete = (props) => {
  const classes = useStyles();

  const autocompelteProps = omit(props, [
    "error",
    "itemName",
    "label",
    "getOptionListLabel",
  ]);

  return (
    <Autocomplete
      {...autocompelteProps}
      renderInput={(params) => {
        return (
          <div className={classes.inputField}>
            <InputLabel required={props.requiredField}>
              {props.label}
            </InputLabel>
            <TextField
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                error: props.error,
                placeholder: props.placeholder,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText error={props.error}>
              {props.helperText}
            </FormHelperText>
          </div>
        );
      }}
      renderOption={(option) => (
        <Typography variant="body2" color="textSecondary">
          {props.getOptionListLabel(option)}
        </Typography>
      )}
    />
  );
};

CustomAutocomplete.defaultProps = {
  helperText: " ",
  label: "",
};

CustomAutocomplete.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  getOptionListLabel: PropTypes.func,
};

export default CustomAutocomplete;
