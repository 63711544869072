import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import routes from "../../constants/routes";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CustomTextField from "../custom/CustomTextField";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { useHistory, useParams } from "react-router-dom";
import { getDetailBilling } from "../../services/BillingService";
import {
  currencyFormat,
  currencyFormatWithDecimalOrNot,
  dateFormat,
  statusFormat,
} from "../../utils/formatting";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  headerGrid: {
    // height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  header: {
    lineHeight: "60px",
    fontWeight: "bold",
    color: theme.palette.blue,
  },
  title: {
    lineHeight: "60px",
    fontWeight: "bold",
  },
  itemGridLeft: {
    paddingRight: 12,
  },
  itemGridRight: {
    paddingLeft: 12,
  },
  table: {
    marginBottom: 30,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  textField: {
    width: "100%",
  },
  itemWidth: {
    maxWidth: "97%",
  },
  invoiceHead: {
    fontWeight: "normal",
    backgroundColor: "white",
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: 0,
    color: "#666666",
    fontSize: 10,
  },
  invoiceBody: {
    borderBottom: 0,
    padding: 0,
    paddingLeft: 0,
  },
  invoiceTable: {
    marginTop: 7,
  },
}));

const BillingDetail = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { invoiceId } = useParams();

  const [invoice, setInvoice] = useState({});

  useEffect(() => {
    if (!invoiceId) {
      history.push(routes.BILLING);
    }

    getDetailBilling(invoiceId).then((res) => {
      setInvoice(res.data.item);
    });
  }, [invoiceId, history]);

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={`Details Pembayaran ${invoice.codePO || ""}`}
        previousPageName="Billing"
        previousPageLink={routes.BILLING}
      />
      <Grid container item xs={12} className={classes.headerGrid}>
        <Table className={classes.invoiceTable}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.invoiceHead} style={{ width: '10%' }}>Invoice</TableCell>
              <TableCell className={classes.invoiceHead} style={{ width: '15%' }}>Supplier</TableCell>
              <TableCell className={classes.invoiceHead} style={{ width: '10%' }}>Status</TableCell>
              <TableCell className={classes.invoiceHead} style={{ width: '10%' }}>
                Tanggal Jatuh Tempo
              </TableCell>
              <TableCell className={classes.invoiceHead} style={{ width: '65%' }}>Alamat</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.invoiceBody}>
                {invoice.code}
              </TableCell>
              <TableCell className={classes.invoiceBody}>
                {invoice.supplierName}
              </TableCell>
              <TableCell className={classes.invoiceBody}>
                {statusFormat(invoice.status)}
              </TableCell>
              <TableCell className={classes.invoiceBody}>
                {dateFormat(invoice.dueDate) || "-"}
              </TableCell>
              <TableCell className={classes.invoiceBody}>
                {invoice.addressSupplier}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            className={`${classes.title} ${classes.borderBottom}`}
          >
            Barang Dipesan
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            className={`${classes.title} ${classes.borderBottom}`}
          >
            Overview
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerLeft}>Obat</TableCell>
                    <TableCell>Jumlah</TableCell>
                    <TableCell className={classes.headerRight}>
                      Harga Beli Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice.TxItems?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.quantity + ` ${row.bigUnit}`}</TableCell>
                      <TableCell>{currencyFormatWithDecimalOrNot(row.totalFee, 2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className={`${classes.title} ${classes.borderBottom}`}
              >
                Riwayat Pembayaran
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerLeft}>
                      Tanggal
                    </TableCell>
                    <TableCell>Nominal Uang (Rp)</TableCell>
                    <TableCell>Metode Pembayaran</TableCell>
                    <TableCell className={classes.headerRight}>PIC</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice.TxPayments?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{dateFormat(row.createdDate)}</TableCell>
                      <TableCell>{currencyFormat(row.totalFee)}</TableCell>
                      <TableCell>{`${row.paymentType} - ${row.paymentName}`}</TableCell>
                      <TableCell>{row.paymentBy}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12} className={classes.itemWidth}>
              <CustomTextField
                label="PIC Supplier"
                disabled
                value={invoice.namePic}
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12} className={classes.itemWidth}>
              <CustomTextField
                label="Keterangan"
                disabled
                value={invoice.notes}
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label="Pajak"
                disabled
                currency
                value={invoice.ppnRp}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label="Jumlah Total"
                disabled
                currency
                value={invoice.totalFee}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label="Total Diskon"
                disabled
                currency
                value={invoice.discountRp}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <CustomTextField
                label="Sisa"
                disabled
                currency
                value={invoice.totalFee - invoice.paidFee}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label="Jumlah Sudah dibayarkan"
                disabled
                currency
                value={invoice.paidFee}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default BillingDetail;
