import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch } from "react-redux";
import CustomTextField from "../custom/CustomTextField";
import CustomSelect from "../custom/CustomSelect";
import {
  getDepotInventoriesDetail,
  getDepotStock,
  mutasiDepot,
} from "../../services/InventoryService";
import { getListRacks } from "../../services/RackService";
import { formatPayloadInt } from "../../utils/formatting";
import routes, { inventoryRoutes } from "../../constants/routes";
import { setSnackbar } from "../../store/general/actions";

const useStyles = makeStyles({
  root: {},
  editGrid: {
    display: "flex",
  },
  marginContainerLeft: {
    marginLeft: 20,
  },
  buttonGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    maxHeight: "100%",
    height: 400,
  },
  detailGrid: {
    marginTop: 20,
  },
  flexButton: {
    display: "flex",
    flexFlow: "wrap-reverse",
  },
});

const mutasiDefault = {
  quantity: 0,
  depotIdTo: "",
  rackIdTo: "",
  stokAwal: 0,
  selectedDepotTo: "",
};

const MutasiDepot = () => {
  const classes = useStyles();
  const { itemID, depotId } = useParams();
  const { search } = useLocation();
  const q = new URLSearchParams(search);
  const source = q.get("source");
  const history = useHistory();
  const dispatch = useDispatch();

  const [mutasi, setMutasi] = useState({ ...mutasiDefault });
  const [depot, setDepot] = useState({
    stock: 0,
  });
  // const [depots, setDepots] = useState([]);
  const [racks, setRacks] = useState([]);
  const [depotInvs, setDepotInvs] = useState([]);
  const [error, setError] = useState({});

  useEffect(() => {
    getDepotInventoriesDetail(depotId).then((res) => {
      setDepot(res.data.item);
    });
    getDepotStock(itemID, 0, 100).then((res) => {
      setDepotInvs(
        res.data.items
          .filter((e) => e._id !== depotId)
          .map((e) => ({ label: `${e.depotName} (${e.rackName})`, value: e }))
      );
    });
    getListRacks("", 0, 0).then((res) => {
      setRacks(res.data.items.map((e) => ({ label: e.name, value: e.id })));
    });
  }, [depotId, itemID]);

  const { depotIdTo, rackIdTo } = mutasi;
  useEffect(() => {
    if (depotIdTo && rackIdTo) {
      const selectedDepot = depotInvs.find(
        (e) => e.depotId === depotIdTo && e.rackId === rackIdTo
      );
      if (selectedDepot) {
        setMutasi((m) => ({ ...m, stokAwal: selectedDepot.stock }));
      }
    }
  }, [depotInvs, depotIdTo, rackIdTo]);

  const checkError = () => {
    const rackIdTo = mutasi.rackIdTo === "";
    const depotIdTo = mutasi.depotIdTo === "";
    const quantity = mutasi.quantity === 0 || mutasi.quantity === "";
    setError({ rackIdTo, depotIdTo, quantity });
    return rackIdTo || depotIdTo || quantity;
  };

  const handleChange = (name) => (event) => {
    let { value } = event.target;
    if (name === "quantity") {
      if (value > depot.stock) {
        value = depot.stock;
        setMutasi({ ...mutasi, [name]: parseInt(value, 10) });
      } else {
        setMutasi({ ...mutasi, [name]: event.target.value !== "" ? parseInt(value, 10) : 0 });
      }
    } else if (name === "depotIdTo") {
      setMutasi({
        ...mutasi,
        [name]: value.depotId,
        rackIdTo: value.rackId,
        stokAwal: value.stock,
        selectedDepotTo: value,
      });
    }
  };

  const handleSubmit = () => {
    if (checkError()) {
      return;
    }

    const newMutasi = { ...mutasi };
    if (newMutasi.selectedDepotTo) {
      delete newMutasi.selectedDepotTo;
    }

    mutasiDepot(depotId, formatPayloadInt(newMutasi, ["quantity"]))
      .then(() => {
        dispatch(setSnackbar("success", "Sukses! Mutasi berhasil dilakukan"));
        history.push(
          routes.INVENTORY +
            inventoryRoutes.DETAIL_BARANG +
            `/${itemID}` +
            inventoryRoutes.DEPOT_STOK +
            `?source=${source}`
        );
      })
      .catch((err) => {
        if (err.response.status === 500) {
          dispatch(
            setSnackbar("error", "Error! Terjadi kesalahan. Coba beberapa saat lagi")
          );
        }
      });
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.detailGrid}
      >
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            <CustomTextField
              label="Nama Depot Asal"
              value={depot.Depots?.name}
              disabled
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField label="Stok Awal" value={depot.stock} disabled/>
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Jumlah Mutasi"
              value={mutasi.quantity}
              onChange={handleChange("quantity")}
              type="number"
              requiredField
              error={error.quantity}
              helperText={error.quantity && "Jumlah tidak boleh kosong"}
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Stok Hasil"
              value={depot.stock - mutasi.quantity}
              disabled
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            <CustomSelect
              value={mutasi.selectedDepotTo}
              label="Nama Depot Tujuan"
              requiredField
              onChange={handleChange("depotIdTo")}
              error={error.depotIdTo}
              helperText={error.depotIdTo && "Nama depot tidak boleh kosong"}
            >
              {depotInvs.map((depot, idx) => (
                <MenuItem key={idx} value={depot.value}>
                  {depot.label}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField label="Stok Awal" value={mutasi.stokAwal} disabled/>
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomSelect
              value={mutasi.rackIdTo}
              label="Nama Rak"
              error={error.rackIdTo}
              helperText={error.rackIdTo && "Nama rak tidak boleh kosong"}
              disabled
            >
              {racks.map((rack) => (
                <MenuItem key={rack.value} value={rack.value}>
                  {rack.label}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Stok Hasil"
              value={
                parseInt(mutasi.stokAwal, 10) +
                parseInt(mutasi.quantity || 0, 10)
              }
              disabled
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.buttonGrid}>
          <div>
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() =>
                history.push(
                  routes.INVENTORY +
                    inventoryRoutes.DETAIL_BARANG +
                    `/${itemID}` +
                    inventoryRoutes.DEPOT_STOK +
                    `?source=${source}`
                )
              }
            >
              {"Batalkan"}
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              onClick={handleSubmit}
            >
              {"Adjustment"}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

MutasiDepot.propTypes = {};
export default MutasiDepot;
