import React from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import routesConst from "../constants/routes";
import cekHakAkses from "../utils/cekHakAkses";

import { Grid, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  settings: {
    height: "100%",
    width: 320,
    display: "inline-block",
    backgroundColor: "white",
    zIndex: 3,
  },
  settingsGrid: {
    paddingTop: 20,
    paddingLeft: 20,
  },
  settingsHeaderLine: {
    borderBottom: "4px solid",
    borderBottomColor: theme.palette.secondaryText,
    borderRadius: 5,
    width: 45,
  },
  iconBar: {
    background:
      "linear-gradient(0deg, rgba(16,85,210,1) 0%, rgba(0,159,255,1) 50%, rgba(0,212,255,1) 100%)",
  },
  routeButton: {
    backgroundColor: "transparent",
    width: "100%",
    height: 80,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.15)",
    },
    "& img": {
      width: 40,
      height: 40,
    },
  },
  routeButtonSelected: {
    backgroundColor: "rgba(0, 0, 0, 0.15)",
  },
  settingRouteButton: {
    backgroundColor: "transparent",
    width: "100%",
    height: 70,
    justifyContent: "left",
    textTransform: "none",
    paddingLeft: 5,
    borderRadius: 0,
  },
  settingRouteButtonSelected: {
    color: theme.palette.blue,
    borderRight: "5px solid",
    borderRightColor: theme.palette.blue,
    transition: theme.transitions.create("borderRightColor", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  imageIcon: {
    display: "flex",
    height: "inherit",
    width: "inherit",
  },
}));

const settingRoutes = [
  {
    link: routesConst.GENERAL_SETTINGS,
    label: "General Settings",
    icon: (
      <img
        src={require("../assets/general_setting_grey.png")}
        alt="general setting"
      />
    ),
    role: "setting.i2",
  },
  {
    link: routesConst.STAFF_MANAGEMENT,
    label: "Management Staf",
    icon: (
      <img
        src={require("../assets/management_staff.png")}
        alt="manajemen staff"
      />
    ),
    role: "setting.i3",
  },
  {
    link: routesConst.ACCESS_RIGHT,
    label: "Hak Akses",
    icon: <img src={require("../assets/hak_akses.png")} alt="Hak akses" />,
    role: "setting.i7",
  },
  {
    link: routesConst.ITEM_CATEGORY,
    label: "Kategori Barang",
    icon: (
      <img
        src={require("../assets/kategori_barang.png")}
        alt="kategori barang"
      />
    ),
    role: "setting.i12",
  },
  {
    link: routesConst.STORAGE_RACK,
    label: "Rak Penyimpanan",
    icon: (
      <img
        src={require("../assets/rak_penyimpanan.png")}
        alt="rak penyimpanan"
      />
    ),
    role: "setting.i19",
  },
  {
    link: routesConst.SUPPPLIER,
    label: "Supplier",
    icon: <img src={require("../assets/supp.png")} alt="supplier" />,
    role: "setting.i26",
  },
  {
    link: routesConst.CLIENT,
    label: "Client",
    icon: <img src={require("../assets/client.png")} alt="client" />,
    role: "setting.i33",
  },
];

const getSelectedSettingButton = (route, currentPath) => {
  return matchPath(currentPath, { path: route.link });
};

const SettingSidebar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const cekRole = cekHakAkses();

  const handleChangeRoute = (route) => {
    history.push(route);
  };

  return (
    <Paper elevation={5} className={classes.settings}>
      <Grid container className={classes.settings}>
        <Grid item xs={12} className={classes.settingsGrid}>
          <Typography variant="h2" color="textPrimary">
            Settings
          </Typography>
          <Typography color="textSecondary" style={{ fontSize: 11 }}>
            <p style={{ marginBottom: 0, lineHeight: "18px" }}>
              Halaman ini digunakan untuk mengatur
            </p>
            <p style={{ marginTop: 0, lineHeight: "18px" }}>
              kerja sistem pada website Anda
            </p>
          </Typography>
          <div className={classes.settingsHeaderLine} />
          {settingRoutes.map((route) => (
            <React.Fragment>
              {cekRole(route.role) && (
                <Button
                  startIcon={route.icon}
                  className={clsx(classes.settingRouteButton, {
                    [classes.settingRouteButtonSelected]: getSelectedSettingButton(
                      route,
                      location.pathname
                    ),
                  })}
                  onClick={() => handleChangeRoute(route.link)}
                >
                  {route.label}
                </Button>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

SettingSidebar.propTypes = {};

export default SettingSidebar;
