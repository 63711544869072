import React, { useState } from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { IconButton } from "@material-ui/core";
import KeyboardCapslockIcon from "@material-ui/icons/KeyboardCapslock";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CustomTextField from "../custom/CustomTextField";

const useStyles = makeStyles((theme) => ({
  actionGroup: {
    flexShrink: 0,
  },
  button: {
    border: "none",
    color: theme.palette.primaryText,
    width: 10,
    height: 10,
  },
  firstPage: {
    transform: "rotate(-90deg)",
    marginLeft: 10,
  },
  lastPage: {
    transform: "rotate(90deg)",
  },
  currentPage: {
    fontWeight: "bold",
    backgroundColor: theme.palette.normalTextfield,
    borderColor: theme.palette.disabledTextfield,
    width: 30,
    height: 24,
    borderRadius: 5,
    "& input": {
      paddingLeft: 5,
      paddingRight: 5,
      textAlign: "center",
    },
  },
  parentClass: {
    display: "inline-table",
  },
}));

const GetPaginationLabel = ({ from, to, count }) =>
  from + "-" + to + " dari " + count + " Data";

const PaginationActions = (props) => {
  const [pageInput, setPageInput] = useState(props.page + 1);

  const getPage = (newValue) => {
    let value = newValue;
    value = value ? parseInt(value, 10) : 0;
    value -= 1;
    if (value > Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1)) {
      return Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1);
    } else if (value < 0) {
      return 0;
    }
    return value;
  };

  const handleChange = (event) => {
    let { value } = event.target;
    value = isNaN(value) ? 1 : value;
    setPageInput(value);
  };

  const handleBlur = (event) => {
    const page = getPage(event.target.value);
    props.onChangePage(event, page);
    setPageInput(page + 1);
  };

  const changePage = (page) => (event) => {
    props.onChangePage(event, page);
    setPageInput(page + 1);
  };

  const classes = useStyles();
  return (
    <div className={classes.actionGroup}>
      <IconButton
        disabled={props.page === 0}
        className={`${classes.button} ${classes.firstPage}`}
        onClick={changePage(0)}
      >
        <KeyboardCapslockIcon />
      </IconButton>
      <IconButton
        disabled={props.page === 0}
        className={`${classes.button}`}
        onClick={changePage(props.page - 1)}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <CustomTextField
        value={pageInput}
        parentClass={classes.parentClass}
        className={classes.currentPage}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText=""
      />
      <IconButton
        disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
        className={`${classes.button}`}
        onClick={changePage(Number.parseInt(props.page) + 1)}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
        className={`${classes.button} ${classes.lastPage}`}
        onClick={changePage(
          Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1)
        )}
      >
        <KeyboardCapslockIcon />
      </IconButton>
    </div>
  );
};

const CustomPagination = (props) => {
  return (
    <div>
      <TablePagination
        component="div"
        count={props.count}
        page={props.page}
        onChangePage={props.onChangePage}
        rowsPerPage={props.rowsPerPage}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        labelRowsPerPage="Jumlah baris perhalaman :"
        labelDisplayedRows={GetPaginationLabel}
        ActionsComponent={PaginationActions}
        SelectProps={{ IconComponent: KeyboardArrowDown }}
      />
    </div>
  );
};

export default CustomPagination;
