import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNext from "@material-ui/icons/NavigateNext";
import Link from "@material-ui/core/Link";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    paddingBottom: 8,
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  icon: {
    width: 40,
    height: 40,
  },
  link: {
    fontFamily: theme.typography.fontFamily,
    display: "flex",
  },
  previousLink: {
    color: theme.palette.disabledTextfield,
    lineHeight: "40px",
    cursor: "pointer",
  },
}));

const CustomBreadcrumbs = (props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Breadcrumbs
      separator={<NavigateNext className={classes.icon} />}
      className={`${classes.breadcrumb} ${classes.borderBottom}`}
    >
      <Link
        onClick={() => history.push(props.previousPageLink)}
        className={classes.link}
        underline="none"
      >
        <NavigateBefore className={`${classes.icon} ${classes.previousLink}`} />
        <Typography variant="h2" className={classes.previousLink}>
          {props.previousPageName}
        </Typography>
      </Link>
      <Typography className={classes.link} variant="h2" color="primary">
        {props.currentPageName}
      </Typography>
    </Breadcrumbs>
  );
};

CustomBreadcrumbs.propTypes = {
  previousPageLink: PropTypes.string,
  previousPageName: PropTypes.string,
  currentPageName: PropTypes.string,
};

export default CustomBreadcrumbs;
