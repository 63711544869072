import { useDispatch } from 'react-redux'
import { setSnackbar } from '../store/general/actions';

export default function useSnackbar() {
  const dispatch = useDispatch();

  function setWarning(message) {
    dispatch(
      setSnackbar('warning', message),
    );
  }

  function setError(message) {
    dispatch(
      setSnackbar('error', message),
    );
  }

  function setSuccess(message) {
    dispatch(
      setSnackbar('success', message),
    );
  }

  return {
    setWarning,
    setError,
    setSuccess,
  };
}
