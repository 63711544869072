import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import CardIcon from "@material-ui/icons/CreditCard";
import RepeatIcon from "@material-ui/icons/Repeat";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import IconButton from "@material-ui/core/IconButton";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import debounce from "awesome-debounce-promise";
import { useHistory } from "react-router-dom";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";

import CustomTextField from "../custom/CustomTextField";
import CustomRangeDatePicker from "../custom/CustomRangeDatePicker";
import CustomPagination from "../custom/CustomPagination";
import routes, { billingRoutes } from "../../constants/routes";
import { changeInvoice, getListBilling } from "../../services/BillingService";
import {
  lockPurchaseOrderForEditing,
  getLockedStatus,
} from "../../services/PurchaseOrderService";
import {
  currencyFormatWithDecimalOrNot,
  dateFormat,
  paramDateFormat,
  statusFormat,
} from "../../utils/formatting";
import useSnackbar from "../../utils/useSnackbar";
import CustomDialog from "../custom/CustomDialog";
import { palette } from "../../theme";
import { getDetailBilling } from "../../services/BillingService";

import BillingKwitansi from "../Document/BillingKwitansi";
import BillingFaktur from "../Document/BillingFaktur";
import clsx from "clsx";
import useCekHakAkses from "../../utils/cekHakAkses";
import usePdfPrint from "../../utils/usePdfPrint";
import Print from "../../utils/Print";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  headerTitle: {
    color: theme.palette.blue,
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  button: {
    marginLeft: 5,
  },
  warningHead: {
    fontWeight: "normal",
    backgroundColor: "white",
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: 0,
    color: "#666666",
    fontSize: 12,
  },
  warningBody: {
    borderBottom: 0,
    padding: 8,
    paddingLeft: 2,
    paddingRight: 18,
    display: "flex",
  },
  alignRight: {
    textAlign: "right",
    marginBottom: 22,
  },
  topGrid: {
    display: "flex",
  },
  marginContainer: {
    marginLeft: 20,
  },
  displayWarning: {
    display: "flex",
    borderRadius: 8,
    backgroundColor: "#f4f4f4",
    height: 40,
  },
  jatuhTempo: {
    color: "#eb5757",
  },
  belumJatuhTempo: {
    color: "#ffbe3b",
  },
  lunas: {
    color: "#31b057",
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debouncedGetListBilling = debounce(getListBilling, 500);

const Billing = () => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = useCekHakAkses();
  const { setSuccess, setWarning } = useSnackbar();

  const [searchForm, setSearchForm] = useState({
    startDate: moment().subtract(30, "days").toDate(),
    endDate: new Date(),
  });
  const [pagination, setPagination] = useState(paginationInitialData);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalEach, setTotalEach] = useState({
    totalBiaya: 0,
  });
  const [openTukarFaktur, setOpenTukarFaktur] = useState(false);
  const [selectedFaktur, setSelectedFaktur] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const { printPdfProps: printPdfKwitansi, setPdfProps: setPdfKwitansi } =
    usePdfPrint({
      frameName: "billing-kwitansi",
    });
  const { printPdfProps: printPdfFaktur, setPdfProps: setPdfFaktur } =
    usePdfPrint({
      frameName: "billing-faktur",
    });

  useEffect(() => {
    setIsFetching(true);
    debouncedGetListBilling(
      searchForm.search,
      paramDateFormat(searchForm.startDate),
      paramDateFormat(searchForm.endDate),
      pagination.page,
      pagination.rowsPerPage,
    ).then((res) => {
      setTotalData(res.data.count);
      setTableData(res.data.items);
      setTotalEach((prevState) => ({
        ...prevState,
        totalBiaya: Array.isArray(res.data.dataTotal.totalBiaya)
          ? res.data.dataTotal.totalBiaya.reduce((a, b) => a + b, 0)
          : 0,
      }));
      setIsFetching(false);
    });
  }, [searchForm, pagination]);

  const askTukarFaktur = (id, idInvoice) => {
    setSelectedFaktur(id);
    setSelectedInvoice(idInvoice);
    setOpenTukarFaktur(true);
  };

  async function downloadDocument(poNumber, id, full) {
    const selectedIndex = tableData.findIndex((data) => data["_id"] === id);
    setTableData((data) => {
      const copy = [...data];
      copy[selectedIndex].isLoadingKwitansi = true;
      return copy;
    });

    const res = await getDetailBilling(id);
    setPdfKwitansi(
      {
        data: res.data.item,
        items: res.data.item.TxPayments.map((value, index) => {
          return {
            ...value,
            no: index + 1,
          };
        }),
      },
      () => {
        setTableData((data) => {
          const copy = [...data];
          copy[selectedIndex].isLoadingKwitansi = false;
          return copy;
        });
      },
    );
  }

  async function downloadFaktur(poNumber, id, full) {
    const selectedIndex = tableData.findIndex((data) => data["_id"] === id);
    setTableData((data) => {
      const copy = [...data];
      copy[selectedIndex].isLoadingFaktur = true;
      return copy;
    });

    const res = await getDetailBilling(id);
    setPdfFaktur(
      {
        data: res.data.item,
        items: res.data.item.TxPayments.map((value, index) => {
          return {
            ...value,
            no: index + 1,
          };
        }),
      },
      () => {
        setTableData((data) => {
          const copy = [...data];
          copy[selectedIndex].isLoadingFaktur = false;
          return copy;
        });
      },
    );
  }

  const handleTukarFaktur = async (answer) => {
    if (answer) {
      try {
        const result = await getLockedStatus({ poId: selectedFaktur });
        if (result.data.isLocked) {
          setWarning("Warning! Data Purchase Order ini sedang di Edit");
          return null;
        } else {
          changeInvoice(selectedInvoice).then(() => {
          setSuccess("Sukses! Faktur berhasil ditukar");
          lockPurchaseOrderForEditing({ poId: selectedFaktur });
        });
        }
        history.push(routes.BILLING);
      } catch (error) {
        console.error(error);
      }
    }

    setSelectedFaktur(null);
    setSelectedInvoice(null);
    setOpenTukarFaktur(false);
  };

  const handleAcceptTukarFaktur = React.useCallback(
    debounce(() => handleTukarFaktur(true), 1000),
    [selectedFaktur, selectedInvoice],
  );

  return (
    <div className={classes.root}>
      <Grid container className={classes.headerGrid}>
        <Grid item container xs={12} alignItems="center">
          <Typography
            variant="h2"
            color="textSecondary"
            className={classes.headerTitle}
          >
            Billing
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid
          item
          xs={8}
          style={{ paddingTop: 10 }}
          className={classes.topGrid}
        >
          <div>
            <CustomTextField
              label="Cari Data Billing"
              placeholder="Kode PO, No Faktur, Nama Supplier"
              value={searchForm.search}
              onChange={(event) =>
                setSearchForm({ ...searchForm, search: event.target.value })
              }
              startIcon={<SearchIcon />}
            />
          </div>
          <div className={classes.marginContainer}>
            <CustomRangeDatePicker
              label="Berdasarkan Tanggal"
              startDate={searchForm.startDate}
              endDate={searchForm.endDate}
              onChange={([start, end]) =>
                setSearchForm({ ...searchForm, startDate: start, endDate: end })
              }
              placeholder="Berdasarkan Tanggal"
            />
          </div>
        </Grid>
        <Grid item xs={4} className={classes.alignRight}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.warningHead}>
                  Peringatan
                </TableCell>
                <TableCell className={classes.warningHead} />
                <TableCell className={classes.warningHead} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.displayWarning}>
                <TableCell className={classes.warningBody}>
                  <FiberManualRecordIcon className={classes.jatuhTempo} />
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className={classes.jatuhTempo}
                    style={{ padding: 1 }}
                  >
                    Jatuh Tempo
                  </Typography>
                </TableCell>
                <TableCell className={classes.warningBody}>
                  <FiberManualRecordIcon className={classes.belumJatuhTempo} />
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className={classes.belumJatuhTempo}
                    style={{ padding: 1 }}
                  >
                    7 Hari Lagi Jatuh Tempo
                  </Typography>
                </TableCell>
                <TableCell className={classes.warningBody}>
                  <FiberManualRecordIcon className={classes.lunas} />
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className={classes.lunas}
                    style={{ padding: 1 }}
                  >
                    Lunas
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      <Grid item xs={12} className=".hideForPrint" id="warehouse-content">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerLeft}>Tanggal</TableCell>
              <TableCell>Kode PO</TableCell>
              <TableCell>Nomor Faktur</TableCell>
              <TableCell>Supplier</TableCell>
              <TableCell>Tanggal Jatuh Tempo</TableCell>
              <TableCell>Total Biaya</TableCell>
              <TableCell>Jenis Faktur</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className={classes.headerRight} />
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : tableData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <p>Tidak ada data yang bisa ditampilkan</p>
                </TableCell>
              </TableRow>
            ) : (
              tableData.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{dateFormat(row.createdDate)}</TableCell>
                  <TableCell>{row.codePo}</TableCell>
                  <TableCell>{row.noFaktur || '-'}</TableCell>
                  <TableCell>{row.supplierName}</TableCell>
                  <TableCell
                    className={clsx({
                      [classes.jatuhTempo]: row.dueWarningColor === "red",
                      [classes.belumJatuhTempo]:
                        row.dueWarningColor === "yellow",
                      [classes.lunas]: row.dueWarningColor === "green",
                    })}
                  >
                    {dateFormat(row.dueDate) || '-'}
                  </TableCell>
                  <TableCell>{currencyFormatWithDecimalOrNot(row.totalFee, 2)}</TableCell>
                  <TableCell>{row.isRealInvoice ? "Asli" : "Copy"}</TableCell>
                  <TableCell>{statusFormat(row.status)}</TableCell>
                  <TableCell align="right">
                    {cekRole("billing.c4")
                      ? !row.isRealInvoice && (
                          <Tooltip title="Tukar Faktur" placement="center" id="warehouse-content">
                            <Button
                              disableElevation
                              variant="contained"
                              size="small"
                              startIcon={<RepeatIcon />}
                              onClick={() => askTukarFaktur(row.poId, row._id)}
                            >
                              {"Tukar Faktur"}
                            </Button>
                          </Tooltip>
                        )
                      : null}
                    {cekRole("billing.c5")
                      ? row.isRealInvoice && (
                          <Tooltip title="Print Faktur" placement="center" id="warehouse-content">
                            <Button
                              disableElevation
                              variant="contained"
                              size="small"
                              className={classes.button}
                              startIcon={
                                row.isLoadingFaktur ? (
                                  <CircularProgress size={18} color="white" />
                                ) : (
                                  <PrintOutlinedIcon />
                                )
                              }
                              onClick={() =>
                                downloadFaktur(row.codePo, row["_id"], false)
                              }
                            >
                              {"Print Faktur"}
                            </Button>
                          </Tooltip>
                        )
                      : null}
                    {cekRole("billing.c5")
                      ? row.isRealInvoice &&
                        row.status === "paid off" && (
                          <Tooltip
                            title="Print Kwitansi Billing"
                            placement="center"
                            id="warehouse-content"
                          >
                            <Button
                              disableElevation
                              variant="contained"
                              size="small"
                              className={classes.button}
                              startIcon={
                                row.isLoadingKwitansi ? (
                                  <CircularProgress size={18} color="white" />
                                ) : (
                                  <PrintOutlinedIcon />
                                )
                              }
                              onClick={() =>
                                downloadDocument(row.codePo, row["_id"], false)
                              }
                            >
                              {"Print Kwitansi Billing"}
                            </Button>
                          </Tooltip>
                        )
                      : null}

                    {cekRole("billing.c2")
                      ? row.status !== "paid off" &&
                        row.isRealInvoice && (
                          <Tooltip title="Bayar Billing" placement="center" id="warehouse-content">
                            <IconButton
                              color="primary"
                              className={classes.button}
                              onClick={() =>
                                history.push(
                                  routes.BILLING +
                                    billingRoutes.PAYMENT +
                                    `/${row._id}`,
                                )
                              }
                            >
                              <CardIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      : null}
                    {cekRole("billing.c3")
                      ? row.status !== "paid off" &&
                        !row.isRealInvoice && (
                          <Tooltip
                            title="Detail Pembayaran Billing"
                            placement="center"
                            id="warehouse-content"
                          >
                            <IconButton
                              color="primary"
                              className={classes.button}
                              onClick={() =>
                                history.push(
                                  routes.BILLING +
                                    billingRoutes.BILLING_DETAIL +
                                    `/${row._id}`,
                                )
                              }
                            >
                              <InsertDriveFileOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      : null}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={9} align={"center"}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell colSpan={5} align={"right"} style={{ fontWeight: "bold" }}>
                  Total
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>{currencyFormatWithDecimalOrNot(totalEach.totalBiaya, 2)}</TableCell>
                <TableCell colSpan={3} />
              </TableRow>
            )}
          </TableFooter>
        </Table>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>

      <CustomDialog
        open={openTukarFaktur}
        handleClose={() => handleTukarFaktur(false)}
        title="Faktur akan ditukar"
        body="Apakah Anda yakin untuk menukar faktur?"
        onCancel={() => handleTukarFaktur(false)}
        cancelButtonText="Batalkan"
        onAccept={handleAcceptTukarFaktur}
        acceptButtonText="Tukar Faktur"
        acceptButtonColor={palette.blue}
      />
      {Object.keys(printPdfKwitansi).length > 0 && (
        <Print isHidden>
          <BillingKwitansi {...printPdfKwitansi} />
        </Print>
      )}
      {Object.keys(printPdfFaktur).length > 0 && (
        <Print isHidden>
          <BillingFaktur {...printPdfFaktur} />
        </Print>
      )}
    </div>
  );
};

export default Billing;
