import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CustomTextField from "../custom/CustomTextField";
import CustomRangeDatePicker from "../custom/CustomRangeDatePicker";
import SearchIcon from "@material-ui/icons/Search";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomPagination from "../custom/CustomPagination";
import { useHistory } from "react-router-dom";
import routesConst from "../../constants/routes";
import DeleteRoDialog from "./DeleteRoDialog";
import { getListRO, deleteRO, getDetailRO } from "../../services/RequestOrderService";
import debounce from "awesome-debounce-promise";
import moment from "moment";
import {
  currencyFormat,
} from "../../utils/formatting";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import { setSnackbar } from "../../store/general/actions";
import { connect } from "react-redux";
import { dateFormat } from "../../utils/formatting";
import useCekHakAkses from "../../utils/cekHakAkses";
import usePdfPrint from "../../utils/usePdfPrint";
import Print from "../../utils/Print";
import BeritaAcaraRO from "../Document/BeritaAcaraRO";


const DATE_FORMAT = "YYYY-MM-DD";

const useStyles = makeStyles((theme) => ({
  root: {},
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debouncedGetListRO = debounce(getListRO, 500);

const NewOrder = ({ setSnackbar }) => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = useCekHakAkses();

  const [searchForm, setSearchForm] = useState({
    startDate: moment().subtract(30, "days").toDate(),
    endDate: new Date(),
  });
  const [pagination, setPagination] = useState(paginationInitialData);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState({ open: false });
  const [isFetching, setIsFetching] = useState(false);
  const { printPdfProps, setPdfProps } = usePdfPrint({
    frameName: "ROKwitansi",
  });

  const handleDeleteDialog = (code, id) => {
    setOpenDeleteDialog({ ...openDeleteDialog, open: true, code, id });
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setSearchForm({ ...searchForm, startDate: start, endDate: end });
  };
  const onSearchChange = (value) =>
    setSearchForm({ ...searchForm, search: value });

  useEffect(() => {
    setIsFetching(true);
    debouncedGetListRO(
      searchForm.search,
      "new",
      moment.isDate(searchForm.startDate)
        ? moment(searchForm.startDate).format(DATE_FORMAT)
        : undefined,
      moment.isDate(searchForm.endDate)
        ? moment(searchForm.endDate).format(DATE_FORMAT)
        : moment(searchForm.startDate).format(DATE_FORMAT),
      pagination.page,
      pagination.rowsPerPage
    ).then((res) => {
      setTotalData(res.data.count);
      setData(res.data.items);
      setIsFetching(false);
    });
  }, [searchForm, pagination, openDeleteDialog]);


  async function downloadDocument(roNumber, id, full) {
    // setup loading button
    const selectedIndex = data.findIndex((d) => d["_id"] === id);
    setData((dataValue) => {
      const copy = [...dataValue];
      copy[selectedIndex].isLoading = true;
      return copy;
    });

    const res = await getDetailRO(id);
    setPdfProps(
      {
        data: res.data.item,
        items: res.data.item.item.map((value, index) => {
          return {
            ...value,
            no: index + 1,
          };
        }),
      },
      () => {
        setData((dataValue) => {
          const copy = [...dataValue];
          copy[selectedIndex].isLoading = false;
          return copy;
        });
      }
    );
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.headerGrid}>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h2" color="textPrimary">
            Request Order
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
        spacing={1}
      >
        <Grid item lg={3} style={{ paddingTop: 10 }}>
          <CustomTextField
            label="Cari Data Request Order"
            placeholder="No. RO atau Nama Client"
            value={searchForm.search}
            onChange={(event) => onSearchChange(event.target.value)}
            startIcon={<SearchIcon />}
          />
        </Grid>
        <Grid item lg={3} style={{ paddingTop: 10 }}>
          <CustomRangeDatePicker
            label="Berdasarkan Tanggal"
            startDate={searchForm.startDate}
            endDate={searchForm.endDate}
            onChange={onDateChange}
            placeholder="Berdasarkan Tanggal"
          />
        </Grid>
        <Grid item lg={6}>
          {cekRole("requestOrder.d3") && (
            <Button
              disableElevation
              variant="contained"
              size="large"
              className={classes.button}
              onClick={() => history.push(routesConst.ADD_NEW_ORDER)}
            >
              + Tambah RO
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} className=".hideForPrint" id="warehouse-content">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerLeft}>Tanggal</TableCell>
              <TableCell>No. Request Order</TableCell>
              <TableCell>Nama Client</TableCell>
              <TableCell align="center">Jumlah Item</TableCell>
              <TableCell align="center">Total Biaya</TableCell>
              <TableCell>Status</TableCell>
              {(cekRole("requestOrder.d4") || cekRole("requestOrder.d5") || cekRole("requestOrder.d12")) && (
                <TableCell className={classes.headerRight} />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <p>Tidak ada data yang bisa ditampilkan</p>
                </TableCell>
              </TableRow>
            ) : (
              data.map((row) => (
                <TableRow key={row["_id"]}>
                  <TableCell>{dateFormat(row.date)}</TableCell>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>
                    {row.hospitalName || row.customerName || ""}
                  </TableCell>
                  <TableCell align="center">{row.countItem}</TableCell>
                  <TableCell align="center">{currencyFormat(row.totalFee)}</TableCell>
                  <TableCell>Menunggu Persetujuan</TableCell>
                  {(cekRole("requestOrder.d4") || cekRole("requestOrder.d5" || cekRole("requestOrder.d12"))) && (
                    <TableCell>
                       {cekRole("requestOrder.d12") && (
                      <Tooltip title="Print Request Order" placement="center" id="warehouse-content">
                        <IconButton
                          color="primary"
                          className={classes.button}
                          onClick={async () =>
                            row.isLoading
                              ? null
                              : downloadDocument(row.code, row["_id"], false)
                          }
                        >
                          {row.isLoading ? (
                            <CircularProgress />
                          ) : (
                            <PrintOutlinedIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                      {cekRole("requestOrder.d5") && (
                        <Tooltip
                          title="Delete Request Order"
                          placement="center"
                          id="warehouse-content"
                        >
                          <IconButton
                            color="secondary"
                            className={classes.button}
                            size="small"
                            onClick={() =>
                              handleDeleteDialog(row.code, row["_id"])
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {cekRole("requestOrder.d5") && (
                        <Tooltip
                          title="Tinjau Request Order"
                          placement="center"
                          id="warehouse-content"
                        >
                          <Button
                            disableElevation
                            variant="contained"
                            size="medium"
                            className={classes.button}
                            onClick={() =>
                              history.push(
                                routesConst.APPROVE_ORDER + `/${row["_id"]}`
                              )
                            }
                            startIcon={<ThumbUpOutlinedIcon />}
                          >
                            Tinjau
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
      <DeleteRoDialog
        open={openDeleteDialog.open}
        handleClose={() => {
          setOpenDeleteDialog({ ...openDeleteDialog, open: false });
        }}
        roNumber={openDeleteDialog.code}
        handleAccept={() => {
          deleteRO(openDeleteDialog.id).then(() => {
            setOpenDeleteDialog({ ...openDeleteDialog, open: false });
            setSnackbar("success", "Sukses! Hapus barang berhasil dilakukan");
          });
        }}
      />
        {Object.keys(printPdfProps).length > 0 && (
        <Print isHidden>
          <BeritaAcaraRO {...printPdfProps} />
        </Print>
      )}
    </div>
  );
};

NewOrder.propTypes = {};
export default connect(null, { setSnackbar })(NewOrder);
