import React, { useEffect, useState } from "react";
import routes from "../../constants/routes";
import { useHistory } from "react-router-dom";
import { getListCategory } from "../../services/CategoryService";
import validations from "../../utils/validations";
import FormInventory from "./FormInventory";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import { makeStyles } from "@material-ui/core/styles";
import { getListRacks } from "../../services/RackService";

const useStyles = makeStyles({
  root: {
    margin: 20,
  },
});

const UpdateItemFromImport = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState(props.data);
  const [categories, setCategories] = useState([]);
  const [racks, setRacks] = useState([]);
  const [error, setError] = useState({});

  useEffect(() => {
    getListCategory("", 0, 0).then((res) => {
      setCategories(res.data.items.map((item) => item.name));
    });
    getListRacks("", 0, 0).then((res) => {
      setRacks(res.data.items.map((item) => item.name));
    });
  }, []);

  const checkError = () => {
    const name = validations.isEmpty(data.name);
    const type = validations.isEmpty(data.type);
    const bigUnit = validations.isEmpty(data.bigUnit);
    const unitPrecision = validations.isEmpty(data.unitPrecision);
    const smallUnit = validations.isEmpty(data.smallUnit);
    const stockWarning = validations.isEmpty(data.stockWarning);
    const stockBuffer = validations.isEmpty(data.stockBuffer);
    const rack = validations.isEmpty(data.rack);
    setError({
      name,
      type,
      bigUnit,
      unitPrecision,
      smallUnit,
      stockWarning,
      stockBuffer,
      rack,
    });
    return (
      name ||
      type ||
      bigUnit ||
      unitPrecision ||
      smallUnit ||
      stockWarning ||
      stockBuffer ||
      rack
    );
  };

  const handleAddItems = () => {
    if (checkError()) {
      return;
    }

    props.updateData(data);
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={`Update Barang ${props.code}`}
        previousPageName="Inventory Barang"
        previousPageLink={routes.INVENTORY}
      />
      <FormInventory
        data={data}
        setData={setData}
        error={error}
        handleAddItems={handleAddItems}
        handleCancel={() => history.push(routes.INVENTORY)}
        categories={categories}
        code={data.code}
        racks={racks}
        fromImport
      />
    </div>
  );
};

export default UpdateItemFromImport;
