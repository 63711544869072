import agent from "./agent";
import { getPlaceId } from "../store/auth/selector";

export const getAccessRightList = (search, skip = 0, limit = 100) => {
  return agent.get(`/access/getList`, {
    params: {
      filter: { search },
      skip: skip * limit,
      limit,
    },
  });
};

export const addAccessRight = (body) => {
  return agent.post("/access/add", { ...body, placeId: getPlaceId() });
};

export const getDetailAccessRight = (id) => {
  return agent.get(`/access/${id}/getDetail`, {});
};

export const editAccessRight = (id, body) => {
  return agent.put(`/access/${id}/edit`, { ...body, placeId: getPlaceId() });
};

export const deleteAccessRight = (id) => {
  return agent.put(`/access/${id}/delete`);
};
