import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { palette } from "../../theme";
import CustomPagination from "../custom/CustomPagination";
import routes, { adjustmentStokRoutes } from "../../constants/routes";
import SyncOutlinedIcon from "@material-ui/icons/SyncOutlined";
import { getListDepots } from "../../services/DepotService";
import useCekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {},
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  editButton: {
    float: "right",
    marginLeft: 5,
    color: palette.blue,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  topGrid: {
    display: "flex",
  },
  marginContainer: {
    marginLeft: 20,
  },
  alignRight: {
    textAlign: "right",
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const AdjustmentStok = (props) => {
  const classes = useStyles();
  const [pagination, setPagination] = useState(paginationInitialData);
  const [totalData, setTotalData] = useState(0);
  const [data, setData] = useState([]);

  const history = useHistory();
  const cekRole = useCekHakAkses();

  const tambahDepot = () => {
    history.push(routes.ADJUSTMENT + adjustmentStokRoutes.TAMBAH_DEPOT);
  };
  const repairStok = (id) => {
    history.push(
      routes.ADJUSTMENT + adjustmentStokRoutes.REPAIR_STOK + `/${id}`
    );
  };

  useEffect(() => {
    getListDepots(undefined, pagination.page, pagination.rowsPerPage).then(
      (res) => {
        setData(res.data.items);
        setTotalData(res.data.count);
      }
    );
  }, [pagination]);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        {cekRole("adjustmentStock.g3") && (
          <Grid item xs={12} className={classes.alignRight}>
            <Button
              disableElevation
              variant="contained"
              size="large"
              className={classes.button}
              onClick={tambahDepot}
            >
              + Depot
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerLeft}>Depot</TableCell>
              <TableCell className={classes.headerRight} />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{`${row.name} (${
                  row.type ? row.type : ""
                })`}</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  {cekRole("adjustmentStock.g4") && (
                  <Tooltip title="Repair Stok" placement="center" id="warehouse-content">
                    <Button
                      disableElevation
                      variant="outlined"
                      size="large"
                      className={classes.button}
                      startIcon={<SyncOutlinedIcon />}
                      onClick={() => repairStok(row.id)}
                    >
                      Repair Stok
                    </Button>
                  </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
    </div>
  );
};

AdjustmentStok.propTypes = {};
export default AdjustmentStok;
