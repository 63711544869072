import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import moment from "moment";

import CustomTextField from "../custom/CustomTextField";
import CustomSelect from "../custom/CustomSelect";
import routes, { inventoryRoutes } from "../../constants/routes";
import {
  addStockExpires,
  getDepotStock,
  getDetailKadaluarsaItem,
  editKadaluarsaItem,
  getTotalStockExpiresByDepotId,
  checkBatch,
} from "../../services/InventoryService";
import validations from "../../utils/validations";
import useDisclosure from "../../utils/useDisclosure";
import CustomDatePicker from "../custom/CustomDatePicker";
import { formatPayloadInt } from "../../utils/formatting";

const useStyles = makeStyles((theme) => ({
  root: {},
  editGrid: {
    display: "flex",
  },
  marginContainerLeft: {
    marginLeft: 20,
  },
  buttonGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    maxHeight: "100%",
    height: 450,
  },
  detailGrid: {
    marginTop: 20,
  },
  flexButton: {
    display: "flex",
    flexFlow: "wrap-reverse",
  },
  datePicker: {
    marginLeft: 20,
    width: 288,
  },
}));

const kadaluarsaDefault = {
  itemId: "",
  batchNo: "",
  expiredDate: "",
  stock: "",
  stockDepotId: "",
};

const TambahKadaluarsaInput = (props) => {
  const classes = useStyles();
  const { itemID, kadaluarsaId } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const q = new URLSearchParams(search);
  const source = q.get("source");
  const dispatch = useDispatch();
  const {
    open: isSubmitting,
    onOpen: setSubmit,
    onClose: setSubmitted,
  } = useDisclosure();

  const [kadaluarsa, setKadauluarsa] = useState({
    ...kadaluarsaDefault,
    itemId: itemID,
  });
  const [depots, setDepots] = useState([]);
  const [stockDepot, setStockDepot] = useState(0);
  const [stockKadaluarsaDepot, setKadaluarsaStockDepot] = useState(0);
  const [error, setError] = useState({});

  useEffect(() => {
    getDepotStock(itemID, 0, 100).then((res) => {
      if (props.edit) {
        setDepots(
          res.data.items.map((e) => ({
            label: `${e.depotName} (${e.rackName})`, value: e._id,
          }))
        );
      } else {
        setDepots(
          res.data.items.map((e) => ({
            label: `${e.depotName} (${e.rackName})`, value: {
              id: e._id,
              stock: e.stock,
            }
          }))
        );
      }
    });
  }, [itemID, props.edit]);

  useEffect(() => {
    if (props.edit) {
      getDetailKadaluarsaItem(kadaluarsaId).then((res) => {
        const expiredDate = res.data.item.expiredDate;
        setKadauluarsa({
          itemId: res.data.item.itemId,
          batchNo: res.data.item.batchNo,
          expiredDate: new Date(expiredDate),
          stock: res.data.item.stock,
          stockDepotId: res.data.item.stockDepotId,
        });
      });
    }
  }, [kadaluarsaId, props.edit, depots])

  const checkError = () => {
    const batchNo = validations.isEmpty(kadaluarsa.batchNo);
    const expiredDate = validations.isEmpty(kadaluarsa.expiredDate);
    const stock = validations.isEmpty(kadaluarsa.stock);
    const stockDepotId = validations.isEmpty(kadaluarsa.stockDepotId);
    const isEmpty = props.edit ? false : (parseInt(kadaluarsa.stock, 10) + parseInt(stockKadaluarsaDepot, 0)) > stockDepot;

    setError({ batchNo, expiredDate, stock, stockDepotId, isEmpty });
    return batchNo || expiredDate || stock || stockDepotId || isEmpty;
  };

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    if (name === 'stockDepotId') {
      getTotalStockExpiresByDepotId(value.id).then((res) => {
        setKadaluarsaStockDepot(res.data);
        setStockDepot(value.stock);
        setKadauluarsa({ ...kadaluarsa, [name]: value });
      }).catch((e) => {
        console.error(e);
        dispatch({
          type: "SET_SNACKBAR",
          payload: {
            open: true,
            variant: "error",
            message: "Error! Gagal mengambil data stock.",
          },
        });
      });
    } else {
      setKadauluarsa({ ...kadaluarsa, [name]: value });
    }
  };

  const handleChangeDate = (value) => {
    setKadauluarsa({ ...kadaluarsa, expiredDate: value });
  };

  const handleSubmit = () => {
    if (checkError()) {
      return;
    }

    setSubmit();
    if (!props.edit) {
      checkBatch(kadaluarsa.batchNo, itemID).then((res) => {
        if (res.data.length > 0) {
          dispatch({
            type: "SET_SNACKBAR",
            payload: {
              open: true,
              variant: "error",
              message: "Error! No Batch sudah digunakan.",
            },
          });
        } else {
          const payload = {
            ...kadaluarsa,
            stockDepotId: kadaluarsa.stockDepotId.id,
          };
          payload.expiredDate = moment(kadaluarsa.expiredDate).format('YYYY-MM-DDT00:00:00');
          addStockExpires(formatPayloadInt(payload, ["stock"]))
            .then((res) => {
              dispatch({
                type: "SET_SNACKBAR",
                payload: {
                  open: true,
                  variant: "success",
                  message: "Sukses! Tambah kadaluarsa barang berhasil dilakukan.",
                },
              });
              setSubmitted();
              history.push(
                routes.INVENTORY +
                inventoryRoutes.DETAIL_BARANG +
                `/${itemID}` +
                inventoryRoutes.KADALUARSA_ITEM +
                `?source=${source}`,
              );
            })
            .catch((e) => {
              console.error(e);
              dispatch({
                type: "SET_SNACKBAR",
                payload: {
                  open: true,
                  variant: "error",
                  message: "Error! Jumlah stok tidak boleh melebihi stok didalam depot.",
                },
              });
              setSubmitted();
            });
        }
      })
    } else {
      checkBatch(kadaluarsa.batchNo, itemID).then((res) => {
        if (res.data.length > 0) {
          dispatch({
            type: "SET_SNACKBAR",
            payload: {
              open: true,
              variant: "error",
              message: "Error! No Batch sudah digunakan.",
            },
          });
        } else {
          const payload = {
            batchNo: kadaluarsa.batchNo,
            expiredDate: moment(kadaluarsa.expiredDate).format('YYYY-MM-DDT00:00:00'),
          };
          editKadaluarsaItem(kadaluarsaId, payload)
            .then((res) => {
              dispatch({
                type: "SET_SNACKBAR",
                payload: {
                  open: true,
                  variant: "success",
                  message: "Sukses! Edit kadaluarsa barang berhasil dilakukan.",
                },
              });
              setSubmitted();
              history.push(
                routes.INVENTORY +
                inventoryRoutes.DETAIL_BARANG +
                `/${itemID}` +
                inventoryRoutes.KADALUARSA_ITEM +
                `?source=${source}`
              );
            })
            .catch((e) => {
              console.error(e);
              dispatch({
                type: "SET_SNACKBAR",
                payload: {
                  open: true,
                  variant: "error",
                  message: "Error! Periksa koneksi anda kembali.",
                },
              });
              setSubmitted();
            })
        }
      })
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.detailGrid}
      >
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            <CustomSelect
              style={{ marginRight: 20 }}
              value={kadaluarsa.stockDepotId}
              label="Nama Depot"
              onChange={handleChange("stockDepotId")}
              error={
                error.stockDepotId &&
                validations.isEmpty(kadaluarsa.stockDepotId)
              }
              helperText={
                error.stockDepotId &&
                  validations.isEmpty(kadaluarsa.stockDepotId)
                  ? "Nama Depot tidak boleh kosong"
                  : " "
              }
              disabled={props.edit}
              requiredField
            >
              {depots.map((depot) => (
                <MenuItem key={depot.value} value={depot.value}>
                  {depot.label}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
          {!props.edit &&
            <div className={classes.marginContainerLeft}>
              <CustomTextField
                label="Jumlah Stok Depot"
                value={stockDepot}
                disabled
              />
            </div>
          }
          {!props.edit &&
            <div className={classes.marginContainerLeft}>
              <CustomTextField
                label="Jumlah Stok Sudah Ada Kadaluarsa"
                value={stockKadaluarsaDepot}
                disabled
              />
            </div>
          }
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            <CustomTextField
              label="No Batch"
              value={kadaluarsa.batchNo}
              requiredField
              onChange={handleChange("batchNo")}
              error={error.batchNo && validations.isEmpty(kadaluarsa.batchNo)}
              helperText={
                error.batchNo && validations.isEmpty(kadaluarsa.batchNo)
                  ? "No Batch tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.datePicker}>
            <CustomDatePicker
              label="Tanggal Kadaluarsa"
              date={kadaluarsa.expiredDate}
              onChange={handleChangeDate}
              requiredField
              error={
                error.expiredDate && validations.isEmpty(kadaluarsa.expiredDate)
              }
              helperText={
                error.expiredDate && validations.isEmpty(kadaluarsa.expiredDate)
                  ? "Tanggal Kadaluarsa tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Jumlah Stok"
              value={kadaluarsa.stock}
              requiredField
              onChange={handleChange("stock")}
              error={(error.stock && validations.isEmpty(kadaluarsa.stock))
                || (error.isEmpty &&
                (kadaluarsa.stockDepotId !== '' && (parseInt(kadaluarsa.stock, 10) + parseInt(stockKadaluarsaDepot, 0)) > stockDepot))}
              helperText={
                error.stock && validations.isEmpty(kadaluarsa.stock)
                  ? "Jumlah Stok tidak boleh kosong"
                  : error.isEmpty &&
                  (kadaluarsa.stockDepotId !== '' && (parseInt(kadaluarsa.stock, 10) + parseInt(stockKadaluarsaDepot, 0)) > stockDepot)
                  ? "Jumlah melebih stok yang tersedia"
                  : " "
              }
              disabled={props.edit}
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.buttonGrid}>
          <div>
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() =>
                history.push(
                  routes.INVENTORY +
                    inventoryRoutes.DETAIL_BARANG +
                    `/${itemID}` +
                    inventoryRoutes.KADALUARSA_ITEM +
                    `?source=${source}`,
                )
              }
            >
              {"Batalkan"}
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              onClick={isSubmitting ? null : handleSubmit}
            >
              {"Simpan"}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

TambahKadaluarsaInput.propTypes = {};
export default TambahKadaluarsaInput;
