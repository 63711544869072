import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table"; 
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import PrintIcon from "@material-ui/icons/PrintOutlined";
import FileIcon from "@material-ui/icons/InsertDriveFileOutlined";
import ThumbDownIcon from "@material-ui/icons/ThumbDownOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import ProsesIcon from "@material-ui/icons/Cached";
import { useHistory } from "react-router-dom";
import debounce from "awesome-debounce-promise";

import CustomTextField from "../custom/CustomTextField";
import CustomRangeDatePicker from "../custom/CustomRangeDatePicker";
import CustomPagination from "../custom/CustomPagination";
import CustomSelect from "../custom/CustomSelect";
import Print from '../../utils/Print';
import routes, { penerimaanRoutes } from "../../constants/routes";
import {
  deletePenerimaan,
  getListPenerimaan,
  getPenerimaanDetail,
  getLockedWhileEditingStatus,
  lockPurchaseOrderPenerimaanForEditing,
} from "../../services/PenerimaanService";
import {
  paramDateFormat,
  dateFormat,
  currencyFormatWithDecimalOrNot,
} from "../../utils/formatting";
import CustomDialog from "../custom/CustomDialog";
import { setSnackbar } from "../../store/general/actions";
import { connect, useDispatch } from "react-redux";
// import { PDFViewer } from "@react-pdf/renderer";
import BeritaAcaraPenerimaanBarang from "../Document/BeritaAcaraPenerimaanBarang";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import useCekHakAkses from "../../utils/cekHakAkses";
import usePdfPrint from "../../utils/usePdfPrint";

import {
  FilterContainer,
  PenerimaanTitle,
  MarginContainer,
} from './FilterPenerimaan';

const useStyles = makeStyles({
  button: {
    marginLeft: 5,
  },
});

export const statusSelect = [
  { key: "", label: "Semuanya" },
  { key: "paid off", label: "Dibayar" },
  { key: "paid some", label: "Dibayar Sebagian" },
  { key: "waited", label: "Menunggu" },
  { key: "received", label: "Diterima" },
  { key: "received some", label: "Diterima Sebagian" },
];

const STATUS_ENUM = {
  "paid off": "Dibayar",
  "paid some": "Dibayar Sebagian",
  waited: "Menunggu",
  received: "Diterima",
  "received some": "Diterima Sebagian",
};

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debouncedGetListPenerimaan = debounce(getListPenerimaan, 500);

const Penerimaan = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = useCekHakAkses();

  const [searchForm, setSearchForm] = useState({
    status: "",
    startDate: moment().subtract(30, "days").toDate(),
    endDate: new Date(),
  });
  const [pagination, setPagination] = useState(paginationInitialData);
  const [totalData, setTotalData] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [deleteItem, setDeleteItem] = useState({ open: false });
  const [isFetching, setIsFetching] = useState(false);
  const { printPdfProps, setPdfProps } = usePdfPrint({ frameName: 'frame-berita-acara' })
  const dispatch = useDispatch();

  async function downloadDocument(id) {
    const selectedIndex = tableData.findIndex(data => data['_id'] === id);
    setTableData(data => {
      const copy = [...data];
      copy[selectedIndex].isLoading = true;
      return copy;
    })
    const res = await getPenerimaanDetail(id);
    setPdfProps({
      data: res.data.item,
      items: res.data.item.item.map((value, index) => ({
        ...value,
        no: index + 1.
      })),
      full: true,
    }, () => {
      setTableData(data => {
        const copy = [...data];
        copy[selectedIndex].isLoading = false;
        return copy;
      })
    });
  }

  useEffect(() => {
    setIsFetching(true);
    debouncedGetListPenerimaan(
      searchForm.search,
      searchForm.status,
      paramDateFormat(searchForm.startDate),
      paramDateFormat(searchForm.endDate),
      pagination.page,
      pagination.rowsPerPage
    ).then((res) => {
      setTotalData(res.data.count);
      setTableData(res.data.items);
      setIsFetching(false);
    });
  }, [searchForm, pagination]);

  const fetchItem = () => {
    debouncedGetListPenerimaan(
      searchForm.search,
      searchForm.status,
      paramDateFormat(searchForm.startDate),
      paramDateFormat(searchForm.endDate),
      pagination.page,
      pagination.rowsPerPage
    ).then((res) => {
      setTotalData(res.data.count);
      setTableData(res.data.items);
    });
  };
  const askDelete = (item) => () => {
    setDeleteItem({ ...item, open: true });
  };

  const handleDeleteItem = (answer) => {
    if (answer) {
      deletePenerimaan(deleteItem._id).then((res) => {
        props.setSnackbar("success", "Sukses! Hapus penerimaan berhasil dilakukan");
        fetchItem();
      });
    }
    setDeleteItem({ open: false });
  };

  return (
    <div>
      <PenerimaanTitle>
        Penerimaan Barang
      </PenerimaanTitle>

      <FilterContainer>
        <div>
          <CustomTextField
            label="Cari Data Barang Inbound"
            placeholder="Kode Penerimaan Barang"
            value={searchForm.search}
            onChange={(event) =>
              setSearchForm({ ...searchForm, search: event.target.value })
            }
            startIcon={<SearchIcon />}
          />
        </div>
        <MarginContainer>
          <CustomSelect
            value={searchForm.status}
            onChange={(event) =>
              setSearchForm({ ...searchForm, status: event.target.value })
            }
            label="Berdasarkan Status"
          >
            {statusSelect.map((status) => (
              <MenuItem key={status.key} value={status.key}>
                {status.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </MarginContainer>
        <MarginContainer>
          <CustomRangeDatePicker
            label="Berdasarkan Tanggal"
            startDate={searchForm.startDate}
            endDate={searchForm.endDate}
            onChange={([start, end]) =>
              setSearchForm({ ...searchForm, startDate: start, endDate: end })
            }
            placeholder="Berdasarkan Tanggal"
          />
        </MarginContainer>
      </FilterContainer>

      <Grid item xs={12} className=".hideForPrint" id="warehouse-content">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerLeft}>Tanggal PO</TableCell>
              <TableCell>Kode PO</TableCell>
              <TableCell>Kode Penerimaan Barang</TableCell>
              <TableCell>Biaya</TableCell>
              <TableCell>PIC Order</TableCell>
              <TableCell>Nama Supplier</TableCell>
              <TableCell>Tanggal Diterima</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className={classes.headerRight} />
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : tableData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <p>Tidak ada data yang bisa ditampilkan.</p>
                </TableCell>
              </TableRow>
            ) : (
              tableData.map((row) => (
                <TableRow key={row.codePO}>
                  <TableCell>{dateFormat(row.createdDate)}</TableCell>
                  <TableCell>{row.codePO}</TableCell>
                  <TableCell>{row.invoiceNo ? row.invoiceNo : "-"}</TableCell>
                  <TableCell>{currencyFormatWithDecimalOrNot(row.totalFee, 2)}</TableCell>
                  <TableCell>{row.createdName}</TableCell>
                  <TableCell>{row.supplier}</TableCell>
                  <TableCell>
                    {dateFormat(row.receivedDate && row.receivedDate[0] ?
                      row.receivedDate[0].receivedDate : '-')}
                  </TableCell>
                  <TableCell>{STATUS_ENUM[row.status]}</TableCell>
                  <TableCell align="right">
                    {cekRole("itemRetrieval.b3")
                      ? (row.status === "received" && !row.isReturn) && (
                          <Tooltip title="Retur Barang" placement="center" id="warehouse-content">
                            <Button
                              disableElevation
                              variant="contained"
                              size="small"
                              color="primary"
                              startIcon={<ThumbDownIcon />}
                              onClick={() =>
                                history.push(
                                  routes.PENERIMAAN +
                                    penerimaanRoutes.RETUR +
                                    `/${row._id}`
                                )
                              }
                              className={classes.button}
                            >
                              {"Retur"}
                            </Button>
                          </Tooltip>
                        )
                      : null}

                    {cekRole("itemRetrieval.b14")
                      ? (row.status === "received" &&
                        row.isRealInvoice === false &&
                        row.isReturn === false) && (
                          <Tooltip
                            title="Edit Penerimaan Barang"
                            placement="center"
                            id="warehouse-content"
                          >
                            <Button
                              disableElevation
                              variant="contained"
                              size="small"
                              startIcon={<CreateOutlinedIcon />}
                              className={classes.button}
                              onClick={() =>
                                history.push(
                                  routes.PENERIMAAN +
                                    penerimaanRoutes.EDIT_PENERIMAAN_BRG +
                                    `/${row._id}`
                                )
                              }
                            >
                              {"Edit"}
                            </Button>
                          </Tooltip>
                        )
                      : null} 
                    {cekRole("itemRetrieval.b2")
                      ? (row.status === "waited" ||
                          row.status === "received some") && (
                          <Tooltip
                            title={(row.invoiceNo && row.status !== "received some") ? "Proses Penerimaan Barang (After Edit PO)" :
                            "Proses Penerimaan Barang"}
                            placement="center"
                            id="warehouse-content"
                          >
                            <Button
                              disableElevation
                              variant="contained"
                              size="small"
                              startIcon={<ProsesIcon />}
                              onClick={async () => {
                                try {
                                  const result = await getLockedWhileEditingStatus({ poId: row._id });
                                  if (result.data.isEditing) {
                                    dispatch(setSnackbar("warning", "Maaf! ada data PO sedang diedit"));
                                  } else {
                                    lockPurchaseOrderPenerimaanForEditing({
                                      poId: row._id,
                                    });
                                    history.push(
                                          routes.PENERIMAAN +
                                            penerimaanRoutes.PROSES +
                                            `/${row._id}`
                                        );
                                  }
                                } catch (error) {
                                  console.error(error);
                                }
                              }}
                              className={classes.button}
                            >
                              {"Proses"}
                            </Button>
                          </Tooltip>
                        )
                      : null}
                    {cekRole("itemRetrieval.b4")
                      ? (row.status !== "waited" ||
                          row.status !== "received some") && (
                          <Tooltip
                            title="Detail Penerimaan Barang"
                            placement="center"
                            id="warehouse-content"
                          >
                            <IconButton
                              color="primary"
                              className={classes.button}
                              onClick={() =>
                                history.push(
                                  routes.PENERIMAAN +
                                    penerimaanRoutes.DETAIL +
                                    `/${row._id}`
                                )
                              }
                            >
                              <FileIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      : null}
                    {cekRole("itemRetrieval.b5") && (
                      <Tooltip
                        title="Print Berita Acara Penerimaan Barang"
                        placement="center"
                        id="warehouse-content"
                      >
                        <IconButton
                          color="primary"
                          className={classes.button}
                          onClick={async () => row.isLoading ? null : downloadDocument(row._id)}
                        >
                            {row.isLoading ? <CircularProgress /> : <PrintIcon />}
                        </IconButton>
                      </Tooltip>
                    )}
                    {cekRole("itemRetrieval.b6")
                      ? row.status !== "paid off" &&
                        row.status !== "received" && (
                          <Tooltip
                            title="Delete Penerimaan Barang"
                            placement="center"
                            id="warehouse-content"
                          >
                            <IconButton
                              color="secondary"
                              className={classes.button}
                              onClick={askDelete(row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      : null}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>

      <CustomDialog
        open={deleteItem.open}
        handleClose={() => handleDeleteItem(false)}
        title={`Anda akan menghapus data ${deleteItem.codePO}`}
        body="Anda akan menghapus PO dan Billing juga, setuju?"
        onCancel={() => handleDeleteItem(false)}
        cancelButtonText="Batalkan"
        onAccept={() => handleDeleteItem(true)}
        acceptButtonText="Hapus Data"
      />
        {Object.keys(printPdfProps).length > 0 && (
        <Print isHidden>
          <BeritaAcaraPenerimaanBarang {...printPdfProps} />
        </Print>
      )}
    </div>
  );
};

export default connect(null, { setSnackbar })(Penerimaan);
