import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import routes, { penerimaanRoutes } from "../../constants/routes";
import useCekHakAkses from "../../utils/cekHakAkses";
import Penerimaan from "./Penerimaan";
import Retur from "./Retur";
import ProsesPenerimaan from "./ProsesPenerimaan";
import DetailPenerimaan from "./DetailPenerimaan";
import ReturnBarang from './ReturnBarang';
import ReturnItem from './ReturnItem';
import EditPenerimaanBarang from './EditPenerimaanBarang'

import PenerimaanLayout from './PenerimaanLayout';

const getRoute = (route) => {
  return routes.PENERIMAAN + route;
};

const PenerimaanRoute = () => {
  const cekRole = useCekHakAkses();
  return (
    <Switch>
      <Route path={getRoute(penerimaanRoutes.PENERIMAAN_MENU)} exact>
        <PenerimaanLayout>
          <Penerimaan />
        </PenerimaanLayout>
      </Route>
      <Route path={getRoute(penerimaanRoutes.RETURN)} exact>
        <PenerimaanLayout>
          <ReturnBarang />
        </PenerimaanLayout>
      </Route>
      <Route path={getRoute(penerimaanRoutes.RETURN + '/:receiveId')} exact>
        <ReturnItem />
      </Route>
      <Route path={getRoute(penerimaanRoutes.RETUR) + "/:receiveId"} exact>
        <Retur />
      </Route>
      <Route path={getRoute(penerimaanRoutes.PROSES) + "/:receiveId"} exact>
        <ProsesPenerimaan />
      </Route>
      <Route path={getRoute(penerimaanRoutes.DETAIL) + "/:receiveId"} exact>
        <DetailPenerimaan />
      </Route>
      <Route path={getRoute(penerimaanRoutes.EDIT_PENERIMAAN_BRG) + "/:receiveId"} exact>
        <EditPenerimaanBarang />
      </Route>
      <Route path={getRoute('/no-access')}>
        Anda tidak memiliki hak akses
      </Route>
     {cekRole('itemRetrieval.b7') && <Redirect to={getRoute(penerimaanRoutes.PENERIMAAN_MENU)} />}
     {cekRole('itemRetrieval.b8') && <Redirect to={getRoute(penerimaanRoutes.RETURN)} />}
      {/* <Redirect to={getRoute(penerimaanRoutes.PENERIMAAN_MENU)} /> */}
    </Switch>
  );
};

export default PenerimaanRoute;
