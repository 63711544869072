import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import routes from "../../constants/routes";
import Typography from "@material-ui/core/Typography";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import CustomTextField from "../custom/CustomTextField";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import formatting from "../../utils/formatting";
import Button from "@material-ui/core/Button";
import ThumbUp from "@material-ui/icons/ThumbUp";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import { getDetailRO, approveRO } from "../../services/RequestOrderService";
import { setSnackbar } from "../../store/general/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  header: {
    lineHeight: "60px",
    fontWeight: "bold",
  },
  clinicName: {
    fontFamily: theme.typography.fontFamily,
    display: "inline",
  },
  itemGridLeft: {
    paddingRight: 12,
  },
  itemGridRight: {
    paddingLeft: 12,
  },
  inputField: {
    width: "100%",
    fontWeight: "bold",
  },
  table: {
    marginBottom: 30,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
}));

const ApproveOrder = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [data, setData] = useState({ item: [], clientName: "" });
  const [totalHarga, setTotalHarga] = useState(0);

  useEffect(() => {
    getDetailRO(id).then((res) => {
      setData(res.data.item);
      setTotalHarga(res.data.item.item.reduce((a, b) => a + (b.quantityRequest * b.price), 0));
    });
  }, [id]);

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName="Approve Item to Processing"
        previousPageName="Request Order"
        previousPageLink={routes.REQUEST_NEW}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className={`${classes.header} ${classes.borderBottom}`}
          >
            Detail RO
            <Typography
              className={`${classes.header} ${classes.clinicName}`}
              variant="body1"
              color="primary"
            >
              {" "}
              ( {data.hospitalName || data.clientName || ""} )
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Nama Client"
                className={classes.inputField}
                value={data.hospitalName || data.clientName || ""}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="PIC RO Client"
                className={classes.inputField}
                value={data.picRoClientName}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Alamat"
                className={classes.inputField}
                value={data.address}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="No. Whatsapp Client"
                className={classes.inputField}
                value={data.hp}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight} />
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="PIC Approval RO"
                className={classes.inputField}
                value={
                  data.picApproveClientName ? data.picApproveClientName : "-"
                }
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Status RO"
                className={classes.inputField}
                value={data.status}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className={`${classes.header} ${classes.borderBottom}`}
          >
            Detail Item
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerLeft}>Nama Item</TableCell>
                <TableCell>Stok Klinik</TableCell>
                <TableCell>Buffer Stok</TableCell>
                <TableCell>Jumlah Request Order</TableCell>
                <TableCell>Harga</TableCell>
                <TableCell className={classes.headerRight}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.item.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{`${
                    row.stockAvailableClinic === undefined
                      ? row.stockExisting
                      : row.stockAvailableClinic
                  } ${row.bigUnit}`}</TableCell>
                  <TableCell>{!row.bufferStockClinic ? row.stockBuffer : row.bufferStockClinic}</TableCell>
                  <TableCell>{row.quantityRequest}</TableCell>
                  <TableCell>
                    {"@" + formatting.currencyFormat(row.price)}
                  </TableCell>
                  <TableCell>{formatting.currencyFormat(row.price * row.quantityRequest)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={5} align="right" style={{ fontWeight: "bold" }}>Total Harga</TableCell>
                <TableCell>{formatting.currencyFormat(totalHarga)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => history.push(routes.REQUEST_NEW)}
            >
              Batalkan
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              startIcon={<ThumbUp />}
              onClick={() => {
                approveRO(id)
                  .then(() => {
                    dispatch(
                      setSnackbar(
                        "success",
                        "Sukses! Request order berhasil diapprove"
                      )
                    );
                    history.push(routes.REQUEST_PROCESSING);
                  })
                  .catch(() => {
                    dispatch(
                      setSnackbar(
                        "error",
                        "Error! Terjadi kesalahan. Coba beberapa saat lagi"
                      )
                    );
                  });
              }}
            >
              Selesai
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ApproveOrder;
