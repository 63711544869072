import { getPlaceId } from "../store/auth/selector";
import agent from "./agent";

export const getListCustomer = (search, skip = 0, limit = 20) => {
  return agent.get("/customers/getList", {
    params: {
      filter: { placeId: getPlaceId(), search },
      skip: skip * limit,
      limit,
    },
  });
};

export const getDetailCustomer = (id) => {
  return agent.get(`/customers/${id}/getDetail`);
};

export const editCustomer = (id, body) => {
  return agent.put(`/customers/${id}/edit`, body);
};

export const addCustomer = (body) => {
  return agent.post(`/customers/add`, { ...body, placeId: getPlaceId() });
};

export const deleteCustomer = (id) => {
  return agent.put(`/customers/${id}/delete`);
};

export const generateCustomerCode = () => {
  return agent.get("/customers/generateCode", {
    params: {
      filter: { placeId: getPlaceId() },
    },
  });
};
