import React from "react";
import { palette } from "../../theme";
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, TableHead, Grid } from '@material-ui/core';
import {
  dateFormat, dateFormatShipping
} from "../../utils/formatting";
import formatting from "../../utils/formatting";
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from "moment";

const CustomTableCell = withStyles({
  head: {
    backgroundColor: '#f2f2f2',
    fontSize: 14,
    fontclassName: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.28,
    letterSpacing: 'normal',
    textAlign: 'left',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: 0,
    paddingLeft: 3,
    paddingRight: 0,
    paddingTop: 0,
    // width: 150,
  },
  body: {
    fontSize: 14,
    fontclassName: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.28,
    letterSpacing: 'normal',
    textAlign: 'left',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: 0,
    paddingLeft: 3,
    paddingRight: 0,
    paddingTop: 0,
    // width: 150,
  },
})(TableCell);

const useStyle = makeStyles({
  page: {
    padding: 20,
  },
  title: {
    fontSize: 17,
    fontWeight: "bold",
    fontFamily: "Roboto",
    marginBottom: 20,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    backgroundColor: palette.whiteBlue,
    marginBottom: 10,
  },
  header: {
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  headerTextLabel: {
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    color: palette.secondaryText,
    width: 100,
    marginBottom: 10,
  },
  headerTextValue: {
    color: palette.black,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    marginBottom: 10,
    marginRight: 100,
  },
  headerLeftWidth: {
    width: 200,
  },
  tableHeaderText: {
    backgroundColor: palette.disabledTextfield,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 17,
    paddingTop: 15,
    paddingBottom: 15,
    borderColor: palette.disabledTextfield,
    paddingLeft: 5,
  },
  tableHeaderLeft: {
    borderTopLeftRadius: 10,
  },
  tableHeaderRight: {
    borderTopRightRadius: 10,
  },
  tableDataText: {
    fontFamily: "Roboto",
    fontSize: 14,
    paddingTop: 5,
    paddingBottom: 5,
    borderColor: palette.disabledTextfield,
    borderTopColor: palette.disabledTextfield,
    paddingLeft: 5,
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    marginTop: 20,
    // marginLeft: 80,
  },
  footerSignatureName: {
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    color: palette.black,
    width: '100%',
    marginBottom: 40,
    textAlign: "center",
    // marginRight: 110,
  },
  footerSignature: {
    color: palette.black,
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    width: '100%',
    textAlign: 'center'
    // marginRight: 110,
  },
  blackFill: {
    backgroundColor: palette.black,
    width: 400,
  },
});

const RequestOrderDocument = ({ data, full }) => {
  const classes = useStyle();
  moment.locale("id");
  return (
    <div>
        <div>
          <div className={classes.title}>Berita Acara Delivery Order</div>
        </div>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <div className={classes.headerTextLabel}> Waktu RO</div>
            <div className={classes.headerTextLabel}> Waktu DO</div>
            <div className={classes.headerTextLabel}> Klinik</div>
            <div className={classes.headerTextLabel}> Supplier</div>
          </div>
          <div className={classes.header}>
            <div className={classes.headerTextValue}>
              {!data.createdAtClinic ? dateFormat(data?.createdDate) : dateFormat(data.createdAtClinic)}
            </div>
            <div className={classes.headerTextValue}>
              {!data?.shippedDate ?  "-" : dateFormatShipping(data?.shippedDate)}
            </div>
            <div className={classes.headerTextValue}>{data?.clientName}</div>
            <div className={classes.headerTextValue}>Central Warehouse</div>
          </div>
          <div className={classes.header}>
            <div className={classes.headerTextLabel}> Tipe Barang</div>
            <div className={classes.headerTextLabel}> User RO</div>
            <div className={classes.headerTextLabel}> User Approval</div>
            <div className={classes.headerTextLabel}> ID RO</div>
          </div>
          <div className={classes.header}>
            <div className={classes.headerTextValue}>
              Obat, Alkes, BHP dan Aksesoris
            </div>
            <div className={classes.headerTextValue}>{data?.picRoClientName}</div>
            <div className={classes.headerTextValue}>
              {data?.picApproveClientName ? data?.picApproveClientName : "-"}
            </div>
            <div className={classes.headerTextValue}>{data?.code}</div>
          </div>
        </div>

        {full ? (
          <Table data={data}>
            <TableHead>
              <CustomTableCell
                className={[classes.tableHeaderText, classes.tableHeaderLeft]}
                weighting={0.05}
              >
                No
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.25}>
                Kode Item
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.22}>
                Nama Item
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.1}>
                Stok
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.1}>
                Qty RO
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.1}>
                Qty DO
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.15}>
                Harga
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.15}>
                Total Harga
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.2}>
                No Batch
              </CustomTableCell>
              <CustomTableCell
                className={[classes.tableHeaderText, classes.tableHeaderRight]}
                weighting={0.12}
              >
                Satuan
              </CustomTableCell>
            </TableHead>
            <TableBody>
              {data.item.map((d, index) => (
                <TableRow>
              <CustomTableCell className={classes.tableDataText} weighting={0.05}>{index + 1}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} weighting={0.25}>{d.itemCode}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} weighting={0.22}>{d.name}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} weighting={0.1}>
                    {d.stockAvailableClinic === undefined ? d.stockExisting : d.stockAvailableClinic}
                  </CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} weighting={0.1}>{d.quantityRequest}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} weighting={0.1}>{d.quantityDeliver}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} weighting={0.15}>{formatting.currencyFormat(d.priceAfterDiscount)}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} weighting={0.15}>{ formatting.currencyFormat(
                        d.priceAfterDiscount * d.quantityDeliver
                      )}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} weighting={0.2}>{d.batchItems.length !== 0 && d.batchItems[0].batchNo}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} weighting={0.12}>{d.bigUnit}</CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Table data={data}>
            <TableHead>
              <CustomTableCell
                className={[classes.tableHeaderText, classes.tableHeaderLeft]}
                weighting={0.05}
              >
                No
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.22}>
                Kode Item
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.25}>
                Nama Item
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.1}>
                Stok
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.1}>
                Qty RO
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.1}>
                Qty DO
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} weighting={0.2}>
                No Batch
              </CustomTableCell>
              <CustomTableCell
                className={[classes.tableHeaderText, classes.tableHeaderRight]}
                weighting={0.12}
              >
                Satuan
              </CustomTableCell>
            </TableHead>
            <TableBody>
            {data.item.map((d, index) => (
              <TableRow>
              <CustomTableCell className={classes.tableDataText} weighting={0.05}>{index + 1}</CustomTableCell>
              <CustomTableCell className={classes.tableDataText} weighting={0.22}>{d.itemCode}</CustomTableCell>
              <CustomTableCell className={classes.tableDataText} weighting={0.25}>{d.name}</CustomTableCell>
              <CustomTableCell className={classes.tableDataText} weighting={0.1}>
                {d.stockAvailableClinic === undefined ? d.stockExisting : d.stockAvailableClinic}
              </CustomTableCell>
              <CustomTableCell className={classes.tableDataText} weighting={0.1}>{d.quantityRequest}</CustomTableCell>
              <CustomTableCell className={classes.tableDataText} weighting={0.1}>{d.quantityDeliver}</CustomTableCell>
              <CustomTableCell className={classes.tableDataText} weighting={0.2}>{d.batchItems.length !== 0 && d.batchItems[0].batchNo}</CustomTableCell>
              <CustomTableCell className={classes.tableDataText} weighting={0.12}>{d.bigUnit}</CustomTableCell>
               </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {full ? (
          <Table data={[{ totalFee: data?.totalFee }]}>
            <TableBody>
              <CustomTableCell className={classes.blackFill} weighting={0.45}></CustomTableCell>
              <CustomTableCell className={classes.tableDataText} weighting={0.45}>Total Harga </CustomTableCell>
              <CustomTableCell className={classes.tableDataText} weighting={0.2}>{formatting.currencyFormat(data.totalFee)} </CustomTableCell>
            </TableBody>
          </Table>
        ) : undefined}
        <Grid container item xs={12} className={classes.footerContainer} justify="space-evenly">
          <Grid item xs={2}>
            <div className={classes.footerSignatureName}>Packaging</div>
            <div className={classes.footerSignature}>________________</div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.footerSignatureName}>Admin</div>
            <div className={classes.footerSignature}>________________</div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.footerSignatureName}>Pengirim</div>
            <div className={classes.footerSignature}>________________</div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.footerSignatureName}>Supervisor</div>
            <div className={classes.footerSignature}>________________</div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.footerSignatureName}>Penerima Barang</div>
            <div className={classes.footerSignature}>________________</div>
          </Grid>
        </Grid>

    </div>
  );
};

RequestOrderDocument.defaultProps = {
  data: {},
  full: false,
};

export default RequestOrderDocument;
