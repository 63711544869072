import { getPlaceId } from "../store/auth/selector";
import agent from "./agent";

export const getListRacks = (search, skip, limit) => {
  return agent.get("/racks/getList", {
    params: {
      filter: { placeId: getPlaceId(), search },
      skip: skip * limit,
      limit,
    },
  });
};

export const getDetailRack = (id) => {
  return agent.get(`/racks/${id}/getDetail`);
};

export const deleteRack = (id) => {
  return agent.put(`/racks/${id}/delete`);
};

export const editRack = (id, name) => {
  return agent.put(`/racks/${id}/edit`, { name });
};

export const addRack = (name) => {
  return agent.post(`/racks/add`, { placeId: getPlaceId(), name });
};
