import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { connect, useSelector } from "react-redux";

import BaseLayout from "./components/BaseLayout";
import AuthLayout from "./components/AuthLayout";
import routes from "./constants/routes";
import RequestOrder from "./components/Request/RequestOrder";
import AddNewOrder from "./components/Request/AddNewOrder";
import ApproveOrder from "./components/Request/ApproveOrder";
import ProcessRo from "./components/Request/ProcessRo";
import Login from "./components/Login/Login";
import ForgotPass from "./components/Login/ForgotPassword";
import ChangePass from "./components/Login/ChangePassword";
import DevOnly from "./components/DevOnly";
// import Shipping from "./components/Request/Return";
import EditProcessDO from "./components/Request/EditProcessDO";
import PurchaseOrder from "./components/PurchaseOrder/PurchaseOrder";
import PurchaseOrderDetail from "./components/PurchaseOrder/PurchaseOrderDetail";
import EditPurchaseOrder from "./components/PurchaseOrder/EditPurchaseOrder";
import AddPurchaseOrder from "./components/PurchaseOrder/AddPurchaseOrder";
import GeneralSettings from "./components/Settings/GeneralSettings";
import StaffManagement from "./components/Settings/StaffManagement";
import AddNewStaff from "./components/Settings/AddNewStaff";
import InventoryRoute from "./components/Inventory/InventoryRoute";
import AccessRight from "./components/Settings/AccessRight";
import AddAccessRight from "./components/Settings/AddAccessRight";
import ItemCategory from "./components/Settings/ItemCategory";
import AddNewCategory from "./components/Settings/AddNewCategory";
import StorageRack from "./components/Settings/StorageRack";
import AddNewStorageRack from "./components/Settings/AddNewStorageRack";
import Supplier from "./components/Settings/Supplier";
import AddNewSupplier from "./components/Settings/AddNewSupplier";
import Client from "./components/Settings/Client";
import AddNewClient from "./components/Settings/AddNewClient";
import BillingRoute from "./components/Billing/BillingRoute";
import LaporanRoute from "./components/Laporan/LaporanRoute";
import PenerimaanRoute from "./components/Penerimaan/PenerimaanRoute";
import AdjustmentStokRoute from "./components/AdjustmentStok/AdjustmentStokRoute";
import Sales from "./components/Sales/Sales";
import PembayaranSales from "./components/Sales/PembayaranSales";
import useCekHakAkses from "./utils/cekHakAkses";

export function useAllowedRoute() {
  const cekRole = useCekHakAkses();
  const location = useLocation();
  const allowedRoutes = [];
  if (cekRole('inventory.f1')) {
    allowedRoutes.push(routes.INVENTORY);
  }
  if (cekRole('requestOrder.d1')) {
    allowedRoutes.push(routes.REQUEST_NEW);
  }
  if (cekRole('sales.e1')) {
    allowedRoutes.push(routes.SALES);
  }
  if (cekRole('purchaseOrder.a1')) {
    allowedRoutes.push(routes.PURCHASE);
  }
  if (cekRole('itemRetrieval.b1')) {
    allowedRoutes.push(routes.PENERIMAAN);
  }
  if (cekRole('billing.c1')) {
    allowedRoutes.push(routes.BILLING);
  }
  if (cekRole('adjustmentStock.g1')) {
    allowedRoutes.push(routes.ADJUSTMENT);
  }
  if (cekRole('report.h1')) {
    allowedRoutes.push(routes.LAPORAN);
  }
  if (cekRole('setting.i1')) {
    allowedRoutes.push(routes.SETTINGS);
  }
  const isCurrentRouteAllowed = allowedRoutes.some(route => {
    const regexp = new RegExp(route);
    return regexp.test(location.pathname);
  });
  // jika route saat ini gaada akses
  if (!isCurrentRouteAllowed) {
    // maka kasih route yang dibolehkan pertama
    return allowedRoutes[0];
  }
  // kalau dibolehkan ,kasih aja route saat ini
  return location.pathname;
}

function useAllowedSettingRoute() {
  const cekRole = useCekHakAkses();
  const location = useLocation();
  const allowedRoutes = [];
  if (cekRole('setting.i2')) {
    allowedRoutes.push(routes.GENERAL_SETTINGS);
  }
  if (cekRole('setting.i3')) {
    allowedRoutes.push(routes.STAFF_MANAGEMENT);
  }
  if (cekRole('setting.i7')) {
    allowedRoutes.push(routes.ACCESS_RIGHT);
  }
  if (cekRole('setting.i12')) {
    allowedRoutes.push(routes.ITEM_CATEGORY);
  }
  if (cekRole('setting.i19')) {
    allowedRoutes.push(routes.STORAGE_RACK);
  }
  if (cekRole('setting.i26')) {
    allowedRoutes.push(routes.SUPPPLIER);
  }
  if (cekRole('setting.i33')) {
    allowedRoutes.push(routes.CLIENT);
  }
  const isCurrentRouteAllowed = allowedRoutes.some(route => {
    const regexp = new RegExp(route.slice(9));
    return regexp.test(location.pathname);
  });
  // jika route saat ini gaada akses
  if (!isCurrentRouteAllowed) {
    // maka kasih route yang dibolehkan pertama
    return allowedRoutes[0];
  }
  // kalau dibolehkan ,kasih aja route saat ini
  return location.pathname;
}

const PrivateRoute = ({ children, ...rest }) => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  return (
    <Route
      {...rest}
      render={() =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect to={{ pathname: routes.LOGIN }} />
        )
      }
    />
  );
};

const Routing = (props) => {

  const cekRole = useCekHakAkses();
  const location = useLocation();
  const allowedRoute = useAllowedRoute();

  const settingRoute = useAllowedSettingRoute();

  return (
    <Switch>
      <Route path="/auth">
        <AuthLayout>
          <Switch>
            <Route exact path={routes.LOGIN} component={() => <Login />} />
            <Route
              exact
              path={routes.FORGOTPASS}
              component={() => <ForgotPass />}
            />
            <Route
              exact
              path={routes.CHANGEPASS + "/:token"}
              component={() => <ChangePass />}
            />
            <Redirect to={routes.LOGIN} />
          </Switch>
        </AuthLayout>
      </Route>
      <PrivateRoute path="/">
        <BaseLayout>
          <Switch>
            <Route
              path={routes.INVENTORY}
              component={() => <InventoryRoute />}
            />
            <Route
              exact
              path={routes.REQUEST_NEW}
              component={() => <RequestOrder index={0} />}
            />
            <Route
              exact
              path={routes.REQUEST_PROCESSING}
              component={() => <RequestOrder index={1} />}
            />
            <Route
              exact
              path={routes.REQUEST_SHIPPING}
              component={() => <RequestOrder index={2} />}
            />
            <Route
              exact
              path={routes.ADD_NEW_ORDER}
              component={() => <AddNewOrder />}
            />
            <Route
              exact
              path={routes.APPROVE_ORDER + "/:id"}
              component={() => <ApproveOrder />}
            />
            <Route
              exact
              path={routes.PROCESS_ORDER + "/:id"}
              component={() => <ProcessRo />}
            />
            <Route
              exact
              path={routes.RETURN + "/:id"}
              // component={() => <Shipping />}
              component={() => <EditProcessDO />}
            />
            <Route path={routes.PURCHASE}>
              <Route
                exact
                path={routes.PURCHASE}
                component={() => <PurchaseOrder />}
              />
              <Route
                exact
                path={routes.ADD_PURCHASE_ORDER}
                component={() => <AddPurchaseOrder />}
              />
              <Route
                exact
                path={routes.PURCHASE_ORDER_DETAIL + "/:id"}
                component={() => <PurchaseOrderDetail />}
              />
               <Route
                exact
                path={routes.EDIT_PURCHASE_ORDER + "/:id"}
                component={() => <EditPurchaseOrder />}
              />
            </Route>
            <Route path={routes.SETTINGS}>
              {cekRole("setting.i2") && (
                <Route
                  exact
                  path={routes.GENERAL_SETTINGS}
                  component={() => <GeneralSettings />}
                />
              )}
              {cekRole("setting.i3") && (
                <Route
                  exact
                  path={routes.STAFF_MANAGEMENT}
                  component={() => <StaffManagement />}
                />
              )}
              {cekRole("setting.i4") && (
                <Route
                  exact
                  path={routes.ADD_NEW_STAFF}
                  component={() => <AddNewStaff />}
                />
              )}
              {cekRole("setting.i5") && (
                <Route
                  exact
                  path={routes.EDIT_STAFF + "/:id"}
                  component={() => <AddNewStaff edit />}
                />
              )}
              {cekRole("setting.i7") && (
                <Route
                  exact
                  path={routes.ACCESS_RIGHT}
                  component={() => <AccessRight />}
                />
              )}
              {cekRole("setting.i8") && (
                <Route
                  exact
                  path={routes.ADD_ACCESS_RIGHT}
                  component={() => <AddAccessRight />}
                />
              )}
              {cekRole("setting.i10") && (
                <Route
                  exact
                  path={routes.EDIT_ACCESS_RIGHT + "/:id"}
                  component={() => <AddAccessRight edit />}
                />
              )}
              {cekRole("setting.i12") && (
                <Route
                  exact
                  path={routes.ITEM_CATEGORY}
                  component={() => <ItemCategory />}
                />
              )}
              {cekRole("setting.i16") && (
                <Route
                  exact
                  path={routes.ADD_NEW_CATEGORY}
                  component={() => <AddNewCategory />}
                />
              )}
              {cekRole("setting.i17") && (
                <Route
                  exact
                  path={routes.EDIT_CATEGORY + "/:id"}
                  component={() => <AddNewCategory edit />}
                />
              )}
              {cekRole("setting.i19") && (
                <Route
                  exact
                  path={routes.STORAGE_RACK}
                  component={() => <StorageRack />}
                />
              )}
              {cekRole("setting.i20") && (
                <Route
                  exact
                  path={routes.ADD_STORAGE_RACK}
                  component={() => <AddNewStorageRack />}
                />
              )}
              {cekRole("setting.i21") && (
                <Route
                  exact
                  path={routes.EDIT_STORAGE_RACK + "/:id"}
                  component={() => <AddNewStorageRack edit />}
                />
              )}
              {cekRole("setting.i26") && (
                <Route
                  exact
                  path={routes.SUPPPLIER}
                  component={() => <Supplier />}
                />
              )}
              {cekRole("setting.i27") && (
                <Route
                  exact
                  path={routes.ADD_NEW_SUPPLIER}
                  component={() => <AddNewSupplier />}
                />
              )}
              {cekRole("setting.i28") && (
                <Route
                  exact
                  path={routes.EDIT_SUPPLIER + "/:id"}
                  component={() => <AddNewSupplier edit />}
                />
              )}
              {cekRole("setting.i33") && (
                <Route
                  exact
                  path={routes.CLIENT}
                  component={() => <Client />}
                />
              )}
              {cekRole("setting.i34") && (
                <Route
                  exact
                  path={routes.ADD_NEW_CLIENT}
                  component={() => <AddNewClient />}
                />
              )}
              {cekRole("setting.i35") && (
                <Route
                  exact
                  path={routes.EDIT_CLIENT + "/:id"}
                  component={() => <AddNewClient edit />}
                />
              )}
              {location.pathname !== settingRoute &&
                <Redirect to={settingRoute} />
              }
            </Route>
            <Route path={routes.BILLING} component={() => <BillingRoute />} />
            <Route
              path={routes.ADJUSTMENT}
              component={() => <AdjustmentStokRoute />}
            />
            <Route path={routes.LAPORAN} component={() => <LaporanRoute />} />
            <Route
              path={routes.PENERIMAAN}
              component={() => <PenerimaanRoute />}
            />
            <Route exact path={routes.SALES} component={() => <Sales />} />
            <Route
              exact
              path={routes.SALES + "/:invoiceId"}
              component={() => <PembayaranSales />}
            />
            <Route exact path="/dev" component={() => <DevOnly />} />
            {allowedRoute !== location.pathname && <Redirect to={allowedRoute} />}
          </Switch>
        </BaseLayout>
      </PrivateRoute>
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
});

export default connect(mapStateToProps)(Routing);
