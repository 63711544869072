import { useSelector } from "react-redux";
import get from 'lodash.get';

export default function useCekHakAkses() {
    const roles = useSelector(({ auth }) => get(auth, 'user.RoleActions.action'));

    if (!roles) {
      return () => false;
    }

    return (rolePath = '') => get(roles, rolePath);
}
