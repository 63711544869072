import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import NewOrder from "./NewOrder";
import Processing from "./Processing";
import Shipping from "./Shipping";
import useCekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  hideMenu: {
    display: 'none',
  },
  processingTab: {
    "&.Mui-selected": {
      color: theme.palette.yellow,
    },
  },
  shippingTab: {
    "&.Mui-selected": {
      color: theme.palette.green,
    },
  },
}));

const RequestOrder = (props) => {
  const classes = useStyles();
  const cekRole = useCekHakAkses();
  const [tab, setTab] = React.useState(0);

  const renderTab = (index) => {
    if (index === 0) {
      return cekRole("requestOrder.d2") ? <NewOrder /> : 
      cekRole("requestOrder.d6") ? <Processing /> :
      cekRole("requestOrder.d9") ? <Shipping /> : '';
    } else if (index === 1) {
      return cekRole("requestOrder.d6") && <Processing />;
    } else if (index === 2) {
      return cekRole("requestOrder.d9") && <Shipping />;
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    setTab(props.index);
  }, [props.index]);

  return (
    <div className={classes.root}>
      <Tabs textColor="primary" value={tab} onChange={handleTabChange}>
        <Tab label="New Order" className={cekRole("requestOrder.d2") ? '' : classes.hideMenu} />
        <Tab label="Processing" className={cekRole("requestOrder.d6") ? classes.processingTab : classes.hideMenu} />
        <Tab label="Shipping" className={cekRole("requestOrder.d9") ? classes.shippingTab : classes.hideMenu} />
      </Tabs>
      {renderTab(tab)}
    </div>
  );
};

export default RequestOrder;
