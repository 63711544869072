import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import routes from "../../constants/routes";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CustomTextField from "../custom/CustomTextField";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory, useParams } from "react-router-dom";
import { getReturDetail, returBarang } from "../../services/PenerimaanService";
import { currencyFormat } from "../../utils/formatting";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  returnHeader: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 65,
  },
  header: {
    lineHeight: "60px",
    fontWeight: "bold",
    color: theme.palette.blue,
  },
  title: {
    lineHeight: "60px",
    fontWeight: "bold",
  },
  table: {
    marginBottom: 30,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  topItem: {
    marginTop: 50,
  },
  alignRight: {
    textAlign: "right",
  },
  tableText1: {
    width: 175,
  },
  tableText2: {
    width: 125,
  },
  grandTotal: {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.whiteBlue,
      color: theme.palette.titleText,
      fontWeight: "bold",
    },
  },
}));

const Retur = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { receiveId } = useParams();

  const [selected, setSelected] = useState({});
  const [retur, setRetur] = useState({});

  useEffect(() => {
    getReturDetail(receiveId).then((res) => {
      setRetur(res.data.item);
    });
  }, [receiveId]);

  const handleCheck = (id) => (event) => {
    const newSelected = { ...selected };
    if (!newSelected[id]) {
      newSelected[id] = true;
    } else {
      newSelected[id] = false;
    }
    setSelected(newSelected);
  };

  const handleChange = (index) => (event) => {
    let { value } = event.target;
    const items = retur.item.slice();

    if (items[index].quantity < value) {
      value = items[index].quantity;
    }

    items[index].quantityReturn = value;
    setRetur({ ...retur, item: items });
  };

  const countItemPrice = useCallback(
    (item) => {
      const quantity =
        item.quantity - (selected[item.id] ? item.quantityReturn : 0);
      return (quantity / item.quantity) * item.price;
    },
    [selected]
  );

  const countItemDiscount = useCallback(
    (item) => {
      return countItemPrice(item) * (item.discountPercent / 100);
    },
    [countItemPrice]
  );

  const totalDiscount = useMemo(() => {
    return retur.item?.reduce((a, b) => a + countItemDiscount(b), 0);
  }, [countItemDiscount, retur.item]);

  const subTotal = useMemo(() => {
    const subTotal = retur.item?.reduce((a, b) => a + countItemPrice(b), 0);
    return subTotal - totalDiscount;
  }, [countItemPrice, retur.item, totalDiscount]);

  const ppnTotal = useMemo(() => {
    return subTotal * (retur.ppnPercent / 100);
  }, [retur.ppnPercent, subTotal]);

  const total = useMemo(() => {
    return subTotal + ppnTotal;
  }, [ppnTotal, subTotal]);

  const handleSubmit = () => {
    const data = {
      totalFeeBefore: retur.totalFeeBefore,
      totalFee: total,
      items: retur.item.map((item) => ({
        id: item.id,
        itemId: item.itemId,
        stockDepotId: item.stockDepotId,
        quantityReturn: parseInt(item.quantityReturn, 10),
        quantityBefore: parseInt(item.batchItems.reduce((a, b) => a + b.quantityReceive, 0)),
      })),
    };

    returBarang(receiveId, data).then((res) => {
      history.push(routes.PENERIMAAN);
    });
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={`Retur ${retur.invoiceNo || ""}`}
        previousPageName="Penerimaan Barang"
        previousPageLink={routes.PENERIMAAN}
      />

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.returnHeader}
      >
        <Grid item xs={6} className={classes.topGrid}>
          <div>
            <Typography variant="body1" className={`${classes.title}`}>
              Retur Barang Dari {retur.invoiceNo}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} className={classes.alignRight}>
          <Typography variant="body2" className={`${classes.title}`}>
            Note : Pilih barang & masukkan jumlah barang yang ingin diretur
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerLeft}></TableCell>
              <TableCell>Nama Barang</TableCell>
              <TableCell>Jumlah Diterima</TableCell>
              <TableCell>Harga</TableCell>
              <TableCell>Diskon</TableCell>
              <TableCell>Jumlah Ingin Diretur</TableCell>
              <TableCell className={classes.headerRight}>Depot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {retur.item?.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Checkbox
                    checked={selected[row.id]}
                    onClick={handleCheck(row.id)}
                    color="primary"
                  />
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.quantity}</TableCell>
                <TableCell>{currencyFormat(row.price)}</TableCell>
                <TableCell>{row.discountPercent !== null ? row.discountPercent : 0} %</TableCell>
                <TableCell>
                  <CustomTextField
                    style={{ marginRight: 20 }}
                    helperText=""
                    onChange={handleChange(index)}
                    className={classes.tableText1}
                    type="number"
                    value={selected[row.id] ? row.quantityReturn : 0}
                    disabled={!selected[row.id]}
                  />
                </TableCell>
                <TableCell>
                  <CustomTextField
                    style={{ marginRight: 20 }}
                    helperText=""
                    value={row.depotName}
                    disabled
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>

      <Grid item xs={6} className={classes.topItem}>
        <Grid container>
          <Grid item xs={6}>
            <CustomTextField
              label="Total Discount"
              disabled
              value={totalDiscount}
              currency
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label="Sub Total"
              disabled
              value={subTotal}
              currency
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label="Total PPN"
              disabled
              value={ppnTotal}
              currency
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label="Total Biaya Awal"
              disabled
              value={retur.totalFeeBefore}
              currency
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label="Total Setelah Barang diretur"
              disabled
              value={total}
              className={classes.grandTotal}
              currency
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="flex-end">
          <Button
            disableElevation
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => history.push(routes.PENERIMAAN)}
          >
            {"Batalkan"}
          </Button>
          <Button
            disableElevation
            variant="contained"
            size="large"
            onClick={handleSubmit}
          >
            {"Retur Barang"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Retur;
