import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from "@material-ui/core/styles";
import { Font } from "@react-pdf/renderer";
import "fontsource-roboto";

import RobotoRegular from "./font/Roboto/Roboto-Regular.ttf";
import RobotoBold from "./font/Roboto/Roboto-Medium.ttf";

import Routing from "./Routing";
import store from "./store/store";
import theme from "./theme";
import Logger from "./services/error-handling/Logger";
import Snackbar from "./components/Snackbar";

function App() {
  registerFont();
  return (
    <Provider store={store}>
      <Logger>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routing />
          </BrowserRouter>
          <Snackbar />
        </ThemeProvider>
      </Logger>
    </Provider>
  );
}

const registerFont = () => {
  Font.register({
    family: "Roboto",
    fonts: [{ src: RobotoRegular }, { src: RobotoBold, fontWeight: "bold" }],
  });
};

export default App;
