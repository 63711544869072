import { getPlaceId } from "../store/auth/selector";
import agent from "./agent";

export const getListRO = (search, status, dateStart, dateEnd, skip, limit) => {
  return agent.get("/requests/getList", {
    params: {
      filter: { placeId: getPlaceId(), search, status, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};

export const deleteRO = (id) => {
  return agent.put(`/requests/${id}/delete`, {});
};

export const getDetailRO = (id) => {
  return agent.get(`/requests/${id}/getDetail`, {});
};

export const approveRO = (id) => {
  return agent.put(`/requests/${id}/approve`, {});
};

export const generateROCOde = () => {
  return agent.get("/requests/generateCode", {
    params: {
      filter: { placeId: getPlaceId() },
    },
  });
};

export const addRO = (body) => {
  return agent.post("/requests/add", body);
};

export const processRO = (id, body) => {
  return agent.put(`/requests/${id}/processV2`, body);
};

export const returnRo = (id, body) => {
  return agent.put(`/requests/${id}/return`, body);
};

export const editDeliveryOrder = (id, body) => {
  return agent.put(`/requests/${id}/processV2Update`, body);
};
