import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import routes, { billingRoutes } from "../../constants/routes";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CustomTextField from "../custom/CustomTextField";
import Button from "@material-ui/core/Button";
import CustomSelect from "../custom/CustomSelect";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { useHistory, useParams } from "react-router-dom";
import { getDetailBilling, payBilling } from "../../services/BillingService";
import {
  currencyFormat,
  currencyFormatWithDecimalOrNot,
  dateFormat,
  statusFormat,
} from "../../utils/formatting";
import validations from "../../utils/validations";
import useSnackbar from "../../utils/useSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  header: {
    lineHeight: "60px",
    fontWeight: "bold",
    color: theme.palette.blue,
  },
  title: {
    lineHeight: "60px",
    fontWeight: "bold",
  },
  itemGridLeft: {
    paddingRight: 12,
  },
  itemGridRight: {
    paddingLeft: 12,
  },
  table: {
    marginBottom: 30,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  textField: {
    width: "100%",
  },
  itemWidth: {
    maxWidth: "97%",
  },
  topItem: {
    marginTop: 15,
  },
  invoiceHead: {
    fontWeight: "normal",
    backgroundColor: "white",
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: 0,
    color: "#666666",
    fontSize: 10,
  },
  invoiceBody: {
    borderBottom: 0,
    padding: 0,
    paddingLeft: 0,
  },
  invoiceTable: {
    marginTop: 7,
  },
}));

const metodePembayarans = {
  Langsung: ["Tunai"],
  "Kartu Debit": [
    "Bank BCA",
    "Bank Mandiri",
    "Bank BRI",
    "Bank Danamon",
    "Bank BII",
    "Bank BNI",
  ],
  "Kartu Kredit": [
    "Bank BCA",
    "Bank Mandiri",
    "Bank BRI",
    "Bank Danamon",
    "Bank BII",
    "Bank BNI",
  ],
  Transfer: [
    "Bank BCA",
    "Bank Mandiri",
    "Bank BRI",
    "Bank Danamon",
    "Bank BII",
    "Bank BNI",
  ],
};

const pembayaranDefault = {
  metode: "Langsung",
  alat: "Tunai",
  dibayarOleh: "",
  bayar: "",
};

const Payment = () => {
  const classes = useStyles();
  const history = useHistory();
  const { invoiceId } = useParams();
  const { setSuccess } = useSnackbar();

  const [error, setError] = useState({});
  const [invoice, setInvoice] = useState({});
  const [pembayaran, setPembayaran] = useState({ ...pembayaranDefault });

  useEffect(() => {
    if (!invoiceId) {
      history.push(routes.BILLING);
    }

    getDetailBilling(invoiceId).then((res) => {
      setInvoice(res.data.item);
    });
  }, [history, invoiceId]);

  const handleChangePembayaran = (name) => (event) => {
    const { value } = event.target;
    if (name === "metode") {
      if (
        (value !== "Langsung" && pembayaran.metode === "Langsung") ||
        (value === "Langsung" && pembayaran.metode !== "Langsung")
      ) {
        return setPembayaran({ ...pembayaran, [name]: value, alat: "" });
      }
    }
    setPembayaran({ ...pembayaran, [name]: value });
  };

  const checkError = () => {
    const metodeError = validations.isEmpty(pembayaran.metode);
    const alatError = validations.isEmpty(pembayaran.alat);
    const dibayarOlehError = validations.isEmpty(pembayaran.dibayarOleh);
    const bayarError = validations.isEmpty(pembayaran.bayar);
    setError({
      metode: metodeError,
      alat: alatError,
      dibayarOleh: dibayarOlehError,
      bayar: bayarError,
    });
    return metodeError || alatError || dibayarOlehError || bayarError;
  };

  const handleBayar = () => {
    if (checkError()) {
      return;
    }

    const calculatedBayar = parseInt(pembayaran.bayar, 10) - calculateSisa();
    payBilling(
      invoiceId,
      pembayaran.metode,
      pembayaran.alat,
      calculatedBayar,
      pembayaran.dibayarOleh
    ).then((res) => {
      setSuccess('Sukses! Pembayaran berhasil dilakukan');
      history.push(routes.BILLING + billingRoutes.BILLING);
    });
  };

  const calculateSisa = () => {
    const sisa = invoice.totalFee - invoice.paidFee - pembayaran.bayar;
    return sisa < 0 ? 0 : sisa;
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={`Pembayaran ${invoice.codePO || ""}`}
        previousPageName="Billing"
        previousPageLink={routes.BILLING}
      />
      <Grid container item xs={7} className={classes.headerGrid}>
        <Table className={classes.invoiceTable}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.invoiceHead}>Invoice</TableCell>
              <TableCell className={classes.invoiceHead}>Supplier</TableCell>
              <TableCell className={classes.invoiceHead}>Status</TableCell>
              <TableCell className={classes.invoiceHead}>
                Tanggal Jatuh Tempo
              </TableCell>
              <TableCell className={classes.invoiceHead}>Alamat</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.invoiceBody}>
                {invoice.code}
              </TableCell>
              <TableCell className={classes.invoiceBody}>
                {invoice.supplierName}
              </TableCell>
              <TableCell className={classes.invoiceBody}>
                {statusFormat(invoice.status)}
              </TableCell>
              <TableCell className={classes.invoiceBody}>
                {dateFormat(invoice.dueDate)}
              </TableCell>
              <TableCell className={classes.invoiceBody}>
                {invoice.addressSupplier}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            className={`${classes.title} ${classes.borderBottom}`}
          >
            Barang Dipesan
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            className={`${classes.title} ${classes.borderBottom}`}
          >
            Overview
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerLeft}>Obat</TableCell>
                    <TableCell>Jumlah</TableCell>
                    <TableCell className={classes.headerRight}>
                      Harga Beli Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice.TxItems?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.quantity + ` ${row.bigUnit}`}</TableCell>
                      <TableCell>{currencyFormatWithDecimalOrNot(row.totalFee, 2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className={`${classes.title} ${classes.borderBottom}`}
              >
                Riwayat Pembayaran
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerLeft}>
                      Tanggal
                    </TableCell>
                    <TableCell>Nominal Uang (Rp)</TableCell>
                    <TableCell>Metode Pembayaran</TableCell>
                    <TableCell className={classes.headerRight}>PIC</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice.TxPayments?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{dateFormat(row.createdDate)}</TableCell>
                      <TableCell>{currencyFormat(row.totalFee)}</TableCell>
                      <TableCell>{`${row.paymentType} - ${row.paymentName}`}</TableCell>
                      <TableCell>{row.paymentBy}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            {/* <Grid item xs={12} className={classes.itemWidth}>
                          <CustomSelect
                              label="PIC Supplier"
                              className={classes.textField}
                          >
                              {pic.map(inCharge => (
                                  <MenuItem key={inCharge.value} value={inCharge.value}>{inCharge.label}</MenuItem>
                              ))}
                          </CustomSelect>
                        </Grid> */}
            <Grid item xs={12} className={classes.itemWidth}>
              <CustomTextField
                label="PIC Supplier"
                disabled
                value={invoice.namePic}
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12} className={classes.itemWidth}>
              <CustomTextField
                label="Keterangan"
                disabled
                value={invoice.notes}
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label="Pajak"
                disabled
                currency
                value={invoice.ppnRp}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label="Jumlah Total"
                disabled
                currency
                value={invoice.totalFee}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label="Total Diskon"
                disabled
                currency
                value={invoice.discountRp}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <CustomTextField
                label="Sisa"
                disabled
                currency
                value={invoice.totalFee - invoice.paidFee}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label="Jumlah Sudah dibayarkan"
                disabled
                currency
                value={invoice.paidFee}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className={`${classes.title} ${classes.borderBottom}`}
              >
                Pembayaran
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.topItem}>
              <CustomSelect
                value={pembayaran.metode}
                label="Pilih Metode Pembayaran"
                className={classes.input}
                onChange={handleChangePembayaran("metode")}
                error={error.metode && validations.isEmpty(pembayaran.metode)}
                helperText={
                  error.metode && validations.isEmpty(pembayaran.metode)
                    ? "Metode pembayaran tidak boleh kosong"
                    : " "
                }
              >
                {Object.keys(metodePembayarans).map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item xs={6} className={classes.topItem}>
              <CustomTextField
                className={classes.input}
                label="Tanggal Bayar"
                value={dateFormat(new Date())}
                helperText=""
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                value={pembayaran.alat}
                label="Alat Pembayaran"
                className={classes.input}
                onChange={handleChangePembayaran("alat")}
                error={error.alat && validations.isEmpty(pembayaran.alat)}
                helperText={
                  error.alat && validations.isEmpty(pembayaran.alat)
                    ? "Alat pembayaran tidak boleh kosong"
                    : " "
                }
              >
                {metodePembayarans[pembayaran.metode]?.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                className={classes.input}
                label="PIC Pembayaran"
                value={pembayaran.dibayarOleh}
                onChange={handleChangePembayaran("dibayarOleh")}
                error={
                  error.dibayarOleh &&
                  validations.isEmpty(pembayaran.dibayarOleh)
                }
                helperText={
                  error.dibayarOleh &&
                  validations.isEmpty(pembayaran.dibayarOleh)
                    ? "Nama pembayar tidak boleh kosong"
                    : " "
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                className={classes.input}
                label="Bayar (Rp)"
                value={pembayaran.bayar}
                currency
                allowNegative={false}
                onChange={handleChangePembayaran("bayar")}
                error={error.bayar && validations.isEmpty(pembayaran.bayar)}
                helperText={
                  error.bayar && validations.isEmpty(pembayaran.bayar)
                    ? "Nominal bayar tidak boleh kosong"
                    : " "
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label="Sisa Belum dibayar"
                disabled
                currency
                value={calculateSisa()}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Button
                  disableElevation
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => history.push(routes.BILLING)}
                >
                  Batalkan
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  size="large"
                  onClick={handleBayar}
                >
                  Bayar Tagihan
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Payment;
