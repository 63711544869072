import React from "react";
import { palette } from "../../theme";
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, TableHead, Grid } from '@material-ui/core';
import formatting from "../../utils/formatting";
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from "moment";

const CustomTableCell = withStyles({
  head: {
    backgroundColor: '#f2f2f2',
    fontSize: 14,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.28,
    letterSpacing: 'normal',
    textAlign: 'left',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    // width: 150,
  },
  body: {
    fontSize: 14,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.28,
    letterSpacing: 'normal',
    textAlign: 'left',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    // width: 150,
  },
})(TableCell);

const useStyle = makeStyles({
  page: {
    padding: 20,
  },
  title: {
    fontSize: 17,
    fontWeight: "bold",
    fontFamily: "Roboto",
    marginBottom: 20,
  },
  idPo: {
    fontSize: 11,
    fontFamily: "Roboto",
  },
  titleView: {
    width: '100%',
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    marginBottom: 10,
  },
  header: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 20,
    border: 1,
    borderColor: palette.black,
  },
  headerTitle: {
    backgroundColor: palette.disabledTextfield,
  },
  headerValue: {
    backgroundColor: palette.white,
  },
  headerTextLabel: {
    fontFamily: "Roboto",
    fontSize: 10,
    flexDirection: "column",
    color: palette.black,
    width: 150,
    marginBottom: 10,
  },
  headerTextValue: {
    color: palette.black,
    fontFamily: "Roboto",
    fontSize: 10,
    flexDirection: "column",
    marginBottom: 10,
    marginRight: 130,
  },
  headerLeftWidth: {
    width: 150,
  },
  tableHeaderText: {
    backgroundColor: palette.secondaryText,
    color: palette.white,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 11,
    paddingTop: 5,
    // paddingBottom: 5,
    borderColor: palette.disabledTextfield,
    textAlign: "center",
    // paddingLeft: 5,
  },
  tableHeaderTexts: {
    backgroundColor: palette.secondaryText,
    color: palette.white,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 11,
    paddingTop: 5,
    // paddingBottom: 5,
    borderColor: palette.disabledTextfield,
    textAlign: "center",
    // paddingLeft: 5,
    width: 50,
  },
  tableHeaderLeft: {
    borderTopLeftRadius: 10,
  },
  tableHeaderRight: {
    borderTopRightRadius: 10,
  },
  tableDataText: {
    fontFamily: "Roboto",
    fontSize: 9,
    paddingTop: 5,
    paddingBottom: 5,
    borderColor: palette.disabledTextfield,
    borderTopColor: palette.disabledTextfield,
    paddingLeft: 5,
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    marginTop: 20,
    // paddingLeft: 680,
  },
  footerSignatureName: {
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    color: palette.black,
    width: '100%',
    marginBottom: 60,
    textAlign: "center",
    // marginRight: 130,
    fontWeight: "bold",
  },
  footerSignature: {
    color: palette.black,
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    width: '100%',
    // marginRight: 130,
  },
  blackFill: {
    backgroundColor: palette.secondaryText,
    color: palette.white,
    width: 450,
  },
});

const BeritaAcaraPenerimaanBarang = (props) => {
  const classes = useStyle();
  moment.locale("id");
  return (
    <div>
        <div className={classes.titleContainer}>
          <div className={classes.titleView}>
            <div className={classes.title}>Berita Acara Penerimaan Barang</div>
          </div>
          <div>
            <div className={classes.idPo}>ID PO : {props.data?.poCode}</div>
          </div>
        </div>
        <div className={classes.headerContainer}>
          <div className={[classes.header, classes.headerTitle]}>
            <div className={classes.headerTextLabel}> Waktu</div>
            <div className={classes.headerTextLabel}> Lokasi</div>
            <div className={classes.headerTextLabel}> User</div>
            <div className={classes.headerTextLabel} />
          </div>
          <div className={[classes.header, classes.headerValue]}>
            <div className={classes.headerTextValue}>
              {moment().format("dddd, DD MMMM YYYY")}
            </div>
            <div className={classes.headerTextValue}>Central Warehouse</div>
            <div className={classes.headerTextValue}>{props.data?.receivePIC}</div>
            <div className={classes.headerTextValue} />
          </div>
          <div className={[classes.header, classes.headerTitle]}>
            <div className={classes.headerTextLabel}> Tipe Barang</div>
            <div className={classes.headerTextLabel}> ID PO</div>
            <div className={classes.headerTextLabel}> Kode Penerimaan Barang</div>
            <div className={classes.headerTextLabel} />
          </div>
          <div className={[classes.header, classes.headerValue]}>
            <div className={classes.headerTextValue}>
              Obat, Alkes, BHP dan Aksesoris
            </div>
            <div className={classes.headerTextValue}>{props.data?.poCode}</div>
            <div className={classes.headerTextValue}>
              {props.data?.invoiceNo || props.data?.code}
            </div>
            <div className={classes.headerTextValue} />
          </div>
        </div>

        <Table data={props.items}>
          <TableHead>
            <CustomTableCell className={[classes.tableHeaderText]} weighting={0.03}>
              No
            </CustomTableCell>
            <CustomTableCell className={classes.tableHeaderText}>
              Kode Item
            </CustomTableCell>
            <CustomTableCell className={classes.tableHeaderText} weighting={0.15}>
              Nama Item
            </CustomTableCell>
            <CustomTableCell className={classes.tableHeaderText} weighting={0.07}>
              Satuan
            </CustomTableCell>
            <CustomTableCell className={classes.tableHeaderText} weighting={0.1}>
              Kadaluarsa
            </CustomTableCell>
            <CustomTableCell className={classes.tableHeaderText}>
                Qty
              <Table>
                <CustomTableCell className={classes.tableHeaderTexts}>
                  PO
                </CustomTableCell>
                <CustomTableCell className={classes.tableHeaderTexts}>
                  Terima
                </CustomTableCell>
              </Table>
            </CustomTableCell>
            <CustomTableCell className={classes.tableHeaderText}>
                Harga
              <Table>
                  <CustomTableCell className={classes.tableHeaderTexts}>
                    Dasar
                  </CustomTableCell>
                  <CustomTableCell className={classes.tableHeaderTexts}>
                    Beli
                  </CustomTableCell>
              </Table>
            </CustomTableCell>
            <CustomTableCell className={classes.tableHeaderText}>
                Diskon
              <Table>
                  <CustomTableCell className={classes.tableHeaderTexts}>
                    %
                  </CustomTableCell>
                  <CustomTableCell className={classes.tableHeaderTexts}>
                    Rp
                  </CustomTableCell>
              </Table>
            </CustomTableCell>
            <CustomTableCell className={classes.tableHeaderText}>
                After Diskon
              <Table>
                  <CustomTableCell className={classes.tableHeaderText}>
                    Sub Total
                  </CustomTableCell>
              </Table>
            </CustomTableCell>
          </TableHead>
          <TableBody>
            {props.items.map((d) => (
              <TableRow>
                <CustomTableCell className={classes.tableDataText} weighting={0.03} >{d.no}</CustomTableCell>
                <CustomTableCell className={classes.tableDataText}>{d.itemCode}</CustomTableCell>
                <CustomTableCell className={classes.tableDataText} weighting={0.15}>{d.name}</CustomTableCell>
                <CustomTableCell className={classes.tableDataText} weighting={0.07} >{d.bigUnit}</CustomTableCell>
                <CustomTableCell className={classes.tableDataText} weighting={0.1}>{moment(d.expireDate).format("DD MMMM YYYY")}</CustomTableCell>
              <CustomTableCell>
              <Table>
                  <CustomTableCell className={classes.tableDataText}>
                  {d.quantityPO}
                  </CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}>
                  {d.quantityReceive}
                  </CustomTableCell>
              </Table>
              </CustomTableCell>
              <CustomTableCell weighting={0.15}>
              <Table>
                <CustomTableCell className={classes.tableDataText} weighting={0.5}>{d.buyFee}</CustomTableCell>
                <CustomTableCell className={classes.tableDataText} weighting={0.5}>{d.buyFee}</CustomTableCell>
              </Table>
              </CustomTableCell>
              <CustomTableCell weighting={0.15}>
              <Table>
                <CustomTableCell className={classes.tableDataText} weighting={0.5}>{d.discountPercent !== null ? d.discountPercent.toFixed(2) : 0}</CustomTableCell>
                <CustomTableCell className={classes.tableDataText} weighting={0.5}>{d.discountRp}</CustomTableCell>
              </Table>
              </CustomTableCell>
              <CustomTableCell className={classes.tableDataText} weighting={0.5}>{d.unitBuyFinalFee}</CustomTableCell>
              </TableRow>
            ))
            }
          </TableBody>
        </Table>
        <Table data={[{ subTotalFee: props.data?.subTotalFee }]}>
          <TableBody>
                <CustomTableCell className={[classes.blackFill, classes.tableDataText]}>Total Harga</CustomTableCell>
                <CustomTableCell className={classes.tableDataText}>{formatting.currencyFormat(props.data.subTotalFee)}</CustomTableCell>
          </TableBody>
        </Table>
        <Table data={[{ ppnRp: props.data?.ppnRp }]}>
          <TableBody>
                <CustomTableCell className={[classes.blackFill, classes.tableDataText]}>PPN</CustomTableCell>
                <CustomTableCell className={classes.tableDataText}>{formatting.currencyFormat(props.data.ppnRp)}</CustomTableCell>
          </TableBody>
        </Table>
        <Table data={[{ totalFee: props.data?.totalFee }]}>
          <TableBody>
              <TableRow>
                <CustomTableCell className={[classes.blackFill, classes.tableDataText]}>Harga Setelah PPN</CustomTableCell>
                <CustomTableCell className={classes.tableDataText}>{formatting.currencyFormat(props.data.totalFee)}</CustomTableCell>
              </TableRow>
          </TableBody>
        </Table>
        <Grid container xs={12} className={classes.footerContainer} justify="flex-end">
          <div>
            <div className={classes.footerSignatureName}>Penerima,</div>
            <div className={classes.footerSignature}>(________________)</div>
          </div>
        </Grid>
    </div>
  );
};

export default BeritaAcaraPenerimaanBarang;
