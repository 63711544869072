import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CustomTextField from "../custom/CustomTextField";
import SearchIcon from "@material-ui/icons/Search";
import CustomRangeDatePicker from "../custom/CustomRangeDatePicker";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import routesConst from "../../constants/routes";
import routes from "../../constants/routes";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import CustomPagination from "../custom/CustomPagination";
import IconButton from "@material-ui/core/IconButton";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../store/general/actions";
import {
  getDetailDO,
  getListPO,
  getLockedStatus,
  lockPurchaseOrderForEditing,
  getLockedWhileEditingStatus,
  lockReceiveForEditing
} from "../../services/PurchaseOrderService";
import moment from "moment";
import debounce from "awesome-debounce-promise";
import { paramDateFormat, currencyFormatWithDecimalOrNot }  from "../../utils/formatting";
import Print from "../../utils/Print";
import PurchaseOrderKwitansi from "../Document/PurchaseOrderKwitansi";
import useCekHakAkses from "../../utils/cekHakAkses";
import usePdfPrint from "../../utils/usePdfPrint";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  headerTitle: {
    color: theme.palette.blue,
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debounceGetListPo = debounce(getListPO, 500);

const PurchaseOrder = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = useCekHakAkses();

  const [searchForm, setSearchForm] = useState({
    startDate: moment().subtract(30, "days").toDate(),
    endDate: new Date(),
  });
  const [pagination, setPagination] = useState(paginationInitialData);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const { printPdfProps, setPdfProps } = usePdfPrint({
    frameName: "poKwitansi",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setIsFetching(true);
    debounceGetListPo(
      searchForm.search,
      paramDateFormat(searchForm.startDate),
      paramDateFormat(searchForm.endDate),
      pagination.page,
      pagination.rowsPerPage
    ).then((res) => {
      setTotalData(res.data.count);
      setData(res.data.items);
      setIsFetching(false);
    });
  }, [searchForm, pagination]);

  async function downloadDocument(poNumber, id, full) {
    // setup loading button
    const selectedIndex = data.findIndex((d) => d["_id"] === id);
    setData((dataValue) => {
      const copy = [...dataValue];
      copy[selectedIndex].isLoading = true;
      return copy;
    });

    const res = await getDetailDO(id);
    setPdfProps(
      {
        data: res.data.item,
        items: res.data.item.TxPurchaseItems.map((value, index) => {
          return {
            ...value,
            no: index + 1,
          };
        }),
      },
      () => {
        setData((dataValue) => {
          const copy = [...dataValue];
          copy[selectedIndex].isLoading = false;
          return copy;
        });
      }
    );
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.headerGrid}>
        <Grid item container xs={12} alignItems="center">
          <Typography
            variant="h2"
            color="textSecondary"
            className={classes.headerTitle}
          >
            Purchase Order
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid item xs={5} lg={3} style={{ paddingTop: 10 }}>
          <CustomTextField
            label="Cari Data Purchase Order"
            placeholder="Kode atau Nama Supplier"
            value={searchForm.search}
            onChange={(event) =>
              setSearchForm({ ...searchForm, search: event.target.value })
            }
            startIcon={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={5} lg={3} style={{ paddingTop: 10 }}>
          <CustomRangeDatePicker
            label="Berdasarkan Tanggal"
            startDate={searchForm.startDate}
            endDate={searchForm.endDate}
            onChange={([start, end]) =>
              setSearchForm({ ...searchForm, startDate: start, endDate: end })
            }
            placeholder="Berdasarkan Tanggal"
          />
        </Grid>
        {cekRole("purchaseOrder.a2") && (
          <Grid item xs={2} lg={6} alignItems="center">
            <Button
              disableElevation
              variant="contained"
              size="large"
              className={classes.button}
              onClick={() => history.push(routesConst.ADD_PURCHASE_ORDER)}
            >
              + Tambah PO
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} className=".hideForPrint" id="warehouse-content">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerLeft}>Kode</TableCell>
              <TableCell>Supplier</TableCell>
              <TableCell>Obat</TableCell>
              <TableCell>Total Biaya</TableCell>
              <TableCell>PIC Order</TableCell>
              <TableCell>Tanggal Order</TableCell>
              <TableCell className={classes.headerRight} />
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <p>Tidak ada data yang bisa ditampilkan</p>
                </TableCell>
              </TableRow>
            ) : (
              data.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{row.supplierName}</TableCell>
                  <TableCell>
                    {row.item.map((item, index) => (
                      <p key={index}>{item}</p>
                    ))}
                  </TableCell>
                  <TableCell>
                    {currencyFormatWithDecimalOrNot(row.totalFee, 2)}
                  </TableCell>
                  <TableCell>{row.createdName}</TableCell>
                  <TableCell>
                    {moment(row.createdDate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    {cekRole("purchaseOrder.a4") && (
                      <Tooltip title="Print Purchase Order" placement="center" id="warehouse-content">
                        <IconButton
                          color="primary"
                          className={classes.button}
                          onClick={async () =>
                            row.isLoading
                              ? null
                              : downloadDocument(row.code, row["_id"], false)
                          }
                        >
                          {row.isLoading ? (
                            <CircularProgress />
                          ) : (
                            <PrintOutlinedIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                    {cekRole("purchaseOrder.a3") && (
                      <Tooltip title="Detail Puchase Order" placement="center" id="warehouse-content">
                        <IconButton
                          color="primary"
                          className={classes.button}
                          onClick={() =>
                            history.push(
                              routes.PURCHASE_ORDER_DETAIL + `/${row["_id"]}`
                            )
                          }
                        >
                          <InsertDriveFileOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {cekRole("purchaseOrder.a5") && (
                    <Tooltip title="Edit Purchase Order" placement="center" id="warehouse-content">
                      <IconButton
                        color="primary"
                        className={classes.button}
                        onClick={async () => {
                          try {
                            const result = await getLockedStatus({ poId: row._id });
                            const result2 = await getLockedWhileEditingStatus({ poId: row._id});
                            if (result.data.isLocked) {
                              dispatch(setSnackbar("warning", "Maaf! No Faktur sudah ditukar, anda tidak bisa melakukan edit"));
                            } else  if (result2.data.isEditing) {
                              dispatch(setSnackbar("warning", "Maaf! Proses penerimaan barang sedang diproses, anda tidak bisa melakukan edit"));
                            } else {
                              lockPurchaseOrderForEditing({
                                poId: row._id,
                              });
                              lockReceiveForEditing({
                                poId: row._id,
                              })
                              history.push(
                                routes.EDIT_PURCHASE_ORDER + `/${row["_id"]}`
                              );
                            }
                          } catch (error) {
                            console.error(error);
                          }
                        }}
                      >
                        <CreateOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
      {Object.keys(printPdfProps).length > 0 && (
        <Print isHidden>
          <PurchaseOrderKwitansi {...printPdfProps} />
        </Print>
      )}
    </div>
  );
};

export default PurchaseOrder;
