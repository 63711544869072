import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import routes from "../../constants/routes";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import clsx from "clsx";
import StepConnector from "@material-ui/core/StepConnector";
import { palette } from "../../theme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import styled from "@material-ui/core/styles/styled";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import readXlsxFile from "read-excel-file";
import { schema } from "../../utils/excel";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import formatting, { formatPayloadInt } from "../../utils/formatting";
import groupBy from "lodash.groupby";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import UpdateItemFromImport from "./UpdateItemFromImport";
import omit from "lodash.omit";
import { Button } from "@material-ui/core";
import { addItems } from "../../services/InventoryService";
import { useHistory } from "react-router-dom";
import CustomDropzone from "../custom/CustomDropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  stepLabel: {
    "& .MuiStepLabel-labelContainer": {
      width: 150,
    },
  },
  dropZoneContainer: {
    borderRadius: 10,
    backgroundColor: palette.whiteBlue,
  },
  successUpload: {
    width: "100%",
    minHeight: 250,
    backgroundColor: palette.whiteBlue,
    borderRadius: 10,
  },
  successUploadText: {
    fontFamily: "Roboto",
    color: palette.secondaryText,
    paddingTop: 20,
    textAlign: "center",
    fontWeight: "bold",
  },
  progressBar: {
    height: 8,
    borderRadius: 8,
  },
  button: {
    marginLeft: 12,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  failedIconColor: {
    color: palette.red,
  },
  successIconColor: {
    color: palette.green,
  },
  errorCell: {
    backgroundColor: "pink",
  },
  errorCellValue: {
    color: palette.red,
    fontWeight: "bold",
    fontStyle: "italic",
  },
  buttonGrid: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  excelIcon: {
    width: 60,
    height: 60,
    backgroundColor: palette.green,
    color: palette.white,
    marginRight: theme.spacing(1),
    marginTop: 5,
    textAlign: "center",
    lineHeight: 3.5,
    borderRadius: 5,
  },
  buttonSubtitle: {
    textAlign: "center",
    width: "100%",
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  notActive: {
    color: palette.disabledTextfield,
  },
}));

const FourGrid = styled(Box)({
  display: "grid",
  gridTemplateRows: "50px 1fr",
  gridTemplateColumns: "1fr 1fr",
});

const useCustomStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 60,
    height: 60,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: palette.blue,
  },
  completed: {
    backgroundColor: palette.blue,
  },
});

function getSteps() {
  return [
    { title: "Pilih File", description: "Pilih file Excel untuk Upload" },
    {
      title: "Preview",
      description:
        "Tinjau kembali file Anda sebelum menambahkan ke inventory Barang",
    },
    {
      title: "Tambah File",
      description:
        "Tinjau kembali file Anda sebelum menambahkan ke inventory Barang",
    },
  ];
}

const CustomStepIcon = (props) => {
  const classes = useCustomStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <img src={require("../../assets/pilih_file.png")} alt="pilih file" />,
    2: <img src={require("../../assets/preview.png")} alt="preview" />,
    3: <img src={require("../../assets/tambah_file.png")} alt="tambah file" />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
};

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: "#0A70C7",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#0A70C7",
    },
  },
  line: {
    height: 8,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const LinearProgressWithLabel = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          {...props}
          className={classes.progressBar}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="subtitle1" style={{ color: palette.green }}>
          100% Complete
        </Typography>
      </Box>
    </div>
  );
};

const FirstStep = (props) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <div style={{ width: 350 }}>
        {props.file == null ? (
          <CustomDropzone handleOnChange={(files) => props.setFile(files[0])} />
        ) : (
          <div className={classes.successUpload}>
            <p className={classes.successUploadText}>
              <p>File Berhasil Diupload</p>
              <p>
                <img
                  src={require("../../assets/sukses_upload.png")}
                  style={{ width: 140 }}
                  alt="sukses upload"
                />
              </p>
            </p>
          </div>
        )}
      </div>
      <Grid item xs={9}>
        {props.file != null ? (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body2">UPLOAD SELESAI</Typography>
            </Grid>
            <p className={classes.excelIcon}>.XLS</p>
            <Grid item xs={8}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                {props.file.name}
              </Typography>
              <Typography variant="body2">
                {(props.file.size / 1024).toFixed(2)} kb
              </Typography>
              <LinearProgressWithLabel value={100} />
            </Grid>
            <FourGrid>
              <IconButton
                color="secondary"
                className={classes.button}
                onClick={() => props.setFile(null)}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
              <IconButton
                color="primary"
                className={classes.button}
                onClick={() => props.setActiveStep(1)}
              >
                <DescriptionOutlinedIcon />
              </IconButton>
              <Typography
                variant="body2"
                color="secondary"
                className={classes.buttonSubtitle}
              >
                Hapus
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                className={classes.buttonSubtitle}
              >
                Preview
              </Typography>
            </FourGrid>
          </Grid>
        ) : undefined}
      </Grid>
    </Grid>
  );
};

const SecondStep = (props) => {
  const classes = useStyles();

  const saveData = () => {
    const promises = props.data.map((data) =>
      addItems({
        ...formatPayloadInt(data, [
          "unitPrecision",
          "stock",
          "stockWarning",
          "stockBuffer",
          "basicFee",
          "sellFee",
          "buyFee",
          "hna",
          "hnaPPN",
          "marginRp",
          "marginPercent",
        ]),
      })
    );
    Promise.all(promises).then(props.setActiveStep(2));
  };

  return (
    <div>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={9}>
          <Typography
            variant="h2"
            color="primary"
            style={{ fontFamily: "Roboto" }}
          >
            Preview Data
          </Typography>
        </Grid>
        <Grid item xs={9}>
          {Object.values(props.error).length === 0 ? (
            <Typography variant="body2" className={classes.successIconColor}>
              Data sudah lengkap!
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.failedIconColor}>
              Maaf, data Anda tidak dapat kami simpan. Ada beberapa data yang
              kosong!
            </Typography>
          )}
        </Grid>
        <Grid item xs={9} style={{ overflow: "auto" }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerLeft}></TableCell>
                <TableCell>Kode</TableCell>
                <TableCell>Nama Barang</TableCell>
                <TableCell>Tipe</TableCell>
                <TableCell>Kategori</TableCell>
                <TableCell>Satuan Kecil</TableCell>
                <TableCell>Satuan Besar</TableCell>
                <TableCell>Harga Jual</TableCell>
                <TableCell>Status</TableCell>
                <TableCell className={classes.headerRight}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row, index) => (
                <TableRow
                  key={row.code}
                  className={clsx({
                    [classes.errorCell]: props.error?.hasOwnProperty(index + 1),
                  })}
                >
                  <TableCell>
                    {props.error?.hasOwnProperty(index + 1) ? (
                      <CancelIcon className={classes.failedIconColor} />
                    ) : (
                      <CheckCircleIcon className={classes.successIconColor} />
                    )}
                  </TableCell>
                  <TableCell>
                    {row.code ? (
                      row.code
                    ) : (
                      <p className={classes.errorCellValue}>
                        Kode Barang Kosong
                      </p>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.name ? (
                      row.name
                    ) : (
                      <p className={classes.errorCellValue}>
                        Nama Barang Kosong
                      </p>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.type ? (
                      row.type
                    ) : (
                      <p className={classes.errorCellValue}>Tipe Kosong</p>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.category ? (
                      row.category
                    ) : (
                      <p className={classes.errorCellValue}>Kategori Kosong</p>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.stock && row.unitPrecision && row.smallUnit ? (
                      row.stock * row.unitPrecision + " " + row.smallUnit
                    ) : (
                      <p className={classes.errorCellValue}>
                        Status Kecil Kosong
                      </p>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.stock && row.bigUnit ? (
                      row.stock + " " + row.bigUnit
                    ) : (
                      <p className={classes.errorCellValue}>
                        Satuan Besar Kosong
                      </p>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.sellFee ? (
                      formatting.currencyFormat(row.sellFee)
                    ) : (
                      <p className={classes.errorCellValue}>
                        Harga Jual Kosong
                      </p>
                    )}
                  </TableCell>
                  <TableCell
                    className={clsx({
                      [classes.notActive]: !row.isActive,
                    })}
                  >
                    {row.isActive !== undefined ? (
                      row.isActive ? (
                        "Aktif"
                      ) : (
                        "Tidak Aktif"
                      )
                    ) : (
                      <p className={classes.errorCellValue}>Status Kosong</p>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      className={classes.button}
                      onClick={() =>
                        props.setEditState({
                          open: true,
                          data: row,
                          index: index + 1,
                        })
                      }
                    >
                      <CreateOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={9} className={classes.buttonGrid}>
          <div>
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => props.setActiveStep(0)}
            >
              {"Batalkan"}
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              disabled={Object.values(props.error).length !== 0}
              onClick={saveData}
            >
              {"Simpan Data"}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const ThirdStep = () => {
  const history = useHistory();
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={3}>
        <Typography
          variant="h2"
          color="primary"
          style={{ fontFamily: "Roboto", textAlign: "center" }}
        >
          <img
            src={require("../../assets/sukses_import.png")}
            alt="sukses import"
            style={{ width: 250 }}
          />
        </Typography>
        <Typography
          variant="h2"
          color="primary"
          style={{ fontFamily: "Roboto", textAlign: "center" }}
        >
          DATA SUDAH DITAMBAHKAN
        </Typography>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={2}>
        <Button
          disableElevation
          variant="contained"
          size="large"
          onClick={() => history.push(routes.INVENTORY)}
          style={{ marginLeft: 25 }}
        >
          {"Kembali"}
        </Button>
      </Grid>
    </Grid>
  );
};

const Import = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [file, setFile] = useState(null);
  const [editState, setEditState] = useState({ open: false, data: {} });
  const [parsedData, setParsedData] = useState([]);
  const [errors, setErrors] = useState({});
  const classes = useStyles();
  const steps = getSteps();

  useEffect(() => {
    if (file != null) {
      readXlsxFile(file, { schema }).then(({ rows, errors }) => {
        setParsedData(rows);
        setErrors(groupBy(errors, "row"));
      });
    }
  }, [file]);

  const updateData = (data) => {
    let errorData = { ...errors };
    errorData = omit(errorData, editState.index);
    let newParsedData = [...parsedData];
    newParsedData[editState.index - 1] = data;

    setErrors(errorData);
    setEditState({ ...editState, open: false });
    setParsedData(newParsedData);
  };

  return (
    <div className={classes.root}>
      {editState.open ? (
        <UpdateItemFromImport
          data={editState.data}
          code={editState.data.code}
          updateData={updateData}
        />
      ) : (
        <div>
          <CustomBreadcrumbs
            currentPageName="Import Barang"
            previousPageName="Inventory Barang"
            previousPageLink={routes.INVENTORY}
          />
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<CustomConnector />}
          >
            {steps.map((label) => (
              <Step key={label.title}>
                <StepLabel
                  StepIconComponent={CustomStepIcon}
                  className={classes.stepLabel}
                >
                  <p style={{ margin: 0 }}>
                    <b>{label.title}</b>
                  </p>
                  <p style={{ margin: 0 }}>{label.description}</p>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 ? (
            <FirstStep
              file={file}
              setFile={setFile}
              setActiveStep={setActiveStep}
            />
          ) : activeStep === 1 ? (
            <SecondStep
              data={parsedData}
              error={errors}
              setEditState={setEditState}
              setActiveStep={setActiveStep}
            />
          ) : activeStep === 2 ? (
            <ThirdStep />
          ) : undefined}
        </div>
      )}
    </div>
  );
};

export default Import;
