import React, { useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNext from "@material-ui/icons/NavigateNext";
import Badge from '@material-ui/core/Badge';
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import Typography from "@material-ui/core/Typography";
import {
  matchPath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import Link from "@material-ui/core/Link";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import routes, { inventoryRoutes } from "../../constants/routes";
import { connect } from "react-redux";
import { checkEmptyWarning } from '../../services/InventoryService';
import useCekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  link: {
    fontFamily: theme.typography.fontFamily,
    display: "flex",
  },
  previousLink: {
    color: theme.palette.disabledTextfield,
    lineHeight: "40px",
    cursor: "pointer",
  },
  icon: {
    width: 40,
    height: 40,
  },
  breadcrumb: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    paddingBottom: 8,
  },
  tabs: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
}));

const tabs = [
  { label: "Detail Barang", path: inventoryRoutes.DETAIL_SATUAN },
  { label: "Harga Beli per-Supplier", path: inventoryRoutes.HARGA_BELI },
  {
    label: "Depot Stok",
    path: inventoryRoutes.DEPOT_STOK,
    subPath: [
      inventoryRoutes.MUTASI_DEPOT + "/:depotId",
      inventoryRoutes.TAMBAH_DEPOT,
    ],
  },
  {
    label: "Kadaluarsa Item",
    path: inventoryRoutes.KADALUARSA_ITEM,
    subPath: [inventoryRoutes.TAMBAH_KADALUARSA],
  },
  { label: "Kartu Stok", path: inventoryRoutes.KARTU_STOK },
];

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DetailBarangSatuanLayout = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { itemID } = useParams();
  const [tab, setTab] = React.useState(0);
  const [depotWarning, setDepotWarning] = React.useState(false);
  const source = useQuery().get("source");
  const cekHakAkses = useCekHakAkses();

  const filteredTabs = useMemo(() => tabs.filter(tab => {
    switch(tab.label) {
      case 'Detail Barang':
        if (source === 'DataBarang') return cekHakAkses('inventory.f4')
        return cekHakAkses('inventory.f25')
      case 'Harga Beli per-Supplier':
        if (source === 'DataBarang') return cekHakAkses('inventory.f6');
        return cekHakAkses('inventory.f27');
      case 'Depot Stok':
        if (source === 'DataBarang') return cekHakAkses('inventory.f11')
        return cekHakAkses('inventory.f32')
      case 'Kadaluarsa Item':
        return cekHakAkses('inventory.f17');
      case 'Kartu Stok':
        return cekHakAkses('inventory.f19');
      default:
        return true;
    }
  }), [cekHakAkses, source]);

  useEffect(() => {
    let selectedTab = filteredTabs.findIndex((tab) => {
      const path =
        routes.INVENTORY +
        inventoryRoutes.DETAIL_BARANG +
        "/:itemID" +
        tab.path;
      let isMatch = matchPath(location.pathname, { path: path, exact: true });
      if (tab.subPath) {
        tab.subPath.forEach((path) => {
          const subPath =
            routes.INVENTORY +
            inventoryRoutes.DETAIL_BARANG +
            "/:itemID" +
            path;
          isMatch =
            isMatch ||
            matchPath(location.pathname, { path: subPath, exact: true });
        });
      }
      return isMatch;
    });
    if (selectedTab === -1) selectedTab = 0;
    setTab(selectedTab);
  }, [location.pathname, filteredTabs]);

  useEffect(function effect() {
    function fetch() {
      checkEmptyWarning(itemID)
        .then(({ data }) => {
          setDepotWarning(data.depotWarning);
        })
        .catch(console.error)
    }
    fetch();
  }, [itemID]);

  const handleTabChange = (event, newValue) => {
    const newRoute =
      routes.INVENTORY +
      inventoryRoutes.DETAIL_BARANG +
      `/${itemID}` +
      filteredTabs[newValue].path +
      "?source=" +
      source;
    history.push(newRoute);
  };

  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNext className={classes.icon} />}
        className={`${classes.breadcrumb}`}
      >
        <Link
          onClick={() =>
            history.push(
              routes.INVENTORY +
                (source === "DataBarang"
                  ? inventoryRoutes.DATA_BARANG
                  : inventoryRoutes.DATA_BARANG_DETAILS)
            )
          }
          className={classes.link}
          underline="none"
        >
          <NavigateBefore
            className={`${classes.icon} ${classes.previousLink}`}
          />
          <Typography variant="h2" className={classes.previousLink}>
            Inventory Barang
          </Typography>
        </Link>
        <Typography className={classes.link} variant="h2" color="primary">
          Detail Barang {props.kode}
        </Typography>
      </Breadcrumbs>

      <Tabs
        textColor="primary"
        value={tab}
        onChange={handleTabChange}
        className={classes.tabs}
        variant="scrollable"
        scrollButtons="auto"
      >
          {filteredTabs.map((tabObj) => (
            <Tab
              key={tabObj.label}
              label={
                tabObj.label === 'Depot Stok' && depotWarning ? (
                  <Badge variant="dot" color="error" >
                    {tabObj.label}
                  </Badge>
                ) : tabObj.label
              }
            />
          ))}
      </Tabs>
      {props.children}
    </div>
  );
};

const mapStateToProps = (state) => ({
  kode: state.inventory.kode,
});

export default connect(mapStateToProps)(DetailBarangSatuanLayout);
