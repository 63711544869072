import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { palette } from "../../theme";
import CustomPagination from "../custom/CustomPagination";
import { getListRepairStock } from "../../services/DepotInventoriesService";
import CustomTextField from "../custom/CustomTextField";
import SearchIcon from "@material-ui/icons/Search";
import CustomRangeDatePicker from "../custom/CustomRangeDatePicker";
import formatting, {
  paramDateFormat,
  typeFormat,
} from "../../utils/formatting";
import debounce from "awesome-debounce-promise";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {},
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  editButton: {
    float: "right",
    marginLeft: 5,
    color: palette.blue,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  topGrid: {
    display: "flex",
  },
  marginContainer: {
    marginLeft: 20,
  },
  alignRight: {
    textAlign: "right",
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const getList = debounce(getListRepairStock, 500);

const HistoryRepair = (props) => {
  const classes = useStyles();
  const [searchForm, setSearchForm] = useState({});
  const [pagination, setPagination] = useState(paginationInitialData);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    getList(
      searchForm.search,
      paramDateFormat(searchForm.startDate),
      paramDateFormat(searchForm.endDate),
      pagination.page,
      pagination.rowsPerPage
    )
      .then((res) => {
        setTotalData(res.data.count);
        setData(res.data.items);
      })
      .finally(() => setIsFetching(false));
  }, [searchForm, pagination]);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid item xs={5} lg={3} style={{ paddingTop: 10 }}>
          <CustomTextField
            label="Cari Data History Repair"
            placeholder="Penanggung Jawab"
            value={searchForm.search}
            onChange={(event) =>
              setSearchForm({ ...searchForm, search: event.target.value })
            }
            startIcon={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={5} lg={3} style={{ paddingTop: 10 }}>
          <CustomRangeDatePicker
            label="Berdasarkan Tanggal"
            startDate={searchForm.startDate}
            endDate={searchForm.endDate}
            onChange={([start, end]) =>
              setSearchForm({ ...searchForm, startDate: start, endDate: end })
            }
            placeholder="Berdasarkan Tanggal"
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerLeft}>Tanggal</TableCell>
              <TableCell>Jenis Transaksi</TableCell>
              <TableCell>Detail</TableCell>
              <TableCell>Stok Sebelumnya</TableCell>
              <TableCell>Stok Perubahan</TableCell>
              <TableCell>Total Stok</TableCell>
              <TableCell className={classes.headerRight}>
                Penanggung Jawab
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={7} align={"center"}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align={"center"}>
                  <p>Tidak ada data yang bisa ditampilkan.</p>
                </TableCell>
              </TableRow>
            ) : (
              data.map((row) => (
                <TableRow key={row["_id"]}>
                  <TableCell>
                    {formatting.dateFormat(row.createdDate)}
                  </TableCell>
                  <TableCell>{typeFormat(row.type)}</TableCell>
                  <TableCell>
                    <p>Nama Barang: {row.name}</p>
                    <p>Depot Penjualan: {row.depotName}</p>
                    <p>Catatan: {row.notes}</p>
                  </TableCell>
                  <TableCell>{row.stockBefore + " " + row.bigUnit}</TableCell>
                  <TableCell>
                    {row.stockAfter - row.stockBefore + " " + row.bigUnit}
                  </TableCell>
                  <TableCell>{row.stockAfter + " " + row.bigUnit}</TableCell>
                  <TableCell>{row.picName}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
    </div>
  );
};

HistoryRepair.propTypes = {};
export default HistoryRepair;
