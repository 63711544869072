import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import CustomTextField from "../custom/CustomTextField";
import SearchIcon from "@material-ui/icons/Search";
import CustomDropdownButton from "../custom/CustomDropdownButton";
import routes from "../../constants/routes";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CustomPagination from "../custom/CustomPagination";
import CustomDialog from "../custom/CustomDialog";
import {
  getListSupplier,
  deleteSupplier,
} from "../../services/SupplierService";
import debounce from "awesome-debounce-promise";
import exportXL from "../../utils/exportXL";
import Print from "../../utils/Print";
import CircularProgress from "@material-ui/core/CircularProgress";
import cekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  headerTitle: {
    color: theme.palette.blue,
  },
  searchBarGrid: {
    height: 100,
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
    width: 100,
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debounceGetList = debounce(getListSupplier, 500);

const Supplier = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = cekHakAkses();

  const [searchSupplier, setSearchSupplier] = useState("");
  const [buttonMenuSelect] = useState();
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState(paginationInitialData);
  const [totalData, setTotalData] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState({ open: false });
  const [exportData, setExportData] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    debounceGetList(searchSupplier, pagination.page, pagination.rowsPerPage)
      .then((res) => {
        setTableData(res.data.items);
        setTotalData(res.data.count);
        setExportData([]);
      })
      .finally(() => setIsFetching(false));
  }, [pagination, searchSupplier]);

  const confirmDeleteSupplier = () => {
    deleteSupplier(deleteDialog.id).then(() => {
      setDeleteDialog({ ...deleteDialog, open: false });
      setIsFetching(true);
      getListSupplier(searchSupplier, pagination.page, pagination.rowsPerPage)
        .then((res) => {
          setTableData(res.data.items);
          setTotalData(res.data.count);
        })
        .finally(() => setIsFetching(false));
    });
  };

  const getDataExport = async () => {
    let page = 0;
    let skip = 0;
    const limit = 10;
    const fetch = async () => {
      const result = await getListSupplier(searchSupplier, page, limit);
      exportData.splice(skip * limit, limit, ...result.data.items);
      setExportData(exportData);
    };
    if (exportData.length >= totalData) return;
    while (skip < totalData) {
      await fetch();
      page += 1;
      skip += limit;
    }
  };

  const handleExport = () => {
    setIsFetching(true);
    try {
      getDataExport()
        .then(() => {
          const hasil = exportData.map((val) => ({
            "Kode Supplier": val.code,
            "Nama Supplier": val.name,
            "No Telp": val.hpPic,
            Alamat: val.address,
            "Cara Pembayaran": val.paymentMethod,
            "Term Of Payment": val.termOfPayment,
            "PPN PKP": val.ppn,
            "User Input": val.userInput,
            "User Edit": val.userEdit,
          }));
          exportXL("Export Data Supplier", hasil);
        })
        .finally(() => setIsFetching(false));
    } catch (e) {
      console.error(e);
      setIsFetching(false);
    }
  };

  const handlePrint = () => {
    setIsFetching(true);
    try {
      getDataExport()
        .then(() => {
          setIsFetching(false);
          setIsPrint(true);
          window.print();
        })
        .finally(() => {
          setIsPrint(false);
          setExportData([]);
        })
        .catch((e) => {
          console.error(e);
          setIsFetching(false);
          setIsPrint(false);
        });
    } catch (e) {
      console.error(e);
      setIsFetching(false);
      setIsPrint(false);
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid item xs={6} style={{ paddingTop: 10 }}>
          <CustomTextField
            label="Cari Supplier"
            placeholder="Kode atau nama supplier"
            value={searchSupplier}
            onChange={(event) => setSearchSupplier(event.target.value)}
            startIcon={<SearchIcon />}
          />
        </Grid>
        <Grid container item xs={6} alignItems="center" justify="flex-end">
          {(cekRole("setting.i27") ||
            cekRole("setting.i31") ||
            cekRole("setting.i32")) && (
            <CustomDropdownButton
              label={
                cekRole("setting.i27")
                  ? "+ Supplier Baru"
                  : cekRole("setting.i31")
                  ? "Export"
                  : cekRole("setting.i32")
                  ? "Print"
                  : ""
              }
              disableElevation
              variant="contained"
              size="large"
              side
              onClick={
                isFetching ? (
                  <CircularProgress />
                ) : cekRole("setting.i27") ? (
                  () => history.push(routes.ADD_NEW_SUPPLIER)
                ) : cekRole("setting.i31") ? (
                  handleExport
                ) : cekRole("setting.i32") ? (
                  handlePrint
                ) : (
                  ""
                )
              }
            >
              {cekRole("setting.i27") && (
                <MenuItem
                  key="+ Supplier Baru"
                  value={1}
                  onClick={() => history.push(routes.ADD_NEW_SUPPLIER)}
                  selected={1 === buttonMenuSelect}
                >
                  + Supplier Baru
                </MenuItem>
              )}
              {cekRole("setting.i31") && (
                <MenuItem
                  key="export"
                  value={2}
                  onClick={handleExport}
                  selected={2 === buttonMenuSelect}
                >
                  Export
                </MenuItem>
              )}
              {cekRole("setting.i32") && (
                <MenuItem
                  key="print"
                  value={3}
                  onClick={handlePrint}
                  selected={3 === buttonMenuSelect}
                >
                  Print
                </MenuItem>
              )}
            </CustomDropdownButton>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Print>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerLeft}>
                  Kode Supplier
                </TableCell>
                <TableCell>Nama Supplier</TableCell>
                <TableCell>No Telp</TableCell>
                <TableCell>Alamat</TableCell>
                <TableCell>Cara Pembayaran</TableCell>
                <TableCell>Term Of Payment</TableCell>
                <TableCell>PPN PKP</TableCell>
                <TableCell>User Input</TableCell>
                <TableCell>User Edit</TableCell>
                <TableCell className={classes.headerRight} />
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={10} align={"center"}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : !isPrint && tableData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10} align={"center"}>
                    <p>Tidak ada data yang bisa ditampilkan.</p>
                  </TableCell>
                </TableRow>
              ) : (
                !isPrint &&
                tableData.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.hpPic}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.paymentMethod}</TableCell>
                    <TableCell>{row.termOfPayment}</TableCell>
                    <TableCell>{row.ppn}</TableCell>
                    <TableCell>{row.userInput}</TableCell>
                    <TableCell>{row.userEdit}</TableCell>
                    <TableCell>
                      {cekRole("setting.i29") && (
                        <Tooltip title="Delete Supplier" placement="center" id="warehouse-content">
                          <IconButton
                            color="secondary"
                            className={classes.button}
                            size="small"
                            onClick={() =>
                              setDeleteDialog({
                                ...deleteDialog,
                                open: true,
                                name: row.name,
                                id: row.id,
                              })
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {cekRole("setting.i28") && (
                        <Tooltip title="Edit Supplier" placement="center" id="warehouse-content">
                          <IconButton
                            color="primary"
                            className={classes.button}
                            onClick={() =>
                              history.push(routes.EDIT_SUPPLIER + `/${row.id}`)
                            }
                          >
                            <CreateOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
              {isPrint &&
                exportData.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.hpPic}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.paymentMethod}</TableCell>
                    <TableCell>{row.termOfPayment}</TableCell>
                    <TableCell>{row.ppn}</TableCell>
                    <TableCell>{row.userInput}</TableCell>
                    <TableCell>{row.userEdit}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Print>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
      <CustomDialog
        open={deleteDialog.open}
        handleClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
        title="Anda akan menghapus supplier"
        body={"Anda akan menghapus supplier " + deleteDialog.name + ", setuju?"}
        onCancel={() => setDeleteDialog({ ...deleteDialog, open: false })}
        cancelButtonText="Batalkan"
        onAccept={confirmDeleteSupplier}
        acceptButtonText="Hapus Data"
      />
    </div>
  );
};

export default Supplier;
