import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import routes, { adjustmentStokRoutes } from "../../constants/routes";
import { useHistory, useLocation } from "react-router-dom";
import useCekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
}));

const tabs = [
  { label: "Adjustment Stok", path: adjustmentStokRoutes.ADJUSTMENT_STOK },
  { label: "History Repair Stok", path: adjustmentStokRoutes.HISTORY_REPAIR },
];

const Adjustment = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const cekRole = useCekHakAkses();
  const [tab, setTab] = React.useState(0);

  useEffect(() => {
    const path = location.pathname.replace(routes.ADJUSTMENT, "");
    let selectedTab = tabs.findIndex((tab) => tab.path === path);
    if (selectedTab === -1) selectedTab = 0;
    setTab(selectedTab);
  }, [location.pathname]);

  const handleTabChange = (event, newValue) => {
    const newRoute = routes.ADJUSTMENT + tabs[newValue].path;
    history.push(newRoute);
  };

  return (
    <div className={classes.root}>
      <Tabs textColor="primary" value={tab} onChange={handleTabChange}>
        {tabs.map((tabObj) => (
          <Tab
            key={tabObj.label}
            label={tabObj.label}
            style={
              !cekRole("adjustmentStock.g2") && tabObj.label === "Adjustment Stok"
                ? { display: "none" }
                : !cekRole("adjustmentStock.g8") && tabObj.label === "History Repair Stok"
                ? { display: "none" }
                : {}
            }
          />
        ))}
      </Tabs>
      {props.children}
    </div>
  );
};

export default Adjustment;
