import { getPlaceId, getConfigId } from "../store/auth/selector";
import agent from "./agent";

export const getListAccount = (search, skip, limit) => {
  return agent.get("/accounts/getListStaff", {
    params: {
      filter: { placeId: getPlaceId(), search },
      skip: skip * limit,
      limit,
    },
  });
};

export const getAccountDetail = (id) => {
  return agent.get(`/accounts/${id}/getDetailStaff`);
};

export const editAccount = (id, body) => {
  return agent.put(`/accounts/${id}/editStaff`, {
    ...body,
    configId: getConfigId(),
  });
};

export const addAccount = (body) => {
  return agent.post("/accounts/inviteMember", {
    ...body,
    configId: getConfigId(),
    placeId: getPlaceId(),
  });
};
