import React from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import routesConst, { devIbbrUrl } from "../constants/routes";
import { palette } from "../theme";
import useCekHakAkses from "../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(0deg, #0A6FC7 0%, #2699FB 100%)",
    width: 70,
    height: "100%",
    "& .simplebar-scrollbar::before": {
      backgroundColor: palette.whiteBlue,
    },
  },
  routeButton: {
    backgroundColor: "transparent",
    display: 'flex',
    padding: '1em',
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.15)",
    },
    "& img": {
      width: 35,
      height: 35,
    },
  },
  routeButtonSelected: {
    backgroundColor: "rgba(0, 0, 0, 0.15)",
  },
  divider: {
    borderTop: "2px solid",
    borderTopColor: theme.palette.normalTextfield,
    height: 5,
    width: "100%",
  },
}));

const routes = [
  {
    link: routesConst.INVENTORY,
    label: "Inventory Barang",
    icon: <img src={require("../assets/inventory.png")} alt="inventory" />,
  },
  {
    link: routesConst.REQUEST_NEW,
    label: "Request Order",
    icon: (
      <img src={require("../assets/request_order.png")} alt="request order" />
    ),
  },
  {
    link: routesConst.SALES,
    label: "Sales",
    icon: <img src={require("../assets/sales.png")} alt="sales" />,
  },
  {
    link: routesConst.PURCHASE,
    label: "Purchase Order",
    icon: (
      <img src={require("../assets/purchase_order.png")} alt="purchase order" />
    ),
  },
  {
    link: routesConst.PENERIMAAN,
    label: "Penerimaan Barang",
    icon: (
      <img
        src={require("../assets/penerimaan_barang.png")}
        alt="penerimaan barang"
      />
    ),
  },
  {
    link: routesConst.BILLING,
    label: "Billing",
    icon: <img src={require("../assets/billing.png")} alt="billing" />,
  },
  {
    link: routesConst.ADJUSTMENT,
    label: "Adjustment Stok",
    icon: (
      <img
        src={require("../assets/adjustment_stock.png")}
        alt="adjustment stock"
      />
    ),
  },
  {
    link: routesConst.LAPORAN,
    label: "Laporan",
    icon: <img src={require("../assets/laporan.png")} alt="laporan" />,
  },
  {
    link: routesConst.SETTINGS,
    label: "Settings",
    icon: <img src={require("../assets/setting.png")} alt="setting" />,
  },
];

const Sidebar = () => {
  const classes = useStyles();
  const cekHakAkses = useCekHakAkses();

  const filteredRoute = routes.filter(route => {
    switch(route.label) {
      case 'Inventory Barang':
        return cekHakAkses('inventory.f1');
      case 'Request Order':
        return cekHakAkses('requestOrder.d1');
      case 'Sales':
        return cekHakAkses('sales.e1');
      case 'Purchase Order':
        return cekHakAkses('purchaseOrder.a1');
      case 'Penerimaan Barang':
        return cekHakAkses('itemRetrieval.b1');
      case 'Billing':
        return cekHakAkses('billing.c1');
      case 'Adjustment Stok':
        return cekHakAkses('adjustmentStock.g1');
      case 'Laporan':
        return cekHakAkses('report.h1');
      case 'Settings':
        return cekHakAkses('setting.i1');
      default:
        return true;
    }
  })

  return (
    <SimpleBar className={classes.root}>
      <Button className={classes.routeButton} style={{ width: '100%', height: 'auto' }}>
        <img src={require("../assets/app_icon.png")} alt="Warehouse" />
      </Button>
      {filteredRoute.map((route) => (
        <Tooltip key={route.link} title={route.label} placement="right" id="warehouse-content">
          <NavLink
            activeClassName={classes.routeButtonSelected}
            className={classes.routeButton}
            to={route.link}
          >
            {route.icon}
          </NavLink>
        </Tooltip>
      ))}
      <div className={classes.divider}></div>
      <a className={classes.routeButton} href={devIbbrUrl}>
        <img src={require("../assets/app_icon.png")} alt="Warehouse" />
      </a>
      {/* <Button
                className={classes.routeButton}
            >
                <img src={require('../assets/app_icon.png')} />
            </Button> */}
    </SimpleBar>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
