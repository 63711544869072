import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import { palette } from "../theme";
import Background from "../assets/auth_background.png";
import { useHistory } from "react-router-dom";
import { useAllowedRoute } from '../Routing';

const useStyles = makeStyles((theme) => ({
  authLoginIntro: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: "0px 30px 95px 150px",
    backgroundColor: theme.palette.blue,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "30%",
    backgroundSize: "75%",
    backgroundImage: `url(${Background})`,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  authTextColor: {
    color: palette.white,
  },
  introNext: {
    cursor: "pointer",
    color: palette.white,
    fontWeight: "bold",
  },
  gridContainer: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
}));

const AuthLayout = (props) => {
  const classes = useStyles();
  const { children } = props;
  const history = useHistory();

  const allowedRoute = useAllowedRoute();

  useEffect(() => {
    if (props.isLoggedIn) {
      history.push(allowedRoute);
    }
  }, [props.isLoggedIn, history, allowedRoute]);

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Grid item md={8} className={classes.authLoginIntro}>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.authTextColor}
            style={{ paddingBottom: "20px" }}
          >
            Pilih hanya yang terbaik!{" "}
          </Typography>
          <Typography
            variant="h1"
            color="textPrimary"
            className={classes.authTextColor}
          >
            Assist.id adalah sistem informasi kesehatan untuk warehouse Anda.
            Berbagai fitur tersedia untuk Anda.{" "}
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.introNext}
            style={{ paddingTop: "20px" }}
          >
            Pelajari lebih lanjut{" "}
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.loginContainer}>
          <Grid container>
            {/* Insert layout for login and forgot password*/}
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.any,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(AuthLayout);
