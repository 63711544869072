import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import routes, { inventoryRoutes } from "../../constants/routes";

import validations from "../../utils/validations";
import useDisclosure from "../../utils/useDisclosure";
import { formatPayloadInt } from "../../utils/formatting";

import { addItems,
  // getGenerateCode,
} from "../../services/InventoryService";
import { getListCategory } from "../../services/CategoryService";

import { setSnackbar } from "../../store/general/actions";

import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import CustomDialog from "../custom/CustomDialog";

import FormInventory from "./FormInventory";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
}));

const tambahBarangDefault = {
  code: "",
  name: "",
  type: "",
  category: "",
  subCategory: "",
  bigUnit: "",
  unitPrecision: "",
  smallUnit: "",
  stock: "",
  stockWarning: "",
  stockBuffer: "",
  basicFee: "",
  sellFee: "",
  buyFee: "",
  hna: "",
  hnaPPN: "",
  isActive: true,
  marginRp: "",
  marginPercent: "",
  isClinicAdd: true,
};

const TambahBarang = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    open: isSubmitting,
    onOpen: setSubmit,
    onClose: setSubmitted,
  } = useDisclosure();

  const [tambahBarang, setTambahBarang] = useState({ ...tambahBarangDefault });
  const [code, setCode] = useState("");
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState({});
  const [isOpenDialogConfirmation, setIsOpenDialogConfirmation] = useState(
    false
  );

  useEffect(() => {
    // getGenerateCode().then((res) => setCode(res.data.code));
    getListCategory("", 0, 0).then((res) => {
      setCategories(res.data.items.map((item) => item.name));
    });
  }, []);

  const checkError = () => {
    const code = validations.isEmpty(tambahBarang.code);
    const name = validations.isEmpty(tambahBarang.name);
    const type = validations.isEmpty(tambahBarang.type);
    const category = validations.isEmpty(tambahBarang.category);
    const subCategory = validations.isEmpty(tambahBarang.subCategory);
    const bigUnit = validations.isEmpty(tambahBarang.bigUnit);
    const unitPrecision = validations.isEmpty(tambahBarang.unitPrecision);
    const smallUnit = validations.isEmpty(tambahBarang.smallUnit);
    const stock = validations.isEmpty(tambahBarang.stock);
    const stockWarning = validations.isEmpty(tambahBarang.stockWarning);
    const stockBuffer = validations.isEmpty(tambahBarang.stockBuffer);
    const basicFee = validations.isEmpty(tambahBarang.basicFee);
    const buyFee = validations.isEmpty(tambahBarang.buyFee);
    const hna = validations.isEmpty(tambahBarang.hna);
    const hnaPPN = validations.isEmpty(tambahBarang.hnaPPN);
    const sellFee = validations.isEmpty(tambahBarang.sellFee);
    const marginRp = validations.isEmpty(tambahBarang.marginRp);
    const marginPercent = validations.isEmpty(tambahBarang.marginPercent);
    setError({
      code,
      name,
      type,
      category,
      subCategory,
      bigUnit,
      unitPrecision,
      smallUnit,
      stock,
      stockWarning,
      stockBuffer,
      basicFee,
      buyFee,
      hna,
      hnaPPN,
      sellFee,
      marginRp,
      marginPercent,
    });
    return (
      code ||
      name ||
      type ||
      category ||
      subCategory ||
      bigUnit ||
      unitPrecision ||
      smallUnit ||
      stock ||
      stockWarning ||
      stockBuffer ||
      basicFee ||
      buyFee ||
      hna ||
      hnaPPN ||
      sellFee ||
      marginRp ||
      marginPercent
    );
  };

  const handleOpenConfirmation = () => {
    if (checkError()) {
      return;
    }

    setIsOpenDialogConfirmation(true);
  };

  const handleAddItems = () => {
    setSubmit();
    addItems({
      ...formatPayloadInt(tambahBarang, [
        // "unitPrecision",
        "stock",
        "stockWarning",
        "stockBuffer",
        "basicFee",
        "sellFee",
        "buyFee",
        "hna",
        "hnaPPN",
        "marginRp",
        "marginPercent",
      ]),
      // code: code,
    })
      .then((res) => {
        dispatch(
          setSnackbar(
            "success",
            "Sukses! Tambah data barang berhasil dilakukan"
          )
        );
        setSubmitted();
        history.push(routes.INVENTORY + inventoryRoutes.DATA_BARANG);
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          setSnackbar("error", "Error! Terjadi kesalahan. Coba beberapa saat lagi")
        );
        setSubmitted();
      });
  };


  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={`Tambah Barang ${code}`}
        previousPageName="Inventory"
        previousPageLink={routes.INVENTORY}
      />
      <FormInventory
        data={tambahBarang}
        setData={setTambahBarang}
        error={error}
        handleAddItems={handleOpenConfirmation}
        handleCancel={() => history.push(routes.INVENTORY)}
        categories={categories}
        code={code}
        setCode={setCode}
      />
      <CustomDialog
        open={isOpenDialogConfirmation}
        handleClose={() => setIsOpenDialogConfirmation(false)}
        title="Kamu akan menambah data barang"
        body={
          "akan menambah data barang " + tambahBarang.name + ", anda setuju?"
        }
        onCancel={() => setIsOpenDialogConfirmation(false)}
        cancelButtonText="Batalkan"
        onAccept={isSubmitting ? null : handleAddItems}
        acceptButtonText={isSubmitting ? <CircularProgress /> : "Tambah Data"}
        acceptButtonColor="green"
      />
    </div>
  );
};

export default TambahBarang;
