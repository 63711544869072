import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import debounce from "awesome-debounce-promise";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import CustomAutocomplete from "../custom/CustomAutocomplete";
import CustomTextField from "../custom/CustomTextField";
import CustomSelect from "../custom/CustomSelect";

import { addPO, generatePOCode } from "../../services/PurchaseOrderService";
import { getListSupplier } from "../../services/SupplierService";
import {
  getLastBuyHistory,
  getListInventory,
  getByMinStockOrLessForPurchaseOrderV2,
} from "../../services/InventoryService";
import { getListInventoryDepot } from "../../services/DepotInventoriesService";
import formatting from "../../utils/formatting";
import routes from "../../constants/routes";
import { getPlaceId } from "../../store/auth/selector";
import { setSnackbar } from "../../store/general/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  title: {
    lineHeight: "60px",
    fontWeight: "bold",
  },
  itemGridLeft: {
    paddingRight: 12,
  },
  itemGridRight: {
    paddingLeft: 12,
  },
  inputField: {
    width: "100%",
  },
  buttonGroupSelected: {
    backgroundColor: theme.palette.lightBlue,
    borderColor: theme.palette.lightBlue,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.lightBlue,
      borderColor: theme.palette.lightBlue,
      color: theme.palette.white,
    },
  },
  buttonGroup: {
    marginBottom: 27,
  },
  expand: {
    float: "right",
    border: "none",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  collapseTitle: {
    display: "inline-block",
    marginTop: 10,
    fontFamily: theme.typography.fontFamily,
  },
  collapse: {
    marginBottom: 27,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.blue,
  },
  select: {
    width: "100%",
  },
  inputWithSelect: {
    paddingLeft: 10,
  },
  table: {
    marginBottom: 30,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  grandTotal: {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.whiteBlue,
      color: theme.palette.titleText,
      fontWeight: "bold",
    },
  },
  paragraph: {
    margin: 0,
    borderRight: "2px solid",
    borderRightColor: theme.palette.whiteBlue,
  },
  lighterBackgroundColor: {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.normalTextfield,
    },
  },
  option: {
    marginLeft: 80,
    backgroundColor: '#2699fb',
    color: 'white',
    '&:hover': {
      backgroundColor: '#2699fb',
    },
  },
}));

const initialData = {
  poNumber: "#F00000024",
};

const units = [
  { value: "FIXED", label: "Rp" },
  { value: "PERCENTAGE", label: "%" },
];

const debounceGetListCustomer = debounce(getListSupplier, 500);
const debounceGetListInventory = debounce(getListInventory, 500);

const AddPurchaseOrder = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [code, setCode] = useState();
  const [error, setError] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [items, setItems] = useState([]);
  const [lastBuyHistory, setLastBuyHistory] = useState({ supplier: "" });
  const [searchSupplier, setSearchSupplier] = useState("");
  const [searchItem, setSearchItem] = useState({ type: "Obat", name: "" });
  const [itemName, setItemName] = useState({});
  const [depots, setDepots] = useState([]);
  const [stock, setStock] = useState({});
  const [PoBySup, setPoBySup] = useState(false);
  const [ppn, setPpn] = useState(0);
  const [normalBuyingPrice, setNormalBuyingPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [newBuyingPrice, setNewBuyingPrice] = useState(0);

  const [supplier, setSupplier] = useState(initialData);
  const [item, setItem] = useState({
    discountType: "FIXED",
    orderQuantity: 0,
    bigStock: "",
    sellFee: 0,
    category: "",
    depotDestination: "",
    remarks: "",
  });
  const [expanded, setExpanded] = useState(true);
  const [detailOrder, setDetailOrder] = useState({ items: [] });
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    generatePOCode().then((res) => setCode(res.data.code));
  }, []);

  useEffect(() => {
    debounceGetListCustomer(searchSupplier).then((res) =>
      setSuppliers(res.data.items)
    );
  }, [searchSupplier]);

  useEffect(() => {
    debounceGetListInventory(
      searchItem.name,
      undefined,
      searchItem.type
    ).then((res) => setItems(res.data.items));
  }, [searchItem]);

  useEffect(() => {
    if (itemName.id) {
      getLastBuyHistory(itemName.id).then((res) => {
        setLastBuyHistory(res.data.item);
        setNormalBuyingPrice(Number(res.data.item.buyFee));
        setDiscount(
          isNaN(res.data.item.discount) ? 0 : Number(res.data.item.discount)
        );
      });
      getListInventoryDepot(itemName.id).then((res) =>
        setDepots(res.data.items)
      );
    }
  }, [itemName]);

  useEffect(() => {
    if (normalBuyingPrice) {
      let newPrice = normalBuyingPrice;
      // if (discount) {
      //   if (item.discountType === "FIXED") {
      //     newPrice -= discount;
      //   } else if (item.discountType === "PERCENTAGE") {
      //     newPrice = newPrice * (1 - discount / 100);
      //   }
      // }
      // newPrice = newPrice * (1 + ppn / 100);
      setNewBuyingPrice(Math.round(newPrice));
    }
    // }, [discount, item.discountType, normalBuyingPrice, ppn]);
  }, [normalBuyingPrice]);

  useEffect(() => {
    if (detailOrder.items) {
      let subTotal = 0;
      let totalDiscount = 0;
      detailOrder.items.forEach((value) => {
        subTotal += value.price * value.qty;
        totalDiscount += Number(value.discount) * value.qty;
      });

      const totalPpn = (subTotal - totalDiscount) * (ppn / 100);
      setDetailOrder((d) => ({
        items: d.items,
        subTotal,
        totalDiscount,
        totalPpn,
        grandTotal: ((subTotal - totalDiscount) + totalPpn) % 2 ? ((subTotal - totalDiscount) + totalPpn).toFixed(2) : ((subTotal - totalDiscount) + totalPpn),
        notes: d.notes,
      }));
    }
  }, [detailOrder.items, ppn]);

  const setCurrentSupplier = (currentSupplier, details) => {
    if (details) {
      setSupplier({
        ...supplier,
        id: details.option.id,
        supplier: currentSupplier,
        supplierPic: details.option.name,
        supplierPhone: details.option.hpPic,
        supplierAddress: details.option.address,
      });
      setPpn(Number(details.option.ppn));
      setError({ ...error, supplierName: undefined });
    }
  };
  const setCurrentItemState = (newValue, details) => {
    if (details) {
      setItemName({ name: newValue, id: details.option["_id"] });
      setItem({
        ...details.option,
        discountType: "FIXED",
        orderQuantity: 0,
      });
    }
  };
  const resetItem = () => {
    setSearchItem({ ...searchItem, name: "" });
    setLastBuyHistory({ supplier: "", quantity: "" });
    setItem({
      discountType: "FIXED",
      bigStock: "",
      orderQuantity: "",
      sellFee: 0,
      category: "",
      depotDestination: "",
      remarks: "",
      stock: "",
      name: undefined,
    });
    setNormalBuyingPrice(0);
    setDiscount(0);
  };

  const addItem = () => {
    if (item["_id"] === undefined) {
      dispatch(
        setSnackbar("warning", "Maaf! Silakan pilih item terlebih dahulu")
      );
      return;
    }
    if (item.depotDestination === undefined) {
      dispatch(
        setSnackbar(
          "warning",
          "Maaf! Silakan pilih tujuan depot terlebih dahulu"
        )
      );
      return;
    }
    if (item.orderQuantity <= 0) {
      dispatch(
        setSnackbar(
          "warning",
          "Maaf! Silakan isi quantity order terlebih dahulu"
        )
      );
      return;
    }
    setDetailOrder(({ items, ...d }) => ({
      ...d,
      notes: supplier.remarks,
      items: [
        ...items,
        {
          id: item["_id"],
          name: item.name,
          oldSellFee: lastBuyHistory.sellFee,
          oldBuyFee: lastBuyHistory.buyFee,
          qtyBefore: item.bigStock || item.stock,
          qty: parseInt(item.orderQuantity, 10),
          originalPrice: parseFloat(
            parseFloat(normalBuyingPrice, 10).toFixed(2),
            10
          ),
          price: parseFloat(parseFloat(newBuyingPrice, 10).toFixed(2), 10),
          discountType: item.discountType,
          discount:
            discount === null
              ? 0
              : item.discountType === "FIXED"
              ? parseFloat(parseFloat(discount, 10).toFixed(2), 10)
              : Math.round(newBuyingPrice * (discount / 100)),
          discountPercent:
            discount === null
              ? 0
              : item.discountType === "PERCENTAGE"
              ? discount
              : (discount / newBuyingPrice) * 100,
          ppnRp: (newBuyingPrice - discount) * (ppn / 100),
          ppnPercent: ppn,
          unit: item.bigUnit,
          stockDepotId: item.depotDestination["_id"],
          sellFee: parseFloat(parseFloat(item.sellFee, 10).toFixed(2), 10),
          notes: item.remarks,
        },
      ],
    }));
    resetItem();
  };

  const deleteRow = (index) => {
    let items = [...detailOrder.items];
    items.splice(index, 1);

    setDetailOrder({ ...detailOrder, items });
  };

  const fetchMinStockPO = async (result) => {
    getByMinStockOrLessForPurchaseOrderV2(supplier.id).then((res) => {
      const resultFilter = res.data.filter(item => item);
      const resultFilter2 = resultFilter.reduce(
        (acc, current) => {
          if (!acc.some(item => item.Inventories.code === current.Inventories.code)) {
            acc.push(current);
          }
          return acc;
        }, [],
      ).sort((a, b) => {
        const nameItemA = a.Inventories.name.toLowerCase();
        const nameItemB = b.Inventories.name.toLowerCase();
        if (nameItemA < nameItemB) {
          return -1;
        }
        if (nameItemA > nameItemB) {
          return 1;
        }
        return 0;
      })
      setStock(resultFilter2);
    });
  };

  const openPObySup = async => {
    if (searchSupplier === '') {
      dispatch(setSnackbar("warning", "Maaf! Supplier belum diisi"));
      return;
    }
    if (PoBySup === false) {
      fetchMinStockPO().then((result) => {
        setStock(result);
        setPoBySup(true);
      });
    } else {
      setPoBySup(false);
      setItemName({})
    }
  };
  const addPo = () => {
    if (detailOrder.items.length === 0) {
      dispatch(
        setSnackbar("warning", "Maaf! Silakan tambah item terlebih dahulu")
      );
      return;
    }
    setIsSubmit(true);
    addPO({
      code,
      supplierId: supplier.id,
      placeId: getPlaceId(),
      picRoSupplierName: supplier.supplierPic,
      picRoSupplierHp: supplier.supplierPhone,
      subTotalFee: detailOrder.subTotal,
      discountRp: detailOrder.totalDiscount,
      discountPercent: detailOrder.totalDiscount / detailOrder.subTotal,
      ppnRp: detailOrder.totalPpn,
      ppnPercent: ppn,
      totalFee: detailOrder.grandTotal,
      notes: detailOrder.notes || supplier.remarks,
      items: detailOrder.items.map((value) => {
        return {
          itemId: value.id,
          name: value.name,
          oldSellFee: value.sellFee,
          oldBuyFee: value.price,
          quantityBefore: value.qtyBefore,
          quantityOrder: value.qty,
          quantityOrderOld: value.qty,
          bigUnit: value.unit,
          buyFee: value.price,
          discountType: value.discountType,
          discountRp: value.discount,
          discountRpOld: value.discount,
          discountPercent: value.discountPercent,
          discountPercentOld: value.discountPercent,
          ppnRp: value.ppnRp,
          ppnRpOld: value.ppnRp,
          ppnPercent: value.ppnPercent,
          ppnPercentOld: value.ppnPercent,
          unitBuyFinalFee: value.price - value.discount,
          stockDepotId: value.stockDepotId,
          sellFee: value.sellFee,
          notes: value.notes,
        };
      }),
    })
      .then(() => {
        dispatch(
          setSnackbar("success", "Sukses! Purchase order berhasil dilakukan")
        );
        history.push(routes.PURCHASE);
        setIsSubmit(false);
      })
      .catch(() => {
        dispatch(
          setSnackbar(
            "error",
            "Error! Terjadi kesalahan. Coba beberapa saat lagi"
          )
        );
      });
  };
  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName="Tambah Order Baru"
        previousPageName="Purchase Order"
        previousPageLink={routes.PURCHASE}
      />

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            className={`${classes.title} ${classes.borderBottom}`}
          >
            Order
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            className={`${classes.title} ${classes.borderBottom}`}
          >
            Detail Order
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomAutocomplete
                options={suppliers}
                onInputChange={(event, newInputValue) =>
                  setSearchSupplier(newInputValue)
                }
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue, reason, details) => {
                  setCurrentSupplier(newValue, details);
                  setError({ ...error, clientName: undefined });
                }}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                inputValue={searchSupplier}
                error={error.supplierName}
                helperText={error.supplierName}
                label="Supplier"
                placeholder="Cari Nama Supplier"
                getOptionListLabel={(option) => option.name}
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Nomor PO"
                className={classes.inputField}
                value={code}
                style={{ color: "black" }}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Penanggung Jawab"
                className={classes.inputField}
                value={supplier.supplierPic}
                style={{ color: "black" }}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="No. Hp. Penanggung Jawab"
                className={classes.inputField}
                value={supplier.supplierPhone}
                style={{ color: "black" }}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="PPN"
                className={classes.inputField}
                value={`${ppn} %`}
                style={{ color: "black" }}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Alamat"
                className={classes.inputField}
                value={supplier.supplierAddress}
                placeholder="Masukan alamat lengkap"
                style={{ color: "black" }}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Keterangan"
                className={classes.inputField}
                onChange={(event) =>
                  setSupplier({ ...supplier, remarks: event.target.value })
                }
                value={supplier.remarks}
              />
            </Grid>
            {!PoBySup &&
            <Grid item xs={8}>
              <ButtonGroup color="primary" className={classes.buttonGroup}>
                <Button
                  className={clsx({
                    [classes.buttonGroupSelected]: searchItem.type === "Obat",
                  })}
                  onClick={() => setSearchItem({ ...searchItem, type: "Obat" })}
                >
                  Obat
                </Button>
                <Button
                  className={clsx({
                    [classes.buttonGroupSelected]: searchItem.type === "BHP",
                  })}
                  onClick={() => setSearchItem({ ...searchItem, type: "BHP" })}
                >
                  Bahan Habis Pakai
                </Button>
                <Button
                  className={clsx({
                    [classes.buttonGroupSelected]:
                      searchItem.type === "Logistik",
                  })}
                  onClick={() =>
                    setSearchItem({ ...searchItem, type: "Logistik" })
                  }
                >
                  Logistik
                </Button>
              </ButtonGroup>
            </Grid>
            }
            <Grid item xs={4}>
              <Button
                className={classes.buttonGroup}
                variant="contained"
                onClick={async () => openPObySup()}
              >            
                PO By Supplier
              </Button>
            </Grid>
            <Grid item xs={12}>
            <Grid container>
            {PoBySup &&
            <CustomSelect
              style={{ marginRight: 20 }}
              value={item.name}
              onChange={(event) =>{
                setItem({ ...item, name: event.target.value })
                setCurrentItemState(event.target.value.Inventories, {option: event.target.value.Inventories})
              }}
              label="Nama Item PO by 0"
             >
            {stock && stock?.map((data) => (
           <MenuItem key={data["_id"]} value={data}>
           {`${data.Inventories.code} - ${data.Inventories.name}( ${
                    data.stock || `0 ${data.Inventories.bigUnit}`
                  } )`}
          </MenuItem>
             ))}
          </CustomSelect>}
          </Grid>
          {!PoBySup &&
              <CustomAutocomplete
                options={items}
                onInputChange={(event, newInputValue) =>
                  setSearchItem({ ...searchItem, name: newInputValue })
                }
                getOptionLabel={(option) => option.code + " - " + option.name}
                onChange={(event, newValue, reason, details) => {
                  setCurrentItemState(newValue, details);
                  setError({ ...error, itemName: undefined });
                }}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                inputValue={searchItem.name}
                value={itemName.name}
                error={error.itemName}
                helperText={error.itemName}
                label="Nama Item"
                getOptionListLabel={(option) =>
                  `${option.code} - ${option.name}( ${
                    option.bigStock || `0 ${option.bigUnit}`
                  } )`
                }
              />
          }
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Obat Saat Ini"
                className={clsx(classes.inputField, {
                  [classes.lighterBackgroundColor]: !expanded,
                })}
                value={item.bigStock || item.stock}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Jumlah Obat Dipesan"
                className={classes.inputField}
                onChange={(event) =>
                  setItem({
                    ...item,
                    orderQuantity: isNaN(event.target.value)
                      ? 0
                      : event.target.value,
                  })
                }
                value={item.orderQuantity}
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.collapse}
              alignItems="center"
              alignContent="center"
            >
              <Typography
                variant="body1"
                color="primary"
                className={classes.collapseTitle}
              >
                Informasi PO
              </Typography>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={() => setExpanded(!expanded)}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Collapse
                in={expanded}
                timeout="auto"
                unmountOnExit
                className={`${classes.borderBottom} ${classes.buttonGroup}`}
              >
                <Grid container>
                  <Grid item xs={6} className={classes.itemGridLeft}>
                    <CustomTextField
                      label="Tanggal Order Terakhir"
                      className={classes.inputField}
                      value={
                        lastBuyHistory.buyDate && lastBuyHistory.buyDate !== "-"
                          ? moment(new Date(lastBuyHistory.buyDate)).format(
                              "DD/MM/YYYY"
                            )
                          : ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridRight}>
                    <CustomTextField
                      label="Supplier Terakhir"
                      className={classes.inputField}
                      value={lastBuyHistory.supplier}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridLeft}>
                    <CustomTextField
                      label="Harga Dasar"
                      className={classes.inputField}
                      value={formatting.currencyFormat(lastBuyHistory.basicFee)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridRight}>
                    <CustomTextField
                      label="Harga Jual Lama"
                      className={classes.inputField}
                      value={formatting.currencyFormat(lastBuyHistory.sellFee)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridLeft}>
                    <CustomTextField
                      label="Harga Beli Lama"
                      className={classes.inputField}
                      value={formatting.currencyFormat(lastBuyHistory.buyFee)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridRight}>
                    <CustomTextField
                      label="Quantity Beli Lama"
                      className={classes.inputField}
                      value={lastBuyHistory.quantity}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridLeft}>
                    <CustomTextField
                      label="Diskon Beli Lama"
                      className={classes.inputField}
                      value={formatting.currencyFormat(lastBuyHistory.discount)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.itemGridRight}>
                    <CustomTextField
                      label="Harga Beli Lama + PPN"
                      className={classes.inputField}
                      value={formatting.currencyFormat(
                        lastBuyHistory.buyPpnFee
                      )}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            {expanded ? (
              <Grid container>
                <Grid item xs={6} className={classes.itemGridLeft}>
                  <Grid container alignItems="flex-end">
                    <Grid item xs={4}>
                      <CustomSelect
                        value={item.discountType}
                        onChange={(event) =>
                          setItem({ ...item, discountType: event.target.value })
                        }
                        label="Diskon"
                        className={classes.select}
                      >
                        {units.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.label}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </Grid>
                    <Grid item xs={8} className={classes.inputWithSelect}>
                      <CustomTextField
                        className={classes.inputField}
                        onChange={(event) =>
                          isNaN(event.target.value) || event.target.value === ""
                            ? 0
                            : setDiscount(
                                event.target.value > item.basicFee &&
                                  item.discountType === "FIXED"
                                  ? item.basicFee
                                  : event.target.value
                              )
                        }
                        value={discount}
                        currency={item.discountType === "FIXED"}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6} className={classes.itemGridRight}>
                  <CustomTextField
                    label="Harga Beli Normal"
                    className={classes.inputField}
                    onChange={(event) =>
                      setNormalBuyingPrice(event.target.value)
                    }
                    value={normalBuyingPrice}
                    currency
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={6} className={classes.itemGridLeft}>
                  <CustomTextField
                    label="Harga Beli Normal"
                    className={classes.inputField}
                    onChange={(event) =>
                      setNormalBuyingPrice(event.target.value)
                    }
                    value={normalBuyingPrice}
                  />
                </Grid>
                <Grid item xs={6} className={classes.itemGridRight}>
                  <Grid container alignItems="flex-end">
                    <Grid item xs={4}>
                      <CustomSelect
                        value={item.discountType}
                        onChange={(event) =>
                          setItem({ ...item, discountType: event.target.value })
                        }
                        label="Diskon"
                        className={classes.select}
                      >
                        {units.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.label}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </Grid>
                    <Grid item xs={8} className={classes.inputWithSelect}>
                      <CustomTextField
                        className={classes.inputField}
                        onChange={(event) =>
                          isNaN(event.target.value)
                            ? 0
                            : setDiscount(event.target.value)
                        }
                        value={discount}
                        currency={item.discountType === "FIXED"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Harga Jual Baru"
                className={classes.inputField}
                onChange={(event) =>
                  setItem({
                    ...item,
                    sellFee: isNaN(event.target.value) ? 0 : event.target.value,
                  })
                }
                value={item.sellFee}
                currency
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Harga Beli Baru"
                className={classes.inputField}
                onChange={(event) =>
                  isNaN(event.target.value)
                    ? 0
                    : setNewBuyingPrice(event.target.value)
                }
                value={newBuyingPrice}
                currency
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomSelect
                value={item.depotDestination}
                onChange={(event) =>
                  setItem({ ...item, depotDestination: event.target.value })
                }
                label="Tujuan Depot"
                className={classes.select}
              >
                {depots?.map((data) => (
                  <MenuItem key={data["_id"]} value={data}>
                    {`${data.depotName} (${data.rackName})`}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Sub Kategori"
                className={classes.inputField}
                value={item.category}
                disabled
              />
            </Grid>
            <Grid item xs={12} className={classes.itemGridLeft}>
              <CustomTextField
                label="Keterangan"
                className={classes.inputField}
                onChange={(event) =>
                  setItem({ ...item, remarks: event.target.value })
                }
                value={item.remarks}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Button
                  disableElevation
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => resetItem()}
                >
                  Reset
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  size="large"
                  onClick={addItem}
                >
                  Tambah {">"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerLeft}>No</TableCell>
                    <TableCell>Obat</TableCell>
                    <TableCell>Jumlah</TableCell>
                    <TableCell>Harga</TableCell>
                    <TableCell>Diskon per Item</TableCell>
                    <TableCell>Total Harga</TableCell>
                    <TableCell className={classes.headerRight}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailOrder.items.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell style={{ textDecoration: "underline" }}>
                        {row.name}
                      </TableCell>
                      <TableCell>
                        {row.qty} {row.unit}
                      </TableCell>
                      <TableCell>
                        <p className={classes.paragraph}>
                          {"@" + formatting.currencyFormat(row.price)}
                        </p>
                      </TableCell>
                      <TableCell>
                        {formatting.currencyFormat(row.discount)}
                      </TableCell>
                      <TableCell>
                      {formatting.currencyFormat(((row.price - row.discount) * row.qty))}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="secondary"
                          className={classes.button}
                          onClick={() => deleteRow(index)}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Sub Total"
                className={classes.inputField}
                value={formatting.currencyFormat(detailOrder.subTotal)}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Total Diskon"
                className={classes.inputField}
                value={detailOrder.totalDiscount}
                currency
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Total PPN"
                className={classes.inputField}
                value={(detailOrder.totalPpn)}
                currency
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Total Seluruhnya"
                className={`${classes.grandTotal} ${classes.inputField}`}
                value={detailOrder.grandTotal}
                currency
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Button
                  disableElevation
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => history.push(routes.PURCHASE)}
                >
                  Batalkan
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  size="large"
                  onClick={() => isSubmit ? null : addPo()}
                >
            {isSubmit ? <CircularProgress /> : "Pesan"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddPurchaseOrder;
