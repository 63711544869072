import { getPlaceId } from "../store/auth/selector";
import agent from "./agent";

export const getListDepots = (search, skip = 0, limit = 0) => {
  return agent.get("/depots/getList", {
    params: {
      filter: { placeId: getPlaceId(), search },
      skip: skip * limit,
      limit,
    },
  });
};

export const addDepot = (body) => {
  return agent.post("/depots/add", { ...body, placeId: getPlaceId() });
};
