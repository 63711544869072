import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { makeStyles, Grid, Typography, MenuItem } from "@material-ui/core";

import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import CustomTextField from "../custom/CustomTextField";
import CustomSelect from "../custom/CustomSelect";
import CustomDatePicker from "../custom/CustomDatePicker";
import CustomPagination from "../custom/CustomPagination";

import formatting from "../../utils/formatting";

import routes from "../../constants/routes";
import { palette } from "../../theme";

import { getPenerimaanDetail } from "../../services/PenerimaanService";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  inputField: {
    width: "100%",
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  header: {
    lineHeight: "60px",
    fontWeight: "bold",
  },
  headerGrid: {
    marginBottom: 20,
  },
  numbering: {
    backgroundColor: palette.lightBlue,
    color: palette.white,
    fontWeight: "normal",
    borderRadius: "100%",
    padding: 4,
    paddingLeft: 7,
    paddingRight: 7,
    fontSize: 10,
    marginRight: 10,
  },
  select: {
    width: "100%",
  },
  inputWithSelect: {
    paddingLeft: 10,
  },
  grandTotal: {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.whiteBlue,
      color: theme.palette.titleText,
      fontWeight: "bold",
    },
  },
  textButton: {
    color: palette.button,
    borderRadius: 50,
    textTransform: "none",
    fontWeight: "bold",
  },
}));

const Item = (props) => {
  const classes = useStyles();
  const { item } = props;
  const [usePercent, setUsePercent] = useState(
    item.discountType === 'PERCENTAGE' ? true : false
  );
  if (!item) {
    return;
  }

  return (
    <div>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          className={`${classes.borderBottom} ${classes.headerGrid}`}
        >
          <Typography variant="body2" className={classes.header}>
            <b className={classes.numbering}>{props.index + 1}</b>
            {item.name}
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Jumlah Diterima"
              value={item.quantityReceive}
              className={classes.inputField}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Jumlah PO"
              value={item.quantityPO}
              className={classes.inputField}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Satuan"
              value={item.bigUnit}
              className={classes.inputField}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Sub Kategori"
              value={item.subCategory}
              className={classes.inputField}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Harga Beli"
              value={item.buyFee}
              className={classes.inputField}
              currency
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <Grid container alignItems="flex-end">
              <Grid item xs={4}>
                <CustomSelect
                  value={usePercent}
                  label="Diskon (%/Rp)"
                  className={classes.select}
                  onChange={(event) => setUsePercent(event.target.value)}
                  disabled
                >
                  <MenuItem value={true}>%</MenuItem>
                  <MenuItem value={false}>Rp</MenuItem>
                </CustomSelect>
              </Grid>
              <Grid item xs={8} className={classes.inputWithSelect}>
                <CustomTextField
                  className={classes.inputField}
                  value={usePercent ? item.discountPercent !== null ? item.discountPercent : 0 : item.discountRp}
                  currency={!usePercent}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Harga Beli Total"
              value={item.unitBuyFinalFee}
              className={classes.inputField}
              currency
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Harga Jual"
              value={item.sellFee}
              className={classes.inputField}
              currency
              disabled
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              value={item.depotName}
              label="Tujuan Depot"
              className={classes.inputField}
              disabled
            />
          </Grid>
        </Grid>
        {item.batchItems.map((val) => (
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={3}>
              <CustomTextField
                label="Nomor Batch"
                value={val.batchNo}
                className={classes.inputField}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <CustomDatePicker
                label="Tanggal Kadaluarsa"
                date={val.expireDate && new Date(val.expireDate)}
                placeholder="Set Tanggal"
                disabled
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const DetailPenerimaan = () => {
  const classes = useStyles();
  const { receiveId } = useParams();

  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(paginationInitialData);
  const [totalData, setTotalData] = useState(0);
  const [usePercent, setUsePercent] = useState(true);

  useEffect(() => {
    getPenerimaanDetail(receiveId).then((res) => {
      setData(res.data.item);
      setTotalData(res.data.item?.item.length);
    });
  }, [receiveId]);

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={`Detail ${data.invoiceNo || ""}`}
        previousPageName="Penerimaan Barang"
        previousPageLink={routes.PENERIMAAN}
      />
      <Grid container>
        <Grid
          container
          item
          xs={12}
          className={`${classes.borderBottom} ${classes.headerGrid}`}
        >
          <Typography variant="body2" className={classes.header}>
            Data Pesanan & Penerimaan
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Nomor Faktur"
              value={data.invoiceNo}
              className={classes.inputField}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Kode PO"
              value={data.poCode}
              className={classes.inputField}
              disabled
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Nama Pembuat PO"
              value={data.poPIC}
              className={classes.inputField}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Nama Penerima Barang"
              value={data.receivePIC}
              className={classes.inputField}
              disabled
            />
            <Grid item xs={3} />
            <Grid item xs={3} />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Nama Supplier"
              value={data.supplierName}
              className={classes.inputField}
              disabled
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>
      </Grid>
      {data.item?.map((value, index) => (
        <Item key={index} item={value} index={index} />
      ))}
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={`${classes.borderBottom} ${classes.headerGrid}`}
      >
        <Grid container item md={6} sm={8}>
          <CustomPagination
            page={pagination.page}
            onChangePage={(event, newPage) =>
              setPagination({ ...pagination, page: newPage })
            }
            rowsPerPage={pagination.rowsPerPage}
            onChangeRowsPerPage={(event) =>
              setPagination({
                ...pagination,
                rowsPerPage: parseInt(event.target.value, 10),
              })
            }
            labelDisplayedRows={({ from, to, count }) =>
              from + "-" + to + " dari " + count + " Item"
            }
            count={totalData}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Sub Total"
              value={data.subTotalFee}
              className={classes.inputField}
              currency
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              label="Total Diskon Didapatkan"
              value={data.discountRp}
              currency
              className={classes.inputField}
              disabled
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <Grid container alignItems="flex-end">
              <Grid item xs={4}>
                <CustomSelect
                  value={usePercent}
                  label="Pajak (%/Rp)"
                  className={classes.select}
                  onChange={(event) => setUsePercent(event.target.value)}
                  disabled
                >
                  <MenuItem value={true}>%</MenuItem>
                  <MenuItem value={false}>Rp</MenuItem>
                </CustomSelect>
              </Grid>
              <Grid item xs={8} className={classes.inputWithSelect}>
                <CustomTextField
                  className={classes.inputField}
                  value={usePercent ? data.ppnPercent : data.ppnRp}
                  disabled
                  currency={!usePercent}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <CustomDatePicker
              label="Tanggal Jatuh Tempo"
              date={data.dueDate && new Date(data.dueDate)}
              placeholder="Set Tanggal Jatuh Tempo"
              disabled
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              label="Total Seluruhnya"
              value={formatting.currencyFormat(data.totalFee)}
              className={`${classes.inputField} ${classes.grandTotal}`}
              disabled
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>
      </Grid>
    </div>
  );
};

export default DetailPenerimaan;
