import * as XLSX from "xlsx";

function exportXL(filename, data) {
  const ws = XLSX.utils.json_to_sheet(data);

  const wb = XLSX.utils.book_new();

  const wsCol = new Array(18).fill({ wch: 30 }, 0, 18);

  ws["!cols"] = wsCol;

  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  XLSX.writeFile(wb, `${filename}.xlsx`);
}

export default exportXL;
