import React from "react";
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment/locale/id";
import id from "date-fns/locale/id";
import CustomTextField from "./CustomTextField";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const DATE_FORMAT = "MMM, DD/MM/YYYY";

const GetDatePickerValue = (props) => {
  moment.locale("id");
  let startDate = props.startDate
    ? moment(props.startDate).format(DATE_FORMAT)
    : "";
  let endDate = props.endDate ? moment(props.endDate).format(DATE_FORMAT) : "";

  if (startDate) {
    if (endDate) {
      return startDate + "  -  " + endDate;
    }
    return startDate;
  }
};

const CustomRangeDatePicker = (props) => {
  registerLocale("id", id);

  return (
    <div>
      <DatePicker
        selected={props.startDate}
        startDate={props.startDate}
        endDate={props.endDate}
        onChange={(date) => props.onChange(date)}
        placeholderText={"Feb, 20/02/2020  -  Apr, 09/04/2020"}
        selectsRange
        shouldCloseOnSelect={false}
        value={GetDatePickerValue(props)}
        locale={"id"}
        customInput={
          <CustomTextField
            label={props.label}
            helperText={props.helperText}
            endIcon={<KeyboardArrowDownIcon />}
          />
        }
      />
    </div>
  );
};

CustomRangeDatePicker.defaultProps = {
  helperText: " ",
  label: "",
  startIcon: null,
  endIcon: null,
};

CustomRangeDatePicker.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  startIcon: PropTypes.node,
};

export default CustomRangeDatePicker;
