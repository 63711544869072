export default {
  INVENTORY: "/inventory",
  REQUEST_NEW: "/request/new",
  REQUEST: "/request",
  REQUEST_PROCESSING: "/request/processing",
  REQUEST_SHIPPING: "/request/shipping",
  ADJUSTMENT: "/adjustment",
  ADD_NEW_ORDER: "/request/new-order",
  APPROVE_ORDER: "/request/approve",
  PROCESS_ORDER: "/request/process",
  RETURN: "/request/return",
  PURCHASE: "/purchase",
  PURCHASE_ORDER_DETAIL: "/purchase/detail",
  EDIT_PURCHASE_ORDER: "/purchase/edit",
  ADD_PURCHASE_ORDER: "/purchase/add",
  SALES: "/sales",
  PENERIMAAN: "/penerimaan",
  BILLING: "/billing",
  LAPORAN: "/laporan",
  SETTINGS: "/settings",
  GENERAL_SETTINGS: "/settings/general-settings",
  STAFF_MANAGEMENT: "/settings/staff-management",
  ADD_NEW_STAFF: "/settings/staff-management/add-new-staff",
  EDIT_STAFF: "/settings/staff-management/edit-staff",
  ACCESS_RIGHT: "/settings/access-right",
  ADD_ACCESS_RIGHT: "/settings/access-right/add-new",
  EDIT_ACCESS_RIGHT: "/settings/access-right/edit",
  ITEM_CATEGORY: "/settings/item-category",
  ADD_NEW_CATEGORY: "/settings/item-category/add-new",
  EDIT_CATEGORY: "/settings/item-category/edit",
  STORAGE_RACK: "/settings/storage-rack",
  ADD_STORAGE_RACK: "/settings/storage-rack/add-new",
  EDIT_STORAGE_RACK: "/settings/storage-rack/edit",
  SUPPPLIER: "/settings/supplier",
  ADD_NEW_SUPPLIER: "/settings/supplier/add-new",
  EDIT_SUPPLIER: "/settings/supplier/edit",
  CLIENT: "/settings/client",
  ADD_NEW_CLIENT: "/settings/client/add-new",
  EDIT_CLIENT: "/settings/client/edit",
  LOGIN: "/auth/login",
  FORGOTPASS: "/auth/forgotpass",
  CHANGEPASS: "/auth/changepass",
};

export const inventoryRoutes = {
  TAMBAH_BARANG: "/tambah-barang",
  DATA_BARANG: "/data-barang",
  DATA_BARANG_DETAILS: "/data-barang-details",
  WARNING_KADALUARSA: "/warning-kadaluasa",
  WARNING_STOK: "/warning-stok",
  BARANG_PER_SUPPLIER: "/barang-per-supplier",
  DETAIL_BARANG: "/detail-barang",
  DETAIL_SATUAN: "/detail-satuan",
  HARGA_BELI: "/harga-beli",
  DEPOT_STOK: "/depot-stok",
  KADALUARSA_ITEM: "/kadaluarsa-item",
  KARTU_STOK: "/kartu-stok",
  MUTASI_DEPOT: "/mutasi-depot",
  TAMBAH_DEPOT: "/tambah-depot",
  TAMBAH_KADALUARSA: "/tambah-kadaluarsa",
  EDIT_KADALUARSA: "/edit-kadaluarsa",
  BARANG_RUSAK: "/barang-rusak",
  BARANG_RUSAK_DETAIL: "/barang-rusak-detail",
  IMPORT: "/import",
};

export const billingRoutes = {
  BILLING: "/billing",
  BILLING_DETAIL: "/billing-details",
  PAYMENT: "/payment",
};

export const adjustmentStokRoutes = {
  ADJUSTMENT_STOK: "/adjustment-stok",
  HISTORY_REPAIR: "/history-repair",
  TAMBAH_DEPOT: "/tambah-depot",
  REPAIR_STOK: "/repair-stok",
};

export const laporanRoutes = {
  LAPORAN_MENU: "/menu",
  LAPORAN_PENJUALAN: "/penjualan",
  LAPORAN_ADJUSTMENT: "/laporan-adjustment",
  LAPORAN_RO: "/ro",
  LAPORAN_PENERIMAAN: "/penerimaan",
  LAPORAN_FAKTUR: "/faktur",
  LAPORAN_FASTMOVING: "/fastmoving",
  LAPORAN_DATASTOK: "/data-stok",
  LAPORAN_DELIVERY: "/delivery",
  LAPORAN_SELISIH_RO: "/selisih-ro",
  LAPORAN_KEKAYAAN: "/kekayaan",
  LAPORAN_SELISIH_PO: "/selisih-po",
  LAPORAN_PO: "/laporan-po",
  LAPORAN_RETURN: "/laporan-return",
};

export const penerimaanRoutes = {
  PENERIMAAN_MENU: "/menu",
  RETUR: "/retur",
  PROSES: "/proses",
  DETAIL: "/detail",
  RETURN: '/return',
  EDIT_PENERIMAAN_BRG: "/EditPenerimaanBarang",
};

export const devIbbrUrl = "https://devibbr.reqaid.com";
