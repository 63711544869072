export const isEmpty = (value) => {
  if (value instanceof Array) return !value.length
  if (typeof value === 'object' && !(value instanceof Date)) return !Object.keys(value).length;
  if (typeof value === 'string' && value === '') return true
  return value === null || value === undefined;
};

export const lessThanZero = (value) => {
  return value < 0;
};

function isMoreThan(value) {
  return (otherValue) => value > otherValue;
}

export default {
  isEmpty,
  lessThanZero,
  isMoreThan,
};
