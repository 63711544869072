import React from "react";
import { withStyles } from "@material-ui/core";

function Print({ children, classes, ...props }) {
  return (
    <div className="root" {...props}>
      {children}
    </div>
  );
}
export default withStyles({
  "@global": {
    ".root": {
      display: props => props.isHidden
        ? 'none'
        : 'block',
      width: '100%',
    },
    "@media print": {
      ".root": {
        visibility: "visible !important",
        display: 'block !important',
        top: 0,
        left: 0,
        position: "absolute",
      },
      '.hideForPrint, #warehouse-content': {
        display: 'none !important',
      },
      body: {
        visibility: "hidden",
      },
    },
  },
})(Print);
