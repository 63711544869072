import React from "react";
import CustomDialog from "../custom/CustomDialog";

const DeleteRoDialog = (props) => {
  return (
    <CustomDialog
      open={props.open}
      handleClose={props.handleClose}
      title={"Anda akan menghapus data " + props.roNumber}
      body={
        "Anda akan menghapus data RO dengan kode " +
        props.roNumber +
        ", setuju?"
      }
      onCancel={props.handleClose}
      cancelButtonText="Cancel"
      onAccept={props.handleAccept}
      acceptButtonText="Hapus Data"
    />
  );
};

export default DeleteRoDialog;
