import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import moment from "moment";
import formatting from "../../utils/formatting";

const useStyle = makeStyles({
  titleView: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "center",
  },
  titleText: {
    padding: 10,
    fontWeight: 700,
    textTransform: "uppercase",
  },
});

const Bordered = ({ children, style, as, ...props }) => {
  return React.createElement(
    "div",
    {
      style: {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "black",
        ...style,
      },
      ...props,
    },
    children,
  );
};

const BillingFaktur = ({ data }) => {
  const classes = useStyle();
  moment.locale("id");
  return (
    <div>
      <div className={classes.titleView}>
        <Bordered className={classes.titleText}>Tukar Faktur</Bordered>
      </div>
      <div style={{ margin: 10, display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1 }}>Telah terima dari:</div>
        <div style={{ flex: 1 }}>{data.supplierName}</div>
      </div>
      <div style={{ margin: 10 }}>Berupa faktur:</div>
      <div style={{ margin: 10 }}>
        Dengan rincian faktur / nota sebagai berikut
      </div>
      <Table
        data={data.TxItems.map((d, i) => ({ ...d, no: i + 1 }))}
        style={{ margin: 10 }}
      >
        <TableHead>
          <TableCell>No</TableCell>
          <TableCell>Kode PO - Tanggal Faktur - Nomor Faktur</TableCell>
          <TableCell>Nominal Faktur / Pc</TableCell>
          <TableCell>Keterangan</TableCell>
        </TableHead>
        <TableBody>
            <TableRow>
              <TableCell>{1}</TableCell>
              <TableCell>{data.codePO} - {formatting.dateFormat(data.createdDate)} - {data.noFaktur}</TableCell>
              <TableCell>{formatting.currencyFormat(data.totalFee)}</TableCell>
              <TableCell />
            </TableRow>
        </TableBody>
      </Table>
      <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
        <div style={{ flexDirection: "column", flex: 1 }}>
          <div style={{ flex: 1, height: 100 }}>Diserahkan oleh,</div>
          <div
            style={{
              width: 100,
              borderBottomWidth: 1,
              borderBottomColor: "black",
              borderBottomStyle: "solid",
            }}
          >
            {" "}
          </div>
          <div>Tgl:</div>
        </div>
        <div style={{ flexDirection: "column" }}>
          <div style={{ height: 100, flex: 1 }}> Diterima oleh,</div>
          <div
            style={{
              width: 100,
              borderBottomWidth: 1,
              borderBottomColor: "black",
              borderBottomStyle: "solid",
            }}
          >
            {" "}
          </div>
          <div>Tgl:</div>
        </div>
      </div>
    </div>
  );
};

export default BillingFaktur;
