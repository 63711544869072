import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import debounce from "awesome-debounce-promise";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";

import { palette } from "../../theme";
import routes, { adjustmentStokRoutes } from "../../constants/routes";
import { setSnackbar } from "../../store/general/actions";
import useDisclosure from '../../utils/useDisclosure';

import CustomTextField from "../custom/CustomTextField";
import CustomPagination from "../custom/CustomPagination";
import CustomSelect from "../custom/CustomSelect";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";

import { editStock } from "../../services/DepotInventoriesService";
import { getListSpecificByDepotId } from "../../services/StockExpires";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  editButton: {
    float: "right",
    marginLeft: 5,
    color: palette.blue,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  topGrid: {
    display: "flex",
  },
  marginContainer: {
    marginLeft: 20,
  },
  alignRight: {
    textAlign: "right",
  },
  buttonGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  totalGudangType: {
    width: 125,
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debounceGetList = debounce(getListSpecificByDepotId, 500);

const EditStok = () => {
  const classes = useStyles();
  const { id, itemId, stockDepotId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    open: isSubmitting,
    onOpen: setSubmitting,
    onClose: setSubmitted,
  } = useDisclosure();

  const [searchForm, setSearchForm] = useState("");
  const [pagination, setPagination] = useState(paginationInitialData);
  const [totalData, setTotalData] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [flag, setFlag] = useState(0);

  useEffect(() => {
    setIsFetching(true);
    debounceGetList(
      itemId,
      stockDepotId,
      searchForm.batchNo,
      pagination.page,
      pagination.rowsPerPage
    )
      .then((res) => {
        setData(res.data.items);
        setTotalData(res.data.count);
      })
      .finally(() => setIsFetching(false));
  }, [itemId, stockDepotId, pagination, searchForm, flag]);

  const types = [
    { key: "audit", value: "Audit" },
    { key: "stockOpname", value: "Stok Opname" },
    { key: "adjustment", value: "Adjustment" },
  ];

  const handleQuantityChange = (index, value) => {
    const newData = [...data];
    newData[index].quantity = value;
    setData(newData);
  };

  const handleTypeChange = (index, value) => {
    const newData = [...data];
    newData[index].type = value;
    setData(newData);
  };

  const handleNotesChange = (index, value) => {
    const newData = [...data];
    newData[index].notes = value;
    setData(newData);
  };

  const handleEditStock = (row) => {
    if (!row.quantity) {
      dispatch(setSnackbar("warning", "Maaf! Silakan isi quantity terlebih dahulu"));
      return;
    }
    if (row.quantity < 0) {
      dispatch(setSnackbar("warning", "Maaf! jumlah total di gudang utama tidak boleh minus"));
      return;
    }
    if (!row.type || row.type === "") {
      dispatch(setSnackbar("warning", "Maaf! Silakan pilih type terlebih dahulu"));
      return;
    }
    setSubmitting();
    editStock(stockDepotId, {
      quantity: Number(row.quantity),
      type: row.type,
      notes: row.notes,
      stockExpireId: row?._id,
    })
      .then(() => {
        dispatch(setSnackbar("success", "Sukses! Adjustment stok berhasil dilakukan"));
      })
      .finally(() => {
        setFlag(flag + 1);
        setSubmitted();
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          setSnackbar("error", "Error! Terjadi kesalahan. Coba beberapa saat lagi")
        );
      });
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={data[0]?.depotName}
        previousPageName="Adjustment Stok"
        previousPageLink={routes.ADJUSTMENT}
      />
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid
          item
          xs={9}
          style={{ paddingTop: 10 }}
          className={classes.topGrid}
        >
          <div>
            <CustomTextField
              label="Cari Data"
              placeholder="Nomor Batch"
              startIcon={<SearchIcon />}
              value={searchForm.batchNo}
              onChange={(event) =>
                setSearchForm({ ...searchForm, batchNo: event.target.value })
              }
            />
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerLeft}>No Batch</TableCell>
              <TableCell>Kadaluarsa</TableCell>
              <TableCell>Total di Sistem</TableCell>
              <TableCell>Total di Gudang Utama</TableCell>
              <TableCell>Tipe</TableCell>
              <TableCell>Keterangan</TableCell>
              <TableCell className={classes.headerRight} />
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={7} align={"center"}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align={"center"}>
                  <p>Tidak ada data yang bisa ditampilkan.</p>
                </TableCell>
              </TableRow>
            ) : (
              data.map((row, index) => (
                <TableRow key={row["_id"]}>
                  <TableCell>{row.batchNo}</TableCell>
                  <TableCell>
                    {moment(new Date(row.expiredDate)).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>{row.stock}</TableCell>
                  <TableCell>
                    <CustomTextField
                      style={{ marginRight: 20 }}
                      helperText=""
                      className={classes.totalGudangType}
                      value={Number.parseFloat(row.quantity)}
                      type="number"
                      onChange={(event) =>
                        handleQuantityChange(index, event.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <CustomSelect
                      style={{ marginRight: 20 }}
                      helperText=""
                      className={classes.totalGudangType}
                      value={row.type}
                      onChange={(event) =>
                        handleTypeChange(index, event.target.value)
                      }
                    >
                      {types.map((data) => (
                        <MenuItem key={data.key} value={data.key}>
                          {data.value}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </TableCell>
                  <TableCell>
                    <CustomTextField
                      style={{ marginRight: 20 }}
                      value={row.notes}
                      onChange={(event) =>
                        handleNotesChange(index, event.target.value)
                      }
                      helperText=""
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      disableElevation
                      variant="outlined"
                      size="large"
                      className={classes.button}
                      onClick={
                        isSubmitting ? null : () => handleEditStock(row)
                      }
                      startIcon={<SaveOutlinedIcon />}
                    >
                      Simpan
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
        <Grid item xs={12} className={classes.buttonGrid}>
          <div>
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() =>
                history.push(
                  routes.ADJUSTMENT +
                    adjustmentStokRoutes.REPAIR_STOK +
                    `/${id}`
                )
              }
            >
              Kembali
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditStok;
