/* eslint-disable import/prefer-default-export */
// Helper functions
// Purely functional - no side effect - no worries 👌
import fp from 'lodash/fp';

// equalTo :: a -> b -> Boolean
export const equalTo = a => b => a === b;

// prop :: propertyName -> Object -> Maybe propertyName
export const prop = propertyName => fp.get(propertyName);

export const defaultEmptyStr = fp.defaultTo('');

export const adjust = fp.curry((idx, fn, list) => {
  const len = list.length;
  if (idx >= len || idx < -len) {
    return list;
  }
  const pidx = (len + idx) % len;
  const plist = [...list];
  plist[pidx] = fn(plist[pidx]);
  return plist;
});
export const update = fp.curry((idx, x, list) =>
    adjust(idx, fp.constant(x), list),
  );
