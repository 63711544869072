import React from "react";
import { palette } from "../../theme";
import { withStyles } from '@material-ui/core/styles';
// import {
//   dateFormat,
// } from "../../utils/formatting";
import { Table, TableBody, TableCell, TableRow, TableHead, Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from "moment";

const CustomTableCell = withStyles({
  head: {
    backgroundColor: '#f2f2f2',
    fontSize: 14,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.28,
    letterSpacing: 'normal',
    textAlign: 'left',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  body: {
    fontSize: 14,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.28,
    letterSpacing: 'normal',
    textAlign: 'left',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
})(TableCell);

const useStyle = makeStyles({
  title: {
    fontSize: 17,
    fontWeight: "bold",
    fontFamily: "Roboto",
    marginBottom: 20,
    textAlign: "center",
  },
  titleView: {
    width: '100%',
    textAlign: "center",
  },
  titleContainer: {
    // display: "flex",
    flexDirection: "row",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    marginBottom: 10,
  },
  header: {
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    border: 1,
    borderColor: palette.black,
  },
  headerTitle: {
    backgroundColor: palette.disabledTextfield,
  },
  headerValue: {
    backgroundColor: palette.white,
  },
  headerTextLabel: {
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    color: palette.black,
    width: 180,
    marginBottom: 10,
  },
  headerTextValue: {
    color: palette.black,
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    marginBottom: 10,
    marginRight: 130,
  },
  tableHeaderText: {
    backgroundColor: palette.secondaryText,
    color: palette.white,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 14,
    paddingTop: 5,
    // paddingBottom: 5,
    borderColor: palette.disabledTextfield,
    textAlign: "center",
    // paddingLeft: 5,
  },
  tableDataText: {
    fontFamily: "Roboto",
    fontSize: 14,
    paddingTop: 5,
    paddingBottom: 5,
    borderColor: palette.disabledTextfield,
    borderTopColor: palette.disabledTextfield,
    paddingLeft: 5,
  },
  footerContainer: {    
    display: "flex",

    flexDirection: "row",
    borderRadius: 10,
    marginTop: 20,
    // marginLeft: 80,
  },
  footerSignatureName: {
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    color: palette.black,
    width: '100%',
    marginBottom: 40,
    textAlign: "center",
    // marginRight: 90,
  },
  footerSignature: {
    color: palette.black,
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    width: '100%',
    textAlign: 'center'
    // marginRight: 90,
  },
  blackFill: {
    backgroundColor: palette.secondaryText,
    color: palette.white,
    width: 400,
  },
});

const BeritaAcaraRO = (props) => {
  moment.locale("id");
  const classes = useStyle();
    return (
      <div>
           <div className={classes.titleContainer}>
            <div className={classes.titleView}>
              <div style={{ textTransform: "capitalize" }} className={classes.title}>{props.data?.clientName}</div>
            </div>
          </div>
          <div className={classes.titleContainer}>
            <div className={classes.titleView}>
              <div className={classes.title}>Berita Acara Request Order</div>
            </div>
          </div>
          <div className={classes.headerContainer}>
            <div className={[classes.header, classes.headerTitle]}>
            <div className={classes.headerTextLabel}> Waktu RO</div>
              <div className={classes.headerTextLabel}> Client</div>
              <div className={classes.headerTextLabel}> Telp Client</div>
              <div className={classes.headerTextLabel} />
            </div>
            <div className={[classes.header, classes.headerValue]}>
            <div className={classes.headerTextValue}>
            {moment(props.data?.createdDate).format("dddd, DD MMMM YYYY")}
            </div>
              <div className={classes.headerTextValue}>{props.data?.clientName}</div>
              <div className={classes.headerTextValue}>{props.data?.hp || "-"}</div>
              <div className={classes.headerTextValue} />
            </div>
            <div className={[classes.header, classes.headerTitle]}>
            <div className={classes.headerTextLabel}> Tipe Barang</div>
            <div className={classes.headerTextLabel}> User RO</div>
            <div className={classes.headerTextLabel}> User Approval</div>
              <div className={classes.headerTextLabel}> ID RO</div>
              <div className={classes.headerTextLabel} />
            </div>
            <div className={[classes.header, classes.headerValue]}>
            <div className={classes.headerTextValue}>
              Obat, Alkes, BHP dan Aksesoris
            </div>
            <div className={classes.headerTextValue}>{props.data?.picRoClientName || "-"}</div>
            <div className={classes.headerTextValue}>
              {props.data?.picApproveClientName ? props.data?.picApproveClientName : "-"}
            </div>

            <div className={classes.headerTextValue}>{props.data?.code}</div>
              <div className={classes.headerTextValue} />
            </div>
          </div>

          <Table data={props.items}>
            <TableHead>
              <CustomTableCell className={[classes.tableHeaderText]}>
                No
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText}>
                Kode Item
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText}>
                Nama Item
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} style={{ width: 45 }}>
                Satuan Besar
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} style={{ width: 45 }}>
                Stok Tersedia
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} style={{ width: 45 }}>
                RO Klinik
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} style={{ width: 45 }}>
                Jumlah Yang Dikirim
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} style={{ width: 45 }}>
                Cek
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} style={{ width: 45 }}>
                Buffer Stok
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText} style={{ width: 45 }}>
                Keterangan
              </CustomTableCell>
            </TableHead>
            <TableBody>
              {props.items.map((d, idx) => (
                <TableRow>
                  <CustomTableCell className={classes.tableDataText} style={{ textAlign: 'center' }}>{idx + 1}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}>{d.itemCode}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}>{d.name}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} style={{ width: 45 }}>{d.bigUnit} </CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} style={{ width: 45 }}>
                    {d.stockAvailableClinic === undefined
                      ? Number.isInteger(d.stockExisting)
                        ? d.stockExisting
                        : (d && d.stockExisting && d.stockExisting.toFixed(2))
                      : Number.isInteger(d.stockAvailableClinic)
                      ? d.stockAvailableClinic
                      : d.stockAvailableClinic === null
                        ? 0
                        : (d && d.stockAvailableClinic && d.stockAvailableClinic.toFixed(2))}
                  </CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} style={{ width: 45 }}>{d.quantityRequest}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} style={{ width: 45 }}></CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} style={{ width: 45 }}></CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} style={{ width: 45 }}>{d.bufferStockClinic ? d.bufferStockClinic : d.stockBuffer}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText} style={{ width: 45 }}>RO</CustomTableCell>
                </TableRow>
              ))
              }
            </TableBody>
          </Table>
          <Grid container item xs={12} className={classes.footerContainer} justify="space-evenly">
          <Grid item xs={2}>
            <div className={classes.footerSignatureName}>Packaging</div>
            <div className={classes.footerSignature}>________________</div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.footerSignatureName}>Admin</div>
            <div className={classes.footerSignature}>________________</div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.footerSignatureName}>Pengirim</div>
            <div className={classes.footerSignature}>________________</div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.footerSignatureName}>Supervisor</div>
            <div className={classes.footerSignature}>________________</div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.footerSignatureName}>Penerima Barang</div>
            <div className={classes.footerSignature}>________________</div>
          </Grid>
        </Grid>
      </div>
    );
  };

export default BeritaAcaraRO;
