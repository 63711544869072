import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { palette } from "../../theme";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.disabledTextfield,
  },
  dialogContent: {
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 0,
    marginLeft: 25,
    borderBottom: "2px solid",
    borderBottomColor: theme.palette.normalTextfield,
    width: 395,
  },
  dialogAction: {
    height: 80,
    marginTop: 30,
    backgroundColor: theme.palette.normalTextfield,
    paddingRight: 27,
  },
}));

const CustomDialog = (props) => {
  const classes = useStyles();
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <MuiDialogTitle className={classes.dialogContent}>
        <Typography
          variant="body1"
          color="textPrimary"
          style={{ fontWeight: "bold" }}
        >
          {props.title}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.handleClose}
          style={{ border: "none" }}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body2" color="textPrimary">
          {props.body}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          disableElevation
          onClick={props.onCancel}
          color="secondary"
          variant="contained"
          size="large"
        >
          {props.cancelButtonText}
        </Button>
        <Button
          disableElevation
          onClick={props.onAccept}
          style={{ backgroundColor: props.acceptButtonColor }}
          variant="contained"
          size="large"
        >
          {props.acceptButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomDialog.defaultProps = {
  acceptButtonColor: palette.red,
};

export default CustomDialog;
