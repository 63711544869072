import React, { useEffect, useState, useRef, useDebugValue } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import CustomTextField from "../custom/CustomTextField";
import CustomSelect from "../custom/CustomSelect";
import routes, { inventoryRoutes } from "../../constants/routes";
import { addDepot } from "../../services/InventoryService";
import validations from "../../utils/validations";
import useDisclosure from "../../utils/useDisclosure";
import { formatPayloadInt } from "../../utils/formatting";
import { getListDepots } from "../../services/DepotService";
import { getListRacks } from "../../services/RackService";
import { setSnackbar } from "../../store/general/actions";
import { getItemDetails, getDepotStock } from "../../services/InventoryService";
import { palette } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {},
  editGrid: {
    display: "flex",
  },
  marginContainerLeft: {
    marginLeft: 20,
  },
  buttonGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    maxHeight: "100%",
    height: 450,
  },
  detailGrid: {
    marginTop: 20,
  },
  flexButton: {
    display: "flex",
    flexFlow: "wrap-reverse",
  },
  datePicker: {
    marginLeft: 20,
    width: 288,
  },
  darkerField: {
    backgroundColor: palette.disabledTextfield,
  },
}));

const depotDefault = {
  itemId: "",
  rackId: "",
  stock: 0,
  depotId: "",
  type: "",
};

function useStockDetail() {
  const { itemID } = useParams();
  const refItem = useRef(null);
  useDebugValue(refItem.current);
  useEffect(() => {
    getItemDetails(itemID).then(
      ({
        data: {
          item: { stock },
        },
      }) => {
        refItem.current = stock;
      }
    );
  }, [itemID]);

  return refItem.current;
}

function useAccumulatedStock() {
  const { itemID } = useParams();
  const refItem = useRef(null);
  useDebugValue(refItem.current);
  useEffect(() => {
    getDepotStock(itemID).then(({ data: { items } }) => {
      const accumulatedStock = items.reduce(
        (acc, current) => acc + current.stock,
        0
      );
      refItem.current = accumulatedStock;
    });
  }, [itemID]);

  return refItem.current;
}

const TambahDepot = (props) => {
  const classes = useStyles();
  const { itemID } = useParams();
  const { search } = useLocation();
  const q = new URLSearchParams(search);
  const source = q.get("source");
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    open: isSubmitting,
    onOpen: setSubmit,
    onClose: setSubmitted,
  } = useDisclosure();

  const [depot, setDepot] = useState({ ...depotDefault, itemId: itemID });
  const [depots, setDepots] = useState([]);
  const [racks, setRacks] = useState([]);
  const [error, setError] = useState({});

  const itemStock = useStockDetail();
  const accumulatedStock = useAccumulatedStock();
  useEffect(() => {
    if (accumulatedStock === 0) {
      setDepot((d) => ({ ...d, stock: itemStock }));
    }
  }, [accumulatedStock, itemStock]);
  useEffect(() => {
    if (accumulatedStock > itemStock) {
      dispatch(
        setSnackbar(
          "info",
          "Jumlah stock depot melebihi jumlah stock satuan besar, silakan lakukan adjustment barang terlebih dahulu"
        )
      );
    }
  }, [itemStock, accumulatedStock, dispatch]);
  useEffect(() => {
    const remainingStockToFill = itemStock - accumulatedStock;
    if (remainingStockToFill < 0) return;
    if (depot.stock > remainingStockToFill) {
      setDepot((d) => ({ ...d, stock: remainingStockToFill }));
      dispatch(
        setSnackbar(
          "warning",
          "Maaf! Stock tidak boleh melebihi jumlah stock satuan"
        )
      );
    }
  }, [depot.stock, itemStock, accumulatedStock, dispatch]);

  useEffect(() => {
    getListDepots("", 0, 0).then((res) => {
      setDepots(
        res.data.items.map((e) => ({
          label: e.name,
          value: e.id,
          type: e.type,
        }))
      );
    });
    getListRacks("", 0, 0).then((res) => {
      setRacks(res.data.items.map((e) => ({ label: e.name, value: e.id })));
    });
  }, []);

  const checkError = () => {
    const rackId = validations.isEmpty(depot.rackId);
    const depotId = validations.isEmpty(depot.depotId);
    setError({ rackId, depotId });
    return rackId || depotId;
  };

  const handleChange = (name) => (event) => {
    let { value } = event.target;
    if (name === "stock") value = Math.abs(value);
    setDepot({ ...depot, [name]: value });
  };

  const handleChangeDepotName = ({ target: { value } }) => {
    // search for depot object matching selected value
    const selectedDepot = depots.find((d) => d.value === value);
    if (!selectedDepot) return;
    setDepot((d) => ({
      ...d,
      depotId: selectedDepot.value,
      type: selectedDepot.type,
    }));
  };

  const handleSubmit = () => {
    if (checkError()) {
      return;
    }

    setSubmit();

    addDepot(formatPayloadInt(depot, ["stock"]))
      .then((res) => {
        dispatch({
          type: "SET_SNACKBAR",
          payload: {
            open: true,
            variant: "success",
            message: "Sukses! Tambah depot berhasil dilakukan",
          },
        });
        setSubmitted();
        history.push(
          routes.INVENTORY +
            inventoryRoutes.DETAIL_BARANG +
            `/${itemID}` +
            inventoryRoutes.DEPOT_STOK +
            `?source=${source}`,
        );
      })
      .catch((error) => {
        if (error.response.data.error.message === "Data Stock Depot ini sudah ada") {
          dispatch({
            type: "SET_SNACKBAR",
            payload: {
              open: true,
              variant: "error",
              message: "Error! Data stock depot Sudah ada",
            },
          });
        } else {
          dispatch({
            type: "SET_SNACKBAR",
            payload: {
              open: true,
              variant: "error",
              message: "Error! Gagal menambahkan depot",
            },
          });
        }
        setSubmitted();
      });
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.detailGrid}
      >
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            <CustomSelect
              style={{ marginRight: 20 }}
              value={depot.depotId}
              label="Nama Depot"
              requiredField
              onChange={handleChangeDepotName}
              error={error.depotId && validations.isEmpty(depot.depotId)}
              helperText={
                error.depotId && validations.isEmpty(depot.depotId)
                  ? "Nama Depot tidak boleh kosong"
                  : " "
              }
            >
              {depots.map((depot) => (
                <MenuItem key={depot.value} value={depot.value}>
                  {depot.label}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
          <div className={classes.datePicker}>
            <CustomSelect
              style={{ marginRight: 20 }}
              value={depot.depotId}
              label="Tipe Depot"
              disabled
            >
              {depots.map((depot) => (
                <MenuItem key={depot.value} value={depot.value}>
                  {depot.type}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomSelect
              style={{ marginRight: 20 }}
              value={depot.rackId}
              label="Nama Rak"
              requiredField
              onChange={handleChange("rackId")}
              error={error.rackId && validations.isEmpty(depot.rackId)}
              helperText={
                error.rackId && validations.isEmpty(depot.rackId)
                  ? "Nama Rak tidak boleh kosong"
                  : " "
              }
            >
              {racks.map((rack) => (
                <MenuItem key={rack.value} value={rack.value}>
                  {rack.label}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div>
            <CustomTextField
              label="Jumlah Stok"
              value={depot.stock}
              type="number"
              // className={classes.darkerField}
              onChange={handleChange("stock")}
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.buttonGrid}>
          <div>
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() =>
                history.push(
                  routes.INVENTORY +
                    inventoryRoutes.DETAIL_BARANG +
                    `/${itemID}` +
                    inventoryRoutes.DEPOT_STOK +
                    `?source=${source}`,
                )
              }
            >
              {"Batalkan"}
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              onClick={isSubmitting ? null : handleSubmit}
            >
              {"Tambah"}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

TambahDepot.propTypes = {};
export default TambahDepot;
