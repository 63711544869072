import React from "react";
import { palette } from "../../theme";
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, TableHead, Grid } from '@material-ui/core';
import formatting from "../../utils/formatting";
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from "moment";

const CustomTableCell = withStyles({
  head: {
    backgroundColor: '#f2f2f2',
    fontSize: 14,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.28,
    letterSpacing: 'normal',
    textAlign: 'left',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    // width: 150,
  },
  body: {
    fontSize: 14,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.28,
    letterSpacing: 'normal',
    textAlign: 'left',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    // width: 150,
  },
})(TableCell);

const useStyle = makeStyles({
  page: {
    padding: 20,
  },
  title: {
    fontSize: 17,
    fontWeight: "bold",
    fontFamily: "Roboto",
    marginBottom: 20,
    textAlign: "center",
  },
  idPo: {
    fontSize: 11,
    fontFamily: "Roboto",
  },
  titleView: {
    width: '100%',
    textAlign: "center",
  },
  titleContainer: {
    // display: "flex",
    flexDirection: "row",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    marginBottom: 10,
  },
  header: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 20,
    border: 1,
    borderColor: palette.black,
  },
  headerTitle: {
    backgroundColor: palette.disabledTextfield,
  },
  headerValue: {
    backgroundColor: palette.white,
  },
  headerTextLabel: {
    fontFamily: "Roboto",
    fontSize: 10,
    flexDirection: "column",
    color: palette.black,
    width: 150,
    marginBottom: 10,
  },
  headerTextValue: {
    color: palette.black,
    fontFamily: "Roboto",
    fontSize: 10,
    flexDirection: "column",
    marginBottom: 10,
    // marginRight: 130,
  },
  headerLeftWidth: {
    width: 150,
  },
  tableHeaderText: {
    backgroundColor: palette.secondaryText,
    color: palette.white,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 11,
    paddingTop: 5,
    // paddingBottom: 5,
    borderColor: palette.disabledTextfield,
    textAlign: "center",
    // paddingLeft: 5,
  },
  tableHeaderTexts: {
    backgroundColor: palette.secondaryText,
    color: palette.white,
    fontWeight: "bold",
    fontFamily: "Roboto",
    fontSize: 11,
    paddingTop: 5,
    // paddingBottom: 5,
    borderColor: palette.disabledTextfield,
    textAlign: "center",
    // paddingLeft: 5,
    width: 50,
  },
  tableHeaderLeft: {
    borderTopLeftRadius: 10,
  },
  tableHeaderRight: {
    borderTopRightRadius: 10,
  },
  tableDataText: {
    fontFamily: "Roboto",
    fontSize: 9,
    paddingTop: 5,
    paddingBottom: 5,
    borderColor: palette.disabledTextfield,
    borderTopColor: palette.disabledTextfield,
    paddingLeft: 5,
  },
  tableDataTextV2: {
    fontFamily: "Roboto",
    fontSize: 9,
    paddingTop: 5,
    paddingBottom: 5,
    borderColor: palette.disabledTextfield,
    borderTopColor: palette.disabledTextfield,
    paddingLeft: 5,
    height: 30,
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    marginTop: 20,
  },
  footerSignatureName: {
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    color: palette.black,
    width: "100%",
    marginBottom: 60,
    textAlign: "center",
    // marginRight: 680,
    fontWeight: "bold",
  },
  footerSignature: {
    color: palette.black,
    fontFamily: "Roboto",
    fontSize: 14,
    flexDirection: "column",
    width: '100%',
    textAlign: 'center'
    // marginRight: 680,
  },
  blackFill: {
    backgroundColor: palette.secondaryText,
    color: palette.white,
    width: 550,
  },
});

const PurchaseOrderKwitansi = (props) => {
  moment.locale("id");
  const classes = useStyle();
    return (
      <div>
           <div className={classes.titleContainer}>
            <div className={classes.titleView}>
              <div style={{ textTransform: "capitalize" }} className={classes.title}>{props.data?.Suppliers.name}</div>
            </div>
          </div>
          <div className={classes.titleContainer}>
            <div className={classes.titleView}>
              <div className={classes.title}>Purchase Order</div>
            </div>
          </div>
          <div className={classes.headerContainer}>
            <div className={[classes.header, classes.headerTitle]}>
              <div className={classes.headerTextLabel}> Supplier</div>
              <div className={classes.headerTextLabel}> Telp Supplier</div>
              <div className={classes.headerTextLabel} />
            </div>
            <div className={[classes.header, classes.headerValue]}>
              <div className={classes.headerTextValue}>{props.data?.Suppliers.name}</div>
              <div className={classes.headerTextValue}>{props.data?.Suppliers.hpPic}</div>
              <div className={classes.headerTextValue} />
            </div>
            <div className={[classes.header, classes.headerTitle]}>
              <div className={classes.headerTextLabel}> No PO</div>
              <div className={classes.headerTextLabel}> Tanggal</div>
              <div className={classes.headerTextLabel} />
            </div>
            <div className={[classes.header, classes.headerValue]}>
            <div className={classes.headerTextValue}>{props.data?.code}</div>
            <div className={classes.headerTextValue}>
                {moment(props.data.createdDate).format("dddd, DD MMMM YYYY")}
              </div>
              <div className={classes.headerTextValue} />
            </div>
          </div>

          <Table data={props.items}>
            <TableHead>
              <CustomTableCell className={[classes.tableHeaderText]}>
                No
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText}>
                Kode Item
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText}>
                Nama Item
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText}>
                Qty
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText}>
                Satuan
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText}>
                Harga Satuan
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText}>
                Diskon
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText}>
                Jumlah
              </CustomTableCell>
              <CustomTableCell className={classes.tableHeaderText}>
                Keterangan
              </CustomTableCell>
            </TableHead>
            <TableBody>
              {props.items.map((d) => (
                <TableRow>
                  <CustomTableCell className={classes.tableDataText}>{d.no}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}>{d.Inventories.code}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}>{d.name}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}>{d.quantityOrderOld ? d.quantityOrderOld : d.quantityOrder}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}>{d.bigUnit}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}>{formatting.currencyFormatWithDecimalOrNot(d.oldBuyFee ? d.oldBuyFee: d.buyFee, 2)}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}>{formatting.currencyFormatWithDecimalOrNot(d.discountRpOld ? d.discountRpOld : d.discountRp, 2)}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}>{formatting.currencyFormatWithDecimalOrNot(d.totalFee, 2)}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}></CustomTableCell>
                </TableRow>
              ))
              }
                <TableRow>
                  <CustomTableCell colSpan={6} className={classes.tableDataText}></CustomTableCell>
                  <CustomTableCell style={{ textAlign: "center" }}className={classes.tableDataText}>DISKON</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}>{formatting.currencyFormatWithDecimalOrNot(props.data.discountRp, 2)}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}></CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell colSpan={6} className={classes.tableDataText}></CustomTableCell>
                  <CustomTableCell style={{ textAlign: "center" }} className={classes.tableDataText}>{`PPN ${props.data.ppnPercent}%`}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}>{formatting.currencyFormatWithDecimalOrNot(props.data.ppnRp, 2)}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}></CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell colSpan={6} className={classes.tableDataText}></CustomTableCell>
                  <CustomTableCell style={{ fontWeight: "bold", textAlign: "center" }}  className={classes.tableDataText}>TOTAL</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}>{formatting.currencyFormatWithDecimalOrNot(props.data.totalFee, 2)}</CustomTableCell>
                  <CustomTableCell className={classes.tableDataText}></CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell style={{ fontWeight: "bold" }} colSpan={8} className={classes.tableDataTextV2}>Note :</CustomTableCell>
                  <CustomTableCell style={{ textDecoration: "underline" }} className={classes.tableDataTextV2}>Konfirmasi Supplier</CustomTableCell>
                </TableRow>
            </TableBody>
          </Table>
          <Grid container item xs={12} className={classes.footerContainer} justify="space-between">
            <Grid item xs={4}>
              <div className={classes.footerSignatureName}>Dibuat,</div>
              <div className={classes.footerSignature}>Purchasing</div>
              <div className={classes.footerSignature} style={{ width: "calc(100% - 50px)" }}>Tgl:</div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.footerSignatureName}>Disetujui,</div>
              <div className={classes.footerSignature}>Spv Purchasing</div>
              <div className={classes.footerSignature} style={{ width: "calc(100% - 75px)" }}>Tgl:</div>

            </Grid>
          </Grid>
      </div>
    );
  };

export default PurchaseOrderKwitansi;
