import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { palette } from "../../theme";
import set from "lodash.set";
import get from "lodash.get";
import CustomTextField from "../custom/CustomTextField";
import Button from "@material-ui/core/Button";
import routes from "../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import {
  addAccessRight,
  getDetailAccessRight,
  editAccessRight,
} from "../../services/AccessRightService";
import useSnackbar from '../../utils/useSnackbar';
import useDeepCompareEffect from '../../utils/useDeepCompareEffect';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  title: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.normalTextfield,
    height: 60,
  },
  titleText: {
    display: "inline-block",
    marginTop: 10,
    fontFamily: theme.typography.fontFamily,
  },
  label: {
    "& .MuiFormControlLabel-label": {
      fontWeight: "bold",
      color: theme.palette.blue,
    },
  },
  switch: {
    "& .MuiFormControlLabel-label": {
      width: "inherit",
      paddingLeft: 32,
    },
  },
  subHeader: {
    "& .MuiFormControlLabel-label": {
      fontWeight: "bold",
    },
  },
  subContent: {
    "& .MuiFormControlLabel-label": {
      width: "inherit",
      paddingLeft: 47,
    },
  },
  subContentSecondChild: {
    "& .MuiFormControlLabel-label": {
      width: "inherit",
      paddingLeft: 67,
    },
  },
  itemGridLeft: {
    paddingRight: 18,
  },
  itemGridRight: {
    paddingLeft: 18,
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: 8,
    marginRight: 16,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: palette.green,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: palette.green,
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: palette.disabledTextfield,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const AccessModule = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container className={classes.borderBottom}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.headerValue}
                onChange={props.onHeaderChange}
                color="primary"
                className={classes.checkbox}
              />
            }
            label={props.headerLabel}
            className={classes.label}
          />
        </Grid>
        {props.options.map((option, index) => {
          if (option.children === undefined) {
            return (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={option.checked}
                      onChange={(event) => props.onCheckChange(event, index)}
                      color="primary"
                      disabled={!props.headerValue}
                    />
                  }
                  label={option.label}
                  labelPlacement="start"
                  className={clsx(classes.switch, {
                    [classes.subHeader]: option.isBold,
                  })}
                  style={{ width: "100%" }}
                />
              </Grid>
            );
          } else {
            return (
              <Grid container>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={props.childHeaderValue(index)}
                        onChange={(event) =>
                          props.onChildHeaderChange(event, index)
                        }
                        color="primary"
                        disabled={!props.headerValue}
                      />
                    }
                    label={option.label}
                    labelPlacement="start"
                    className={`${classes.switch} ${classes.subHeader}`}
                    style={{ width: "100%" }}
                  />
                </Grid>
                {option.children.map((child, childIndex) => {
                  if (child.children === undefined) {
                    return (
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={child.checked}
                              onChange={(event) =>
                                props.onChildCheckChange(
                                  event,
                                  index,
                                  childIndex
                                )
                              }
                              color="primary"
                              disabled={!props.headerValue}
                            />
                          }
                          label={child.label}
                          labelPlacement="start"
                          className={`${classes.switch} ${classes.subContent}`}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid container>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={props.secondChildHeaderValue(
                                  index,
                                  childIndex
                                )}
                                onChange={(event) =>
                                  props.onSecondChildHeaderChange(
                                    event,
                                    index,
                                    childIndex
                                  )
                                }
                                color="primary"
                                disabled={!props.headerValue}
                              />
                            }
                            label={child.label}
                            labelPlacement="start"
                            className={`${classes.switch} ${classes.subContent}`}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        {child.children.map((secondChild, secondChildIndex) => (
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={secondChild.checked}
                                  onChange={(event) =>
                                    props.onSecondChildCheckChange(
                                      event,
                                      index,
                                      childIndex,
                                      secondChildIndex
                                    )
                                  }
                                  color="primary"
                                  disabled={!props.headerValue}
                                />
                              }
                              label={secondChild.label}
                              labelPlacement="start"
                              className={`${classes.switch} ${classes.subContentSecondChild}`}
                              style={{ width: "100%" }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    );
                  }
                })}
              </Grid>
            );
          }
        })}
      </Grid>
    </div>
  );
};

const keyMapping = {
  a1: "purchaseOrder[0].checked",
  a2: "purchaseOrder[1].checked",
  a3: "purchaseOrder[2].checked",
  a4: "purchaseOrder[3].checked",
  a5: "purchaseOrder[4].checked",
  b1: "itemRetrieval[0].checked",
  b7: "itemRetrieval[1].checked",
  b14: "itemRetrieval[2].checked",
  b2: "itemRetrieval[1].children[0].checked",
  b3: "itemRetrieval[1].children[1].checked",
  b4: "itemRetrieval[1].children[2].checked",
  b5: "itemRetrieval[1].children[3].checked",
  b6: "itemRetrieval[1].children[4].checked",
  b8: "itemRetrieval[2].checked",
  b9: "itemRetrieval[2].children[0].checked",
  b10: "itemRetrieval[2].children[1].checked",
  b11: "itemRetrieval[2].children[2].checked",
  b12: 'itemRetrieval[2].children[3].checked',
  b13: 'itemRetrieval[2].children[4].checked',
  c1: "billing[0].checked",
  c2: "billing[1].checked",
  c3: "billing[2].checked",
  c4: "billing[3].checked",
  c5: "billing[4].checked",
  d1: "requestOrder[0].checked",
  d2: "requestOrder[1].checked",
  d3: "requestOrder[1].children[0].checked",
  d4: "requestOrder[1].children[1].checked",
  d5: "requestOrder[1].children[2].checked",
  d12: "requestOrder[1].children[3].checked",
  d6: "requestOrder[2].checked",
  d7: "requestOrder[2].children[0].checked",
  d8: "requestOrder[2].children[1].checked",
  d9: "requestOrder[3].checked",
  d10: "requestOrder[3].children[0].checked",
  d11: "requestOrder[3].children[1].checked",
  d13: "requestOrder[3].children[2].checked",
  e1: "sales[0].checked",
  e2: "sales[1].checked",
  e3: "sales[2].checked",
  f1: "inventory[0].checked",
  f2: "inventory[1].checked",
  f3: "inventory[1].children[0].checked",
  f4: "inventory[1].children[1].checked",
  f5: "inventory[1].children[2].checked",
  f6: "inventory[1].children[3].checked",
  // f7: "inventory[1].children[3].children[0].checked",
  f8: "inventory[1].children[3].children[0].checked",
  // f9: "inventory[1].children[3].children[1].checked",
  f10: "inventory[1].children[3].children[1].checked",
  f11: "inventory[1].children[4].checked",
  f12: "inventory[1].children[4].children[0].checked",
  f13: "inventory[1].children[4].children[1].checked",
  // f14: "inventory[1].children[4].children[2].checked",
  f15: "inventory[1].children[4].children[2].checked",
  f16: "inventory[1].children[4].children[3].checked",
  f17: "inventory[2].checked",
  f18: "inventory[2].children[0].checked",
  f19: "inventory[3].checked",
  // f20: "inventory[3].children[0].checked",
  f21: "inventory[3].children[0].checked",
  f22: "inventory[3].children[1].checked",
  f23: "inventory[4].checked",
  f24: "inventory[4].children[0].checked",
  f25: "inventory[4].children[1].checked",
  f26: "inventory[4].children[2].checked",
  f27: "inventory[4].children[3].checked",
  // f28: "inventory[4].children[3].children[0].checked",
  f29: "inventory[4].children[3].children[0].checked",
  f30: "inventory[4].children[3].children[1].checked",
  f31: "inventory[4].children[3].children[2].checked",
  f32: "inventory[4].children[4].checked",
  f33: "inventory[4].children[4].children[0].checked",
  // f34: "inventory[4].children[4].children[1].checked",
  f35: "inventory[4].children[4].children[1].checked",
  // f36: "inventory[4].children[4].children[2].checked",
  f37: "inventory[5].checked",
  f38: "inventory[5].children[0].checked",
  // f39: "inventory[5].children[0].children[0].checked",
  f40: "inventory[5].children[0].children[0].checked",
  f41: "inventory[5].children[0].children[1].checked",
  f42: "inventory[6].checked",
  f43: "inventory[6].children[0].checked",
  // f44: "inventory[6].children[0].children[0].checked",
  f45: "inventory[6].children[0].children[0].checked",
  f46: "inventory[6].children[0].children[1].checked",
  f47: "inventory[7].checked",
  f48: "inventory[7].children[0].checked",
  // f49: "inventory[7].children[0].children[0].checked",
  f50: "inventory[7].children[0].children[0].checked",
  f51: "inventory[7].children[0].children[1].checked",
  // hak akses barang rusak
  f52: "inventory[8].checked",
  f53: "inventory[8].children[0].checked",
  f54: "inventory[8].children[1].checked",
  // hak akses barang rusak detail
  f55: "inventory[9].checked",
  f56: "inventory[9].children[0].checked",
  f57: "inventory[9].children[1].checked",
  g1: "adjustmentStock[0].checked",
  g2: "adjustmentStock[1].checked",
  g3: "adjustmentStock[1].children[0].checked",
  g4: "adjustmentStock[1].children[1].checked",
  // g5: "adjustmentStock[1].children[1].children[0].checked",
  g6: "adjustmentStock[1].children[1].children[0].checked",
  g7: "adjustmentStock[1].children[1].children[1].checked",
  g8: "adjustmentStock[2].checked",
  g9: "adjustmentStock[2].children[0].checked",
  // g10: "adjustmentStock[1].children[0].children[0].checked",
  // g11: "adjustmentStock[1].children[0].children[1].checked",
  // g12: "adjustmentStock[1].children[0].children[2].checked",
  h1: "report[0].checked",
  // h2: "report[1].checked",
  h3: "report[1].checked",
  h4: "report[2].checked",
  h5: "report[3].checked",
  h6: "report[4].checked",
  h7: "report[5].checked",
  h8: "report[6].checked",
  h9: "report[7].checked",
  h10: "report[8].checked",
  h11: "report[9].checked",
  h12: "report[10].checked",
  h13: "report[11].checked",
  h14: "report[12].checked",
  h15: "report[13].checked",
  h16: "report[14].checked",
  h17: "report[15].checked",
  h18: "report[16].checked",
  i1: "setting[0].checked",
  i2: "setting[1].checked",
  i3: "setting[2].checked",
  i4: "setting[2].children[0].checked",
  i5: "setting[2].children[1].checked",
  i6: "setting[2].children[2].checked",
  i7: "setting[3].checked",
  i8: "setting[3].children[0].checked",
  i9: "setting[3].children[1].checked",
  i10: "setting[3].children[2].checked",
  i11: "setting[3].children[3].checked",
  i12: "setting[4].checked",
  // i13: "setting[4].children[0].checked",
  i14: "setting[4].children[0].checked",
  i15: "setting[4].children[1].checked",
  i16: "setting[4].children[2].checked",
  i17: "setting[4].children[3].checked",
  i18: "setting[4].children[4].checked",
  i19: "setting[5].checked",
  i20: "setting[5].children[0].checked",
  i21: "setting[5].children[1].checked",
  i22: "setting[5].children[2].checked",
  // i23: "setting[5].children[3].checked",
  i24: "setting[5].children[3].checked",
  i25: "setting[5].children[4].checked",
  i26: "setting[6].checked",
  i27: "setting[6].children[0].checked",
  i28: "setting[6].children[1].checked",
  i29: "setting[6].children[2].checked",
  // i30: "setting[6].children[3].checked",
  i31: "setting[6].children[3].checked",
  i32: "setting[6].children[4].checked",
  i33: "setting[7].checked",
  i34: "setting[7].children[0].checked",
  i35: "setting[7].children[1].checked",
  i36: "setting[7].children[2].checked",
  // i37: "setting[7].children[3].checked",
  i38: "setting[7].children[3].checked",
  i39: "setting[7].children[4].checked",
  i40: "setting[2].children[3].checked",
};

const initialData = {
  purchaseOrder: [
    {
      label: "Purchase Order",
      checked: false,
      key: "a1",
    },
    {
      label: "Melakukan tambah baru Purchase Order (PO)",
      checked: false,
      key: "a2",
    },
    {
      label: "Melihat detail Purchase Order (PO)",
      checked: false,
      key: "a3",
    },
    {
      label: "Melakukan print pada data PO",
      checked: false,
      key: "a4",
    },
    {
      label: "Melakukan edit pada data PO",
      checked: false,
      key: "a5",
    },
  ],
  itemRetrieval: [
    {
      label: "Penerimaan barang",
      checked: false,
      key: "b1",
    },
    {
      label: "Penerimaan barang PO",
      checked: false,
      key: "b7",
      children: [
    {
      label: "Bisa melakukan proses penerimaan barang PO",
      checked: false,
      key: "b2",
    },
    {
      label: "Bisa melakukan retur barang",
      checked: false,
      key: "b3",
    },
    {
      label: "Bisa melihat detail",
      checked: false,
      key: "b4",
    },
    {
      label: "Bisa melakukan print",
      checked: false,
      key: "b5",
    },
    {
      label: "Bisa melakukan delete data",
      checked: false,
      key: "b6",
    },
    {
      label: "Bisa melakukan edit penerimaan data",
      checked: false,
      key: "b14",
    },
  ],
  },
  {
    label: "Penerimaan barang Return",
    checked: false,
    key: "b8",
    children: [
      {
        label: "Bisa melakukan proses penerimaan barang return",
        checked: false,
        key: "b9",
      },
      {
        label: "Bisa melihat detail",
        checked: false,
        key: "b10",
      },
      {
        label: "Bisa melakukan print",
        checked: false,
        key: "b11",
      },
      {
        label: "Bisa melakukan reject penerimaan barang return",
        checked: false,
        key: "b12",
      },
      {
        label: "Bisa melakukan download file pengajuan barang rusak",
        checked: false,
        key: "b13",
      },
    ],
},
],
  billing: [
    {
      label: "Billing",
      checked: false,
      key: "c1",
    },
    {
      label: "Bisa melakukan pembayaran",
      checked: false,
      key: "c2",
    },
    {
      label: "Bisa melihat detail",
      checked: false,
      key: "c3",
    },
    {
      label: "Bisa melakukan tukar faktur",
      checked: false,
      key: "c4",
    },
    {
      label: "Bisa melakukan print bukti pembayaran",
      checked: false,
      key: "c5",
    },
  ],
  requestOrder: [
    {
      label: "Request Order",
      checked: false,
      key: "d1",
    },
    {
      label: "New Order",
      checked: false,
      key: "d2",
      children: [
        {
          label: "Bisa melakukan tambah RO baru",
          checked: false,
          key: "d3",
        },
        {
          label: "Bisa melakukan Approve RO dari client",
          checked: false,
          key: "d4",
        },
        {
          label: "Bisa melakukan delete data RO",
          checked: false,
          key: "d5",
        },
        {
          label: "Bisa melakukan print berita acara RO",
          checked: false,
          key: "d12",
        },
      ],
    },
    {
      label: "Processing",
      checked: false,
      key: "d6",
      children: [
        {
          label: "Bisa melakukan proses pada RO",
          checked: false,
          key: "d7",
        },
        {
          label: "Bisa melakukan delete data Processing",
          checked: false,
          key: "d8",
        },
      ],
    },
    {
      label: "Shipping",
      checked: false,
      key: "d9",
      children: [
        {
          label: "Bisa melakukan cetak detail",
          checked: false,
          key: "d10",
        },
        {
          label: "Bisa melakukan cetak biasa",
          checked: false,
          key: "d11",
        },
        {
          label: "Bisa melakukan edit shipping",
          checked: false,
          key: "d13",
        },
      ],
    },
  ],
  sales: [
    {
      label: "Sales",
      checked: false,
      key: "e1",
    },
    {
      label: "Bisa melakukan pembayaran",
      checked: false,
      key: "e2",
    },
    {
      label: "Bisa cetak invoice",
      checked: false,
      key: "e3",
    },
  ],
  inventory: [
    {
      label: "Inventory Barang",
      checked: false,
      key: "f1",
    },
    {
      label: "Data Barang",
      checked: false,
      key: "f2",
      children: [
        {
          label: "Bisa tambah barang",
          checked: false,
          key: "f3",
        },
        {
          label: "Bisa melihat detail barang",
          checked: false,
          key: "f4",
        },
        {
          label: "Bisa mengedit detail barang",
          checked: false,
          key: "f5",
        },
        {
          label: "Bisa melihat harga beli per supplier",
          checked: false,
          key: "f6",
          children: [
            {
              label: "Bisa export",
              checked: false,
              key: "f8",
            },
            {
              label: "Bisa print",
              checked: false,
              key: "f10",
            },
          ],
        },
        {
          label: "Bisa melihat depot stok",
          checked: false,
          key: "f11",
          children: [
            {
              label: "Bisa tambah depot",
              checked: false,
              key: "f12",
            },
            {
              label: "Bisa Mutasi barang",
              checked: false,
              key: "f13",
            },
            // {
            //   label: "Bisa export & print",
            //   checked: false,
            //   key: "f14",
            // },
            {
              label: "Bisa export",
              checked: false,
              key: "f15",
            },
            {
              label: "Bisa print",
              checked: false,
              key: "f16",
            },
          ],
        },
      ],
    },
    {
      label: "Kadaluarsa Item",
      checked: false,
      key: "f17",
      children: [
        {
          label: "Bisa tambah kaldaluarsa item",
          checked: false,
          key: "f18",
        },
      ],
    },
    {
      label: "Kartu Stok",
      checked: false,
      key: "f19",
      children: [
        // {
        //   label: "Bisa export & print",
        //   checked: false,
        //   key: "f20",
        // },
        {
          label: "Bisa export",
          checked: false,
          key: "f21",
        },
        {
          label: "Bisa print",
          checked: false,
          key: "f22",
        },
      ],
    },
    {
      label: "Data Barang Detail",
      checked: false,
      key: "f23",
      children: [
        {
          label: "Bisa tambah barang",
          checked: false,
          key: "f24",
        },
        {
          label: "Bisa melihat detail barang",
          checked: false,
          key: "f25",
        },
        {
          label: "Bisa mengedit detail barang",
          checked: false,
          key: "f26",
        },
        {
          label: "Bisa melihat harga beli per supplier",
          checked: false,
          key: "f27",
          children: [
            // {
            //   label: "Bisa export, import & print",
            //   checked: false,
            //   key: "f28",
            // },
            {
              label: "Bisa export",
              checked: false,
              key: "f29",
            },
            {
              label: "Bisa import",
              checked: false,
              key: "f30",
            },
            {
              label: "Bisa print",
              checked: false,
              key: "f31",
            },
          ],
        },
        {
          label: "Bisa melihat depot stok",
          checked: false,
          key: "f32",
          children: [
            {
              label: "Bisa tambah depot",
              checked: false,
              key: "f33",
            },
            // {
            //   label: "Bisa export & print",
            //   checked: false,
            //   key: "f34",
            // },
            {
              label: "Bisa export",
              checked: false,
              key: "f35",
            },
            // {
            //   label: "Bisa print",
            //   checked: false,
            //   key: "f36",
            // },
          ],
        },
      ],
    },
    {
      label: "Warning Kadaluarsa",
      checked: false,
      key: "f37",
      children: [
        {
          label: "Bisa melihat warning kadaluarsa",
          checked: false,
          key: "f38",
          children: [
            // {
            //   label: "Bisa export & print",
            //   checked: false,
            //   key: "f39",
            // },
            {
              label: "Bisa export",
              checked: false,
              key: "f40",
            },
            {
              label: "Bisa print",
              checked: false,
              key: "f41",
            },
          ],
        },
      ],
    },
    {
      label: "Warning Stok",
      checked: false,
      key: "f42",
      children: [
        {
          label: "Bisa melihat warning stok",
          checked: false,
          key: "f43",
          children: [
            // {
            //   label: "Bisa export & print",
            //   checked: false,
            //   key: "f44",
            // },
            {
              label: "Bisa export",
              checked: false,
              key: "f45",
            },
            {
              label: "Bisa print",
              checked: false,
              key: "f46",
            },
          ],
        },
      ],
    },
    {
      label: "Barang per Supplier",
      checked: false,
      key: "f47",
      children: [
        {
          label: "Bisa melihat barang per supplier",
          checked: false,
          key: "f48",
          children: [
            // {
            //   label: "Bisa export & print",
            //   checked: false,
            //   key: "f49",
            // },
            {
              label: "Bisa export",
              checked: false,
              key: "f50",
            },
            {
              label: "Bisa print",
              checked: false,
              key: "f51",
            },
          ],
        },
      ],
    },
    {
      label: "Data Barang Rusak",
      checked: false,
      key: "f52",
      children: [
        {
          label: "Export data barang rusak",
          checked: false,
          key: "f53",
        },
        {
          label: "Print data barang rusak",
          checked: false,
          key: "f54",
        },
      ],
    },
    {
      label: "Data Barang Rusak Detail",
      checked: false,
      key: "f55",
      children: [
        {
          label: "Export data barang rusak detail",
          checked: false,
          key: "f56",
        },
        {
          label: "Print data barang rusak detail",
          checked: false,
          key: "f57",
        },
      ],
    },
  ],
  adjustmentStock: [
    {
      label: "Adjustment Stock",
      checked: false,
      key: "g1",
    },
    {
      label: "Adjustment Stock",
      checked: false,
      key: "g2",
      children: [
        {
          label: "Bisa melakukan tambah depot",
          checked: false,
          key: "g3",
        },
        {
          label: "Bisa melakukan Repair Stock",
          checked: false,
          key: "g4",
          children: [
            // {
            //   label: "Bisa export & print",
            //   checked: false,
            //   key: "g5",
            // },
            {
              label: "Bisa export",
              checked: false,
              key: "g6",
            },
            {
              label: "Bisa print",
              checked: false,
              key: "g7",
            },
          ],
        },
      ],
    },
    {
      label: "History Repair Stock",
      checked: false,
      key: "g8",
      children: [
        {
          label: "Bisa melihat History Repair Stock",
          checked: false,
          key: "g9",
    //       children: [
    //         {
    //           label: "Bisa export & print",
    //           checked: false,
    //           key: "g10",
    //         },
    //         {
    //           label: "Hanya export",
    //           checked: false,
    //           key: "g11",
    //         },
    //         {
    //           label: "Hanya print",
    //           checked: false,
    //           key: "g12",
    //         },
    //       ],
        },
      ],
    },
  ],
  report: [
    {
      label: "Laporan",
      checked: false,
      key: "h1"
    },
    // {
    //   label: "Bisa export & print",
    //   checked: false,
    //   key: "h2",
    // },
    {
      label: "Bisa export",
      checked: false,
      key: "h3",
    },
    {
      label: "Bisa print",
      checked: false,
      key: "h4",
    },
    {
      label: "Melihat laporan penjualan barang",
      checked: false,
      key: "h5",
    },
    {
      label: "Melihat laporan penerimaan barang",
      checked: false,
      key: "h6",
    },
    {
      label: "Melihat laporan data stok barang",
      checked: false,
      key: "h7",
    },
    {
      label: "Melihat laporan selisih RO dari klinik ke warehouse",
      checked: false,
      key: "h8",
    },
    {
      label: "Melihat laporan selisih dari PO ke supplier",
      checked: false,
      key: "h9",
    },
    {
      label: "Melihat laporan adjustment stock",
      checked: false,
      key: "h10",
    },
    {
      label: "Melihat laporan faktur jatuh tempo",
      checked: false,
      key: "h11",
    },
    {
      label: "Melihat laporan delivery order (DO)",
      checked: false,
      key: "h12",
    },
    {
      label: "Laporan kekayaan barang",
      checked: false,
      key: "h13",
    },
    {
      label: "Melihat laporan PO barang",
      checked: false,
      key: "h14",
    },
    {
      label: "Melihat laporan DO barang",
      checked: false,
      key: "h15",
    },
    {
      label: "Melihat laporan RO barang",
      checked: false,
      key: "h16",
    },
    {
      label: "Melihat laporan fast moving, slow moving",
      checked: false,
      key: "h17",
    },
    {
      label: "Melihat laporan return barang",
      checked: false,
      key: "h18",
    },
  ],
  setting: [
    {
      label: "Setting",
      checked: false,
      key: "i1",
    },
    {
      label: "General Setting",
      checked: false,
      key: "i2",
      isBold: true,
    },
    {
      label: "Management Staff",
      checked: false,
      key: "i3",
      children: [
        {
          label: "Tambah staff baru",
          checked: false,
          key: "i4",
        },
        {
          label: "Edit data staff",
          checked: false,
          key: "i5",
        },
        {
          label: "Reset password staff",
          checked: false,
          key: "i6",
        },
        {
          label: "Export data staff",
          checked: false,
          key: "i40",
        },
      ],
    },
    {
      label: "Hak Akses",
      checked: false,
      key: "i7",
      children: [
        {
          label: "Tambah hak akses baru",
          checked: false,
          key: "i8",
        },
        {
          label: "Melihat detail hak akses",
          checked: false,
          key: "i9",
        },
        {
          label: "Edit hak akses",
          checked: false,
          key: "i10",
        },
        {
          label: "Hapus hak akses",
          checked: false,
          key: "i11",
        },
      ],
    },
    {
      label: "Kategori Barang",
      checked: false,
      key: "i12",
      children: [
        // {
        //   label: "Bisa export & print",
        //   checked: false,
        //   key: "i13",
        // },
        {
          label: "Bisa export",
          checked: false,
          key: "i14",
        },
        {
          label: "Bisa print",
          checked: false,
          key: "i15",
        },
        {
          label: "Tambah kategori baru",
          checked: false,
          key: "i16",
        },
        {
          label: "Edit kategori",
          checked: false,
          key: "i17",
        },
        {
          label: "Hapus kategori",
          checked: false,
          key: "i18",
        },
      ],
    },
    {
      label: "Rak Penyimpanan",
      checked: false,
      key: "i19",
      children: [
        {
          label: "Tambah rak baru",
          checked: false,
          key: "i20",
        },
        {
          label: "Edit rak penyimpanan",
          checked: false,
          key: "i21",
        },
        {
          label: "Hapus rak penyimpanan",
          checked: false,
          key: "i22",
        },
        // {
        //   label: "Bisa export & print",
        //   checked: false,
        //   key: "i23",
        // },
        {
          label: "Bisa export",
          checked: false,
          key: "i24",
        },
        {
          label: "Bisa print",
          checked: false,
          key: "i25",
        },
      ],
    },
    {
      label: "Supplier",
      checked: false,
      key: "i26",
      children: [
        {
          label: "Tambah supplier baru",
          checked: false,
          key: "i27",
        },
        {
          label: "Edit supplier",
          checked: false,
          key: "i28",
        },
        {
          label: "Hapus supplier",
          checked: false,
          key: "i29",
        },
        // {
        //   label: "Bisa export & print",
        //   checked: false,
        //   key: "i30",
        // },
        {
          label: "Bisa export",
          checked: false,
          key: "i31",
        },
        {
          label: "Bisa print",
          checked: false,
          key: "i32",
        },
      ],
    },
    {
      label: "Client",
      checked: false,
      key: "i33",
      children: [
        {
          label: "Tambah client baru",
          checked: false,
          key: "i34",
        },
        {
          label: "Edit client",
          checked: false,
          key: "i35",
        },
        {
          label: "Hapus client",
          checked: false,
          key: "i36",
        },
        // {
        //   label: "Bisa export & print",
        //   checked: false,
        //   key: "i37",
        // },
        {
          label: "Bisa export",
          checked: false,
          key: "i38",
        },
        {
          label: "Bisa print",
          checked: false,
          key: "i39",
        },
      ],
    },
  ],
};

const AddAccessRight = (props) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [data, setData] = useState(initialData);
  const history = useHistory();
  const snackbar = useSnackbar();
  const { id } = useParams();

  const handleCheckedChange = (event, path, callback = (param) => {}) => {
    const newData = { ...data };
    set(newData, path, event.target.checked);
    setData(newData);
    // jika semua child di uncheck, maka headernya juga uncheck
    callback(newData);
    // jika salah satu child di check, maka headernya juga check
  };

  useDeepCompareEffect(() => {
    if (props.edit) {
      getDetailAccessRight(id).then((res) => {
        setName(res.data.item.name);
        const newData = JSON.parse(JSON.stringify(data));
        if (Array.isArray(res.data.item.action)) {
          res.data.item.action.forEach((value) => {
            set(newData, keyMapping[value], true);
          });
        } else {
          Object.values(res.data.item.action)
            .flatMap(act => Object.keys(act))
            .forEach(function loop(value) {
              if (!!get(keyMapping, value)) {
                set(newData, keyMapping[value], true);
              }
          });
        }
        setData(newData);
      });
    }

  }, []);

  const handleHeaderCheckedChange = (event, length, path) => {
    const newData = { ...data };
    for (let i = 0; i < length; i++) {
      set(newData, `${path}[${i}].checked`, event.target.checked);
      if (get(newData, `${path}[${i}].children`) !== undefined) {
        for (
          let j = 0;
          j < get(newData, `${path}[${i}].children`).length;
          j++
        ) {
          set(
            newData,
            `${path}[${i}].children[${j}].checked`,
            event.target.checked
          );
          if (
            get(newData, `${path}[${i}].children[${j}].children`) !== undefined
          ) {
            for (
              let k = 0;
              k < get(newData, `${path}[${i}].children[${j}].children`).length;
              k++
            ) {
              set(
                newData,
                `${path}[${i}].children[${j}].children[${k}].checked`,
                event.target.checked
              );
            }
          }
        }
      }
    }
    setData(newData);
  };

  const saveAccessRight = () => {
    let action = {};
    Object.entries(data).forEach(([key, value]) => {
      action[key] = getActions(value);
    });
    // Cek minimal ada 1 hak akses
    const noAccessSelected =
      !action.purchaseOrder.a1 &&
      !action.itemRetrieval.b1 &&
      !action.billing.c1 &&
      !action.requestOrder.d1 &&
      !action.sales.e1 &&
      !action.inventory.f1 &&
      !action.adjustmentStock.g1 &&
      !action.report.h1 &&
      !action.setting.i1;
    if (noAccessSelected) {
      snackbar.setWarning('Maaf! Hak akses wajib diisi minimal 1');
      return;
    }
    if (!name) {
      snackbar.setError('Nama hak akses wajib diisi');
      return;
    }
    if (props.edit) {
      editAccessRight(id, { name, action }).then(() =>
        history.push(routes.ACCESS_RIGHT)
      );
    } else {
      addAccessRight({ name, action }).then(() =>
        history.push(routes.ACCESS_RIGHT)
      );
    }
  };

  const getActions = (value) => {
    let result = {};
    value.forEach((data) => {
      if (data.children !== undefined) {
        result = {...result, ...getActions(data.children)};
      }

      if (data.checked && data.key) {
        return result[data.key] = data.checked;
      }
    });
    return result;
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.title}
          alignItems="center"
          alignContent="center"
        >
          <Typography
            variant="h2"
            color="primary"
            className={classes.titleText}
          >
            Set Hak Akses
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <CustomTextField
            label="Nama"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid item xs={6} className={classes.itemGridLeft}>
          <AccessModule
            options={data.purchaseOrder.slice(1)}
            headerLabel={data.purchaseOrder[0].label}
            onCheckChange={(event, index) =>
              handleCheckedChange(event, `purchaseOrder[${index + 1}].checked`)
            }
            onHeaderChange={(event) => {
              handleCheckedChange(event, `purchaseOrder[0].checked`);
              handleHeaderCheckedChange(
                event,
                data.purchaseOrder.length,
                "purchaseOrder"
              )
            }}
            headerValue={data.purchaseOrder.slice(1).some((value) => !!value.checked)}
          />
          <AccessModule
            // options={data.itemRetrieval.slice(1)}
            // headerLabel="Penerimaan Barang"
            // onCheckChange={(event, index) =>
            //   handleCheckedChange(event, `itemRetrieval[${index + 1}].checked`)
            // }
            // onHeaderChange={(event) =>{
            //   handleCheckedChange(event, `itemRetrieval[0].checked`)
            //   handleHeaderCheckedChange(
            //     event,
            //     data.itemRetrieval.length,
            //     "itemRetrieval"
            //   )
            // }}
            // headerValue={data.itemRetrieval.slice(1).some((value) => !!value.checked)}
            options={data.itemRetrieval.slice(1)}
            headerLabel="Penerimaan Barang"
            onCheckChange={(event, index) => {
              handleCheckedChange(event, `itemRetrieval[${index + 1}].checked`, )
            }}
            onHeaderChange={(event) =>
              handleHeaderCheckedChange(event, data.itemRetrieval.length, "itemRetrieval")
            }
            onChildCheckChange={(event, index, childIndex) => {
              handleCheckedChange(
                event,
                `itemRetrieval[${index + 1}].children[${childIndex}].checked`,
                function callback(newData) {
                  if (!newData.itemRetrieval[index + 1].children) {
                    return;
                  }
                  const isSemuaChildUncheck = newData.itemRetrieval[index + 1].children.every(ch => !ch.checked);
                  if (isSemuaChildUncheck) {
                    set(newData, `itemRetrieval[${index + 1}].checked`, false);
                    setData(newData);
                  } else {
                    set(newData, `itemRetrieval[${index + 1}].checked`, true);
                    setData(newData);
                  }
                }
              )
            }}
            onChildHeaderChange={(event, index) => {
              handleCheckedChange(event, `itemRetrieval[${index + 1}].checked`);
              handleHeaderCheckedChange(
                event,
                get(data, `itemRetrieval[${index + 1}].children`, []).length,
                `itemRetrieval[${index + 1}].children`
              )
            }}
            childHeaderValue={(index) =>
              data.itemRetrieval[index + 1].children.some((value) => !!value.checked)
            }
            headerValue={data.itemRetrieval.slice(1).some((value) => !!value.checked)}
          />
          <AccessModule
            options={data.billing.slice(1)}
            headerLabel="Billing"
            onCheckChange={(event, index) =>
              handleCheckedChange(event, `billing[${index + 1}].checked`)
            }
            onHeaderChange={(event) => {
              handleCheckedChange(event, `billing[0].checked`)
              handleHeaderCheckedChange(event, data.billing.length, "billing")
            }}
            headerValue={data.billing.slice(1).some((value) => !!value.checked)}
          />
          <AccessModule
            options={data.requestOrder.slice(1)}
            headerLabel="Request Order (RO)"
            onCheckChange={(event, index) => {
              handleCheckedChange(event, `requestOrder[${index + 1}].checked`, )
            }}
            onHeaderChange={(event) => 
              handleHeaderCheckedChange(
                event,
                data.requestOrder.length,
                "requestOrder"
              )
            }
            onChildCheckChange={(event, index, childIndex) => {
              handleCheckedChange(
                event,
                `requestOrder[${index + 1}].children[${childIndex}].checked`,
                function callback(newData) {
                  if (!newData.requestOrder[index + 1].children) {
                    return;
                  }
                  const isSemuaChildUncheck = newData.requestOrder[index + 1].children.every(ch => !ch.checked);
                  if (isSemuaChildUncheck) {
                    set(newData, `requestOrder[${index + 1}].checked`, false);
                    setData(newData);
                  } else {
                    set(newData, `requestOrder[${index + 1}].checked`, true);
                    setData(newData);
                  }
                }
              )
            }}
            onChildHeaderChange={(event, index) => {
              handleCheckedChange(event, `requestOrder[${index + 1}].checked`);
              handleHeaderCheckedChange(
                event,
                get(data, `requestOrder[${index + 1}].children`, []).length,
                `requestOrder[${index + 1}].children`
              )
            }}
            childHeaderValue={(index) =>
              data.requestOrder[index + 1].children.some(
                (value) => !!value.checked
              )
            }
            headerValue={data.requestOrder.slice(1).some((value) => !!value.checked)}
          />
          <AccessModule
            options={data.sales.slice(1)}
            headerLabel="Sales"
            onCheckChange={(event, index) =>
              handleCheckedChange(event, `sales[${index + 1}].checked`)
            }
            onHeaderChange={(event) => {
              handleCheckedChange(event, `sales[0].checked`)
              handleHeaderCheckedChange(event, data.sales.length, "sales")
            }}
            headerValue={data.sales.slice(1).some((value) => !!value.checked)}
          />
          <AccessModule
            options={data.inventory.slice(1)}
            headerLabel="Inventory"
            onCheckChange={(event, index) =>
              handleCheckedChange(event, `inventory[${index + 1}].checked`)
            }
            onHeaderChange={(event) => {
              handleCheckedChange(event, `inventory[0].checked`)
              handleHeaderCheckedChange(
                event,
                data.inventory.length,
                "inventory"
              )
            }}
            headerValue={data.inventory.slice(1).some((value) => !!value.checked)}
            onChildCheckChange={(event, index, childIndex) =>
              handleCheckedChange(
                event,
                `inventory[${index + 1}].children[${childIndex}].checked`,
                function callback(newData) {
                  if (!newData.inventory[index + 1].children) {
                    return;
                  }
                  const isSemuaChildUncheck = newData.inventory[index + 1].children.every(ch => !ch.checked);
                  if (isSemuaChildUncheck) {
                    set(newData, `inventory[${index + 1}].checked`, false);
                    setData(newData);
                  } else {
                    set(newData, `inventory[${index + 1}].checked`, true);
                    setData(newData);
                  }
                }
              )
            }
            onChildHeaderChange={(event, index) => {
              handleCheckedChange(event, `inventory[${index + 1}].checked`);
              handleHeaderCheckedChange(
                event,
                get(data, `inventory[${index + 1}].children`, []).length,
                `inventory[${index + 1}].children`
              )
            }}
            childHeaderValue={(index) =>
              data.inventory[index + 1].children.some((value) => !!value.checked)
            }
            onSecondChildCheckChange={(
              event,
              index,
              childIndex,
              secondChildIndex
            ) =>
              handleCheckedChange(
                event,
                `inventory[${index + 1}].children[${childIndex}].children[${secondChildIndex}].checked`
              )
            }
            onSecondChildHeaderChange={(event, index, childIndex) =>
              handleHeaderCheckedChange(
                event,
                get(
                  data,
                  `inventory[${index + 1}].children[${childIndex}].children`,
                  []
                ).length,
                `inventory[${index + 1}].children[${childIndex}].children`
              )
            }
            secondChildHeaderValue={(index, childIndex) =>
              data.inventory[index + 1].children[childIndex].children.some(
                (value) => !!value.checked
              )
            }
          />
          <AccessModule
            options={data.adjustmentStock.slice(1)}
            headerLabel="Adjustment Stock"
            onCheckChange={(event, index) =>
              handleCheckedChange(event, `adjustmentStock[${index + 1}].checked`)
            }
            onHeaderChange={(event) => {
              handleCheckedChange(event, `adjustmentStock[0].checked`)
              handleHeaderCheckedChange(
                event,
                data.adjustmentStock.length,
                "adjustmentStock"
              )
            }}
            headerValue={data.adjustmentStock.slice(1).some((value) => !!value.checked)}
            onChildCheckChange={(event, index, childIndex) =>
              handleCheckedChange(
                event,
                `adjustmentStock[${index + 1}].children[${childIndex}].checked`,
                function callback(newData) {
                  if (!newData.adjustmentStock[index + 1].children) {
                    return;
                  }
                  const isSemuaChildUncheck = newData.adjustmentStock[index + 1].children.every(ch => !ch.checked);
                  if (isSemuaChildUncheck) {
                    set(newData, `adjustmentStock[${index + 1}].checked`, false);
                    setData(newData);
                  } else {
                    set(newData, `adjustmentStock[${index + 1}].checked`, true);
                    setData(newData);
                  }
                }
              )
            }
            onChildHeaderChange={(event, index) => {
              handleCheckedChange(event, `adjustmentStock[${index + 1}].checked`);
              handleHeaderCheckedChange(
                event,
                get(data, `adjustmentStock[${index + 1}].children`, []).length,
                `adjustmentStock[${index + 1}].children`
              )
            }}
            childHeaderValue={(index) =>
              data.adjustmentStock[index + 1].children.some(
                (value) => !!value.checked
              )
            }
            onSecondChildCheckChange={(
              event,
              index,
              childIndex,
              secondChildIndex
            ) =>
              handleCheckedChange(
                event,
                `adjustmentStock[${index + 1}].children[${childIndex}].children[${secondChildIndex}].checked`
              )
            }
            onSecondChildHeaderChange={(event, index, childIndex) =>
              handleHeaderCheckedChange(
                event,
                get(
                  data,
                  `adjustmentStock[${index + 1}].children[${childIndex}].children`,
                  []
                ).length,
                `adjustmentStock[${index + 1}].children[${childIndex}].children`
              )
            }
            secondChildHeaderValue={(index, childIndex) =>
              data.adjustmentStock[index + 1].children[childIndex].children.some(
                (value) => !!value.checked
              )
            }
          />
        </Grid>
        <Grid item xs={6} className={classes.itemGridRight}>
          <AccessModule
            options={data.report.slice(1)}
            headerLabel="Laporan"
            onCheckChange={(event, index) =>
              handleCheckedChange(event, `report[${index + 1}].checked`)
            }
            onHeaderChange={(event) => {
              handleCheckedChange(event, `report[0].checked`)
              handleHeaderCheckedChange(event, data.report.length, "report")
            }}
            headerValue={data.report.slice(1).some((value) => !!value.checked)}
          />
          <AccessModule
            options={data.setting.slice(1)}
            headerLabel="Setting"
            onCheckChange={(event, index) =>
              handleCheckedChange(event, `setting[${index + 1}].checked`)
            }
            onHeaderChange={(event) =>
              handleHeaderCheckedChange(event, data.setting.length, "setting")
            }
            onChildCheckChange={(event, index, childIndex) =>
              handleCheckedChange(
                event,
                `setting[${index + 1}].children[${childIndex}].checked`,
                function callback(newData) {
                  if (!newData.setting[index + 1].children) {
                    return;
                  }
                  const isSemuaChildUncheck = newData.setting[index + 1].children.every(ch => !ch.checked);
                  if (isSemuaChildUncheck) {
                    set(newData, `setting[${index + 1}].checked`, false);
                    setData(newData);
                  } else {
                    set(newData, `setting[${index + 1}].checked`, true);
                    setData(newData);
                  }
                }
              )
            }
            onChildHeaderChange={(event, index) => {
              handleCheckedChange(event, `setting[${index + 1}].checked`);
              handleHeaderCheckedChange(
                event,
                get(data, `setting[${index + 1}].children`, []).length,
                `setting[${index + 1}].children`
              )
            }}
            childHeaderValue={(index) =>
              data.setting[index + 1].children.some((value) => !!value.checked)
            }
            headerValue={data.setting.slice(1).some((value) => !!value.checked)}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => history.push(routes.ACCESS_RIGHT)}
            >
              Batalkan
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              onClick={saveAccessRight}
            >
              Simpan
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddAccessRight;
