import React from "react";
import ReactDOM from "react-dom";
import LogRocket from 'logrocket';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (window.location.hostname !== 'localhost' &&
  window.location.pathname !== '/moon' &&
  window.location.hostname !== 'devwarehouse.reqaid.com' &&
  window.localStorage.getItem('moon') === null) { // run logrocket when not in development environment
  LogRocket.init('6snuof/fdc');
  }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
