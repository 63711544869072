import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styled from "@material-ui/core/styles/styled";

const BaseFilterContainer = styled(Grid)(({ theme }) => ({
  borderBottomColor: theme.palette.normalTextfield,
  borderBottom: "1px solid",
  height: 100,
  paddingTop: 8,
}));

export function FilterContainer({ children }) {
  return (
    <BaseFilterContainer
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
    >
      <Grid
        item
        container
        xs={12}
        style={{ paddingTop: 10 }}
      >
        {children}
      </Grid>
    </BaseFilterContainer>
  );
}

const HeaderTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.blue,
}), { withTheme: true });

const HeaderContainer = styled(Grid)(({ theme }) => ({
  height: 60,
  borderBottomColor: theme.palette.normalTextfield,
  borderTopColor: theme.palette.normalTextfield,
  borderTop: "1px solid",
  borderBottom: "1px solid",
}), { withTheme: true });

export function PenerimaanTitle({ children }) {
  return (
    <HeaderContainer container>
      <Grid item container xs={12} alignItems="center">
        <HeaderTitle
          variant="h2"
          color="textSecondary"
        >
          {children}
        </HeaderTitle>
      </Grid>
    </HeaderContainer>
  );
}

export const MarginContainer = styled((props) => <div {...props} />)({
  marginLeft: 20,
})

export const Container = styled((props) => <div {...props} />)({
  margin: 20,
})
