import React, { useEffect, useState, useRef } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import debounce from 'awesome-debounce-promise';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import styled from '@material-ui/core/styles/styled';
import routes, { penerimaanRoutes } from '../../constants/routes';

import { getSpecificStockExpires } from "../../services/InventoryService";
import { getListReturn, processReturn, processReturnRusak, rejectReturn,
  rejectReturnBarangRusak
 } from '../../services/PenerimaanService';
import { getListDepots } from '../../services/DepotService';

import useSnackbar from '../../utils/useSnackbar';
import useCekHakAkses from "../../utils/cekHakAkses";
import CustomDialog from "../custom/CustomDialog";
import { palette } from "../../theme";

import CustomBreadcrumbs from '../custom/CustomBreadcrumbs';

import { Container } from './FilterPenerimaan';
import CustomAutocomplete from "../custom/CustomAutocomplete";
import CustomDatePicker from "../custom/CustomDatePicker";

const Title = styled(Typography)({
  lineHeight: "60px",
  fontWeight: "bold",
})

const debounceGetListReturn = debounce(getListReturn);

export default function ReturnItem() {
  const history = useHistory();
  const { receiveId } = useParams();
  const snackbar = useSnackbar();

  const [itemsReturn, setItemsReturn] = useState([]);
  const [isDetail, setIsDetail] = useState(true);
  const [searchList, setSearchList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isDamaged, setIsDamaged] = useState(false);
  const itemMeta = useRef(null);
  const cekRole = useCekHakAkses();
  useEffect(() => {
    async function fetch() {
      const { data } = await debounceGetListReturn({
        skip: 0,
        limit: 1,
        codeReturn: receiveId,
      });
      if (data.items.length === 0) return;

      if (data.items[0].Items.length !== 0) {
        setIsDamaged(false);
        const promiseItem = data.items[0].Items.map(async item => {
          const { data: { items: listExpire } } = await getSpecificStockExpires(
            item.itemId,
            0,
            1000,
          );
          
  
          const indexMatch = listExpire.findIndex(itemExpire => {
            return itemExpire.batchNo === item.batchNo;
          });
  
          if (indexMatch === -1) {
            return {
              ...item,
              isMatchExpire: false,
              listExpire,
            };
          }
  
          return {
            ...item,
            isMatchExpire: true,
            depotName: listExpire[indexMatch].depotName,
            expireId: listExpire[indexMatch]._id,
            expireDate: new Date(listExpire[indexMatch].expiredDate),
            isOld: true,
          };
        });

      itemMeta.current = data.items[0];
      setIsDetail(data.items[0].status === 'received');

      setItemsReturn(await Promise.all(promiseItem));
      } else {
      setIsDamaged(true);
      const promiseItemDamaged = data.items[0].ItemsDemage.map(async item => {
        const { data: { items: listExpire } } = await getSpecificStockExpires(
          item.itemId,
          0,
          1000,
        );

        const indexMatch = listExpire.findIndex(itemExpire => {
          return itemExpire.batchNo === item.batchNo;
        });

        if (indexMatch === -1) {
          return {
            ...item,
            isMatchExpire: false,
            listExpire,
          };
        }

        return {
          ...item,
          isMatchExpire: true,
          depotName: listExpire[indexMatch].depotName,
          expireId: listExpire[indexMatch]._id,
          expireDate: new Date(listExpire[indexMatch].expiredDate),
          isOld: true,
        };
      });
      itemMeta.current = data.items[0];
      setIsDetail(data.items[0].status === 'received');

      setItemsReturn(await Promise.all(promiseItemDamaged));
    }
    }
    fetch();
  }, [receiveId]);

  const [listDepot, setListDepot] = useState([]);

  useEffect(() => {
    function fetch() {
      getListDepots(null, 0 , 9999)
        .then(({ data }) => {
          setListDepot(data.items);
        });
    }
    fetch();
  }, [])
    
  const changeBatchNo = itemIndex => value => {
    setItemsReturn(items => {
      const clone = [...items];
      if (value === "" || value === undefined || value === null) {
        return clone;
      }
      console.log(items)
      const selectedItemExpire = clone[itemIndex].listExpire
        .find(itemExpire => itemExpire.batchNo === value.batchNo);
      if (!selectedItemExpire) {
        clone[itemIndex].batchNo = value.batchNo;
        clone[itemIndex].isOld = false;
        return clone;
      };
      clone[itemIndex].batchNo = selectedItemExpire.batchNo;
      clone[itemIndex].depotName = selectedItemExpire.depotName;
      clone[itemIndex].expireId = selectedItemExpire._id;
      clone[itemIndex].expireDate = new Date(selectedItemExpire.expiredDate);
      clone[itemIndex].isOld = true;
      return clone;
    })
  }

  const changeExpireDate = itemIndex => date => {
    setItemsReturn(items => {
      const clone = [...items];
      clone[itemIndex].expireDate = new Date(date);
      return clone;
    });
  }

  const changeDepotName = itemIndex => ({ target: { value: depot } }) => {
    setItemsReturn(items => {
      const clone = [...items];
      const depotData = listDepot.find(d => d.name === depot);
      clone[itemIndex].depotName = depot;
      clone[itemIndex].depotId = depotData.id;
      return clone;
    });
  }

  const handleSave = () => {
    const returnRequestId = itemMeta.current._id;
    const isHasNoDepot = itemsReturn.some(item => !item.depotName);
    if (isHasNoDepot) {
      snackbar.setError('Nomor Batch / Depot harus diisi');
      return;
    }
    const isHasNoExpire = itemsReturn.some(item => !item.expireDate);
    if (isHasNoExpire) {
      snackbar.setError('Tanggal Expire harus diisi');
      return;
    }
    if (isDamaged) {
      const ItemsDemage = itemsReturn.map(item => ({
        quantity: item.quantityRequest,
        itemId: item.itemId,
        id: item._id,
        expireId: item.expireId,
        batchNo: item.batchNo,
        expireDate: item.expireDate,
        placeId: item.placeId,
        depotId: item.depotId,
        depotName: item.depotName,
      }));
      processReturnRusak({ returnRequestId, ItemsDemage })
      .then(() => {
      snackbar.setSuccess('Sukses! Penerimaan return barang rusak berhasil dilakukan');
      history.push('/penerimaan/return')});
    } else {
      const items = itemsReturn.map(item => ({
        quantity: item.quantityRequest,
        itemId: item.itemId,
        id: item._id,
        expireId: item.expireId,
        batchNo: item.batchNo,
        expireDate: item.expireDate,
        placeId: item.placeId,
        depotId: item.depotId,
        depotName: item.depotName,
      }));
    processReturn({ returnRequestId, items })
    .then(() => {
    snackbar.setSuccess('Sukses! Penerimaan return barang berhasil dilakukan');
    history.push('/penerimaan/return')});
    }
  }
  const handleReject = () => {
    setIsFetching(true);
    if (isDamaged) {
      rejectReturnBarangRusak({ id: itemMeta.current._id })
      .then(() => {
      snackbar.setSuccess('Sukses! Penerimaan return barang rusak berhasil direject');
      history.push('/penerimaan/return')});
      setIsFetching(false);
    } else {
      rejectReturn({ id: itemMeta.current._id })
      .then(() => {
      snackbar.setSuccess('Sukses! Penerimaan return barang berhasil direject');
      history.push('/penerimaan/return')});
      setIsFetching(false);
    }
  }
  return (
    <Container>
      <CustomBreadcrumbs
        currentPageName={`Retur ${receiveId}`}
        previousPageName="Penerimaan Return Barang Barang"
        previousPageLink={routes.PENERIMAAN + penerimaanRoutes.RETURN}
      />
      <Title variant="body1">Return Barang dari {receiveId}</Title>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nama barang</TableCell>
            <TableCell>Jumlah Return</TableCell>
            <TableCell>Nomor Batch</TableCell>
            <TableCell>Tanggal kadaluarsa</TableCell>
            <TableCell>Depot</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemsReturn && itemsReturn.map((itemReturn, itemIndex) => (
            <TableRow key={itemReturn._id}>
              <TableCell>{itemReturn.name}</TableCell>
              <TableCell>{itemReturn.quantityRequest}</TableCell>
              <TableCell>
                {
                  itemReturn.isMatchExpire
                    ? itemReturn.batchNo
                    : (
                      <CustomAutocomplete
                        options={itemReturn.listExpire}
                        onInputChange={(evt, newInputValue) => {
                          setSearchList(slist => {
                            const copy = [...slist];
                            copy[itemIndex] = newInputValue;
                            return copy;
                          });
                        }}
                        getOptionLabel={(option) => option.batchNo}
                        getOptionSelected={(option, value) =>
                          option.batchNo === value.batchNo
                        }
                        onChange={(event, value, reason, details) => {
                          changeBatchNo(itemIndex)(value);
                        }}
                        getOptionListLabel={(option) => option.batchNo}
                        inputValue={searchList[itemIndex]}
                        label="Nomor Batch"
                        filterOptions={(options, params) => {
                          const filtered = [...options];
                          if (params.inputValue !== "") {
                            filtered.push({
                              inputValue: params.inputValue,
                              batchNo: params.inputValue,
                            });
                          }
                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                      />
                    )
                }
              </TableCell>
              <TableCell>
                <CustomDatePicker
                  disabled={itemReturn.isOld}
                  label="Tanggal Kadaluarsa"
                  date={itemReturn.expireDate}
                  placeholder="Set Tanggal"
                  onChange={changeExpireDate(itemIndex)}
                />
              </TableCell>
              <TableCell>
                {
                  itemReturn.isOld
                    ? (
                      itemReturn.depotName
                    ) : (
                      <Select onChange={changeDepotName(itemIndex)} value={itemReturn.depotName}>
                        {listDepot.map(depot => (
                          <MenuItem key={depot.id} value={depot.name}>
                            {depot.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <br />
      {!isDetail && (
        <Grid container justify="flex-end">
          <Grid item xs={12} md={3}>
            <ButtonGroup>
              <Button
                onClick={() => history.push('/penerimaan/return')}
              >
                Batal
              </Button>
              {cekRole('itemRetrieval.b12') &&
                <Button
                  style={{ backgroundColor: 'red', color: 'white' }}
                  // onClick={handleReject}
                  onClick={() => setOpen(true)}
                >
                  Reject
                </Button>
              }
              <Button
                onClick={handleSave}
                style={{ backgroundColor: 'blue', color: 'white' }}
                disabled={!cekRole('itemRetrieval.b9')}
              >
                Simpan
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      )}
      <CustomDialog
        open={open}
        handleClose={() => setOpen(false)}
        title="Reject data return"
        body="Apakah Anda yakin melakukan reject data return?"
        onCancel={() => setOpen(false)}
        cancelButtonText="Batalkan"
        onAccept={isFetching ? null : handleReject}
        acceptButtonText="Reject"
        acceptButtonColor={palette.red}
      />
    </Container>
  );
}
