import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  makeStyles,
  Grid,
  Button,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import routes, { inventoryRoutes } from "../../constants/routes";

import validations from "../../utils/validations";
import { formatPayloadInt } from "../../utils/formatting";
import useCekHakAkses from "../../utils/cekHakAkses";

import { editInventory, getItemDetails } from "../../services/InventoryService";
import { getListCategory } from "../../services/CategoryService";

import { setKode } from "../../store/inventory/actions";
import { setSnackbar } from "../../store/general/actions";

import CustomTextField from "../custom/CustomTextField";
import CustomSelect from "../custom/CustomSelect";

const useStyles = makeStyles((theme) => ({
  root: {},
  editGrid: {
    display: "flex",
    flexWrap: "wrap",
  },
  marginContainerLeft: {
    margin: "0 10px",
  },
  buttonGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  detailGrid: {
    marginTop: 20,
  },
  unitTextField: {
    width: 240,
  },
  equal: {
    width: 40,
    marginLeft: 8,
  },
  equal2: {
    width: 40,
    marginLeft: 8,
    padding: 0,
    fontSize: 48,
  },
}));

const tipeSelect = ["BHP", "Obat", "Logistik"];

const subCategorySelect = [
  "Aksesoris",
  "Clinpro",
  "General",
  "Member",
  "Rontgen",
];

const tambahBarangDefault = {
  code: "",
  name: "",
  type: "",
  category: "",
  subCategory: "",
  bigUnit: "",
  unitPrecision: "",
  smallUnit: "",
  stock: "",
  stockWarning: "",
  stockBuffer: "",
  basicFee: "",
  sellFee: "",
  buyFee: "",
  hna: "",
  hnaPPN: "",
  isActive: true,
  marginRp: "",
  marginPercent: "",
  isClinicAdd: true,
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DataBarangSatuan = ({ setKode, setSnackbar }) => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = useCekHakAkses();
  const { itemID } = useParams();
  const [tambahBarang, setTambahBarang] = useState({ ...tambahBarangDefault });
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const source = useQuery().get("source");

  useEffect(() => {
    getItemDetails(itemID).then((res) => setTambahBarang(res.data.item));
    getListCategory("", 0, 0).then((res) => {
      setCategories(res.data.items.map((item) => item.name));
    });
  }, [itemID]);

  useEffect(() => {
    setKode(tambahBarang.code);
  }, [tambahBarang.code, setKode]);

  useEffect(() => {
    if (tambahBarang.sellFee) {
      setTambahBarang((d) => ({
        ...d,
        marginRp: tambahBarang.sellFee - tambahBarang.hnaPPN,
        marginPercent:
          ((tambahBarang.sellFee - tambahBarang.hnaPPN) / tambahBarang.hnaPPN) *
          100,
      }));
    }
  }, [tambahBarang.sellFee, tambahBarang.hnaPPN]);

  const checkError = () => {
    const name = validations.isEmpty(tambahBarang.name);
    const type = validations.isEmpty(tambahBarang.type);
    const bigUnit = validations.isEmpty(tambahBarang.bigUnit);
    const unitPrecision = validations.isEmpty(tambahBarang.unitPrecision);
    const smallUnit = validations.isEmpty(tambahBarang.smallUnit);
    setError({ name, type, bigUnit, unitPrecision, smallUnit });
    return name || type || bigUnit || unitPrecision || smallUnit;
  };

  const handleTambahBarang = (name) => (event) => {
    const { value } = event.target;
    setTambahBarang({ ...tambahBarang, [name]: value });
  };

  const handleChange = (name) => (event) => {
    setTambahBarang({ ...tambahBarang, [name]: event.target.checked });
  };

  const handleSaveItems = () => {
    if (checkError()) {
      return;
    }
    setIsFetching(true);
    const newTambahBarang = {
      ...tambahBarang,
      unitPrecision: parseFloat(tambahBarang.unitPrecision),
    }
    editInventory(
      formatPayloadInt(newTambahBarang, [
        // "unitPrecision",
        "stock",
        "stockWarning",
        "stockBuffer",
        "basicFee",
        "sellFee",
        "buyFee",
        "hna",
        "hnaPPN",
        "marginRp",
        "marginPercent",
      ]),
      itemID
    ).then((res) => {
      setSnackbar("success", "Sukses! Data telah disimpan");
      history.push(
        routes.INVENTORY +
          (source === "DataBarang"
            ? inventoryRoutes.DATA_BARANG
            : inventoryRoutes.DATA_BARANG_DETAILS)
      );
    });
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.detailGrid}
      >
        <Grid item xs={12} className={classes.editGrid}>
          <div className={classes.marginContainerLeft}>
            <CustomTextField label="Kode" value={tambahBarang.code} disabled />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Nama Barang"
              value={tambahBarang.name}
              onChange={handleTambahBarang("name")}
              requiredField
              error={error.name && validations.isEmpty(tambahBarang.name)}
              helperText={
                error.name && validations.isEmpty(tambahBarang.name)
                  ? "Nama Barang tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomSelect
              style={{ marginRight: 20 }}
              value={tambahBarang.type}
              label="Berdasarkan Tipe"
              onChange={handleTambahBarang("type")}
              requiredField
              error={error.type && validations.isEmpty(tambahBarang.type)}
              helperText={
                error.type && validations.isEmpty(tambahBarang.type)
                  ? "Tipe tidak boleh kosong"
                  : " "
              }
            >
              {tipeSelect.map((tipe) => (
                <MenuItem key={tipe} value={tipe}>
                  {tipe}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div className={classes.marginContainerLeft}>
            <CustomSelect
              value={tambahBarang.category}
              label="Kategori"
              onChange={handleTambahBarang("category")}
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomSelect
              style={{ marginRight: 20 }}
              value={tambahBarang.subCategory}
              label="Sub - Category"
              onChange={handleTambahBarang("subCategory")}
              disabled
            >
              {subCategorySelect.map((subCategory) => (
                <MenuItem key={subCategory} value={subCategory}>
                  {subCategory}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div className={classes.marginContainerLeft}>
            <InputLabel required>{"Unit Besar"}</InputLabel>
            <OutlinedInput
              value={tambahBarang.bigUnit}
              onChange={handleTambahBarang("bigUnit")}
              error={error.bigUnit && validations.isEmpty(tambahBarang.bigUnit)}
              className={classes.unitTextField}
            />
            <FormHelperText
              error={error.bigUnit && validations.isEmpty(tambahBarang.bigUnit)}
              style={{ paddingLeft: 0 }}
            >
              {error.bigUnit && validations.isEmpty(tambahBarang.bigUnit)
                ? "Unit Besar tidak boleh kosong"
                : " "}
            </FormHelperText>
          </div>
          <div>
            <InputLabel style={{ height: 12 }}></InputLabel>
            <OutlinedInput
              value="="
              disabled
              className={classes.equal}
              classes={{
                input: classes.equal2,
              }}
            />
            <FormHelperText> </FormHelperText>
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Presisi Unit"
              requiredField
              value={tambahBarang.unitPrecision}
              type="number"
              onChange={handleTambahBarang("unitPrecision")}
              error={
                error.unitPrecision &&
                validations.isEmpty(tambahBarang.unitPrecision)
              }
              helperText={
                error.unitPrecision &&
                validations.isEmpty(tambahBarang.unitPrecision)
                  ? "Presisi Unit tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Unit Kecil"
              requiredField
              value={tambahBarang.smallUnit}
              onChange={handleTambahBarang("smallUnit")}
              error={
                error.smallUnit && validations.isEmpty(tambahBarang.smallUnit)
              }
              helperText={
                error.smallUnit && validations.isEmpty(tambahBarang.smallUnit)
                  ? "Unit Kecil tidak boleh kosong"
                  : " "
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Jumlah Stok Satuan Besar"
              value={tambahBarang.stock}
              onChange={handleTambahBarang("stock")}
              endIcon={tambahBarang.bigUnit}
              disabled
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Jumlah Stok Satuan Kecil"
              value={tambahBarang.stock * tambahBarang.unitPrecision}
              disabled
              endIcon={tambahBarang.smallUnit}
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Minimum Stok"
              value={tambahBarang.stockWarning}
              requiredField
              onChange={handleTambahBarang("stockWarning")}
              error={
                error.stockWarning &&
                validations.isEmpty(tambahBarang.stockWarning)
              }
              helperText={
                error.stockWarning &&
                validations.isEmpty(tambahBarang.stockWarning)
                  ? "Minimum Stock tidak boleh kosong"
                  : " "
              }
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Buffer Stok"
              value={tambahBarang.stockBuffer}
              requiredField
              onChange={handleTambahBarang("stockBuffer")}
              error={
                error.stockBuffer &&
                validations.isEmpty(tambahBarang.stockBuffer)
              }
              helperText={
                error.stockBuffer &&
                validations.isEmpty(tambahBarang.stockBuffer)
                  ? "Stock Buffer tidak boleh kosong"
                  : " "
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Harga Dasar"
              value={tambahBarang.basicFee}
              onChange={handleTambahBarang("basicFee")}
              currency
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Harga Beli"
              value={tambahBarang.buyFee}
              onChange={handleTambahBarang("buyFee")}
              disabled
              currency
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomSelect
              value={tambahBarang.isActive}
              label="Status Aktif"
              disabled
              onChange={handleTambahBarang("isActive")}
            >
              <MenuItem value={true}>Aktif</MenuItem>
              <MenuItem value={false}>Tidak Aktif</MenuItem>
            </CustomSelect>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="HNA"
              value={tambahBarang.hna}
              onChange={handleTambahBarang("hna")}
              currency
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="HNA + PPN"
              value={tambahBarang.hnaPPN}
              onChange={handleTambahBarang("hnaPPN")}
              currency
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Harga Jual"
              value={tambahBarang.sellFee}
              onChange={handleTambahBarang("sellFee")}
              currency
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Margin Rp"
              value={tambahBarang.marginRp}
              onChange={handleTambahBarang("marginRp")}
              currency
            />
          </div>
          <div className={classes.marginContainerLeft}>
            <CustomTextField
              label="Margin %"
              value={tambahBarang.marginPercent}
              onChange={handleTambahBarang("marginPercent")}
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.editGrid}>
        <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  value={tambahBarang.isClinicAdd}
                  checked={tambahBarang.isClinicAdd}
                  onChange={handleChange("isClinicAdd")}
                  name="isClinicAdd"
                />
              }
              label="Connect To Clinic"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} className={classes.buttonGrid}>
          <div className={classes.marginContainerLeft}>
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() =>
                history.push(
                  routes.INVENTORY +
                    (source === "DataBarang"
                      ? inventoryRoutes.DATA_BARANG
                      : inventoryRoutes.DATA_BARANG_DETAILS)
                )
              }
            >
              {"Batalkan"}
            </Button>
            {((source === "DataBarang" && cekRole("inventory.f5")) ||
              (source === "DataBarangDetails" && cekRole("inventory.f26"))) && (
              <Button
                disableElevation
                variant="contained"
                size="large"
                onClick={isFetching ? null  : handleSaveItems}
              >
                {isFetching ? <CircularProgress /> : "Simpan"}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default connect(null, { setKode, setSnackbar })(DataBarangSatuan);
