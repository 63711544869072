import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import MenuItem from "@material-ui/core/MenuItem";
import TableRow from "@material-ui/core/TableRow";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import { palette } from "../../theme";
import CustomTextField from "../custom/CustomTextField";
import CustomPagination from "../custom/CustomPagination";
import CustomDropdownButton from "../custom/CustomDropdownButton";
import CustomRangeDatePicker from "../custom/CustomRangeDatePicker";
import { getSupplierPrice } from "../../services/InventoryService";

import debounce from "awesome-debounce-promise";
import { useParams, useLocation } from "react-router-dom";

import moment from "moment";
import formatting, { paramDateFormat } from "../../utils/formatting";
import exportXL from "../../utils/exportXL";
import Print from "../../utils/Print";
import useCekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {},
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  editButton: {
    float: "right",
    marginLeft: 5,
    color: palette.blue,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  topGrid: {
    display: "flex",
    flexWrap: "wrap",
  },
  marginContainer: {
    margin: "0 20px",
  },
  alignRight: {
    textAlign: "right",
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debouncedGetSupplierPrice = debounce(getSupplierPrice, 500);

const HargaBeliPerSupplier = (props) => {
  const classes = useStyles();
  const [searchForm, setSearchForm] = useState({
    startDate: moment().subtract(30, "days").toDate(),
    endDate: new Date(),
  });
  const [pagination, setPagination] = useState(paginationInitialData);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const cekRole = useCekHakAkses();
  const { search } = useLocation();
  const q = new URLSearchParams(search);
  const source = q.get('source');

  const { itemID } = useParams();

  useEffect(() => {
    setIsFetchingData(true);
    debouncedGetSupplierPrice(
      searchForm.search,
      itemID,
      paramDateFormat(searchForm.startDate),
      paramDateFormat(searchForm.endDate),
      pagination.page,
      pagination.rowsPerPage
    ).then((res) => {
      setTotalData(res.data.count);
      setTableData(res.data.items);
      setExportData([]);
    }).finally(() => setIsFetchingData(false));
  }, [searchForm, pagination, itemID]);

  const getDataExport = async () => {
    let page = 0;
    let skip = 0;
    const limit = 10;
    const fetch = async () => {
      const result = await getSupplierPrice(
        searchForm.search,
        itemID,
        paramDateFormat(searchForm.startDate),
        paramDateFormat(searchForm.endDate),
        page,
        limit
      );
      exportData.splice(skip * limit, limit, ...result.data.items);
      setExportData(exportData);
    };
    if (exportData.length >= totalData) return;
    while (skip < totalData) {
      await fetch();
      page += 1;
      skip += limit;
    }
  };

  const handleExport = () => {
    setIsFetching(true);
    try {
      getDataExport().then(() => {
        const hasil = exportData.map((val) => ({
          "Nama Supplier": val.supplierName,
          "Harga Beli": formatting.currencyFormat(val.buyFee),
          "Harga Beli - Diskon": formatting.currencyFormat(val.buyFeeDiscount),
          "Harga Beli (-Diskon) + PPN": formatting.currencyFormat(val.buyFeeFinal),
          Tanggal: formatting.dateFormat(val.createdDate),
        }));
        exportXL("Export Harga Beli per Supplier", hasil);
        setIsFetching(false);
      });
    } catch (e) {
      console.error(e);
      setIsFetching(false);
    }
  };

  const handlePrint = () => {
    setIsFetching(true);
    try {
      getDataExport()
        .then(() => {
          setIsPrint(true);
          window.print();
          setIsFetching(false);
        })
        .finally(() => {
          setIsPrint(false);
          setExportData([]);
          setIsFetching(false);
        })
        .catch((e) => {
          console.error(e);
          setIsPrint(false);
          setIsFetching(false);
        });
    } catch (e) {
      console.error(e);
      setIsPrint(false);
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid
          item
          xs={12}
          md={9}
          style={{ paddingTop: 10 }}
          className={classes.topGrid}
        >
          <div>
            <CustomTextField
              disabled={isFetching}
              label="Berdasarkan Nama Supplier"
              placeholder="Nama Supplier"
              value={searchForm.search}
              onChange={(event) =>
                setSearchForm({ ...searchForm, search: event.target.value })
              }
              startIcon={<PersonOutlineIcon />}
            />
          </div>
          <div className={classes.marginContainer}>
            <CustomRangeDatePicker
              disabled={isFetching}
              label="Berdasarkan Tanggal"
              startDate={searchForm.startDate}
              endDate={searchForm.endDate}
              onChange={([start, end]) =>
                setSearchForm({ ...searchForm, startDate: start, endDate: end })
              }
              placeholder="Berdasarkan Tanggal"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={3} className={classes.alignRight}>
          <div style={{ paddingTop: 20 }}>
            {isFetching ?
              <CircularProgress />
            : <CustomDropdownButton label="Export">
                {
                  ((source === 'DataBarang' && (cekRole('inventory.f8')))
                    || (source === 'DataBarangDetails' && (cekRole('inventory.f29')))
                  ) &&
                  <MenuItem
                    value={1}
                    onClick={handleExport}
                    selected
                  >
                    Export
                  </MenuItem>
                }
                {
                  ((source === 'DataBarang' && (cekRole('inventory.f10')))
                    || (source === 'DataBarangDetails' && (cekRole('inventory.f31')))
                  ) &&
                    <MenuItem
                      value={2}
                      onClick={handlePrint}
                    >
                      Print
                    </MenuItem>
                }
              </CustomDropdownButton>
            }
          </div>
        </Grid>
        <Grid item xs={12}>
          <Print style={{ overflow: "auto" }}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerLeft}>
                    Nama Supplier
                  </TableCell>
                  <TableCell>Harga Beli</TableCell>
                  <TableCell>Harga Beli - Diskon</TableCell>
                  <TableCell>Harga Beli (-Diskon) + PPN</TableCell>
                  <TableCell className={classes.headerRight}>Tanggal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  isFetchingData
                    ? <TableRow>
                      <TableCell colSpan={5} align={'center'}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                    : !isPrint && tableData.length === 0
                    ? <TableRow>
                      <TableCell colSpan={5} align={'center'}>
                        <p>Tidak ada data yang bisa ditampilkan.</p>
                      </TableCell>
                    </TableRow>
                    : !isPrint && tableData.map((row) => (
                      <TableRow key={row._id}>
                        <TableCell>{row.supplierName}</TableCell>
                        <TableCell>
                          {formatting.currencyFormat(row.buyFee)}
                        </TableCell>
                        <TableCell>
                          {formatting.currencyFormat(row.buyFeeDiscount)}
                        </TableCell>
                        <TableCell>
                          {formatting.currencyFormat(row.buyFeeFinal)}
                        </TableCell>
                        <TableCell>
                          {formatting.dateFormat(row.createdDate)}
                        </TableCell>
                      </TableRow>
                    ))
                }
                {isPrint &&
                  exportData.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell>{row.supplierName}</TableCell>
                      <TableCell>
                        {formatting.currencyFormat(row.buyFee)}
                      </TableCell>
                      <TableCell>
                        {formatting.currencyFormat(row.buyFeeDiscount)}
                      </TableCell>
                      <TableCell>
                        {formatting.currencyFormat(row.buyFeeFinal)}
                      </TableCell>
                      <TableCell>
                        {formatting.dateFormat(row.createdDate)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Print>
          <CustomPagination
            page={pagination.page}
            onChangePage={(event, newPage) =>
              setPagination({ ...pagination, page: newPage })
            }
            rowsPerPage={pagination.rowsPerPage}
            onChangeRowsPerPage={(event) =>
              setPagination({
                ...pagination,
                rowsPerPage: parseInt(event.target.value, 10),
              })
            }
            labelDisplayedRows={({ from, to, count }) =>
              from + "-" + to + " dari " + count + " Data"
            }
            count={totalData}
          />
        </Grid>
      </Grid>
    </div>
  );
};

HargaBeliPerSupplier.propTypes = {};

export default HargaBeliPerSupplier;
