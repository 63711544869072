import React from "react";
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import id from "date-fns/locale/id";
import moment from "moment";
import "moment/locale/id";
import CustomTextField from "./CustomTextField";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { makeStyles } from "@material-ui/core/styles";

const DATE_FORMAT = "DD/MM/YYYY"

const GetDatePickerValue = (props) => {
  moment.locale("id");
  let date = props.date ? moment(props.date).format(DATE_FORMAT) : '';

    if (date) {
      return date;
    }
  };

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "100%",
    "& .react-datepicker-wrapper": {
      width: "100%",
    },
  },
}));

const CustomDate = (props) => {
  const classes = useStyles();
  registerLocale("id", id);

  return (
    <div className={classes.inputField}>
      <DatePicker
        selected={props.date}
        onChange={(date) => props.onChange(date)}
        placeholderText={props.placeholder}
        locale={"id"}
        value={GetDatePickerValue(props)}
        disabled={props.disabled}
        showMonthDropdown
        showYearDropdown
        customInput={
          <CustomTextField
            requiredField={props.requiredField}
            label={props.label}
            error={props.error}
            disabled={props.disabled}
            helperText={props.helperText}
            className={classes.inputField}
            startIcon={<CalendarTodayIcon />}
          />
        }
      />
    </div>
  );
};

CustomDate.defaultProps = {
  helperText: " ",
  label: "",
  startIcon: null,
  endIcon: null,
};

CustomDate.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  startIcon: PropTypes.node,
};

export default CustomDate;
