import { getPlaceId } from "../store/auth/selector";
import agent from "./agent";

export const getListPO = (search, dateStart, dateEnd, skip, limit) => {
  return agent.get("/purchases/getList", {
    params: {
      filter: { placeId: getPlaceId(), search, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};

export const getDetailDO = (id) => {
  return agent.get(`/purchases/${id}/getDetail`, {});
};

export const generatePOCode = () => {
  return agent.get("/purchases/generateCode", {
    params: {
      filter: { placeId: getPlaceId() },
    },
  });
};

export const addPO = (body) => {
  return agent.post("/purchases/add", { ...body, placeId: getPlaceId() });
};

export const addItemPOWhenEdit = (body) => {
  return agent.post("/purchases/addItemPOWhenEdit", { ...body });
};
// ini untuk cek lock unlock dengan billing

export function lockPurchaseOrderForEditing({ poId }) {
  return agent.put('/purchases/lockPurchaseOrderForEditing', {
    poId,
  });
}

export function unlockPurchaseOrder({ poId }) {
  return agent.put('/purchases/unlockPurchaseOrder', {
    poId,
  });
}

export function getLockedStatus({ poId }) {
  if (poId) {
    return agent.get('/purchases/getLockedStatus', {
      params: {
        poId,
      },
    });
  }
  return new Promise(res =>
    res({
      data: {
        isLocked: false,
      },
    }),
  );
}

export const editPurchaseOrder = (body) => {
  return agent.post(`/purchases/editV2`, { ...body, placeId: getPlaceId() });
};
// id1: txPurcahse, id2: txPurchaseItem
export const deleteRowPO = (id1, id2, item) => {
  return agent.put(`/purchases/${id1}/deleteItemPO`, {
    id: id2,
    itemId: item,
  });
};
// ini untuk cek lock unlock dengan penerimaan barang
export function lockReceiveForEditing({ poId }) {
  return agent.put('/purchases/lockReceiveForEditing', {
    poId,
  });
}

export function unlockReceiveForEditing({ poId }) {
  return agent.put('/purchases/unlockReceiveForEditing', {
    poId,
  });
}

export function getLockedWhileEditingStatus({ poId }) {
  if (poId) {
    return agent.get('/purchases/getLockedWhileEditingStatus', {
      params: {
        poId,
      },
    });
  }
  return new Promise(res =>
    res({
      data: {
        isEditing: false,
      },
    }),
  );
}

