import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CustomTextField from "../custom/CustomTextField";
import SearchIcon from "@material-ui/icons/Search";
import CustomRangeDatePicker from "../custom/CustomRangeDatePicker";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CustomPagination from "../custom/CustomPagination";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router-dom";
import routes from "../../constants/routes";
import Print from '../../utils/Print';
// import { PDFViewer } from "@react-pdf/renderer";
import RequestOrderDocument from "../Document/RequestOrderDocument";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import moment from "moment";
import debounce from "awesome-debounce-promise";
import { getListRO, getDetailRO } from "../../services/RequestOrderService";
import CircularProgress from "@material-ui/core/CircularProgress";
import useCekHakAkses from "../../utils/cekHakAkses";
import usePdfPrint from "../../utils/usePdfPrint";
import { dateFormatShipping, statusFormat } from "../../utils/formatting";

const DATE_FORMAT = "YYYY-MM-DD";

const useStyles = makeStyles((theme) => ({
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
}));

const debouncedGetListRO = debounce(getListRO, 500);

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const Shipping = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = useCekHakAkses();

  const [searchForm, setSearchForm] = useState({
    startDate: moment().subtract(30, "days").toDate(),
    endDate: new Date(),
  });
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [pagination, setPagination] = useState(paginationInitialData);
  const [isFetching, setIsFetching] = useState(false);
  const { printPdfProps, setPdfProps } = usePdfPrint({
    frameName: 'my-frame-2',
  });

  useEffect(() => {
    setIsFetching(true);
    debouncedGetListRO(
      searchForm.search,
      "shipped",
      moment.isDate(searchForm.startDate)
        ? moment(searchForm.startDate).format(DATE_FORMAT)
        : undefined,
      moment.isDate(searchForm.endDate)
        ? moment(searchForm.endDate).format(DATE_FORMAT)
        : moment(searchForm.startDate).format(DATE_FORMAT),
      pagination.page,
      pagination.rowsPerPage
    ).then((res) => {
      setTotalData(res.data.count);
      setData(res.data.items);
      setIsFetching(false);
    });
  }, [searchForm, pagination]);

  const downloadDocument = useCallback(async (roNumber, id, full) => {
    // setup loading button
    const selectedIndex = data.findIndex(row => row['_id'] === id);
    setData(dataValue => {
      const copy = [...dataValue];
      if (full) {
        copy[selectedIndex].isLoadingDetail = true;
      } else {
        copy[selectedIndex].isLoadingNormal = true;
      }
      return copy;
    })

    // fetch RO
    const res = await getDetailRO(id);
    const arrOfItemsShipped = res.data.item.item.filter(f => f.quantityDeliver > 0).reduce((all, current, index) => {
      const batches = current.batchItems.map((item) => ({
        ...current,
        ...item,
        no: index + 1,
      }));

      return [...all, ...batches];
    }, []);
    setPdfProps({
      data: res.data.item,
      items: arrOfItemsShipped,
      full,
    }, () => {
      setData(dataValue => {
        const copy = [...dataValue];
        copy[selectedIndex].isLoadingNormal = false;
        copy[selectedIndex].isLoadingDetail = false;
        return copy;
      });
    });
  }, [data, setPdfProps]);

  return (
    <div>
      <Grid container className={classes.headerGrid}>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h2" color="textPrimary">
            Shipping
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid item xs={5} lg={3} style={{ paddingTop: 10 }}>
          <CustomTextField
            label="Cari Data Request Order"
            placeholder="No. RO atau Nama Client"
            value={searchForm.search}
            onChange={(event) =>
              setSearchForm({ ...searchForm, search: event.target.value })
            }
            startIcon={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={5} lg={3} style={{ paddingTop: 10 }}>
          <CustomRangeDatePicker
            label="Berdasarkan Tanggal"
            startDate={searchForm.startDate}
            endDate={searchForm.endDate}
            onChange={([start, end]) =>
              setSearchForm({ ...searchForm, startDate: start, endDate: end })
            }
            placeholder="Berdasarkan Tanggal"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} className=".hideForPrint" id="warehouse-content">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerLeft}>Tanggal</TableCell>
              <TableCell>No. Delivery Order</TableCell>
              <TableCell>No. Request Order</TableCell>
              <TableCell>Nama Client</TableCell>
              <TableCell align="center">Jumlah Item</TableCell>
              <TableCell align="center">From Approval</TableCell>
              <TableCell style={{ width: 25 }}>Status Pembayaran</TableCell>
              <TableCell className={classes.headerRight} />
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <p>Tidak ada data yang bisa ditampilkan</p>
                </TableCell>
              </TableRow>
            ) : (
              data.map((row) => (
                <TableRow key={row["_id"]}>
                  <TableCell>{row.shippedDate ? dateFormatShipping(row.shippedDate) : ''}</TableCell>
                  <TableCell>{row.codeDo}</TableCell>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{row.hospitalName || row.customerName}</TableCell>
                  <TableCell align="center">{row.countItem}</TableCell>
                  <TableCell align="center">
                    {row.isFromApi ? "Iya" : "Tidak"}
                  </TableCell>
                  <TableCell>
                    {statusFormat(row.paymentStatus.status)}
                  </TableCell>
                  <TableCell>
                    {
                      (cekRole("requestOrder.d13") && 
                      row.paymentStatus.status === "not paid off"
                      && !row.isAccepted)
                        ? (
                        <Tooltip title={"Edit barang" } placement="center" id="warehouse-content">
                          <Button
                            disableElevation
                            variant="outlined"
                            size="medium"
                            className={classes.button}
                            startIcon={<CreateOutlinedIcon />}
                            onClick={() =>
                              history.push(routes.RETURN + `/${row["_id"]}`)
                            }
                          >
                            {'Edit'}
                          </Button>
                        </Tooltip>
                    ) : undefined}
                    {cekRole("requestOrder.d11") && (
                      <Tooltip title="Cetak biasa" placement="center" id="warehouse-content">
                        <Button
                          disableElevation
                          variant="outlined"
                          size="medium"
                          className={classes.button}
                          startIcon={<PrintOutlinedIcon />}
                          onClick={async () =>
                            downloadDocument(row.code, row["_id"], false)
                          }
                        >
                          {row.isLoadingNormal
                              ? <CircularProgress size={24}  />
                              : 'Cetak Biasa'}
                        </Button>
                      </Tooltip>
                    )}
                    {cekRole("requestOrder.d10") && (
                      <Tooltip title="Cetak Detail" placement="center" id="warehouse-content">
                        <Button
                          disableElevation
                          variant="contained"
                          size="medium"
                          className={classes.button}
                          startIcon={<PrintOutlinedIcon />}
                          onClick={async () =>
                            downloadDocument(row.code, row["_id"], true)
                          }
                        >
                          {row.isLoadingDetail
                              ? <CircularProgress size={24} color="white"  />
                              : 'Cetak Detail'}
                        </Button>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
      {Object.keys(printPdfProps).length > 0 && (
        <Print isHidden>
          <RequestOrderDocument {...printPdfProps} />
        </Print>
      )}
    </div>
  );
};

export default Shipping;
