import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import routes, { inventoryRoutes } from "../../constants/routes";
import useCekHakAkses from "../../utils/cekHakAkses";
import BarangPerSupplier from "./BarangPerSupplier";
import DataBarang from "./DataBarang";
import DataBarangDetails from "./DataBarangDetails";
import DataBarangSatuan from "./DataBarangSatuan";
import DepotStok from "./DepotStok";
import DetailBarangSatuanLayout from "./DetailBarangSatuanLayout";
import HargaBeliPerSupplier from "./HargaBeliPerSupplier";
import KadaluarsaItem from "./KadaluarsaItem";
import MutasiDepot from "./MutasiDepot";
import KartuStok from "./KartuStok";
import TambahKadaluarsaInput from "./TambahKadaluarsaInput";
import Inventory from "./Inventory";
import WarningKadaluarsa from "./WarningKadaluarsa";
import WarningStok from "./WarningStok";
import TambahBarang from "./TambahBarang";
import TambahDepot from "./TambahDepot";
import BarangRusak from "./BarangRusak";
import BarangRusakDetail from "./BarangRusakDetail";
import Import from "./Import";

const getRoute = (route) => {
  return routes.INVENTORY + route;
};

const getRouteDetail = (route) => {
  return routes.INVENTORY + inventoryRoutes.DETAIL_BARANG + "/:itemID" + route;
};

const InventoryRoute = (props) => {
  const cekRole = useCekHakAkses();
  return (
    <Switch>
      <Route path={getRoute(inventoryRoutes.DATA_BARANG)} exact>
        <Inventory>
          <DataBarang />
        </Inventory>
      </Route>
      <Route path={getRoute(inventoryRoutes.DATA_BARANG_DETAILS)} exact>
        <Inventory>
          <DataBarangDetails />
        </Inventory>
      </Route>
      <Route path={getRoute(inventoryRoutes.WARNING_KADALUARSA)} exact>
        <Inventory>
          <WarningKadaluarsa />
        </Inventory>
      </Route>
      <Route path={getRoute(inventoryRoutes.WARNING_STOK)} exact>
        <Inventory>
          <WarningStok />
        </Inventory>
      </Route>
      <Route path={getRoute(inventoryRoutes.BARANG_PER_SUPPLIER)} exact>
        <Inventory>
          <BarangPerSupplier />
        </Inventory>
      </Route>
      <Route path={getRoute(inventoryRoutes.BARANG_RUSAK)} exact>
        <Inventory>
          <BarangRusak />
        </Inventory>
      </Route>
      <Route path={getRoute(inventoryRoutes.BARANG_RUSAK_DETAIL)} exact>
        <Inventory>
          <BarangRusakDetail />
        </Inventory>
      </Route>
      <Route path={getRoute(inventoryRoutes.TAMBAH_BARANG)} exact>
        <TambahBarang />
      </Route>
      <Route path={getRoute(inventoryRoutes.IMPORT)} exact>
        <Import />
      </Route>

      <Route path={getRoute(inventoryRoutes.DETAIL_BARANG) + "/:itemID"}>
        <DetailBarangSatuanLayout>
          <Switch>
            <Route path={getRouteDetail(inventoryRoutes.DETAIL_SATUAN)} exact>
              <DataBarangSatuan />
            </Route>
            <Route path={getRouteDetail(inventoryRoutes.HARGA_BELI)} exact>
              <HargaBeliPerSupplier />
            </Route>
            <Route path={getRouteDetail(inventoryRoutes.DEPOT_STOK)} exact>
              <DepotStok />
            </Route>
            <Route path={getRouteDetail(inventoryRoutes.KADALUARSA_ITEM)} exact>
              <KadaluarsaItem />
            </Route>
            <Route path={getRouteDetail(inventoryRoutes.KARTU_STOK)} exact>
              <KartuStok />
            </Route>
            <Route
              path={getRouteDetail(inventoryRoutes.MUTASI_DEPOT) + "/:depotId"}
              exact
            >
              <MutasiDepot />
            </Route>
            <Route path={getRouteDetail(inventoryRoutes.TAMBAH_DEPOT)} exact>
              <TambahDepot />
            </Route>
            <Route
              path={getRouteDetail(inventoryRoutes.TAMBAH_KADALUARSA)}
              exact
            >
              <TambahKadaluarsaInput />
            </Route>
            <Route
              path={getRouteDetail(inventoryRoutes.EDIT_KADALUARSA) + "/:kadaluarsaId"}
              exact
            >
              <TambahKadaluarsaInput edit />
             </Route>
            <Redirect to={getRouteDetail(inventoryRoutes.DETAIL_SATUAN)} />
          </Switch>
        </DetailBarangSatuanLayout>
      </Route>
      <Route path={getRoute('/no-access')}>
        Anda tidak memiliki hak akses
      </Route>
     {cekRole('inventory.f2') && <Redirect to={getRoute(inventoryRoutes.DATA_BARANG)} />}
     {cekRole('inventory.f23') && <Redirect to={getRoute(inventoryRoutes.DATA_BARANG_DETAILS)} />}
     {cekRole('inventory.f37') && <Redirect to={getRoute(inventoryRoutes.WARNING_KADALUARSA)} />}
     {cekRole('inventory.f42') && <Redirect to={getRoute(inventoryRoutes.WARNING_STOK)} />}
     {cekRole('inventory.f47') && <Redirect to={getRoute(inventoryRoutes.BARANG_PER_SUPPLIER)} />}
     {cekRole('inventory.f52') && <Redirect to={getRoute(inventoryRoutes.BARANG_RUSAK)} />}
     {cekRole('inventory.f55') && <Redirect to={getRoute(inventoryRoutes.BARANG_RUSAK_DETAIL)} />}
    </Switch>
  );
};

export default InventoryRoute;
