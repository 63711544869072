import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

import {
  makeStyles,
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  MenuItem,
  List,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import { PrintOutlined, Delete } from "@material-ui/icons";

import routes from "../../constants/routes";

import CustomTextField from "../custom/CustomTextField";
import CustomDatePicker from "../custom/CustomDatePicker";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";

import formatting from "../../utils/formatting";
import validations from "../../utils/validations";
import useDisclosure from "../../utils/useDisclosure";
import usePdfPrint from "../../utils/usePdfPrint";
import Print from "../../utils/Print";

import { getDetailRO, editDeliveryOrder } from "../../services/RequestOrderService";
import { getListSpecificExpires } from "../../services/StockExpires";

import { getPlaceId } from "../../store/auth/selector";
import { setSnackbar } from "../../store/general/actions";

import RequestOrderDocument from "../Document/RequestOrderDocument";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  header: {
    lineHeight: "60px",
    fontWeight: "bold",
  },
  clinicName: {
    fontFamily: theme.typography.fontFamily,
    display: "inline",
  },
  itemGridLeft: {
    paddingRight: 12,
  },
  itemGridRight: {
    paddingLeft: 12,
  },
  inputField: {
    width: "100%",
    fontWeight: "bold",
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  tableInputField: {
    width: 140,
  },
  asterisk: {
    color: theme.palette.red,
    display: "inline",
    fontWeight: "normal",
  },
  inputWithSelect: {
    paddingLeft: 10,
  },
  select: {
    width: "100%",
  },
  button: {
    marginLeft: 10,
  },
  grandTotal: {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.whiteBlue,
      color: theme.palette.titleText,
      fontWeight: "bold",
    },
  },
}));

function DialogBatch({ onAdd, onDelete, onChange, items, batches, ...props }) {
  if (items === null || items === undefined) return null;
  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <DialogTitle>Setup Batch</DialogTitle>
      <DialogContent>
        <List>
          {(items === null || items === undefined) && items.length === 0 && (
            <ListItem>
              <ListItemText>No Data :(</ListItemText>
            </ListItem>
          )}
          {items.map((item, i) => (
            <ListItem key={i}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item md={3}>
                  <TextField
                    select
                    fullWidth
                    label="Batch No"
                    id="batchNo"
                    value={item.batchNo}
                    onChange={onChange(i, "batchNo")}
                  >
                    {batches &&
                      batches.map((batch) => (
                        <MenuItem
                          value={batch.batchNo}
                          data-batch={JSON.stringify(batch)}
                          key={batch.batchNo}
                        >
                          {batch.batchNo}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item md={3}>
                  <TextField
                    label="Batch Stock"
                    value={item.stock}
                    disabled
                    InputLabelProps={{ shrink: true }}
                    id="batchStock"
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    fullWidth
                    label="Jumlah Delivery Order"
                    id="quantityDeliver"
                    onChange={onChange(i, "quantityDeliver")}
                    value={item.quantityDeliver}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    label="Expired Date"
                    value={item.expireDate}
                    disabled
                    InputLabelProps={{ shrink: true }}
                    id="expiredDate"
                  />
                </Grid>
                <Grid item md={1}>
                  <IconButton
                    color="secondary"
                    size="small"
                    onClick={() => onDelete(i)}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
        {/* <Button onClick={onAdd}>Tambah</Button> */}
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          size="large"
          onClick={props.onClose}
          disabled={items.some(($item) => $item.ebatchNo === null)}
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function calculateSubtotal(data) {
  const newSubTotal = +data.item.reduce((subtotal, currentItem) => {
    const kuantitasDO = currentItem.batchItems.reduce(
      (quantity, currentBatch) => quantity + currentBatch.quantityDeliver,
      0
    );

    const hargaBarang = currentItem.priceAfterDiscount * kuantitasDO;

    return hargaBarang + subtotal;
  }, 0);

  return newSubTotal;
}

const EditProcessDO = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [data, setData] = useState({ item: [] });
  const [ppnFixed, setPpnFixed] = useState(0);
  const [ppnPercent, setPpnPercent] = useState(0);
  const { open, onOpen, onClose } = useDisclosure();
  const [grandTotal, setGrandTotal] = useState(0);
  const [subTotal, setSubtotal] = useState(0);
  const [discountRp, setDiscountRp] = useState(0);

  const {
    open: isSubmitting,
    onOpen: setSubmit,
    onClose: setSubmitted,
  } = useDisclosure();
  const [batchItemIndex, setBatchItemIndex] = useState(-1);
  const { printPdfProps, setPdfProps } = usePdfPrint({
    frameName: "processRO",
  });

  const [error, setError] = useState({ items: [] });

  const addBatchItem = React.useCallback(() => {
    setData(({ item, ...rest }) => {
      item[batchItemIndex].batchItems.push({
        batchNo: null,
        quantityDeliver: 0,
        expireDate: null,
        expireId: null,
        depotName: "",
        rackName: "",
      });
      return {
        ...rest,
        item,
      };
    });
  }, [setData, batchItemIndex]);

  const deleteBatchItem = React.useCallback(
    (idx) => {
      setData(({ item, ...rest }) => {
        item[batchItemIndex].batchItems[idx].quantityDeliver = 0

        const subTotal = calculateSubtotal({ item });
        const newGrandTotal = subTotal + ppnFixed;
        setSubtotal(subTotal);
        setGrandTotal(newGrandTotal);

        return {
          ...rest,
          item,
        };
      });
    },
    [setData, batchItemIndex, setSubtotal, setGrandTotal, ppnFixed]
  );
  function changeBatchItem(index, propName) {
    return (evt) => {
      const value = evt.target.value;
      const batchItem = JSON.parse(
        evt.currentTarget.getAttribute("data-batch")
      );
      const parsedValue = value < 0 ? 0 : +value;
      setData(({ item, ...res }) => {
        if (propName === "quantityDeliver") {
          item[batchItemIndex].batchItems[index][propName] = parsedValue;
          const total = item.reduce((jumlahQuantityDeliver, currentItem) => {
            const batchItemsTotal = currentItem.batchItems.reduce(
              (totalBatch, currentBatch) =>
                totalBatch + currentBatch.quantityDeliver,
              0
            );
            return batchItemsTotal + jumlahQuantityDeliver;
          }, 0);
          if (parsedValue > item[batchItemIndex].batchItems[index].stock) {
            dispatch(
              setSnackbar(
                "warning",
                "Maaf! Total jumlah DO tidak boleh melebihi jumlah stok batch!"
              )
            );
            item[batchItemIndex].batchItems[index][propName] = 0;
          }
          // else if (parsedValue === 0) {
          //   dispatch(
          //     setSnackbar(
          //       "warning",
          //       "Maaf! Jumlah DO tidak boleh 0!"
          //     )
          //   );
          // }
          else {
            const sum = (name) => (a, b) => (a + b[name]) * total;

            const newSubTotal = calculateSubtotal(data);

            const newDiscountRp = +data.item.reduce(sum("discountRp"), 0);
            const newGrandTotal = newSubTotal + ppnFixed;
            const newPpnPercent = (ppnFixed / newSubTotal) * 100;
            setSubtotal(newSubTotal);
            setDiscountRp(newDiscountRp);
            setGrandTotal(newGrandTotal);
            setPpnPercent(newPpnPercent);
          }
        }

        if (propName === "batchNo") {
          const cek = item[batchItemIndex].batchItems.find(
            (val) => val.batchNo === value
          );
          if (cek) {
            dispatch(
              setSnackbar("warning", "Maaf! Silakan memilih batch yang berbeda")
            );
          } else {
            item[batchItemIndex].batchItems[index][propName] = value;
            item[batchItemIndex].batchItems[index].expireDate = moment(
              batchItem.expiredDate
            ).format("DD/MM/YYYY");
            item[batchItemIndex].batchItems[index].stock = batchItem.stock;
            item[batchItemIndex].batchItems[index].expireId = batchItem._id;
            item[batchItemIndex].batchItems[index].depotName =
              batchItem.depotName;
            item[batchItemIndex].batchItems[index].rackName =
              batchItem.rackName;
            item[batchItemIndex].batchItems[index].quantityDeliver = 0;
          }
        }

        return { ...res, item };
      });
    };
  }

  const handleCloseDialogBatch = React.useCallback(() => {
    const cumulativeItemValue = data.item[batchItemIndex].batchItems.reduce(
      (totalBatch, current) => totalBatch + current.quantityDeliver,
      0
    );
    // const cekTotal = data.item[batchItemIndex].batchItems.find(val => val.quantityDeliver === 0);
    if (+cumulativeItemValue > data.item[batchItemIndex].quantityRequest) {
      dispatch(setSnackbar("warning", "Maaf! Total jumlah DO tidak boleh melebihi jumlah RO"));
    }
    // else if (cekTotal) {
    //   dispatch(setSnackbar("warning", "Maaf! Jumlah DO tidak boleh 0"));
    // }
    else {
      data.item[batchItemIndex].quantityDeliver = +cumulativeItemValue;
      setData(data);
      onClose();
      setBatchItemIndex(-1);
    }
  }, [data, setData, onClose, setBatchItemIndex, batchItemIndex, dispatch]);

  async function downloadDocument() {
    const items = data.item
      .flatMap((value) => {
        // Jika batch item kosong, return value aja
        if (value.batchItems.length === 0) {
          return [value];
        }
        // kalau ada batch itemnya, bikin yg di print sesuai jumlah batch
        return value.batchItems.map((itemPerBatch) => ({
          ...value,
          ...itemPerBatch,
        }));
        // tambah line number tiap item
      })
      .map((item, index) => ({ ...item, no: index + 1 }));

    setPdfProps({
      data,
      items: items.map((value, index) => {
        return {
          ...value,
          no: index + 1,
        };
      }),
    });
  }

  useEffect(() => {
    async function fetchRO() {
      const {
        data: { item },
      } = await getDetailRO(id);
      const expires = await Promise.all(
        item.item.map((item) => getListSpecificExpires(item.itemId))
      ).then((items) => Promise.resolve(items.map((i) => i.data)));
      const data = {
        ...item,
        item: item.item.map((i, index) => ({
          ...i,
          batches: expires[index].items,
        })),
      };
      setData(data);
      setPpnFixed(data.ppnRp);
      setPpnPercent(data.ppnPercent);
      setGrandTotal(data.totalFee);
      setSubtotal(data.subTotalFee);
    }
    fetchRO();
  }, [id]);

  useEffect(() => {
    const calculatedPpn = ppnFixed;
    setGrandTotal(Number(subTotal) + Number(calculatedPpn));
  }, [ppnFixed, subTotal]);

  const validateOrderProcessRO = () => {
    const valid = {
      shippedDate: !data.shippedDate
        ? "Tanggal pengiriman tidak boleh kosong"
        : undefined,
      items: validateItems(),
    };
    setError(valid);
    return Object.values(valid).every((error) => {
      if (Array.isArray(error)) {
        return error.every((value) =>
          Object.values(value).every((data) => !data)
        );
      }
      return !error;
    });
  };

  const validateItems = () => {
    const items = [];

    data.item.forEach((value) => {
      value.batchItems.forEach((item) => {
        const isMoreThanQuantityRequest = validations.isMoreThan(
          +item.quantityDeliver
        );
        const isEmptyQuantityDeliver = validations.isEmpty(
          item.quantityDeliver
        );
        const valid = {
          quantityDeliver: isEmptyQuantityDeliver
            ? "Total DO tidak boleh kosong"
            // : isMoreThanQuantityRequest(+value.stockExisting)
            // ? "Total Delivery Order tidak boleh lebih dari Stock Available"
            : isMoreThanQuantityRequest(+value.quantityRequest)
            ? "Total Delivery Order tidak boleh lebih dari Jumlah Request Order"
            : "",
        };
        if (valid.quantityDeliver) {
          dispatch(setSnackbar('error', valid.quantityDeliver));
        }
        items.push(valid);
      });
    });

    return items;
  };

  const shipRO = () => {
    const noZeroAll = data.item.some((f) => f.batchItems.length !== 0);
    const emptyBatch = data.item.some((f) =>
      f.batchItems.some((g) => g.batchNo === null)
    );
    if (noZeroAll && !emptyBatch) {
      const err = validateOrderProcessRO();
      if (err) {
        setSubmit();
        editDeliveryOrder(data.id, {
          id: data.id,
          placeId: getPlaceId(),
          shippedDate: moment(data.shippedDate).format("YYYY-MM-DD"),
          ppnRp: parseInt(ppnFixed, 10),
          discountRp: parseInt(data.discountRp, 10),
          subTotalFee: subTotal,
          totalFee: grandTotal,
          items: data.item
            .filter(value => value.stockExisting >= value.quantityDeliver)
            .map((value) => {
              return {
                id: value.id,
                discountPercent: value.discountPercent,
                discountRp: value.discountRp,
                ppnPercent: ppnPercent,
                batchItems: value.batchItems,
              };
            }),
        })
          .then(() => {
            dispatch(
              setSnackbar("success", "Sukses! Data Delivery Order berhasil diupdate")
            );
            setSubmitted();
            history.push(routes.REQUEST_SHIPPING);
          })
          .catch(() => {
            dispatch(
              setSnackbar("error", "Error! Terjadi kesalahan. Coba beberapa saat lagi")
            );
            setSubmitted();
          });
       }
    } else {
      dispatch(setSnackbar("warning", "Maaf! Silakan setup batch terlebih dahulu"));
    }
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName="Edit Delivery Order"
        previousPageName="Request Order"
        previousPageLink={routes.REQUEST_NEW}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className={`${classes.header} ${classes.borderBottom}`}
          >
            Detail RO
            <Typography
              className={`${classes.header} ${classes.clinicName}`}
              variant="body1"
              color="primary"
            >
              {" "}
              ( {data.hospitalName || data.clientName || ""} )
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Nama Client"
                className={classes.inputField}
                value={data.hospitalName || data.clientName || ""}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="PIC RO Client"
                className={classes.inputField}
                value={data.picRoClientName}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="No. Whatsapp Client"
                className={classes.inputField}
                value={data.hp}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Status RO"
                className={classes.inputField}
                value={data.status}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <CustomTextField
              label="Alamat"
              className={classes.inputField}
              value={data.address}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label="Keterangan"
              className={classes.inputField}
              value={data.notes}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className={`${classes.header} ${classes.borderBottom}`}
          >
            Detail Item
            <IconButton
              color="primary"
              className={classes.button}
              onClick={async () => downloadDocument()}
            >
              <PrintOutlined />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerLeft}>Nama Obat</TableCell>
                <TableCell>Jumlah Request Order</TableCell>
                <TableCell>Stock Available</TableCell>
                <TableCell>Harga</TableCell>
                <TableCell>Discount % / Discount Fix</TableCell>
                <TableCell>Harga - Discount</TableCell>
                <TableCell>
                  Batch <p className={classes.asterisk}>&nbsp;*</p>
                </TableCell>
                <TableCell>Depot</TableCell>
                <TableCell className={classes.headerRight} />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.item.map((row, index) => (
                <TableRow key={row.itemId}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {row.quantityRequest + " " + row.bigUnit}
                  </TableCell>
                  <TableCell>
                    {(row.stockExisting || 0) + " " + row.bigUnit}
                  </TableCell>
                  <TableCell>
                    {"@" + formatting.currencyFormat(row.price)}
                  </TableCell>
                  <TableCell>
                    {row.discountPercent} % /{" "}
                    {formatting.currencyFormat(row.discountRp)}
                  </TableCell>
                  <TableCell>
                    {"@" + formatting.currencyFormat(row.priceAfterDiscount)}
                  </TableCell>
                  <TableCell>
                    {row.batchItems.map((data) => (
                      <CustomTextField
                        style={{ width: 110 }}
                        value={data.batchNo}
                        helperText=""
                        disabled
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    {row.batchItems.map((data) => (
                      <CustomTextField
                        style={{ width: 110 }}
                        value={`${data.depotName} ${
                          data.rackName && `(${data.rackName})`
                        }`}
                        helperText=""
                        disabled
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: 150 }}
                      onClick={() => {
                        setBatchItemIndex(index);
                        onOpen();
                      }}
                    >
                      Setup Batch
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Sub Total"
                className={classes.inputField}
                value={formatting.currencyFormat(subTotal)}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Total Diskon"
                className={classes.inputField}
                value={formatting.currencyFormat(discountRp)}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <Grid container alignItems="flex-end">
                <Grid item xs={6}>
                  <CustomTextField
                    className={classes.inputField}
                    value={ppnFixed}
                    label="PPN Fix"
                    currency
                    onChange={(event) => {
                      const v = event.target.value;
                      if (subTotal === 0) return;
                      setPpnFixed(v > subTotal ? subTotal : v);
                      setPpnPercent((v / subTotal) * 100);
                    }}
                  />
                </Grid>
                <Grid item xs={6} className={classes.inputWithSelect}>
                  <CustomTextField
                    className={classes.inputField}
                    value={ppnPercent}
                    label="PPN Persen"
                    onChange={(event) => {
                      const v = event.target.value;
                      setPpnPercent(v > 100 ? 100 : v);
                      setPpnFixed(subTotal * (v / 100));
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomDatePicker
                label="Tanggal Pengiriman Obat"
                date={new Date(data.shippedDate)}
                onChange={(date) => {
                  setData({ ...data, shippedDate: date });
                  setError({ ...error, shippedDate: undefined });
                }}
                placeholder="Set Tanggal"
                error={error.shippedDate}
                helperText={error.shippedDate}
                requiredField
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Total Seluruhnya"
                className={`${classes.inputField} ${classes.grandTotal}`}
                value={formatting.currencyFormat(grandTotal)}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Button
                  disableElevation
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => history.push(routes.REQUEST_SHIPPING)}
                >
                  Batalkan
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  size="large"
                  onClick={isSubmitting ? null : shipRO}
                >
                  Edit Delivery Order
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogBatch
        open={open}
        onClose={handleCloseDialogBatch}
        onAdd={addBatchItem}
        onDelete={deleteBatchItem}
        onChange={changeBatchItem}
        items={
          batchItemIndex !== -1 ? data.item[batchItemIndex].batchItems : []
        }
        batches={batchItemIndex !== -1 ? data.item[batchItemIndex].batches : []}
      />
      {Object.keys(printPdfProps).length > 0
      && <Print isHidden>
        <RequestOrderDocument
          {...printPdfProps}
        />
      </Print>}
    </div>
  );
};

export default EditProcessDO;
