import { getPlaceId } from "../store/auth/selector";
import agent from "./agent";

export const getListSupplier = (search, skip, limit) => {
  return agent.get("suppliers/getList", {
    params: {
      filter: { placeId: getPlaceId(), search },
      skip: isNaN(skip) && isNaN(limit) ? undefined : skip * limit,
      limit,
    },
  });
};
export const deleteSupplier = (id) => {
  return agent.put(`/suppliers/${id}/delete`);
};

export const getDetailSupplier = (id) => {
  return agent.get(`/suppliers/${id}/getDetail`);
};

export const editSupplier = (id, body) => {
  return agent.put(`/suppliers/${id}/edit`, body);
};

export const addSupplier = (body) => {
  return agent.post(`/suppliers/add`, { ...body, placeId: getPlaceId() });
};

export const generateSupplierCode = () => {
  return agent.get("/suppliers/generateCode", {
    params: {
      filter: { placeId: getPlaceId() },
    },
  });
};
