export const schema = {
  Kode: {
    prop: "code",
    type: String,
    required: true,
  },
  "Nama Barang": {
    prop: "name",
    type: String,
    required: true,
  },
  Tipe: {
    prop: "type",
    type: String,
    required: true,
  },
  Kategori: {
    prop: "category",
    type: String,
    required: true,
  },
  "Sub - Category": {
    prop: "subCategory",
    type: String,
  },
  "Unit Besar": {
    prop: "bigUnit",
    type: String,
    required: true,
  },
  "Presisi Unit": {
    prop: "unitPrecision",
    type: Number,
    required: true,
  },
  "Unit Kecil": {
    prop: "smallUnit",
    type: String,
    required: true,
  },
  "Jumlah Stok Satuan Besar": {
    prop: "stock",
    type: Number,
    required: true,
  },
  "Minimum Stok": {
    prop: "stockWarning",
    type: Number,
  },
  "Buffer Stok": {
    prop: "stockBuffer",
    type: Number,
  },
  "Harga Dasar": {
    prop: "basicFee",
    type: Number,
  },
  "Harga Beli": {
    prop: "buyFee",
    type: Number,
  },
  HNA: {
    prop: "hna",
    type: Number,
  },
  "HNA + PPN": {
    prop: "hnaPPN",
    type: Number,
  },
  "Harga Jual": {
    prop: "sellFee",
    type: Number,
  },
  "Margin Rp": {
    prop: "marginRp",
    type: Number,
  },
  "Margin %": {
    prop: "marginPercent",
    type: Number,
  },
  Status: {
    prop: "isActive",
    type: (value) => {
      return value === "Aktif";
    },
    required: true,
  },
};
