import React from "react";
import Dropzone from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { palette } from "../../theme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { saveAs } from "file-saver";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import template from "../../assets/template/template_import_inventory.xlsx";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    backgroundColor: palette.whiteBlue,
    height: 250,
    borderStyle: "dashed",
    borderColor: palette.disabledTextfield,
  },
  headerText: {
    textAlign: "center",
    fontWeight: "bold",
  },
  icon: {
    color: palette.lightBlue,
    fontSize: 120,
  },
  greyText: {
    color: palette.secondaryText,
  },
  blueText: {
    color: palette.lightBlue,
  },
  link: {
    cursor: "pointer",
  },
  underline: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const CustomDropzone = (props) => {
  const classes = useStyles();
  return (
    <Dropzone
      onDrop={(acceptedFiles) => props.handleOnChange(acceptedFiles)}
      accept={[
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ]}
      maxFiles={1}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <Grid className={classes.root} container>
            <input {...getInputProps()} />
            <Grid item xs={12}>
              <Typography
                className={[classes.headerText, classes.greyText]}
                variant="subtitle1"
              >
                Mohon upload file Excel.
              </Typography>
              <Typography
                className={[classes.headerText, classes.greyText]}
                variant="subtitle1"
              >
                Assist.id hanya memproses file dari
              </Typography>
              <Typography
                className={[
                  classes.headerText,
                  classes.blueText,
                  classes.link,
                  classes.underline,
                ]}
                variant="subtitle1"
                onClick={() => saveAs(template, "template.xlsx")}
              >
                Template
              </Typography>
              <div {...getRootProps()} className={classes.link}>
                <Typography className={classes.headerText} variant="subtitle1">
                  <BackupOutlinedIcon className={classes.icon} />
                </Typography>
                <Typography
                  className={[classes.headerText, classes.blueText]}
                  variant="subtitle1"
                >
                  DRAG & DROP
                </Typography>
                <Typography
                  className={[classes.headerText, classes.greyText]}
                  variant="subtitle1"
                >
                  Atau pilih file dari komputer Anda
                </Typography>
              </div>
            </Grid>
          </Grid>
        </section>
      )}
    </Dropzone>
  );
};

export default CustomDropzone;
