import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import TableRow from "@material-ui/core/TableRow";
import { palette } from "../../theme";
import CustomTextField from "../custom/CustomTextField";
import CustomPagination from "../custom/CustomPagination";
import CustomSelect from "../custom/CustomSelect";
import CustomDropdownButton from "../custom/CustomDropdownButton";
import routes, { inventoryRoutes } from "../../constants/routes";

import debounce from "awesome-debounce-promise";

import {
  getListInventory,
  getListExportInventory,
} from "../../services/InventoryService";
import clsx from "clsx";
import exportXL from "../../utils/exportXL";
import Print from "../../utils/Print";
import formatting from "../../utils/formatting";
import useCekRole from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  editButton: {
    float: "right",
    marginLeft: 5,
    color: palette.blue,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  marginContainer: {
    marginLeft: 20,
  },
  alignRight: {
    textAlign: "right",
  },
  notActive: {
    color: palette.disabledTextfield,
  },
}));

const statusSelect = [
  { key: "Semuanya", label: "Semuanya" },
  { key: "Aktif", label: "Aktif" },
  { key: "Tidak", label: "Tidak Aktif" },
];

const tipeSelect = [
  { key: undefined, label: "Semuanya" },
  { key: "Obat", label: "Obat" },
  { key: "BHP", label: "BHP" },
  { key: "Logistik", label: "Logistik" },
];

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debouncedGetList = debounce(getListInventory, 500);

function HeaderItem(props) {
  return (
    <Grid item xs={12} md={12} lg={3} style={{ paddingTop: 10 }} {...props} />
  );
}

const DataBarangDetails = () => {
  const classes = useStyles();
  const [searchForm, setSearchForm] = useState({
    status: "Semuanya",
  });
  const [pagination, setPagination] = useState(paginationInitialData);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [buttonMenuSelect] = useState();
  const [exportData, setExportData] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);

  const history = useHistory();
  const cekRole = useCekRole();
  const detailBarangAccess = cekRole("inventory.f23");

  const detailRoute = cekRole("inventory.f25")
    ? inventoryRoutes.DETAIL_SATUAN
    : cekRole("inventory.f27")
    ? inventoryRoutes.HARGA_BELI
    : cekRole("inventory.f32")
    ? inventoryRoutes.DEPOT_STOK
    : inventoryRoutes.KADALUARSA_ITEM;

  useEffect(() => {
    setIsFetchingData(true);
    debouncedGetList(
      searchForm.search,
      searchForm.category,
      searchForm.type,
      pagination.page,
      pagination.rowsPerPage,
      searchForm.status
    )
      .then((res) => {
        setTotalData(res.data.count);
        setTableData(res.data.items);
        setExportData([]);
      })
      .finally(() => setIsFetchingData(false));
  }, [searchForm, pagination]);

  const tambahBarang = () => {
    history.push(routes.INVENTORY + inventoryRoutes.TAMBAH_BARANG);
  };

  const importBarang = () => {
    history.push(routes.INVENTORY + inventoryRoutes.IMPORT);
  };
  const getDataExport = async () => {
    let page = 0;
    let skip = 0;
    const limit = 10;
    const fetch = async () => {
      const result = await getListExportInventory(
        searchForm.search,
        searchForm.category,
        searchForm.type,
        page,
        limit,
        searchForm.status
      );
      exportData.splice(skip * limit, limit, ...result.data.items);
      setExportData(exportData);
    };
    if (exportData.length >= totalData) return;
    while (skip < totalData) {
      await fetch();
      page += 1;
      skip += limit;
    }
  };

  const handleExport = () => {
    setIsFetching(true);
    try {
      getDataExport().then(() => {
        const hasil = exportData.map((val) => ({
          Kode: val.code,
          "Nama Barang": val.name,
          Tipe: val.type,
          Kategori: val.category,
          "Satuan Kecil": val.smallStock,
          "Satuan Besar": val.bigStock,
          "Harga Beli": val.basicFee,
          "Harga Jual": val.sellFee,
          "Min Stok": val.stockWarning,
          Status: val.isActive ? "Aktif" : "Tidak",
          "Buffer Stok": val.stockBuffer,
          "Selisih Stok": val.stockDifference,
        }));
        setIsFetching(false);
        exportXL("Export Data Barang Details", hasil);
      });
    } catch (e) {
      setIsFetching(false);
      console.error(e);
    }
  };

  const handlePrint = () => {
    setIsFetching(true);
    try {
      getDataExport()
        .then(() => {
          setIsPrint(true);
          window.print();
          setIsFetching(false);
        })
        .finally(() => {
          setIsPrint(false);
          setExportData([]);
          setIsFetching(false);
        })
        .catch((e) => {
          console.error(e);
          setIsPrint(false);
          setIsFetching(false);
        });
    } catch (e) {
      console.error(e);
      setIsPrint(false);
      setIsFetching(false);
    }
  };

  return (
    <div>
      {!detailBarangAccess && (
        <Redirect to={routes.INVENTORY + inventoryRoutes.WARNING_KADALUARSA} />
      )}
      <Grid container className={classes.headerGrid}>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h2" color="textPrimary">
            <b>Data Barang Details</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
        spacing={1}
      >
        <HeaderItem>
          <CustomTextField
            disabled={isFetching}
            label="Cari Data Inventory"
            placeholder="Kode dan Nama barang"
            value={searchForm.search}
            onChange={(event) =>
              setSearchForm({ ...searchForm, search: event.target.value })
            }
            startIcon={<SearchIcon />}
          />
        </HeaderItem>
        <HeaderItem>
          <CustomTextField
            disabled={isFetching}
            label="Berdasarkan Kategori"
            placeholder="Kategori"
            value={searchForm.category}
            onChange={(event) =>
              setSearchForm({ ...searchForm, category: event.target.value })
            }
            startIcon={<SearchIcon />}
          />
        </HeaderItem>
        <HeaderItem>
          <CustomSelect
            disabled={isFetching}
            value={searchForm.status}
            onChange={(event) =>
              setSearchForm({ ...searchForm, status: event.target.value })
            }
            label="Berdasarkan Status"
          >
            {statusSelect.map((status) => (
              <MenuItem key={status.key} value={status.key}>
                {status.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </HeaderItem>
        <Grid item xs={3} container justify="flex-end">
          {isFetching ? (
            <CircularProgress />
          ) : (
            <CustomDropdownButton
              disableElevation
              variant="contained"
              size="large"
              onClick={tambahBarang}
              side
              label="Tambah Barang"
              disabled={!cekRole("inventory.f24")}
            >
              <MenuItem
                key="import"
                value={1}
                selected={1 === buttonMenuSelect}
                onClick={importBarang}
              >
                Import
              </MenuItem>
              <MenuItem
                key="export"
                value={2}
                selected={2 === buttonMenuSelect}
                onClick={handleExport}
              >
                Export
              </MenuItem>
              <MenuItem
                key="print"
                value={3}
                selected={3 === buttonMenuSelect}
                onClick={handlePrint}
              >
                Print
              </MenuItem>
            </CustomDropdownButton>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={12} alignItems="center">
        <div style={{ paddingTop: 20 }}>
          <CustomSelect
            disabled={isFetching}
            style={{ marginRight: 20 }}
            value={searchForm.type}
            onChange={(event) =>
              setSearchForm({ ...searchForm, type: event.target.value })
            }
            label="Berdasarkan Tipe"
          >
            {tipeSelect.map((tipe) => (
              <MenuItem key={tipe.key} value={tipe.key}>
                {tipe.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Print>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerLeft}>Kode</TableCell>
                <TableCell>Nama Barang</TableCell>
                <TableCell>Tipe</TableCell>
                <TableCell>Kategori</TableCell>
                <TableCell>Satuan Kecil</TableCell>
                <TableCell>Satuan Besar</TableCell>
                <TableCell>Harga Beli</TableCell>
                <TableCell>Harga Jual</TableCell>
                <TableCell>Min Stock</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Buffer Stock</TableCell>
                <TableCell>Selisih Stock</TableCell>
                {detailRoute && <TableCell className={classes.headerRight} />}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetchingData ? (
                <TableRow>
                  <TableCell colSpan={detailRoute ? 13 : 12} align={"center"}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : !isPrint && tableData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={detailRoute ? 13 : 12} align={"center"}>
                    <p>Tidak ada data yang bisa ditampilkan.</p>
                  </TableCell>
                </TableRow>
              ) : (
                !isPrint &&
                tableData.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{row.smallStock}</TableCell>
                    <TableCell>{row.bigStock}</TableCell>
                    <TableCell>
                      {formatting.currencyFormat(row.basicFee)}
                    </TableCell>
                    <TableCell>
                      {formatting.currencyFormat(row.sellFee)}
                    </TableCell>
                    <TableCell>{row.stockWarning}</TableCell>
                    <TableCell
                      className={clsx({
                        [classes.notActive]: !row.isActive,
                      })}
                    >
                      {row.isActive ? "Aktif" : "Tidak Aktif"}
                    </TableCell>
                    <TableCell>{row.stockBuffer}</TableCell>
                    <TableCell>{row.stockDifference}</TableCell>
                    {detailRoute && (
                      <TableCell>
                        <Tooltip title="Detail Barang" placement="right" id="warehouse-content">
                          <IconButton
                            disabled={isFetching}
                            color="primary"
                            className={classes.editButton}
                            size="small"
                            onClick={() =>
                              history.push(
                                routes.INVENTORY +
                                  inventoryRoutes.DETAIL_BARANG +
                                  `/${row._id}` +
                                  detailRoute +
                                  "?source=DataBarangDetails"
                              )
                            }
                          >
                            <InsertDriveFileIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              )}
              {isPrint &&
                exportData.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{row.smallStock}</TableCell>
                    <TableCell>{row.bigStock}</TableCell>
                    <TableCell>{row.basicFee}</TableCell>
                    <TableCell>{row.sellFee}</TableCell>
                    <TableCell>{row.stockWarning}</TableCell>
                    <TableCell>{row.isActive ? "Aktif" : "Tidak"}</TableCell>
                    <TableCell>{row.stockBuffer}</TableCell>
                    <TableCell>{row.stockDifference}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Print>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
    </div>
  );
};

DataBarangDetails.propTypes = {};
export default DataBarangDetails;
