import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CustomTextField from "../custom/CustomTextField";
import SearchIcon from "@material-ui/icons/Search";
import CustomRangeDatePicker from "../custom/CustomRangeDatePicker";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Sync from "@material-ui/icons/Sync";
import CustomPagination from "../custom/CustomPagination";
import DeleteRoDialog from "./DeleteRoDialog";
import routesConst from "../../constants/routes";
import { useHistory } from "react-router-dom";
import moment from "moment";
import debounce from "awesome-debounce-promise";
import { deleteRO, getListRO } from "../../services/RequestOrderService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { dateFormat } from "../../utils/formatting";
import useCekHakAkses from "../../utils/cekHakAkses";

const DATE_FORMAT = "YYYY-MM-DD";

const useStyles = makeStyles((theme) => ({
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debouncedGetListRO = debounce(getListRO, 500);

const Processing = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = useCekHakAkses();

  const [searchForm, setSearchForm] = useState({
    startDate: moment().subtract(30, "days").toDate(),
    endDate: new Date(),
  });
  const [pagination, setPagination] = useState(paginationInitialData);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState({ open: false });
  const [isFetching, setIsFetching] = useState(false);

  const handleDeleteDialog = (code, id) => {
    setOpenDeleteDialog({ ...openDeleteDialog, open: true, code, id });
  };

  useEffect(() => {
    setIsFetching(true);
    debouncedGetListRO(
      searchForm.search,
      "approved",
      moment.isDate(searchForm.startDate)
        ? moment(searchForm.startDate).format(DATE_FORMAT)
        : undefined,
      moment.isDate(searchForm.endDate)
        ? moment(searchForm.endDate).format(DATE_FORMAT)
        : moment(searchForm.startDate).format(DATE_FORMAT),
      pagination.page,
      pagination.rowsPerPage
    ).then((res) => {
      setTotalData(res.data.count);
      setData(res.data.items);
      setIsFetching(false);
    });
  }, [searchForm, pagination, openDeleteDialog]);

  return (
    <div>
      <Grid container className={classes.headerGrid}>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h2" color="textPrimary">
            Processing
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid item xs={5} lg={3} style={{ paddingTop: 10 }}>
          <CustomTextField
            label="Cari Data Request Order"
            placeholder="No. RO atau Nama Client"
            value={searchForm.search}
            onChange={(event) =>
              setSearchForm({ ...searchForm, search: event.target.value })
            }
            startIcon={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={5} lg={3} style={{ paddingTop: 10 }}>
          <CustomRangeDatePicker
            label="Berdasarkan Tanggal"
            startDate={searchForm.startDate}
            endDate={searchForm.endDate}
            onChange={([start, end]) =>
              setSearchForm({ ...searchForm, startDate: start, endDate: end })
            }
            placeholder="Berdasarkan Tanggal"
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerLeft}>Tanggal</TableCell>
              <TableCell>No. Request Order</TableCell>
              <TableCell>Nama Client</TableCell>
              <TableCell align="center">Jumlah Item</TableCell>
              <TableCell align="center">From Approval</TableCell>
              <TableCell>Approved By</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className={classes.headerRight} />
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <p>Tidak ada data yang bisa ditampilkan</p>
                </TableCell>
              </TableRow>
            ) : (
              data.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{dateFormat(row.date)}</TableCell>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>
                    {row.hospitalName || row.customerName || ""}
                  </TableCell>
                  <TableCell align="center">{row.countItem}</TableCell>
                  <TableCell align="center">
                    {row.isFromApi ? "Iya" : "Tidak"}
                  </TableCell>
                  <TableCell>
                    {row.picApproveClientName !== undefined
                      ? row.picApproveClientName
                      : "-"}
                  </TableCell>
                  <TableCell>Sedang Diproses</TableCell>
                  {(cekRole("requestOrder.d7") || cekRole("requestOrder.d8")) && (
                    <TableCell>
                      {cekRole("requestOrder.d8") && (
                        <Tooltip
                          title="Delete Proces Request Order"
                          placement="center"
                          id="warehouse-content"
                        >
                          <IconButton
                            color="secondary"
                            className={classes.button}
                            size="small"
                            disabled={!row.isFromApi}
                            onClick={() =>
                              handleDeleteDialog(row.code, row["_id"])
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {cekRole("requestOrder.d7") && (
                        <Tooltip
                          title="Proces Request Order"
                          placement="center"
                          id="warehouse-content"
                        >
                          <Button
                            disableElevation
                            variant="contained"
                            size="medium"
                            className={classes.button}
                            onClick={() =>
                              history.push(
                                routesConst.PROCESS_ORDER + `/${row["_id"]}`
                              )
                            }
                            startIcon={<Sync />}
                          >
                            Proses
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
      <DeleteRoDialog
        open={openDeleteDialog.open}
        handleClose={() =>
          setOpenDeleteDialog({ ...openDeleteDialog, open: false })
        }
        roNumber={openDeleteDialog.code}
        handleAccept={() => {
          deleteRO(openDeleteDialog.id).then(() =>
            setOpenDeleteDialog({ ...openDeleteDialog, open: false })
          );
        }}
      />
    </div>
  );
};

export default Processing;
