import { useMemo, useState } from "react";

export default function useDisclosure(defaultValue = false) {
  const [state, setState] = useState(defaultValue);

  const methods = useMemo(() => ({
    onOpen: () => setState(true),
    onClose: () => setState(false),
    onToggle: () => setState(s => !s),
  }), []);

  return { open: state, ...methods };
}