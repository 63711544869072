import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import routes from "../../constants/routes";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CustomTextField from "../custom/CustomTextField";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";
import CustomSelect from "../custom/CustomSelect";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Radio from "@material-ui/core/Radio";
import { useParams } from "react-router-dom";
import { getDetailDO } from "../../services/PurchaseOrderService";
import { getDetailStockDepot } from "../../services/StockDepotService";
import { getItemDetails } from "../../services/InventoryService";
import formatting from "../../utils/formatting";
import keyBy from "lodash.keyby";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  header: {
    lineHeight: "60px",
    fontWeight: "bold",
    color: theme.palette.blue,
  },
  title: {
    lineHeight: "60px",
    fontWeight: "bold",
  },
  itemGridLeft: {
    paddingRight: 12,
  },
  itemGridRight: {
    paddingLeft: 12,
  },
  inputField: {
    width: "100%",
  },
  buttonGroup: {
    marginBottom: 27,
  },
  buttonGroupSelected: {
    backgroundColor: theme.palette.lightBlue,
    borderColor: theme.palette.lightBlue,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.lightBlue,
      borderColor: theme.palette.lightBlue,
      color: theme.palette.white,
    },
  },
  select: {
    width: "100%",
  },
  inputWithSelect: {
    paddingLeft: 10,
  },
  table: {
    marginBottom: 30,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  grandTotal: {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.whiteBlue,
      color: theme.palette.titleText,
      fontWeight: "bold",
    },
  },
  paragraph: {
    margin: 0,
    borderRight: "2px solid",
    borderRightColor: theme.palette.whiteBlue,
  },
  hiddenRadioButton: {
    visibility: "hidden",
  },
  selectRow: {
    "&:hover": {
      color: theme.palette.blue,
      cursor: "pointer",
    },
  },
}));

const units = [
  { value: "FIXED", label: "Rp" },
  { value: "PERCENTAGE", label: "%" },
];

const PurchaseOrderDetail = (props) => {
  const classes = useStyles();
  // const history = useHistory();
  const { id } = useParams();

  const [data, setData] = useState({
    items: [{}],
    Suppliers: {},
  });
  const [selected, setSelected] = useState(0);
  const [depots, setDepots] = useState({});
  const [items, setItems] = useState({});

  useEffect(() => {
    getDetailDO(id).then((res) => {
      setData({
        ...res.data.item,
        items: res.data.item.TxPurchaseItems,
      });

      const inventoryPromises = [];
      const depotPromises = [];
      res.data.item.TxPurchaseItems.forEach((value) => {
        depotPromises.push(getDetailStockDepot(value.stockDepotId));
        inventoryPromises.push(getItemDetails(value.itemId));
      });

      Promise.all(depotPromises).then((values) => {
        setDepots(keyBy(values, (data) => data.data.item.id));
      });

      Promise.all(inventoryPromises).then((values) => {
        setItems(keyBy(values, (data) => data.data.item.id));
      });
    });
  }, [id]);

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={`Details Purchase Order ${data.code}`}
        previousPageName="Purchase Order"
        previousPageLink={routes.PURCHASE}
      />
      <Grid container item xs={12} className={classes.headerGrid}>
        <Typography variant="body1" className={`${classes.header}`}>
          Detail Purchase Order
        </Typography>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            className={`${classes.title} ${classes.borderBottom}`}
          >
            Order
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            className={`${classes.title} ${classes.borderBottom}`}
          >
            Detail Order
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                startIcon={<SearchIcon />}
                label="Supplier"
                className={classes.inputField}
                value={data.Suppliers.name}
                placeholder="Cari Nama Supplier"
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Nomor PO"
                className={classes.inputField}
                value={data.code}
                style={{ color: "black" }}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Penanggung Jawab"
                className={classes.inputField}
                value={data.picRoSupplierName}
                style={{ color: "black" }}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="No. Hp. Penanggung Jawab"
                className={classes.inputField}
                value={data.Suppliers.hpPic}
                style={{ color: "black" }}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Alamat"
                className={classes.inputField}
                value={data.Suppliers.address}
                placeholder="Masukan alamat lengkap"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Keterangan"
                className={classes.inputField}
                value={data.notes}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup color="primary" className={classes.buttonGroup}>
                <Button
                  className={clsx({
                    [classes.buttonGroupSelected]: data.type === "Obat",
                  })}
                  onClick={() => setData({ ...data, type: "Obat" })}
                  disabled
                >
                  Obat
                </Button>
                <Button
                  className={clsx({
                    [classes.buttonGroupSelected]:
                      data.type === "Bahan Habis Pakai",
                  })}
                  onClick={() =>
                    setData({ ...data, type: "Bahan Habis Pakai" })
                  }
                  disabled
                >
                  Bahan Habis Pakai
                </Button>
                <Button
                  className={clsx({
                    [classes.buttonGroupSelected]: data.type === "Logistik",
                  })}
                  onClick={() => setData({ ...data, type: "Logistik" })}
                  disabled
                >
                  Logistik
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Nama Item"
                className={classes.inputField}
                value={data.items[selected].name}
                startIcon={<SearchIcon />}
                placeholder="Cari Nama Obat"
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Jumlah Obat Dipesan"
                className={classes.inputField}
                value={`${data.items[selected].quantityOrder} ${data.items[selected].bigUnit}`}
                disabled
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Harga Dasar"
                className={classes.inputField}
                value={formatting.currencyFormat(
                  data.items[selected]?.Inventories?.basicFee
                )}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Harga Beli"
                className={classes.inputField}
                value={formatting.currencyFormat(data.items[selected].buyFee)}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <Grid container alignItems="flex-end">
                <Grid item xs={4}>
                  <CustomSelect
                    value="FIXED"
                    label="Diskon"
                    className={classes.select}
                    disabled
                  >
                    {units.map((data) => (
                      <MenuItem key={data.value} value={data.value}>
                        {data.label}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </Grid>
                <Grid item xs={8} className={classes.inputWithSelect}>
                  <CustomTextField
                    className={classes.inputField}
                    value={data.items[selected].discountRp}
                    currency
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Harga Jual"
                className={classes.inputField}
                value={formatting.currencyFormat(data.items[selected].sellFee)}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Tujuan Depot"
                className={classes.inputField}
                value={
                  depots[data.items[selected].stockDepotId]?.data?.item?.Depots
                    ?.name
                }
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Sub Kategori"
                className={classes.inputField}
                value={
                  items[data.items[selected].itemId]?.data?.item?.subCategory
                }
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Keterangan"
                className={classes.inputField}
                value={data.notes}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerLeft}></TableCell>
                    <TableCell>No</TableCell>
                    <TableCell>Obat</TableCell>
                    <TableCell>Jumlah</TableCell>
                    <TableCell>Harga</TableCell>
                    <TableCell className={classes.headerRight}>
                      Diskon
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.items.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell>
                        <Radio
                          color="primary"
                          checked
                          className={clsx({
                            [classes.hiddenRadioButton]: index !== selected,
                          })}
                        />
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell
                        className={classes.selectRow}
                        onClick={() => setSelected(index)}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell>
                        {row.quantityOrderOld ? row.quantityOrderOld : row.quantityOrder} {row.bigUnit}
                      </TableCell>
                      <TableCell>
                        <p className={classes.paragraph}>
                          {"@" + formatting.currencyFormat(row.oldBuyFee ? row.oldBuyFee : row.buyFee)}
                        </p>
                      </TableCell>
                      <TableCell>
                        {formatting.currencyFormat(row.discountRpOld ? row.discountRpOld : row.discountRp)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Sub Total"
                className={classes.inputField}
                value={formatting.currencyFormat(data.subTotalFee)}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Total PPN"
                className={classes.inputField}
                value={formatting.currencyFormatWithDecimalOrNot(data.ppnRp, 2)}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Total Diskon"
                className={classes.inputField}
                value={formatting.currencyFormat(data.discountRp)}
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Total Seluruhnya"
                className={`${classes.grandTotal} ${classes.inputField}`}
                value={formatting.currencyFormat(data.totalFee)}
                disabled
              />
            </Grid>
            {/* <Grid item xs={12}>
                            <Grid container justify="flex-end">
                                <Button
                                    disableElevation
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    onClick={() => history.push(routes.PURCHASE)}
                                >
                                    Batalkan
                                </Button>
                                <Button
                                    disableElevation
                                    variant="contained"
                                    size="large"
                                    onClick={() => history.push(routes.PURCHASE)}
                                >
                                    Pesan
                                </Button>
                            </Grid>
                        </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PurchaseOrderDetail;
