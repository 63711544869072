import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { palette } from "../../theme";
import CustomTextField from "../custom/CustomTextField";
import CustomSelect from "../custom/CustomSelect";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import routes, { adjustmentStokRoutes } from "../../constants/routes";
import CustomDialog from "../custom/CustomDialog";
import useSnackbar from "../../utils/useSnackbar";
import { addDepot } from "../../services/DepotService";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../store/general/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  editButton: {
    float: "right",
    marginLeft: 5,
    color: palette.blue,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  topGrid: {
    display: "flex",
    marginTop: 20,
  },
  marginContainer: {
    marginLeft: 20,
  },
  alignRight: {
    textAlign: "right",
  },
  buttonGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
}));

const types = ["Gudang", "Ruangan"];

const TambahDepot = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({ type: "Gudang" });
  const [isFetching, setIsFetching] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { setWarning } = useSnackbar();

  const handleAddDepot = () => {
    if (!data.name) {
      setWarning("Maaf! Silakan isi nama depot terlebih dahulu");
      return;
    }
    setIsFetching(true);
    addDepot(data)
      .then((res) => {
        dispatch(
          setSnackbar("success", "Sukses! Tambah data depot berhasil dilakukan")
        );
        history.push(routes.ADJUSTMENT + adjustmentStokRoutes.ADJUSTMENT_STOK);
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          setSnackbar(
            "error",
            "Error! Terjadi kesalahan. Coba beberapa saat lagi"
          )
        );
      });
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={`Tambah Depot`}
        previousPageName="Adjustment Stok"
        previousPageLink={routes.ADJUSTMENT}
      />
      <Grid item xs={12} className={classes.topGrid}>
        <div>
          <CustomTextField
            label="Nama"
            value={data.name}
            onChange={(event) => setData({ ...data, name: event.target.value })}
            placeholder="Isi Nama depot"
          />
        </div>
        <div className={classes.marginContainer}>
          <CustomSelect
            value={data.type}
            onChange={(event) => {
              setData({ ...data, type: event.target.value });
            }}
            label="Tipe"
            className={classes.select}
          >
            {types.map((data) => (
              <MenuItem key={data} value={data}>
                {data}
              </MenuItem>
            ))}
          </CustomSelect>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.buttonGrid}>
        <div>
          <Button
            disableElevation
            variant="contained"
            size="large"
            color="secondary"
            onClick={() =>
              history.push(
                routes.ADJUSTMENT + adjustmentStokRoutes.ADJUSTMENT_STOK
              )
            }
          >
            Batalkan
          </Button>
          <Button
            disableElevation
            variant="contained"
            size="large"
            onClick={() => setOpen(true)}
          >
            {isFetching ? <CircularProgress /> : "Tambah"}
          </Button>
        </div>
        <CustomDialog
          open={open}
          handleClose={() => setOpen(false)}
          title="Anda akan menambah Depot"
          body="Apakah data sudah benar? Data tidak dapat diedit / dihapus"
          onCancel={() => setOpen(false)}
          cancelButtonText="Batalkan"
          onAccept={isFetching ? null : handleAddDepot}
          acceptButtonText="Tambahkan"
          acceptButtonColor={palette.blue}
        />
      </Grid>
    </div>
  );
};

TambahDepot.propTypes = {};
export default TambahDepot;
