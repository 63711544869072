import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Header from "./Header";
import Sidebar from "./Sidebar";
import SettingSidebar from "./SettingSidebar";
import routesConst from "../constants/routes";
import { matchPath, useLocation } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  sidebarWrapper: {
    display: "flex",
    height: "100vh",
  },
  contentWrapperWithSettings: {
    width: "calc(100% - 320px)",
  },
  contentWrapperWithoutSettings: {
    width: "calc(100% - 70px)",
  },
  contentWrapper: {
    display: "inline-block",
    maxHeight: "100vh",
    overflow: "auto",
  },
}));

const BaseLayout = (props) => {
  const classes = useStyles();
  const location = useLocation();

  const { children } = props;

  const isSettingsSelected = useMemo(
    () => matchPath(location.pathname, { path: routesConst.SETTINGS }),
    [location.pathname]
  );

  return (
    <div className={classes.root}>
      <div className={classes.sidebarWrapper}>
        <Sidebar />
        {isSettingsSelected && <SettingSidebar />}
      </div>
      <div
        className={clsx(classes.contentWrapper, {
          [classes.contentWrapperWithSettings]: isSettingsSelected,
          [classes.contentWrapperWithoutSettings]: !isSettingsSelected,
        })}
      >
        <Header />
        {children}
      </div>
    </div>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.any,
};

export default BaseLayout;
