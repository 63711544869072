import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import CustomTextField from "../custom/CustomTextField";
import { palette } from "../../theme";
import routesConst from "../../constants/routes";
import validations from "../../utils/validations";
import { loginSuccess } from "../../store/auth/actions";
import { loginUser } from "../../services/AuthService";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loginTitle: {
    color: palette.titleText,
  },
  secondText: {
    color: palette.placeholderText,
  },
  lupaPassword: {
    cursor: "pointer",
    color: palette.titleText,
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: "13px",
    maxWidth: "288px",
    width: "100%",
  },
  loginButton: {
    maxWidth: "288px",
    width: "100%",
    borderRadius: "8px",
  },
  textField: {
    paddingTop: 25,
    maxWidth: "288px",
    width: "100%",
  },
  checkboxContainer: {
    display: "flex",
  },
  checkboxLabel: {
    paddingTop: "18px",
  },
}));

const Login = (props) => {
  const { dispatch } = props;
  const classes = useStyles();
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleLupaPassword = () => {
    history.push(routesConst.FORGOTPASS);
  };

  const checkError = () => {
    const usernameError = validations.isEmpty(username);
    const passwordError = validations.isEmpty(password);
    setError({ username: usernameError, password: passwordError });
    return usernameError || passwordError;
  };

  const handleLogin = () => {
    if (checkError()) {
      return;
    }

    loginUser(username, password)
      .then((res) => {
        dispatch(loginSuccess(res.data.data, checked));
      })
      .catch(console.error);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Typography
          variant="h1"
          color="textPrimary"
          className={classes.loginTitle}
        >
          Login
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Masukkan data yang telah Anda registrasi
        </Typography>
        <div className={classes.textField}>
          <CustomTextField
            label="Username"
            placeholder="Masukkan email/username Anda"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            error={error.username && validations.isEmpty(username)}
            helperText={
              error.username && validations.isEmpty(username)
                ? "Username tidak boleh kosong"
                : " "
            }
          />
        </div>
        <CustomTextField
          label="Password"
          placeholder="Masukkan Password Anda"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          error={error.password && validations.isEmpty(password)}
          helperText={
            error.password && validations.isEmpty(password)
              ? "Password tidak boleh kosong"
              : " "
          }
        />
        <div className={classes.checkboxContainer}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  name="tetap_masuk"
                />
              }
              label="Tetap masuk"
            />
          </FormGroup>
        </div>

        <Button
          type="submit"
          disableElevation
          variant="contained"
          size="large"
          className={classes.loginButton}
        >
          Login
        </Button>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.lupaPassword}
          onClick={handleLupaPassword}
        >
          Lupa Password
        </Typography>
      </form>
    </div>
  );
};

Login.propTypes = {};

export default connect()(Login);
