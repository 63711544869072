import { combineReducers } from "redux";
import general from "./general/reducer";
import auth from "./auth/reducer";
import inventory from "./inventory/reducer";

const reducers = combineReducers({
  general,
  auth,
  inventory,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return reducers(state, action);
};

export default rootReducer;
