import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import CustomTextField from "./custom/CustomTextField";
import CustomSelect from "./custom/CustomSelect";
import validations from "../utils/validations";
import CustomDropdownButton from "./custom/CustomDropdownButton";

const useStyles = makeStyles((theme) => ({
  border: {
    border: "1px solid black",
  },
  grid: {
    marginBottom: 30,
  },
  wrapper: {
    padding: 20,
    maxHeight: "calc(100vh - 19px)",
    overflow: "auto",
  },
}));

const selectTest = [
  { value: 1, label: "Hello 1" },
  { value: 2, label: "Hello 2" },
  { value: 3, label: "Hello 3" },
  { value: 4, label: "Hello 4" },
  { value: 5, label: "Hello 5" },
];

const DevOnly = (props) => {
  const classes = useStyles();
  const [test, setTest] = useState("");
  const [error, setError] = useState({});

  const checkError = () => {
    const testError = validations.isEmpty(test);
    setError({ test: testError });
  };

  return (
    <Grid container className={classes.wrapper}>
      <Grid item xs={12} container className={classes.grid}>
        <CustomSelect
          style={{ marginRight: 20 }}
          value={1}
          label="Label"
          helperText="Helper Text"
        >
          {selectTest.map((test) => (
            <MenuItem key={test.value} value={test.value}>
              {test.label}
            </MenuItem>
          ))}
        </CustomSelect>
        <CustomTextField
          style={{ marginRight: 20 }}
          label="Label"
          placeholder="Placeholder"
          value={test}
          onChange={(event) => setTest(event.target.value)}
          error={error.test && validations.isEmpty(test)}
          helperText={
            error.test && validations.isEmpty(test) ? "Error" : "Not Error"
          }
        />
        <CustomTextField
          style={{ marginRight: 20 }}
          label="Error"
          helperText="Error"
          placeholder="Error"
          error
        />
        <CustomTextField
          style={{ marginRight: 20 }}
          label="Disabled"
          helperText="Disabled"
          placeholder="Disabled"
          disabled
        />
        <CustomTextField
          style={{ marginRight: 20 }}
          label="With Icon"
          helperText="With Icon"
          placeholder="With Icon"
          startIcon={<CloudUploadIcon />}
        />
      </Grid>
      <Grid item xs={12} className={classes.grid}>
        <CustomDropdownButton label="Dropdown Button">
          {selectTest.map((test) => (
            <MenuItem
              key={test.value}
              value={test.value}
              selected={1 === test.value}
            >
              {test.label}
            </MenuItem>
          ))}
        </CustomDropdownButton>
      </Grid>
      <Grid item xs={12} className={classes.grid}>
        <Button
          disableElevation
          variant="contained"
          size="large"
          style={{ marginRight: 20 }}
          onClick={checkError}
        >
          Check Error
        </Button>
        <Button
          disableElevation
          variant="contained"
          size="large"
          style={{ marginRight: 20 }}
        >
          Slightly Long Label
        </Button>
        <Button
          disableElevation
          variant="contained"
          size="large"
          style={{ marginRight: 20 }}
        >
          Long Long Long Long Label
        </Button>
        <Button
          disableElevation
          variant="contained"
          size="large"
          disabled
          style={{ marginRight: 20 }}
        >
          Disabled
        </Button>
        <Button
          disableElevation
          variant="contained"
          size="large"
          color="secondary"
          style={{ marginRight: 20 }}
        >
          Secondary
        </Button>
        <Button
          disableElevation
          variant="contained"
          size="large"
          color="secondary"
          disabled
          style={{ marginRight: 20 }}
        >
          Disabled Secondary
        </Button>
        <Button
          disableElevation
          variant="contained"
          size="small"
          style={{ marginRight: 20 }}
        >
          Small button
        </Button>
        <Button
          disableElevation
          variant="contained"
          size="small"
          color="primary"
          style={{ marginRight: 20 }}
        >
          Small button 2
        </Button>
        <Button
          disableElevation
          variant="contained"
          size="small"
          style={{ marginRight: 20 }}
          startIcon={<CloudUploadIcon />}
        >
          With Icon
        </Button>
        <Button
          disableElevation
          variant="contained"
          size="small"
          color="primary"
          style={{ marginRight: 20 }}
          startIcon={<CloudUploadIcon />}
        >
          With Icon 2
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.grid}>
        <IconButton color="primary">
          <CloudUploadIcon />
        </IconButton>
        <IconButton color="secondary">
          <CloudUploadIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} className={classes.grid}>
        <Typography variant="h1" color="textPrimary">
          Heading 1
        </Typography>
        <Typography variant="h2" color="textPrimary">
          Heading 2
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Body 1
        </Typography>
        <Typography variant="body2" color="textPrimary">
          Body 2
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          Small Body
        </Typography>
        <Typography variant="h1" color="textSecondary">
          Heading 1
        </Typography>
        <Typography variant="h2" color="textSecondary">
          Heading 2
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Body 1
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Body 2
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Small Body
        </Typography>
        <Typography variant="h2" color="primary">
          Title
        </Typography>
      </Grid>
    </Grid>
  );
};

DevOnly.propTypes = {};

export default DevOnly;
