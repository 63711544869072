import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import routes from "../../constants/routes";
import Grid from "@material-ui/core/Grid";
import CustomTextField from "../custom/CustomTextField";
import CustomSelect from "../custom/CustomSelect";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import validations from "../../utils/validations";
import {
  getDetailSupplier,
  editSupplier,
  generateSupplierCode,
  addSupplier,
} from "../../services/SupplierService";

import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  inputField: {
    width: "100%",
  },
}));

const paymentMethods = ["COD", "TOP"];

const AddNewSupplier = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState({ paymentMethod: "" });
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState({});
  const { id } = useParams();

  useEffect(() => {
    if (!props.edit) {
      generateSupplierCode().then((res) =>
        setData({
          paymentMethod: "",
          code: res.data.code,
        })
      );
    }
  }, [props.edit]);

  useEffect(() => {
    if (props.edit) {
      getDetailSupplier(id).then((res) => setData({ ...res.data.item }));
    }
  }, [id, props.edit]);

  const validateSupplier = () => {
    const valid = {
      code: validations.isEmpty(data.code)
        ? "Kode supplier tidak boleh kosong"
        : undefined,
      name: validations.isEmpty(data.name)
        ? "Nama supplier tidak boleh kosong"
        : undefined,
      hpPic: validations.isEmpty(data.hpPic)
        ? "Nomor telepon tidak boleh kosong"
        : undefined,
      address: validations.isEmpty(data.address)
        ? "Alamat supplier tidak boleh kosong"
        : undefined,
      paymentMethod: validations.isEmpty(data.paymentMethod)
        ? "Cara pembayaran tidak boleh kosong"
        : undefined,
      termOfPayment: validations.isEmpty(data.termOfPayment)
        ? "Term of payment tidak boleh kosong"
        : undefined,
      ppn: validations.isEmpty(data.ppn)
        ? "PPN PKP tidak boleh kosong"
        : undefined,
    };
    setError(valid);
    return Object.values(valid).every((error) => !error);
  };

  const submitSupplier = () => {
    if (validateSupplier()) {
      if (props.edit) {
        setIsFetching(true);
        editSupplier(id, data).then((res) => history.push(routes.SUPPPLIER));
      } else {
        setIsFetching(true);
        addSupplier(data).then(() => history.push(routes.SUPPPLIER));
      }
    }
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={props.edit ? "Edit Supplier" : "Tambah Supplier"}
        previousPageName="Setting"
        previousPageLink={routes.SUPPPLIER}
      />
      <Grid container spacing={1} style={{ padding: 20 }}>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <CustomTextField
              label="Kode Supplier"
              value={data.code}
              onChange={(event) => {
                setData({ ...data, code: event.target.value });
                setError({ ...error, code: undefined });
              }}
              error={error.code}
              helperText={error.code}
              className={classes.inputField}
              requiredField
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              label="Nama Supplier"
              value={data.name}
              onChange={(event) => {
                setData({ ...data, name: event.target.value });
                setError({ ...error, name: undefined });
              }}
              error={error.name}
              helperText={error.name}
              className={classes.inputField}
              requiredField
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              label="No Telp"
              value={data.hpPic}
              type="number"
              onChange={(event) => {
                setData({ ...data, hpPic: event.target.value });
                setError({ ...error, hpPic: undefined });
              }}
              error={error.hpPic}
              helperText={error.hpPic}
              className={classes.inputField}
              requiredField
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <CustomTextField
              label="Alamat Supplier"
              value={data.address}
              onChange={(event) => {
                setData({ ...data, address: event.target.value });
                setError({ ...error, address: undefined });
              }}
              error={error.address}
              helperText={error.address}
              className={classes.inputField}
              requiredField
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelect
              value={data.paymentMethod}
              onChange={(event) => {
                setData({ ...data, paymentMethod: event.target.value });
                setError({ ...error, paymentMethod: undefined });
              }}
              label="Cara Pembayaran"
              error={error.paymentMethod}
              helperText={error.paymentMethod}
              requiredField
              className={classes.inputField}
            >
              {paymentMethods.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </CustomSelect>
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              label="Term Of Payment"
              value={data.termOfPayment}
              onChange={(event) => {
                setData({
                  ...data,
                  termOfPayment: isNaN(event.target.value)
                    ? ""
                    : event.target.value,
                });
                setError({ ...error, termOfPayment: undefined });
              }}
              error={error.termOfPayment}
              helperText={error.termOfPayment}
              className={classes.inputField}
              requiredField
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <CustomTextField
              label="PPN PKP"
              value={data.ppn}
              onChange={(event) =>
                setData({
                  ...data,
                  ppn: isNaN(event.target.value) ? "" : event.target.value,
                })
              }
              className={classes.inputField}
              error={error.ppn}
              helperText={error.ppn}
              requiredField
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              label="User Input"
              value={data.userInput}
              onChange={(event) =>
                setData({ ...data, userInput: event.target.value })
              }
              className={classes.inputField}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              label="User Edit"
              value={data.userEdit}
              onChange={(event) =>
                setData({ ...data, userEdit: event.target.value })
              }
              className={classes.inputField}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => history.push(routes.SUPPPLIER)}
            >
              Batalkan
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              onClick={isFetching ? null : submitSupplier}
            >
              {isFetching ? <CircularProgress /> : "Simpan"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddNewSupplier;
