import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { palette } from "../../theme";
import CustomTextField from "../custom/CustomTextField";
import CustomDropdownButton from "../custom/CustomDropdownButton";
import CustomSelect from "../custom/CustomSelect";

import debounce from "awesome-debounce-promise";
import moment from "moment";
import routes, { inventoryRoutes } from "../../constants/routes";

import { getStockExpires } from "../../services/InventoryService";
import CustomPagination from "../custom/CustomPagination";
import exportXL from "../../utils/exportXL";
import Print from "../../utils/Print";
import useCekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  editButton: {
    float: "right",
    marginLeft: 5,
    color: palette.blue,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  topGrid: {
    display: "flex",
  },
  marginContainer: {
    marginLeft: 20,
  },
  alignRight: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const FilterKadaluarsa = [
  {key: '', label: 'Semua'},
  { key: "Telah Kadaluarsa", label: "Kadaluarsa" },
  { key: "6-12 bulan", label: "6 Bulan" },
  { key: "12 bulan", label: "1 Tahun" },
];

const debouncedGetStockExpires = debounce(getStockExpires, 500);

function HeaderItem(props) {
  return (
    <Grid item xs={12} md={12} lg={3} style={{ paddingTop: 10 }} {...props} />
  );
}

const WarningKadaluarsa = (props) => {
  const classes = useStyles();
  const [searchForm, setSearchForm] = useState({});
  const [pagination, setPagination] = useState(paginationInitialData);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const cekRole = useCekHakAkses();
  const WarningKadaluarsaAccess = cekRole('inventory.f37');

  useEffect(() => {
    setIsFetchingData(true);
    debouncedGetStockExpires(
      searchForm.search,
      searchForm.status,
      pagination.page,
      pagination.rowsPerPage
    ).then((res) => {
      setTotalData(res.data.count);
      setTableData(res.data.items);
      setExportData([]);
    }).finally(() => setIsFetchingData(false));
  }, [searchForm, pagination]);

  const getDataExport = async () => {
    let page = 0;
    let skip = 0;
    const limit = 10;
    const fetch = async () => {
      const result = await getStockExpires(searchForm.search, searchForm.status, page, limit);
      exportData.splice(skip * limit, limit, ...result.data.items);
      setExportData(exportData);
    };
    if (exportData.length >= totalData) return;
    while (skip < totalData) {
      await fetch();
      page += 1;
      skip += limit;
    }
  };

  const handleExport = () => {
    setIsFetching(true);
    try {
      getDataExport().then(() => {
        const hasil = [];
        for (let i = 0; i < exportData.length; i += 1) {
          for (let j = 0; j < exportData[i].expiredDate.length; j += 1) {
            const dataPush = {
              "Kode Item": exportData[i].code,
              "Nama Item": exportData[i].name,
              "Tanggal Kadaluarsa": moment(exportData[i].expiredDate[j]).format(
                "DD/MM/YYYY"
              ),
              "No Batch": exportData[i].batchNo[j],
              Status:
                exportData[i].status[j] === "Telah Kadaluarsa"
                  ? "Kadaluarsa"
                  : `${exportData[i].status[j]} Hari Tersisa`,
              "Stok Obat": exportData[i].stock[j],
              Depot: exportData[i].depotName[j],
            };
            hasil.push(dataPush);
          }
        }
        setIsFetching(false);
        exportXL("Export Warning Kadaluarsa", hasil);
      });
    } catch (e) {
      setIsFetching(false);
      console.error(e);
    }
  };

  const handlePrint = () => {
    setIsFetching(true);
    try {
      getDataExport()
        .then(() => {
          setIsPrint(true);
          window.print();
          setIsFetching(false);
        })
        .finally(() => {
          setIsPrint(false);
          setExportData([]);
          setIsFetching(false);
        })
        .catch((e) => {
          console.error(e);
          setIsPrint(false);
          setIsFetching(false);
        });
    } catch (e) {
      console.error(e);
      setIsPrint(false);
      setIsFetching(false);
    }
  };

  return (
    <>
      <Grid container className={classes.headerGrid}>
      {!WarningKadaluarsaAccess && <Redirect to={(routes.INVENTORY + inventoryRoutes.WARNING_STOK)} />}
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h2" color="textPrimary">
            <b>Warning Kadaluarsa</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
        spacing={1}
      >
        <HeaderItem>
          <CustomTextField
            disabled={isFetching}
            label="Cari Data Inventory"
            placeholder="Nama Obat"
            value={searchForm.search}
            onChange={(event) =>
              setSearchForm({ ...searchForm, search: event.target.value })
            }
            startIcon={<SearchIcon />}
          />
        </HeaderItem>
        <HeaderItem>
          <CustomSelect
            disabled={isFetching}
            value={searchForm.status}
            onChange={(event) =>
              setSearchForm({ ...searchForm, status: event.target.value })
            }
            label="Berdasarkan Kadaluarsa"
          >
            {FilterKadaluarsa.map((status) => (
              <MenuItem key={status.key} value={status.key}>
                {status.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </HeaderItem>
        <Grid item xs={12} sm={3} className={classes.alignRight}>
          {isFetching ?
            <CircularProgress />
          :
            <CustomDropdownButton label="Export">
              {
                (cekRole('inventory.f40')) &&
                  <MenuItem
                    value={1}
                    onClick={
                      handleExport
                    }
                    selected
                  >
                    Export
                  </MenuItem>
              }
              {
                (cekRole('inventory.f41')) &&
                  <MenuItem
                    value={2}
                    onClick={
                      handlePrint
                    }
                  >
                    Print
                  </MenuItem>
              }
            </CustomDropdownButton>
          }
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Print style={{ overflow: "auto" }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerLeft}>Kode Item</TableCell>
                <TableCell>Nama Item</TableCell>
                <TableCell>Tanggal Kadaluarsa</TableCell>
                <TableCell>No Batch</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Stok Obat</TableCell>
                <TableCell className={classes.headerRight}>Depot</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                isFetchingData
                  ? <TableRow>
                    <TableCell colSpan={6} align={'center'}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                  : !isPrint && tableData.length === 0
                  ? <TableRow>
                    <TableCell colSpan={6} align={'center'}>
                      <p>Tidak ada data yang bisa ditampilkan.</p>
                    </TableCell>
                  </TableRow>
                  : !isPrint && tableData.map((row) => (
                    <TableRow key={row._id.name}>
                      <TableCell>{row.code}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {row.expiredDate.map((item) => (
                          <p>{`${moment(item).format("DD/MM/YYYY")}`}</p>
                        ))}
                      </TableCell>
                      <TableCell>
                        {row.batchNo.map((item) => (
                          <p>{`${item}`}</p>
                        ))}
                      </TableCell>
                      <TableCell>
                        {row.status.map((item) => {
                          if (item === "Telah Kadaluarsa") {
                            return <p style={{ color: "red" }}>Kadaluarsa</p>;
                          } else {
                            return <p>{`${item} Hari Tersisa`}</p>;
                          }
                        })}
                      </TableCell>
                      <TableCell>
                        {row.stock.map((item) => (
                          <p>{`${item}`}</p>
                        ))}
                      </TableCell>
                      <TableCell>
                        {row.depotName.map((item) => (
                          <p>{`${item}`}</p>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))
              }
              {isPrint &&
                exportData.map((row) => (
                  <TableRow key={row._id.name}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      {row.expiredDate.map((item) => (
                        <p>{`${moment(item).format("DD/MM/YYYY")}`}</p>
                      ))}
                    </TableCell>
                    <TableCell>
                      {row.batchNo.map((item) => (
                        <p>{`${item}`}</p>
                      ))}
                    </TableCell>
                    <TableCell>
                      {row.status.map((item) => {
                        if (item === "Telah Kadaluarsa") {
                          return <p style={{ color: "red" }}>Kadaluarsa</p>;
                        } else {
                          return <p>{`${item} Hari Tersisa`}</p>;
                        }
                      })}
                    </TableCell>
                    <TableCell>
                      {row.stock.map((item) => (
                        <p>{`${item}`}</p>
                      ))}
                    </TableCell>
                    <TableCell>
                      {row.depotName.map((item) => (
                        <p>{`${item}`}</p>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Print>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
    </>
  );
};

WarningKadaluarsa.propTypes = {};
export default WarningKadaluarsa;
