import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import moment from 'moment';
import debounce from 'awesome-debounce-promise';
import get from 'lodash/get';

import SearchIcon from '@material-ui/icons/Search';
import ProsesIcon from '@material-ui/icons/Cached';
import PrintIcon from '@material-ui/icons/Print';
import MenuItem from '@material-ui/core/MenuItem';
import CloudDownload from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';

import CustomTextField from '../custom/CustomTextField';
import CustomRangeDatePicker from '../custom/CustomRangeDatePicker';
import CustomSelect from '../custom/CustomSelect';
import CustomPagination from '../custom/CustomPagination';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import { dateFormat } from '../../utils/formatting';
import { getListReturn } from '../../services/PenerimaanService';
import { getSpecificStockExpires } from '../../services/InventoryService';

import {
  PenerimaanTitle,
  FilterContainer,
  MarginContainer,
} from './FilterPenerimaan';
import useCekHakAkses from '../../utils/cekHakAkses';

import PrintReturnBarang from './PrintReturnBarang';

export const statusSelect = [
  { key: '', label: 'Semuanya' },
  { key: 'new', label: 'Baru' },
  { key: 'received', label: 'Diterima' },
  { key: 'rejected', label: 'Direject' },
];

const STATUS_ENUM = {
  new: 'Baru',
  received: 'Diterima',
  rejected: 'Direject',
};

export const S3_URL = 'https://s3-ap-southeast-1.amazonaws.com';
export const S3_HOSPITAL_BUCKET = 'public-medicaboo'; // gambar dan file rumah sakit

const debouncedGetListReturn = debounce(getListReturn, 500);

function ReturnBarang() {
  const [searchForm, setSearchForm] = useState({
    status: '',
    startDate: moment().subtract(30, 'days').toDate(),
    endDate: new Date(),
  });
  const [page, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const [listReturn, setListReturn] = useState([]);
  useEffect(() => {
    async function fetch() {
      setIsFetching(true);
      const { data } = await debouncedGetListReturn({
        skip: page * rowPerPage,
        limit: rowPerPage,
        codeReturn: searchForm.search,
        status: searchForm.status,
        dateStart: moment(searchForm.startDate).format('YYYY-MM-DD 00:00:00'),
        dateEnd: moment(searchForm.endDate).format('YYYY-MM-DD 23:59:59'),
      });
      setCount(data.count);
      const promiseItem = data.items.map(async (item) => {
        const barangReturn = item.Items.map(async (barang) => {
          const {
            data: { items: listExpire },
          } = await getSpecificStockExpires(barang.itemId, 0, 1000);
          const indexMatch = listExpire.findIndex((itemExpire) => {
            return itemExpire.batchNo === barang.batchNo;
          });

          if (indexMatch === -1) {
            return {
              ...barang,
              isMatchExpire: false,
              listExpire,
            };
          }

          return {
            ...barang,
            isMatchExpire: true,
            depotName: listExpire[indexMatch].depotName,
            expireId: listExpire[indexMatch]._id,
          };
        });

        return {
          ...item,
          Items: await Promise.all(barangReturn),
        };
      });
      setListReturn(await Promise.all(promiseItem));
      setIsFetching(false);
    }
    fetch();
  }, [
    searchForm.status,
    searchForm.startDate,
    searchForm.endDate,
    searchForm.search,
    page,
    rowPerPage,
  ]);

  const [indexToPrint, setIndexToPrint] = useState(-1);

  useEffect(() => {
    if (indexToPrint !== -1) {
      window.print();
    }
    window.onafterprint = () => {
      setIndexToPrint(-1);
    };

    return function cleanup() {
      window.onafterprint = null;
    };
  }, [indexToPrint]);

  const history = useHistory();
  const cekRole = useCekHakAkses();

  function redirectToItemPage(code) {
    return () => history.push('/penerimaan/return/' + code);
  }

  const openPrint = (itemReturn) => {
    itemReturn.ItemsDemage.map((file) => {
      return window.open(`${S3_URL}/${S3_HOSPITAL_BUCKET}/${file.attachmentFile[0]}`, '_blank');
    });
  };

  return (
    <div>
      <PenerimaanTitle>Penerimaan Barang Return</PenerimaanTitle>
      <FilterContainer>
        <div>
          <CustomTextField
            label="Cari Data Barang Inbound"
            placeholder="Kode Return Barang"
            value={searchForm.search}
            onChange={(event) =>
              setSearchForm({ ...searchForm, search: event.target.value })
            }
            startIcon={<SearchIcon />}
          />
        </div>
        <MarginContainer>
          <CustomSelect
            value={searchForm.status}
            onChange={(event) =>
              setSearchForm({ ...searchForm, status: event.target.value })
            }
            label="Berdasarkan Status"
          >
            {statusSelect.map((status) => (
              <MenuItem key={status.key} value={status.key}>
                {status.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </MarginContainer>
        <MarginContainer>
          <CustomRangeDatePicker
            label="Berdasarkan Tanggal"
            startDate={searchForm.startDate}
            endDate={searchForm.endDate}
            onChange={([start, end]) =>
              setSearchForm({ ...searchForm, startDate: start, endDate: end })
            }
            placeholder="Berdasarkan Tanggal"
          />
        </MarginContainer>
      </FilterContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nama Client</TableCell>
            <TableCell>PIC Return</TableCell>
            <TableCell>PIC Approve</TableCell>
            <TableCell>User Receive</TableCell>
            <TableCell>Tanggal Return</TableCell>
            <TableCell>Kode Return</TableCell>
            <TableCell>PIC Return</TableCell>
            <TableCell>Nama Client</TableCell>
            <TableCell>Tanggal Diterima</TableCell>
            <TableCell>Jenis Return</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetching ? (
            <TableRow>
              <TableCell colSpan={12} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : listReturn.length === 0 ? (
            <TableRow>
              <TableCell colSpan={12} align="center">
                <p>Tidak ada data yang bisa ditampilkan.</p>
              </TableCell>
            </TableRow>
          ) : (
            listReturn.map((itemReturn, indexReturn) => (
              <TableRow>
                <TableCell>{itemReturn.hospitalName}</TableCell>
                <TableCell>{itemReturn.picRoClientName}</TableCell>
                <TableCell>{itemReturn.picApproveClientName}</TableCell>
                <TableCell>{itemReturn.hospitalName}</TableCell>
                <TableCell>{dateFormat(itemReturn.createdDate)}</TableCell>
                <TableCell>{itemReturn.code}</TableCell>
                <TableCell>{itemReturn.picRoClientName}</TableCell>
                <TableCell>{itemReturn.picApproveClientName}</TableCell>
                <TableCell>
                  {dateFormat(itemReturn.receivedDate) || '-'}
                </TableCell>
                <TableCell>
                  {itemReturn.ItemsDemage.length === 0
                    ? 'barang'
                    : 'barang rusak'}
                </TableCell>
                <TableCell>{STATUS_ENUM[itemReturn.status]}</TableCell>
                <TableCell>
                  {(() => {
                    if (
                      cekRole('itemRetrieval.b9') ||
                      cekRole('itemRetrieval.b10') ||
                      cekRole('itemRetrieval.b11') ||
                      cekRole('itemRetrieval.b13')
                    ) {
                      if (itemReturn.status === 'received') {
                        return (
                          <ButtonGroup>
                            {cekRole('itemRetrieval.b10') && (
                              <Button
                                onClick={redirectToItemPage(itemReturn.code)}
                              >
                                Detail
                              </Button>
                            )}
                            {cekRole('itemRetrieval.b11') && (
                              <Button
                                variant="contained"
                                onClick={() => setIndexToPrint(indexReturn)}
                              >
                                <PrintIcon />
                              </Button>
                            )}
                            {cekRole('itemRetrieval.b13') &&
                              itemReturn.ItemsDemage.length !== 0 &&
                              itemReturn.ItemsDemage.find(
                                (item) => item.attachmentFile,
                              ) && (
                                <Button
                                  variant="contained"
                                  onClick={() => openPrint(itemReturn)}
                                >
                                  <CloudDownload />
                                </Button>
                              )}
                          </ButtonGroup>
                        );
                      } else if (itemReturn.status === 'new') {
                        return (
                          (cekRole('itemRetrieval.b9') ||
                            cekRole('itemRetrieval.b12') ||
                            cekRole('itemRetrieval.b13')) && (
                            <ButtonGroup>
                              <Button
                                variant="contained"
                                startIcon={<ProsesIcon />}
                                onClick={redirectToItemPage(itemReturn.code)}
                              >
                                Proses
                              </Button>
                              {cekRole('itemRetrieval.b13') &&
                                itemReturn.ItemsDemage.length !== 0 &&
                                itemReturn.ItemsDemage.find(
                                  (item) => item.attachmentFile,
                                ) && (
                                  <Button
                                    variant="contained"
                                    onClick={() => openPrint(itemReturn)}
                                  >
                                    <CloudDownload />
                                  </Button>
                                )}
                            </ButtonGroup>
                          )
                        );
                      }
                      return null;
                    }
                  })()}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <CustomPagination
        count={count}
        onChangePage={(evt, pageNumber) => setPage(pageNumber)}
        onChangeRowsPerPage={(evt) => setRowPerPage(evt.target.value)}
        page={page}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} dari ${count} data`
        }
        rowsPerPage={rowPerPage}
      />
      <PrintReturnBarang
        itemsReturn={get(listReturn, `[${indexToPrint}].Items`, [])}
        itemsDemage={get(listReturn, `[${indexToPrint}].ItemsDemage`, [])}
      />
    </div>
  );
}

export default ReturnBarang;
