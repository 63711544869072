import { getPlaceId } from "../store/auth/selector";
import agent from "./agent";

export const getReportPenjualan = (search, dateStart, dateEnd, skip, limit) => {
  return agent.get("/reports/one", {
    params: {
      filter: { placeId: getPlaceId(), search, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};
export const getReportAdjustmentStock = (
  search,
  dateStart,
  dateEnd,
  skip,
  limit
) => {
  return agent.get("/reports/two", {
    params: {
      filter: { placeId: getPlaceId(), search, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};
export const getReportRO = (search, dateStart, dateEnd, skip, limit) => {
  return agent.get("/reports/three", {
    params: {
      filter: { placeId: getPlaceId(), search, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};
export const getReportPenerimaan = (
  search,
  dateStart,
  dateEnd,
  skip,
  limit
) => {
  return agent.get("/reports/four", {
    params: {
      filter: { placeId: getPlaceId(), search, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};
export const getReportFaktur = (
  search,
  status,
  dateStart,
  dateEnd,
  skip,
  limit
) => {
  return agent.get("/reports/five", {
    params: {
      filter: { placeId: getPlaceId(), search, status, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};
export const getReportFastMoving = (
  search,
  dateStart,
  dateEnd,
  sort,
  skip,
  limit
) => {
  return agent.get("/reports/six", {
    params: {
      filter: { placeId: getPlaceId(), search, dateStart, dateEnd },
      sort,
      skip: skip * limit,
      limit,
    },
  });
};
export const getReportDataStok = (search, skip, limit) => {
  return agent.get("/reports/seven", {
    params: {
      filter: { placeId: getPlaceId(), search },
      skip: skip * limit,
      limit,
    },
  });
};
export const getReportDeliveryOrder = (
  search,
  clientName,
  dateStart,
  dateEnd,
  skip,
  limit
) => {
  return agent.get("/reports/eight", {
    params: {
      filter: { placeId: getPlaceId(), search, clientName, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};
export const getReportSelisihRO = (search, dateStart, dateEnd, skip, limit) => {
  return agent.get("/reports/nine", {
    params: {
      filter: { placeId: getPlaceId(), search, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};
export const getReportKekayaanBarang = (
  search,
  category,
  dateStart,
  dateEnd,
  skip,
  limit
) => {
  return agent.get("/reports/ten", {
    params: {
      filter: { placeId: getPlaceId(), search, category, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};
export const getReportSelisihPO = (search, dateStart, dateEnd, skip, limit) => {
  return agent.get("/reports/eleven", {
    params: {
      filter: { placeId: getPlaceId(), search, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};
export const getReportPO = (search, dateStart, dateEnd, skip, limit) => {
  return agent.get("/reports/twelve", {
    params: {
      filter: { placeId: getPlaceId(), search, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};
export const getReportReturn = (search, status, dateStart, dateEnd, createdDateStart, createdDateEnd, skip, limit) => {
  return agent.get("/reports/fourteen", {
    params: {
      filter: { placeId: getPlaceId(), search, status, dateStart, dateEnd, createdDateStart, createdDateEnd },
      skip: skip * limit,
      limit,
    },
  });
};
