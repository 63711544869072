import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import debounce from "awesome-debounce-promise";
import CircularProgress from "@material-ui/core/CircularProgress";

import CustomTextField from "../custom/CustomTextField";
import CustomRangeDatePicker from "../custom/CustomRangeDatePicker";
import CustomPagination from "../custom/CustomPagination";
import routes from "../../constants/routes";
import { getDetailSales, getListSales } from "../../services/SalesService";
import {
  dateFormat,
  paramDateFormat,
  statusFormat,
} from "../../utils/formatting";
import Print from '../../utils/Print';
import InvoiceDocument from "../Document/InvoiceDocument";
import useCekHakAkses from "../../utils/cekHakAkses";
import usePdfPrint from "../../utils/usePdfPrint";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  headerTitle: {
    color: theme.palette.blue,
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  button: {
    marginLeft: 5,
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debouncedGetListSales = debounce(getListSales, 500);

const Sales = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = useCekHakAkses();

  const [searchForm, setSearchForm] = useState({
    startDate: moment().subtract(30, "day").toDate(),
    endDate: new Date(),
  });
  const [pagination, setPagination] = useState(paginationInitialData);
  const [totalData, setTotalData] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const { printPdfProps, setPdfProps } = usePdfPrint({ frameName: 'invoice' });

  useEffect(() => {
    setIsFetching(true);
    debouncedGetListSales(
      searchForm.search,
      paramDateFormat(searchForm.startDate),
      paramDateFormat(searchForm.endDate),
      pagination.page,
      pagination.rowsPerPage
    ).then((res) => {
      setTotalData(res.data.count);
      setTableData(res.data.items);
      setIsFetching(false);
    });
  }, [searchForm, pagination]);

  async function downloadDocument(id) {
    // get current item and assign loading indicator
    const dataIndex = tableData.findIndex(data => data['_id'] === id);
    setTableData(data => {
      const copy = [...data];
      copy[dataIndex].isLoading = true;
      return copy;
    });

    // fetch
    const res = await getDetailSales(id);

    // set the data
    setPdfProps({
      data: res.data.item,
      items: res.data.item.TxItems.map((value, index) => {
          return {
            ...value,
            no: index + 1,
          };
        })
    }, () => {
      setTableData(data => {
        // reset loading
        const copy = [...data];
        copy[dataIndex].isLoading = false;
        return copy;
      });
    });
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.headerGrid}>
        <Grid item container xs={12} alignItems="center">
          <Typography
            variant="h2"
            color="textSecondary"
            className={classes.headerTitle}
          >
            Sales
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid item xs={5} lg={3} style={{ paddingTop: 10 }}>
          <CustomTextField
            label="Cari Data Sales"
            placeholder="Kode atau Nama Customer"
            value={searchForm.search}
            onChange={(event) =>
              setSearchForm({ ...searchForm, search: event.target.value })
            }
            startIcon={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={5} lg={3} style={{ paddingTop: 10 }}>
          <CustomRangeDatePicker
            label="Berdasarkan Tanggal"
            startDate={searchForm.startDate}
            endDate={searchForm.endDate}
            onChange={([start, end]) =>
              setSearchForm({ ...searchForm, startDate: start, endDate: end })
            }
            placeholder="Berdasarkan Tanggal"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} className=".hideForPrint" id="warehouse-content">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Tanggal</TableCell>
              <TableCell>No. Invoice</TableCell>
              <TableCell>Nama Customer</TableCell>
              <TableCell>Last Edit By</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : tableData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <p>Tidak ada data yang bisa ditampilkan</p>
                </TableCell>
              </TableRow>
            ) : (
              tableData.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{dateFormat(row.createdDate)}</TableCell>
                  <TableCell>{row.codeRo}</TableCell>
                  <TableCell>{row.hospitalName || row.customerName}</TableCell>
                  <TableCell>{row.updatedName}</TableCell>
                  <TableCell>{statusFormat(row.status)}</TableCell>
                  <TableCell>
                    <Grid container justify="flex-end">
                      {cekRole("sales.e2") && (
                        <Tooltip
                          title={
                            row.status !== "paid off"
                              ? "Bayar Invoice"
                              : "Detail Pembayaran"
                          }
                          placement="center"
                          id="warehouse-content"
                        >
                          <Button
                            disableElevation
                            variant="contained"
                            size="small"
                            onClick={() =>
                              history.push(routes.SALES + `/${row._id}`)
                            }
                            className={classes.button}
                            startIcon={
                              row.status !== "paid off" ?
                              <img
                                width={20}
                                src={
                                 require("../../assets/dompet.png")
                                }
                                alt="Dompet"
                              />
                              :
                              <InsertDriveFileIcon />
                            }
                          >
                            {row.status !== "paid off" ? "Bayar" : "Detail"}
                          </Button>
                        </Tooltip>
                      )}
                      {cekRole("sales.e3") && row.status === 'paid off' && (
                        <Tooltip title="Cetak Invoice" placement="center" id="warehouse-content">
                          <Button
                            disableElevation
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => downloadDocument(row._id)}
                            className={classes.button}
                            startIcon={
                              row.isLoading
                                ? (
                                  <CircularProgress size={24} />
                                )
                                : (
                                  <img
                                    width={20}
                                    src={require("../../assets/printer.png")}
                                    alt="Printer"
                                  />
                                )
                            }
                          >
                            Cetak Invoice
                          </Button>
                        </Tooltip>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
      {Object.keys(printPdfProps).length > 0 && (
        <Print isHidden>
          <InvoiceDocument {...printPdfProps} />
        </Print>
      )}
    </div>
  );
};

export default Sales;
