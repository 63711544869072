import React, { useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles, Button, Menu } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import omit from "lodash.omit";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex" },
  menuPaper: {
    // marginLeft: 16,
    // marginRight: 16,
    minWidth: "fit-content",
  },
  button: {
    paddingRight: 0,
    borderRadius: "50px 0 0 50px",
  },
  dropdownBtn: {
    borderRadius: "0 50px 50px 0",
    minWidth: 0,
  },
}));

const CustomDropdownButton = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [buttonWidth, setButtonWidth] = useState(168);
  const buttonRef = useRef(null);

  useLayoutEffect(() => {
    setButtonWidth(buttonRef.current.clientWidth);
  }, [props.label]);

  const buttonProps = omit(props, ["children", "label"]);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <Button
        disableElevation
        variant="contained"
        size="large"
        onMouseEnter={!props.side && openMenu}
        className={props.side && classes.button}
        ref={buttonRef}
        {...buttonProps}
      >
        {props.label}
      </Button>
      {props.side && (
        <Button
          disableElevation
          variant="contained"
          size="large"
          onClick={openMenu}
          className={classes.dropdownBtn}
        >
          <ExpandMore />
        </Button>
      )}
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        classes={{
          paper: classes.menuPaper,
        }}
        PaperProps={{
          style: {
            width: buttonWidth - 32,
          },
        }}
        style={{ width: buttonWidth }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
      >
        {props.children}
      </Menu>
    </div>
  );
};

CustomDropdownButton.defaultProps = {
  label: "",
  side: false,
};

CustomDropdownButton.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  side: PropTypes.bool,
};

export default CustomDropdownButton;
