import { getPlaceId } from "../store/auth/selector";
import agent from "./agent";

export const getListInventoryDepot = (itemId) => {
  return agent.get("/depotInventories/getList", {
    params: {
      filter: { placeId: getPlaceId(), itemId },
    },
  });
};

export const getListRepairStock = (search, dateStart, dateEnd, skip, limit) => {
  return agent.get("/depotInventories/getHistoryRepair", {
    params: {
      filter: { placeId: getPlaceId(), search, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};

export const getListForAdjustment = (depotId, search, skip, limit) => {
  return agent.get("depotInventories/getListForAdjustment", {
    params: {
      filter: { placeId: getPlaceId(), depotId, search },
      skip: skip * limit,
      limit,
    },
  });
};

export const editStock = (stockDepotId, body) => {
  return agent.put(`/depotInventories/${stockDepotId}/repairStockV2`, body);
};
