import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import CustomTextField from "../custom/CustomTextField";
import {
  addCustomer,
  editCustomer,
  generateCustomerCode,
  getDetailCustomer,
} from "../../services/CustomerService";
import routes from "../../constants/routes";
import validations from "../../utils/validations";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  inputField: {
    width: "100%",
  },
}));

const AddNewClient = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState({});
  const { id } = useParams();
  const [error, setError] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!props.edit) {
      generateCustomerCode().then((res) =>
        setData({
          code: res.data.code,
        })
      );
    }
  }, [props.edit]);

  useEffect(() => {
    if (props.edit) {
      getDetailCustomer(id).then((res) => setData({ ...res.data.item }));
    }
  }, [id, props.edit]);

  const submitCustomer = (e) => {
    setIsFetching(true);
    e.preventDefault();
    if (validateCustomer()) {
      if (props.edit) {
        editCustomer(id, data).then(() => history.push(routes.CLIENT));
        setIsFetching(false);
      } else {
        addCustomer(data).then(() => history.push(routes.CLIENT));
        setIsFetching(false);
      }
    } else {
      setIsFetching(false);
    }
  };

  const validateCustomer = () => {
    const valid = {
      code: validations.isEmpty(data.code)
        ? "Kode client tidak boleh kosong"
        : undefined,
      name: validations.isEmpty(data.name)
        ? "Nama client tidak boleh kosong"
        : undefined,
      hp: validations.isEmpty(data.hp)
        ? "Nomor telepon tidak boleh kosong"
        : undefined,
      address: validations.isEmpty(data.address)
        ? "Alamat client tidak boleh kosong"
        : undefined,
      company: validations.isEmpty(data.company)
        ? "Perusahaan / Fasilitas Kesehatan tidak boleh kosong"
        : undefined,
    };
    setError(valid);
    return Object.values(valid).every((error) => !error);
  };

  return (
    <form onSubmit={submitCustomer} className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={props.edit ? "Edit Client" : "Tambah Client"}
        previousPageName="Setting"
        previousPageLink={routes.CLIENT}
      />
      <Grid container spacing={1} style={{ padding: 20 }}>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <CustomTextField
              label="Kode Client"
              value={data.code}
              onChange={(event) =>
                setData({ ...data, code: event.target.value })
              }
              className={classes.inputField}
              error={error.code}
              helperText={error.code}
              requiredField
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              label="Nama Client"
              value={data.name}
              onChange={(event) =>
                setData({ ...data, name: event.target.value })
              }
              className={classes.inputField}
              error={error.name}
              helperText={error.name}
              requiredField
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              label="No. WA"
              value={data.hp}
              type="number"
              onChange={(event) => setData({ ...data, hp: event.target.value })}
              className={classes.inputField}
              error={error.hp}
              helperText={error.hp}
              requiredField
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <CustomTextField
              label="Alamat"
              value={data.address}
              onChange={(event) =>
                setData({ ...data, address: event.target.value })
              }
              className={classes.inputField}
              error={error.address}
              helperText={error.address}
              requiredField
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              label="Perusahaan / Fasilitas Kesehatan"
              value={data.company}
              onChange={(event) =>
                setData({ ...data, company: event.target.value })
              }
              className={classes.inputField}
              error={error.company}
              helperText={error.company}
              requiredField
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => history.push(routes.CLIENT)}
            >
              Batalkan
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              type="submit"
            >
              {isFetching ? (
                <CircularProgress />
              ) : props.edit ? (
                "Update"
              ) : (
                "Simpan"
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddNewClient;
