import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import routes, { penerimaanRoutes } from "../../constants/routes";
import { useHistory, useLocation } from "react-router-dom";
import useCekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
}));

const tabs = [
  { label: "Penerimaan Barang PO", path: penerimaanRoutes.PENERIMAAN_MENU},
  { label: "Penerimaan Barang Return", path: penerimaanRoutes.RETURN },
];

const PenerimaanLayout = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const cekRole = useCekHakAkses();
  const [tab, setTab] = React.useState(0);

  useEffect(() => {
    const path = location.pathname.replace(routes.PENERIMAAN, "");
    let selectedTab = tabs.findIndex((tab) => tab.path === path);
    if (selectedTab === -1) selectedTab = 0;
    setTab(selectedTab);
  }, [location.pathname]);

  const handleTabChange = (event, newValue) => {
    const newRoute = routes.PENERIMAAN + tabs[newValue].path;
    history.push(newRoute);
  };

  return (
    <div className={classes.root}>
      <Tabs textColor="primary" value={tab} onChange={handleTabChange}>
        {tabs.map((tabObj) => (
          <Tab
            key={tabObj.label}
            label={tabObj.label}
            style={
              !cekRole("itemRetrieval.b7") && tabObj.label === "Penerimaan Barang PO"
                ? { display: "none" }
                : !cekRole("itemRetrieval.b8") && tabObj.label === "Penerimaan Barang Return"
                ? { display: "none" }
                : {}
            }
          />
        ))}
      </Tabs>
      {props.children}
    </div>
  );
};

export default PenerimaanLayout;
