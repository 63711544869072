import { SET_USER, SET_TOKEN, LOGOUT } from "./action-types";
import LogRocket from 'logrocket';

export const loginSuccess = (data, remember) => (dispatch) => {
  LogRocket.identify(data.userId, { // identify user so logrocket knows the current user
    name: data.username,
    email: data.email,
    hospitalName: 'Warehouse',
  });
  dispatch(setUser(data, remember));
  dispatch(setToken(data.id, remember));
};

export const logout = (isIdle = false) => (dispatch) => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("token");
  return dispatch({
    type: LOGOUT,
    payload: { isIdle },
  });
};

export const setUser = (data, remember) => (dispatch) => {
  if (remember) {
    localStorage.setItem("user", JSON.stringify(data));
  } else {
    sessionStorage.setItem("user", JSON.stringify(data));
  }
  return dispatch({
    type: SET_USER,
    payload: data,
  });
};

export const setToken = (data, remember) => (dispatch) => {
  if (remember) {
    localStorage.setItem("token", data);
  } else {
    sessionStorage.setItem("token", data);
  }
  return dispatch({
    type: SET_TOKEN,
    payload: data,
  });
};
