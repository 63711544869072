import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { palette } from "../../theme";
import CustomTextField from "../custom/CustomTextField";
import CustomPagination from "../custom/CustomPagination";
import CustomSelect from "../custom/CustomSelect";
import CustomDropdownButton from "../custom/CustomDropdownButton";

import debounce from "awesome-debounce-promise";

import {
  getListBarangRusak,
  getListExportBarangRusak,
} from "../../services/InventoryService";
import clsx from "clsx";
import exportXL from "../../utils/exportXL";
import Print from "../../utils/Print";
import useCekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {},
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  editButton: {
    float: "right",
    marginLeft: 5,
    color: palette.blue,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  topGrid: {
    display: "flex",
  },
  marginContainer: {
    marginLeft: 20,
  },
  alignRight: {
    textAlign: "right",
  },
  notActive: {
    color: palette.disabledTextfield,
  },
}));

const statusSelect = [
  { key: "Semuanya", label: "Semuanya" },
  { key: "Aktif", label: "Aktif" },
  { key: "Tidak", label: "Tidak Aktif" },
];

const tipeSelect = [
  { key: undefined, label: "Semuanya" },
  { key: "Obat", label: "Obat" },
  { key: "BHP", label: "BHP" },
  { key: "Logistik", label: "Logistik" },
];

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debouncedGetList = debounce(getListBarangRusak, 500);

function HeaderItem(props) {
  return (
    <Grid item xs={12} md={12} lg={3} style={{ paddingTop: 10 }} {...props} />
  );
}

const BarangRusak = (props) => {
  const classes = useStyles();
  const [searchForm, setSearchForm] = useState({
    status: "Semuanya",
  });
  const [pagination, setPagination] = useState(paginationInitialData);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [buttonMenuSelect] = useState();
  const [exportData, setExportData] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);

  const cekRole = useCekHakAkses();

  useEffect(() => {
    setIsFetchingData(true);
    debouncedGetList(
      searchForm.search,
      searchForm.category,
      searchForm.type,
      pagination.page,
      pagination.rowsPerPage,
      searchForm.status
    )
      .then((res) => {
        setTotalData(res.data.count);
        setTableData(res.data.items);
        setExportData([]);
      })
      .finally(() => setIsFetchingData(false));
  }, [searchForm, pagination]);

  // const matches = useMediaQuery(theme => theme.breakpoints.down('md'))

  const getDataExport = async () => {
    let page = 0;
    let skip = 0;
    const limit = 10;
    const fetch = async () => {
      const result = await getListExportBarangRusak(
        searchForm.search,
        searchForm.category,
        searchForm.type,
        page,
        limit,
        searchForm.status
      );
      exportData.splice(skip * limit, limit, ...result.data.items);
      setExportData(exportData);
    };
    if (exportData.length >= totalData) return;
    while (skip < totalData) {
      await fetch();
      page += 1;
      skip += limit;
    }
  };

  const handleExport = () => {
    setIsFetching(true);
    try {
      getDataExport().then(() => {
        const hasil = exportData.map((val) => ({
          Kode: val.code,
          "Nama Barang": val.name,
          Tipe: val.type,
          Kategori: val.category,
          "Satuan Kecil": val.smallStock,
          "Satuan Besar": val.bigStock,
          "Min Stok": val.stockWarning,
          Status: val.isActive ? "Aktif" : "Tidak Aktif",
          "Buffer Stok": val.stockBuffer,
        }));
        exportXL("Export Barang Rusak", hasil);
        setIsFetching(false);
      });
    } catch (e) {
      console.error(e);
      setIsFetching(false);
    }
  };

  const handlePrint = () => {
    setIsFetching(true);
    try {
      getDataExport()
        .then(() => {
          setIsPrint(true);
          window.print();
        })
        .finally(() => {
          setIsPrint(false);
          setExportData([]);
          setIsFetching(false);
        })
        .catch((e) => {
          console.error(e);
          setIsPrint(false);
          setIsFetching(false);
        });
    } catch (e) {
      console.error(e);
      setIsPrint(false);
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.headerGrid}>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h2" color="textPrimary">
            <b>Data Barang Rusak</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
        spacing={1}
      >
        <HeaderItem>
          <CustomTextField
            disabled={isFetching}
            label="Cari Data Inventory"
            placeholder="Kode dan Nama Barang"
            value={searchForm.search}
            onChange={(event) =>
              setSearchForm({ ...searchForm, search: event.target.value })
            }
            startIcon={<SearchIcon />}
          />
        </HeaderItem>
        <HeaderItem>
          <CustomTextField
            disabled={isFetching}
            label="Berdasarkan Kategori"
            placeholder="Kategori"
            value={searchForm.category}
            onChange={(event) =>
              setSearchForm({ ...searchForm, category: event.target.value })
            }
            startIcon={<SearchIcon />}
          />
        </HeaderItem>
        <HeaderItem>
          <CustomSelect
            disabled={isFetching}
            value={searchForm.status}
            onChange={(event) =>
              setSearchForm({ ...searchForm, status: event.target.value })
            }
            label="Berdasarkan Status"
          >
            {statusSelect.map((status) => (
              <MenuItem key={status.key} value={status.key}>
                {status.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </HeaderItem>
        <Grid item xs={3} container justify="flex-end">
          {isFetching ? (
            <CircularProgress />
          ) : (
            <CustomDropdownButton
              disableElevation
              variant="contained"
              size="large"
              onClick={handleExport}
              label="Export Barang Rusak"
              side
              disabled={!cekRole("inventory.f53")}
            >
              <MenuItem
                key="print"
                value={1}
                selected={1 === buttonMenuSelect}
                onClick={handlePrint}
                disabled={!cekRole("inventory.f54")}
              >
                Print
              </MenuItem>
            </CustomDropdownButton>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={12} alignItems="center">
        <div style={{ paddingTop: 20 }}>
          <CustomSelect
            disabled={isFetching}
            style={{ marginRight: 20 }}
            value={searchForm.type}
            onChange={(event) =>
              setSearchForm({ ...searchForm, type: event.target.value })
            }
            label="Berdasarkan Tipe"
          >
            {tipeSelect.map((tipe) => (
              <MenuItem key={tipe.key} value={tipe.key}>
                {tipe.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </div>
      </Grid>
      <Grid item xs={12} style={{ overflow: "auto" }}>
        <Print>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerLeft}>Kode</TableCell>
                <TableCell>Nama Barang</TableCell>
                <TableCell>Tipe</TableCell>
                <TableCell>Kategori</TableCell>
                <TableCell>Satuan Kecil</TableCell>
                <TableCell>Satuan Besar</TableCell>
                <TableCell>Min Stock</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Buffer Stock</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetchingData ? (
                <TableRow>
                  <TableCell colSpan={10} align={"center"}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : !isPrint && tableData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10} align={"center"}>
                    <p>Tidak ada data yang bisa ditampilkan.</p>
                  </TableCell>
                </TableRow>
              ) : (
                !isPrint &&
                tableData.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{row.smallStock}</TableCell>
                    <TableCell>{row.bigStock}</TableCell>
                    <TableCell>{row.stockWarning}</TableCell>
                    <TableCell
                      className={clsx({
                        [classes.notActive]: !row.isActive,
                      })}
                    >
                      {row.isActive ? "Aktif" : "Tidak Aktif"}
                    </TableCell>
                    <TableCell>{row.stockBuffer}</TableCell>                   
                  </TableRow>
                ))
              )}
              {isPrint &&
                exportData.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{row.smallStock}</TableCell>
                    <TableCell>{row.bigStock}</TableCell>
                    <TableCell>{row.stockWarning}</TableCell>
                    <TableCell>
                      {row.isActive ? "Aktif" : "Tidak Aktif"}
                    </TableCell>
                    <TableCell>{row.stockBuffer}</TableCell>
                    <TableCell>{row.stockDifference}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Print>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
    </div>
  );
};

BarangRusak.propTypes = {};
export default BarangRusak;
