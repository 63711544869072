import { getPlaceId } from "../store/auth/selector";
import agent from "./agent";

export const getListPenerimaan = (
  search,
  status,
  dateStart,
  dateEnd,
  skip,
  limit
) => {
  return agent.get("/receives/getList", {
    params: {
      filter: { placeId: getPlaceId(), search, status, dateStart, dateEnd },
      skip: skip * limit,
      limit,
    },
  });
};

export const getPenerimaanDetail = (id) => {
  return agent.get(`/receives/${id}/getDetail`);
};

export const processPenerimaan = (id, data) => {
  return agent.put(`/receives/${id}/processVer2`, data);
};

export const getReturDetail = (id) => {
  return agent.get(`/receives/${id}/getDetailForReturn`);
};

export const deletePenerimaan = (id) => {
  return agent.put(`/receives/${id}/delete`);
};

export const returBarang = (id, data) => {
  return agent.put(`/receives/${id}/return`, data);
};

// Bagian penerimaan return barang
export const getListReturn = ({
  skip,
  limit,
  codeReturn,
  status,
  dateStart,
  dateEnd,
}) => {
  return agent.get('/returns/getList', {
    params: {
      skip,
      limit,
      codeReturn,
      status,
      dateStart,
      dateEnd,
    },
  });
}

export const processReturn = ({
  returnRequestId,
  items,
}) => {
  return agent.put('/returns/processVer2', {
    returnRequestId,
    items,
  });
}

export const processReturnRusak = ({
  returnRequestId,
  ItemsDemage,
}) => {
  return agent.post('/returns/processVer2Damaged', {
    returnRequestId,
    ItemsDemage,
  });
}

export const rejectReturn = ({
  id,
}) => {
  return agent.put(`/returns/${id}/reject`);
}

export const rejectReturnBarangRusak = ({
  id,
}) => {
  return agent.put(`/returns/${id}/rejectDamage`);
}
// api untuk edit proses penerimaan barang
export const editPenerimaan = (id, data) => {
  return agent.put(`/receives/${id}/editAfterProcessed`, data);
};
// api untuk lock proses penerimaan barang dan edit po
export function lockPurchaseOrderPenerimaanForEditing({ poId }) {
  return agent.put('/receives/lockPurchaseOrderForEditing', {
    poId,
  });
}

export function unlockPurchaseOrderPenerimaan({ poId }) {
  return agent.put('/receives/unlockPOForEditing', {
    poId,
  });
}

export function getLockedWhileEditingStatus({ poId }) {
  if (poId) {
    return agent.get('/receives/getLockedWhileEditingStatus', {
      params: {
        poId,
      },
    });
  }
  return new Promise(res =>
    res({
      data: {
        isEditing: false,
      },
    }),
  );
}