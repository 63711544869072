import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import routes from "../../constants/routes";
import { setSnackbar } from "../../store/general/actions";
import CustomTextField from "../custom/CustomTextField";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import { getDetailRack, addRack, editRack } from "../../services/RackService";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
}));

const AddNewStorageRack = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [data, setData] = useState();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (props.edit) {
      getDetailRack(id).then((res) => setData(res.data.item.name));
    }
  }, [id, props.edit]);

  const saveRack = () => {
    if (!data) {
      dispatch(
        setSnackbar("warning", "Maaf! Silakan isi nama rak terlebih dahulu")
      );
      return;
    }
    if (props.edit) {
      setIsFetching(true);
      editRack(id, data).then(() => history.push(routes.STORAGE_RACK));
    } else {
      setIsFetching(true);
      addRack(data).then(() => history.push(routes.STORAGE_RACK));
    }
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={props.edit ? "Edit Rak" : "Tambah Rak"}
        previousPageName="Setting"
        previousPageLink={routes.STORAGE_RACK}
      />
      <Grid container style={{ marginTop: 20 }}>
        <Grid item xs={3}>
          <CustomTextField
            label="Nama"
            className={classes.inputField}
            value={data}
            onChange={(event) => setData(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => history.push(routes.STORAGE_RACK)}
            >
              Batalkan
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              onClick={isFetching ? null : saveRack}
            >
              {isFetching ?  <CircularProgress /> : "Simpan"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddNewStorageRack;
