import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CircularProgress from "@material-ui/core/CircularProgress";
import { palette } from "../../theme";
import CustomPagination from "../custom/CustomPagination";
import CustomDropdownButton from "../custom/CustomDropdownButton";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { getDepotStock } from "../../services/InventoryService";

import debounce from "awesome-debounce-promise";
import routes, { inventoryRoutes } from "../../constants/routes";
import MenuItem from "@material-ui/core/MenuItem";
import exportXL from "../../utils/exportXL";
import useCekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {},
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  button: {
    float: "right",
    marginLeft: 5,
  },
  editButton: {
    float: "right",
    marginLeft: 5,
    color: palette.blue,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  topGrid: {
    display: "flex",
  },
  marginContainer: {
    marginLeft: 20,
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debouncedGetDepotStock = debounce(getDepotStock, 500);

const DepotStok = (props) => {
  const classes = useStyles();
  const { itemID } = useParams();
  const history = useHistory();
  const cekRole = useCekHakAkses();

  const [pagination, setPagination] = useState(paginationInitialData);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [buttonMenuSelect] = useState();
  const [exportData, setExportData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const { search } = useLocation();
  const q = new URLSearchParams(search);
  const source = q.get("source");

  useEffect(() => {
    setIsFetchingData(true);
    debouncedGetDepotStock(itemID, pagination.page, pagination.rowsPerPage)
      .then((res) => {
        setTotalData(res.data.count);
        setTableData(res.data.items);
      })
      .finally(() => setIsFetchingData(false));
  }, [itemID, pagination]);

  const getDataExport = async () => {
    let page = 0;
    let skip = 0;
    const limit = 10;
    const fetch = async () => {
      const result = await getDepotStock(itemID, page, limit);
      exportData.splice(skip * limit, limit, ...result.data.items);
      setExportData(exportData);
    };
    while (skip < totalData) {
      await fetch();
      page += 1;
      skip += limit;
    }
  };

  const handleExport = () => {
    setIsFetching(true);
    try {
      getDataExport().then(() => {
        const hasil = exportData.map((val) => ({
          "Nama Depot": val.depotName,
          "Tipe Depot": val.depotType,
          "Nama Rak": val.rackName,
          "Jumlah Stok": val.stock,
        }));
        exportXL("Export Depot Stok", hasil);
        setIsFetching(false);
      });
    } catch (e) {
      console.error(e);
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid item xs={12} className={classes.alignRight}>
          <div style={{ paddingTop: 20 }}>
            {isFetching ? (
              <CircularProgress />
            ) : (
              <CustomDropdownButton
                disableElevation
                variant="contained"
                size="large"
                side
                onClick={() =>
                  history.push(
                    routes.INVENTORY +
                      inventoryRoutes.DETAIL_BARANG +
                      `/${itemID}` +
                      inventoryRoutes.TAMBAH_DEPOT +
                      `?source=${source}`,
                  )
                }
                label="+ Depot"
                disabled={
                  (source === "DataBarang" && !cekRole("inventory.f12")) ||
                  (source === "DataBarangDetails" && !cekRole("inventory.f33"))
                }
              >
                {/*<MenuItem
                  key="export"
                  value={1}
                  selected={1 === buttonMenuSelect}
                >
                  Import
                </MenuItem>*/}
                {((source === "DataBarang" &&
                  (cekRole("inventory.f15"))) ||
                  (source === "DataBarangDetails" &&
                    (cekRole("inventory.f35")))) && (
                  <MenuItem
                    key="export"
                    value={2}
                    selected={2 === buttonMenuSelect}
                    onClick={handleExport}
                  >
                    Export
                  </MenuItem>
                )}
              </CustomDropdownButton>
            )}
          </div>
        </Grid>
        <Grid item xs={12} style={{ overflow: "auto" }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerLeft}>Nama Depot</TableCell>
                <TableCell>Tipe Depot</TableCell>
                <TableCell>Nama Rak</TableCell>
                <TableCell>Jumlah Stok</TableCell>
                {cekRole("inventory.f13") && (
                  <TableCell className={classes.headerRight} />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetchingData ? (
                <TableRow>
                  <TableCell colSpan={cekRole("inventory.f13") ? 5 : 4} align={"center"}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : tableData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={cekRole("inventory.f13") ? 5 : 4} align={"center"}>
                    <p>Tidak ada data yang bisa ditampilkan.</p>
                  </TableCell>
                </TableRow>
              ) : (
                tableData.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.depotName}</TableCell>
                    <TableCell>{row.depotType}</TableCell>
                    <TableCell>{row.rackName}</TableCell>
                    <TableCell>{row.stock}</TableCell>
                    {cekRole("inventory.f13") && (
                      <TableCell>
                        <Tooltip title="Mutasi Depot Stok" placement="center" id="warehouse-content">
                          <IconButton
                            color={palette.lightBlue}
                            className={classes.editButton}
                            size="small"
                            onClick={() =>
                              history.push(
                                routes.INVENTORY +
                                  inventoryRoutes.DETAIL_BARANG +
                                  `/${itemID}` +
                                  inventoryRoutes.MUTASI_DEPOT +
                                  `/${row._id}` + 
                                  `?source=${source}`
                              )
                            }
                          >
                            <ArrowRightAltIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <CustomPagination
            page={pagination.page}
            onChangePage={(event, newPage) =>
              setPagination({ ...pagination, page: newPage })
            }
            rowsPerPage={pagination.rowsPerPage}
            onChangeRowsPerPage={(event) =>
              setPagination({
                ...pagination,
                rowsPerPage: parseInt(event.target.value, 10),
              })
            }
            labelDisplayedRows={({ from, to, count }) =>
              from + "-" + to + " dari " + count + " Data"
            }
            count={totalData}
          />
        </Grid>
      </Grid>
    </div>
  );
};

DepotStok.propTypes = {};

export default DepotStok;
