import moment from "moment";

export const currencyFormat = (value) => {
  return value || value === 0 ? "Rp " + numberFormat(value) : "";
};

// untuk format angka-angka yang mempunyai decimal dan yang tidak dalam seluruh data
export const currencyFormatWithDecimalOrNot = (value = 0, comma) => {
  const hasDecimal = value.toString().includes('.');
  if (hasDecimal) {
    const parts = value.toString().split('.');
    const hasAferOneDecimal = parts.length > 1 && parts[1].length < 2;
    if (hasAferOneDecimal) {
      return currencyFormatterWithDecimal(value, 'Rp ', 1)
    }
    return currencyFormatterWithDecimal(value, 'Rp ', comma)
  } else {
    return currencyFormat(value);
  }
}

export const currencyFormatterWithDecimal = (number, symbol = 'Rp. ', comma = '') => {
  let parsedNumber = parseFloat(number);
  if (parsedNumber < 0) {
    parsedNumber = Math.abs(parsedNumber);
  }
  const newNumber = parsedNumber.toFixed(comma);
  const formattedNumber = newNumber.replace('.', ',').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
  return `${symbol}${formattedNumber}`;
};

export const numberFormat = (value) => {
  if (!value && value !== 0) {
    return "";
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const addressFormat = (address) => {
  let formattedAddress = "";
  if (!!address) {
    if (address.street) formattedAddress += `${address.street}, `;
    if (address.district) formattedAddress += `${address.district}, `;
    if (address.city) formattedAddress += `${address.city}, `;
    if (address.region) formattedAddress += `${address.region}, `;
    if (address.postcode) formattedAddress += `${address.postcode}`;
  }

  return formattedAddress;
};

export const paramDateFormat = (date) => {
  const DATE_FORMAT = "YYYY-MM-DD";
  if (date === null) return undefined;
  return date !== undefined ? moment(date).format(DATE_FORMAT) : undefined;
};

export const dateFormat = (date, options = {}) => {
  const { addTime = false } = options
  const DATE_FORMAT = !addTime ? 'DD/MM/YYYY' : "DD/MM/YYYY HH:mm:ss";
  return date !== undefined
    ? (!addTime
    ? moment(date).utc().format(DATE_FORMAT) 
    : (moment(date).locale('id').format(DATE_FORMAT))
    ) : undefined;
};

export const dateFormatShipping = (date) => {
  const DATE_FORMAT = "DD/MM/YYYY";
  const newDate = new Date(date).setDate(new Date(date).getDate() + 1);
  const isoDate = new Date(newDate).toISOString();
  return date !== undefined
    ? moment(isoDate).utc().format(DATE_FORMAT)
    : undefined;
};

export const reportDateFormat = (date, isDateStart) => {
  let DATE_FORMAT = "YYYY-MM-DD 00:00:00";
  if (!isDateStart) {
    DATE_FORMAT = "YYYY-MM-DD 23:59:59";
  }
  if (date === null) return undefined;
  return date !== undefined ? moment(date).format(DATE_FORMAT) : undefined;
};

export const formatPayloadInt = (payload, fields) => {
  const formattedPayload = {};
  Object.entries(payload).forEach(([field, value]) => {
    if (fields.includes(field)) {
      formattedPayload[field] = parseInt(value, 10);
    } else {
      formattedPayload[field] = value;
    }
  });

  return formattedPayload;
};

const createNumberCallback = (callback) => (evt) =>
  callback({
    target: {
      value: parseInt(evt.target.value, 10) || 0,
    },
  });

export const typeFormat = (tipe) => {
  if (tipe === "auditPlus" || tipe === "auditMinus") {
    return "Audit";
  } else if (tipe === "adjustmentPlus" || tipe === "adjustmentMinus") {
    return "Adjustment";
  } else if (tipe === "new") {
    return "New";
  } else if (tipe === "mutate") {
    return "Mutate";
  } else if (tipe === "stockOpnamePlus" || tipe === "stockOpnameMinus") {
    return "Stock Opname";
  }
  return tipe;
};

export const statusFormat = (status) => {
  if (status === "paid off") {
    return "Lunas";
  } else if (status === "not paid off") {
    return "Belum Lunas";
  } else if (status === "debt") {
    return "Hutang";
  } else if (status === "not billed yet" || status === "not yet billed") {
    return "Belum Ditagih";
  }
  return status;
};

export default {
  currencyFormat,
  numberFormat,
  addressFormat,
  paramDateFormat,
  dateFormat,
  reportDateFormat,
  createNumberCallback,
  typeFormat,
  statusFormat,
  currencyFormatWithDecimalOrNot,
};
