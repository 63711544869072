import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import routes, { laporanRoutes } from "../../constants/routes";

import Laporan from "./Laporan";
import Penjualan from "./Penjualan";
import Adjustment from "./LaporanAdjustment";
import LaporanRO from "./LaporanRO";
import LaporanPenerimaan from "./LaporanPenerimaan";
import LaporanFaktur from "./LaporanFaktur";
import LaporanFastMoving from "./LaporanFastMoving";
import LaporanDataStok from "./LaporanDataStok";
import LaporanDelivery from "./LaporanDelivery";
import LaporanSelisihRO from "./LaporanSelisihRO";
import LaporanKekayaanBarang from "./LaporanKekayaanBarang";
import LaporanSelisihPO from "./LaporanSelisihPO";
import LaporanPO from "./LaporanPO";
import LaporanReturn from "./LaporanReturn";
import cekHakAkses from "../../utils/cekHakAkses";

const getRoute = (route) => {
  return routes.LAPORAN + route;
};

const LaporanRoute = () => {
  const cekRole = cekHakAkses();

  return (
    <Switch>
      <Route path={getRoute(laporanRoutes.LAPORAN_MENU)} exact>
        <Laporan />
      </Route>
      {cekRole("report.h10") && (
        <Route path={getRoute(laporanRoutes.LAPORAN_ADJUSTMENT)} exact>
          <Adjustment />
        </Route>
      )}
      {cekRole("report.h5") && (
        <Route path={getRoute(laporanRoutes.LAPORAN_PENJUALAN)} exact>
          <Penjualan />
        </Route>
      )}
      {cekRole("report.h16") && (
        <Route path={getRoute(laporanRoutes.LAPORAN_RO)} exact>
          <LaporanRO />
        </Route>
      )}
      {cekRole("report.h6") && (
        <Route path={getRoute(laporanRoutes.LAPORAN_PENERIMAAN)} exact>
          <LaporanPenerimaan />
        </Route>
      )}
      {cekRole("report.h11") && (
        <Route path={getRoute(laporanRoutes.LAPORAN_FAKTUR)} exact>
          <LaporanFaktur />
        </Route>
      )}
      {cekRole("report.h17") && (
        <Route path={getRoute(laporanRoutes.LAPORAN_FASTMOVING)} exact>
          <LaporanFastMoving />
        </Route>
      )}
      {cekRole("report.h7") && (
        <Route path={getRoute(laporanRoutes.LAPORAN_DATASTOK)} exact>
          <LaporanDataStok />
        </Route>
      )}
      {(cekRole("report.h12") || cekRole("report.h15")) && (
        <Route path={getRoute(laporanRoutes.LAPORAN_DELIVERY)} exact>
          <LaporanDelivery />
        </Route>
      )}
      {cekRole("report.h8") && (
        <Route path={getRoute(laporanRoutes.LAPORAN_SELISIH_RO)} exact>
          <LaporanSelisihRO />
        </Route>
      )}
      {cekRole("report.h13") && (
        <Route path={getRoute(laporanRoutes.LAPORAN_KEKAYAAN)} exact>
          <LaporanKekayaanBarang />
        </Route>
      )}
      {cekRole("report.h9") && (
        <Route path={getRoute(laporanRoutes.LAPORAN_SELISIH_PO)} exact>
          <LaporanSelisihPO />
        </Route>
      )}
      {cekRole("report.h14") && (
        <Route path={getRoute(laporanRoutes.LAPORAN_PO)} exact>
          <LaporanPO />
        </Route>
      )}
         {cekRole("report.h18") && (
        <Route path={getRoute(laporanRoutes.LAPORAN_RETURN)} exact>
          <LaporanReturn />
        </Route>
      )}

      <Redirect to={getRoute(laporanRoutes.LAPORAN_MENU)} />
    </Switch>
  );
};

export default LaporanRoute;
