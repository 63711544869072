import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import routes from "../../constants/routes";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CustomTextField from "../custom/CustomTextField";
import CustomSelect from "../custom/CustomSelect";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { useHistory, useParams } from "react-router-dom";
import validations from "../../utils/validations";
import {
  getAccountDetail,
  editAccount,
  addAccount,
} from "../../services/AccountService";
import { getAccessRightList } from "../../services/AccessRightService";
import { getListOccupation } from "../../services/OccupationService";
import {debounce} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  title: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.blue,
    height: 40,
  },
  titleText: {
    display: "inline-block",
    marginTop: 10,
    fontFamily: theme.typography.fontFamily,
  },
  itemGridLeft: {
    paddingRight: 12,
  },
  itemGridRight: {
    paddingLeft: 12,
  },
  adornment: {
    color: theme.palette.red,
    marginBottom: 10,
  },
  inputField: {
    width: "100%",
  },
  select: {
    width: "100%",
  },
}));

const AddNewStaff = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const [data, setData] = useState({ occupation: "", accessType: "" });
  const [accessTypes, setAccessTypes] = useState([]);
  const [error, setError] = useState({});
  const [occupations, setOccupations] = useState([]);

  const validateStaff = () => {
    const valid = {
      name: validations.isEmpty(data.name)
        ? "Nama tidak boleh kosong"
        : undefined,
      occupation: validations.isEmpty(data.occupation)
        ? "Jabatan tidak boleh kosong"
        : undefined,
      accessType: validations.isEmpty(data.accessType)
        ? "Tipe Akses tidak boleh kosong"
        : undefined,
      email: validations.isEmpty(data.email)
        ? "Email tidak boleh kosong"
        : undefined,
      phone: validations.isEmpty(data.phone)
        ? "No. Telp tidak boleh kosong"
        : undefined,
    };
    setError(valid);
    return Object.values(valid).every((error) => !error);
  };

  const submitStaff = () => {
    if (validateStaff()) {
      if (props.edit) {
        editAccount(id, {
          name: data.name,
          occupationId: data.occupation,
          occupation: occupations.find((value) => value.id === data.occupation)
            ?.name,
          accessId: data.accessType,
          access: accessTypes.find((value) => value.id === data.accessType)
            ?.name,
          hp: data.phone,
          notes: data.notes,
          isActive: !data.active,
        }).then(() => history.push(routes.STAFF_MANAGEMENT));
      } else {
        addAccount({
          name: data.name,
          occupationId: data.occupation,
          accessId: data.accessType,
          email: data.email,
          username: data.username,
          hp: data.phone,
          notes: data.notes,
          isActive: !data.active,
        }).then(() => history.push(routes.STAFF_MANAGEMENT));
      }
    }
  };

  const debouncedSubmitStaff = React.useCallback(debounce(submitStaff, 1000), [data]);

  useEffect(() => {
    getAccessRightList(undefined).then((res) => {
      setAccessTypes(res.data.items);
    });
    getListOccupation().then((res) => setOccupations(res.data.items));
    if (props.edit) {
      getAccountDetail(id).then((res) =>
        setData({
          name: res.data.item.name,
          email: res.data.item.Confidentials?.email,
          username: res.data.item.Confidentials?.username,
          phone: res.data.item.Confidentials?.hp,
          active: !res.data.item.isActive,
          notes: res.data.item.notes,
          occupation: res.data.item.occupationId,
          accessType: res.data.item.accessId,
        })
      );
    }
  }, [id, props.edit]);

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={(props.edit ? "Edit" : "Tambah") + " Staff"}
        previousPageName="Setting"
        previousPageLink={routes.STAFF_MANAGEMENT}
      />
      <Grid container>
        <Grid
          item
          xs={9}
          className={classes.title}
          alignItems="center"
          alignContent="center"
        >
          <Typography
            variant="body1"
            color="primary"
            className={classes.titleText}
          >
            Tambah Staff
          </Typography>
        </Grid>
        <Grid item xs={6} alignItems="center" alignContent="center">
          <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.itemGridLeft}>
              <Typography variant="subtitle1" className={classes.adornment}>
                * Wajib isi
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Nama Lengkap"
                className={classes.inputField}
                value={data.name}
                onChange={(event) => {
                  setData({ ...data, name: event.target.value });
                  setError({ ...error, name: undefined });
                }}
                error={error.name}
                helperText={error.name}
                requiredField
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomSelect
                value={data.occupation}
                onChange={(event) => {
                  setData({ ...data, occupation: event.target.value });
                  setError({ ...error, occupation: undefined });
                }}
                label="Jabatan"
                className={classes.select}
                error={error.occupation}
                helperText={error.occupation}
                requiredField
              >
                {occupations.map((data) => (
                  <MenuItem key={data.id} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomSelect
                value={data.accessType}
                onChange={(event) => {
                  setData({ ...data, accessType: event.target.value });
                  setError({ ...error, accessType: undefined });
                }}
                label="Tipe Akses"
                className={classes.select}
                error={error.accessType}
                helperText={error.accessType}
                requiredField
              >
                {accessTypes.map((data) => (
                  <MenuItem key={data.id} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Alamat Email"
                className={classes.inputField}
                value={data.email}
                onChange={(event) => {
                  setData({ ...data, email: event.target.value });
                  setError({ ...error, email: undefined });
                }}
                error={error.email}
                helperText={error.email}
                requiredField
                disabled={props.edit}
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="No Telp"
                className={classes.inputField}
                value={data.phone}
                type="number"
                onChange={(event) => {
                  setData({ ...data, phone: event.target.value });
                  setError({ ...error, phone: undefined });
                }}
                error={error.phone}
                helperText={error.phone}
                requiredField
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridLeft}>
              <CustomTextField
                label="Username"
                className={classes.inputField}
                value={data.username}
                onChange={(event) =>
                  setData({ ...data, username: event.target.value })
                }
                disabled={props.edit}
              />
            </Grid>
            <Grid item xs={6} className={classes.itemGridRight}>
              <CustomTextField
                label="Catatan"
                className={classes.inputField}
                value={data.notes}
                onChange={(event) =>
                  setData({ ...data, notes: event.target.value })
                }
              />
            </Grid>
            {props.edit ? (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.active}
                      onChange={(event) =>
                        setData({ ...data, active: event.target.checked })
                      }
                    />
                  }
                  label="Status staff tidak aktif (Tidak bisa mengakses Assist.id)"
                />
              </Grid>
            ) : undefined}
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <Grid container justify="flex-end">
            <Button
              disableElevation
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => history.push(routes.STAFF_MANAGEMENT)}
            >
              Batalkan
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              onClick={debouncedSubmitStaff}
            >
              Simpan
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddNewStaff;
