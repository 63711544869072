import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import routes, { laporanRoutes } from "../../constants/routes";
import { useHistory } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import useCekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  headerGrid: {
    height: 60,
    borderBottomColor: theme.palette.normalTextfield,
    borderTopColor: theme.palette.normalTextfield,
    borderTop: "1px solid",
    borderBottom: "1px solid",
  },
  headerTitle: {
    color: theme.palette.blue,
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    paddingTop: 8,
  },
  alignRight: {
    textAlign: "right",
    marginTop: 7,
  },
  laporanItemsOrange: {
    borderLeft: "3px solid orange",
    padding: 5,
    display: "flex",
  },
  laporanItemsGreen: {
    borderLeft: "3px solid green",
    padding: 5,
    display: "flex",
  },
  laporanItemsYellow: {
    borderLeft: "3px solid yellow",
    padding: 5,
    display: "flex",
  },
  laporanItemsLightBlue: {
    borderLeft: "3px solid lightblue",
    padding: 5,
    display: "flex",
  },
  laporanItemsDarkBlue: {
    borderLeft: "3px solid darkblue",
    padding: 5,
    display: "flex",
  },
  itemsGrid: {
    borderBottom: "1px solid black",
    padding: 10,
    maxWidth: "30%",
    marginRight: 40,
    cursor: "pointer",
  },
  arrowRight: {
    marginTop: 10,
    marginLeft: 5,
  },
  halfContainer: {
    display: "flex",
  },
  itemText: {
    width: "90%",
    paddingLeft: 10,
  },
}));

const Laporan = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const cekRole = useCekHakAkses();

  const handlePenjualan = () => {
    history.push(routes.LAPORAN + laporanRoutes.LAPORAN_PENJUALAN);
  };
  const laporanAdjustment = () => {
    history.push(routes.LAPORAN + laporanRoutes.LAPORAN_ADJUSTMENT);
  };
  const laporanRO = () => {
    history.push(routes.LAPORAN + laporanRoutes.LAPORAN_RO);
  };
  const laporanPenerimaan = () => {
    history.push(routes.LAPORAN + laporanRoutes.LAPORAN_PENERIMAAN);
  };
  const laporanFaktur = () => {
    history.push(routes.LAPORAN + laporanRoutes.LAPORAN_FAKTUR);
  };
  const laporanFastMoving = () => {
    history.push(routes.LAPORAN + laporanRoutes.LAPORAN_FASTMOVING);
  };
  const laporanDataStok = () => {
    history.push(routes.LAPORAN + laporanRoutes.LAPORAN_DATASTOK);
  };
  const laporanDelivery = () => {
    history.push(routes.LAPORAN + laporanRoutes.LAPORAN_DELIVERY);
  };
  const laporanSelisihRO = () => {
    history.push(routes.LAPORAN + laporanRoutes.LAPORAN_SELISIH_RO);
  };
  const laporanKekayaanBarang = () => {
    history.push(routes.LAPORAN + laporanRoutes.LAPORAN_KEKAYAAN);
  };
  const laporanSelisihPO = () => {
    history.push(routes.LAPORAN + laporanRoutes.LAPORAN_SELISIH_PO);
  };
  const laporanPO = () => {
    history.push(routes.LAPORAN + laporanRoutes.LAPORAN_PO);
  };
  const laporanReturn = () => {
    history.push(routes.LAPORAN + laporanRoutes.LAPORAN_RETURN);
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.headerGrid}>
        <Grid item container xs={9} alignItems="center">
          <Typography
            variant="h2"
            color="textSecondary"
            className={classes.headerTitle}
          >
            Laporan
          </Typography>
        </Grid>
        {/*<Grid item xs={3} className={classes.alignRight}>
          <CustomDropdownButton
            disableElevation
            variant="contained"
            size="large"
            label="Export"
            endIcon={<ExpandMoreIcon />}
          >
            <MenuItem key="export" value={1} selected={1 === buttonMenuSelect}>
              Export
            </MenuItem>
            <MenuItem key="print" value={2} selected={2 === buttonMenuSelect}>
              Print
            </MenuItem>
          </CustomDropdownButton>
        </Grid>*/}
      </Grid>

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid item xs={12} className={classes.halfContainer}>
          {cekRole("report.h5") && (
            <Grid item xs={4} className={classes.itemsGrid}>
              <div
                className={classes.laporanItemsOrange}
                onClick={handlePenjualan}
              >
                <div className={classes.itemText}>
                  <Typography variant="body1" color="textPrimary">
                    Penjualan Barang
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Data seluruh penjualan barang yang terjadi
                  </Typography>
                </div>
                <div className={classes.arrowRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
          {cekRole("report.h10") && (
            <Grid item xs={4} className={classes.itemsGrid}>
              <div
                className={classes.laporanItemsOrange}
                onClick={laporanAdjustment}
              >
                <div className={classes.itemText}>
                  <Typography variant="body1" color="textPrimary">
                    Adjustment Stock
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Laporan adjustment stock barang
                  </Typography>
                </div>
                <div className={classes.arrowRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
          {cekRole("report.h16") && (
            <Grid item xs={4} className={classes.itemsGrid}>
              <div className={classes.laporanItemsGreen} onClick={laporanRO}>
                <div className={classes.itemText}>
                  <Typography variant="body1" color="textPrimary">
                    RO Barang
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Laporan data RO barang
                  </Typography>
                </div>
                <div className={classes.arrowRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} className={classes.halfContainer}>
          {cekRole("report.h6") && (
            <Grid item xs={4} className={classes.itemsGrid}>
              <div
                className={classes.laporanItemsGreen}
                onClick={laporanPenerimaan}
              >
                <div className={classes.itemText}>
                  <Typography variant="body1" color="textPrimary">
                    Penerimaan Barang
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Data seluruh penerimaan barang yang terjadi
                  </Typography>
                </div>
                <div className={classes.arrowRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
          {cekRole("report.h11") && (
            <Grid item xs={4} className={classes.itemsGrid}>
              <div
                className={classes.laporanItemsGreen}
                onClick={laporanFaktur}
              >
                <div className={classes.itemText}>
                  <Typography variant="body1" color="textPrimary">
                    Faktur Jatuh Tempo
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Data informasi seluruh faktur jatuh tempo
                  </Typography>
                </div>
                <div className={classes.arrowRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
          {cekRole("report.h17") && (
            <Grid item xs={4} className={classes.itemsGrid}>
              <div
                className={classes.laporanItemsYellow}
                onClick={laporanFastMoving}
              >
                <div className={classes.itemText}>
                  <Typography variant="body1" color="textPrimary">
                    Fast Moving and Slow Moving
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Laporan fast moving dan slow moving barang
                  </Typography>
                </div>
                <div className={classes.arrowRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} className={classes.halfContainer}>
          {cekRole("report.h7") && (
            <Grid
              item
              xs={4}
              className={classes.itemsGrid}
              onClick={laporanDataStok}
            >
              <div className={classes.laporanItemsYellow}>
                <div className={classes.itemText}>
                  <Typography variant="body1" color="textPrimary">
                    Data Stok Barang
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Data seluruh stok barang yang ada
                  </Typography>
                </div>
                <div className={classes.arrowRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
          {(cekRole("report.h12") || cekRole("report.h15")) && (
            <Grid item xs={4} className={classes.itemsGrid}>
              <div
                className={classes.laporanItemsYellow}
                onClick={laporanDelivery}
              >
                <div className={classes.itemText}>
                  <Typography variant="body1" color="textPrimary">
                    Delivery Order
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Data informasi delivery order
                  </Typography>
                </div>
                <div className={classes.arrowRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
          {cekRole("report.h18") && (
            <Grid item xs={4} className={classes.itemsGrid}>
              <div className={classes.laporanItemsDarkBlue} onClick={laporanReturn}>
                <div className={classes.itemText}>
                  <Typography variant="body1" color="textPrimary">
                    Laporan Return Barang
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Laporan Return Barang{" "}
                  </Typography>
                </div>
                <div className={classes.arrowRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} className={classes.halfContainer}>
          {cekRole("report.h8") && (
            <Grid
              item
              xs={4}
              className={classes.itemsGrid}
              onClick={laporanSelisihRO}
            >
              <div className={classes.laporanItemsLightBlue}>
                <div className={classes.itemText}>
                  <Typography variant="body1" color="textPrimary">
                    Selisih RO Klinik ke Warehouse
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Laporan selisih RO dari klinik ke warehouse
                  </Typography>
                </div>
                <div className={classes.arrowRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
          {cekRole("report.h13") && (
            <Grid item xs={4} className={classes.itemsGrid}>
              <div
                className={classes.laporanItemsLightBlue}
                onClick={laporanKekayaanBarang}
              >
                <div className={classes.itemText}>
                  <Typography variant="body1" color="textPrimary">
                    Laporan Kekayaan Barang
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Laporan kekayaan barang dan pergerakannya
                  </Typography>
                </div>
                <div className={classes.arrowRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} className={classes.halfContainer}>
          {cekRole("report.h9") && (
            <Grid
              item
              xs={4}
              className={classes.itemsGrid}
              onClick={laporanSelisihPO}
            >
              <div className={classes.laporanItemsDarkBlue}>
                <div className={classes.itemText}>
                  <Typography variant="body1" color="textPrimary">
                    Selisih PO dari Warehouse ke Supplier
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Laporan selisih PO dari purchase ke supplier
                  </Typography>
                </div>
                <div className={classes.arrowRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
          {cekRole("report.h14") && (
            <Grid item xs={4} className={classes.itemsGrid}>
              <div className={classes.laporanItemsDarkBlue} onClick={laporanPO}>
                <div className={classes.itemText}>
                  <Typography variant="body1" color="textPrimary">
                    Laporan PO Barang
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Laporan PO Barang{" "}
                  </Typography>
                </div>
                <div className={classes.arrowRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Laporan;
