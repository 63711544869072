import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import SearchIcon from "@material-ui/icons/Search";
import CustomTextField from "../custom/CustomTextField";
import CustomRangeDatePicker from "../custom/CustomRangeDatePicker";
import CustomPagination from "../custom/CustomPagination";
import CustomDropdownButton from "../custom/CustomDropdownButton";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import routes from "../../constants/routes";
import debounce from "awesome-debounce-promise";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { getReportRO } from "../../services/LaporanService";
import {
  dateFormat,
  reportDateFormat,
  dateFormatShipping,
} from "../../utils/formatting";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@material-ui/core/MenuItem";
import exportXL from "../../utils/exportXL";
import Print from "../../utils/Print";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import cekHakAkses from "../../utils/cekHakAkses";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  searchBarGrid: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    height: 100,
    paddingTop: 8,
  },
  table: {
    marginTop: 20,
    minWidth: 650,
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  topGrid: {
    display: "flex",
    marginTop: 20,
  },
  marginContainer: {
    marginLeft: 20,
  },
  alignRight: {
    textAlign: "right",
  },
  informationIcon: {
    marginTop: "2px",
    fontSize: "1rem",
    color: "grey",
  },
}));

const paginationInitialData = {
  page: 0,
  rowsPerPage: 10,
};

const debouncedGetReportRO = debounce(getReportRO, 500);

const LaporanRO = (props) => {
  const classes = useStyles();
  const cekRole = cekHakAkses();

  const [searchForm, setSearchForm] = useState({
    dateStart: moment().startOf("month").toDate(),
    dateEnd: moment().endOf("month").toDate(),
  });
  const [pagination, setPagination] = useState(paginationInitialData);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalEach, setTotalEach] = useState({
    totalSLA: 0,
    totalJumlahDipesan: 0,
    totalJumlahDikirim: 0,
    totalSelisih: 0,
  });
  const [buttonMenuSelect] = useState();
  const [exportData, setExportData] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    debouncedGetReportRO(
      searchForm.search,
      reportDateFormat(searchForm.dateStart, true),
      reportDateFormat(
        searchForm.dateEnd === null ? searchForm.dateStart : searchForm.dateEnd,
        false,
      ),
      pagination.page,
      pagination.rowsPerPage,
    )
      .then((res) => {
        setTotalData(res.data.count);
        setTableData(res.data.items);
        setExportData([]);
        setTotalEach((prevState) => ({
          ...prevState,
          totalSLA: Array.isArray(res.data.dataTotal.totalSLA)
            ? res.data.dataTotal.totalSLA.reduce((a, b) => a + b, 0)
            : 0,
          totalJumlahDipesan: Array.isArray(
            res.data.dataTotal.totalJumlahDipesan,
          )
            ? res.data.dataTotal.totalJumlahDipesan.reduce((a, b) => a + b, 0)
            : 0,
          totalJumlahDikirim: Array.isArray(
            res.data.dataTotal.totalJumlahDikirim,
          )
            ? res.data.dataTotal.totalJumlahDikirim.reduce((a, b) => a + b, 0)
            : 0,
          totalSelisih: Array.isArray(res.data.dataTotal.totalSelisih)
            ? res.data.dataTotal.totalSelisih.reduce((a, b) => a + b, 0)
            : 0,
        }));
      })
      .finally(() => setIsFetching(false));
  }, [searchForm, pagination]);

  const getDataExport = async () => {
    let page = 0;
    let skip = 0;
    const limit = 10;
    const fetch = async () => {
      const result = await getReportRO(
        searchForm.search,
        reportDateFormat(searchForm.dateStart, true),
        reportDateFormat(
          searchForm.dateEnd === null
            ? searchForm.dateStart
            : searchForm.dateEnd,
          false,
        ),
        page,
        limit,
      );
      exportData.splice(skip * limit, limit, ...result.data.items);
      setExportData(exportData);
    };
    if (exportData.length >= totalData) return;
    while (skip < totalData) {
      await fetch();
      page += 1;
      skip += limit;
    }
  };

  const handleExport = () => {
    setIsFetchingData(true);
    try {
      const newData = [];
      getDataExport().then(() => {
        const hasil = exportData.map((val) => ({
          "Tanggal RO": dateFormat(val.approvedDate),
          "ID RO": val.codeRo,
          "Tanggal Dikirim": val.shippedDate
            ? dateFormatShipping(val.shippedDate)
            : "",
          SLA: val.sla,
          "Kode Barang": val.codeItem,
          "Nama Barang": val.name,
          "Jumlah Dipesan": val.quantityRequest,
          "Jumlah Dikirim": val.quantityDeliver,
          Selisih: val.quantityDifference,
          Satuan: val.bigUnit,
          "Nama Klinik": val.hospitalName || val.clientName,
        }));
        const lastRow = {
          "Tanggal RO": "",
          "ID RO": "",
          "Tanggal Dikirim": "Total",
          SLA: totalEach.totalSLA,
          "Kode Barang": "",
          "Nama Barang": "",
          "Jumlah Dipesan": totalEach.totalJumlahDipesan,
          "Jumlah Dikirim": totalEach.totalJumlahDikirim,
          Selisih: totalEach.totalSelisih,
          Satuan: "",
          "Nama Klinik": "",
        };
        newData.push(...hasil, lastRow);
        exportXL("Laporan RO Barang", newData);
        setIsFetchingData(false);
      });
    } catch (e) {
      console.error(e);
      setIsFetchingData(false);
    }
  };

  const handlePrint = () => {
    setIsFetchingData(true);
    try {
      getDataExport()
        .then(() => {
          setIsPrint(true);
          window.print();
        })
        .finally(() => {
          setIsPrint(false);
          setExportData([]);
          setIsFetchingData(false);
        })
        .catch((e) => {
          console.error(e);
          setIsPrint(false);
          setIsFetchingData(false);
        });
    } catch (e) {
      console.error(e);
      setIsPrint(false);
      setIsFetchingData(false);
    }
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={`Laporan RO Barang`}
        previousPageName="Laporan"
        previousPageLink={routes.LAPORAN}
      />
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.searchBarGrid}
      >
        <Grid item xs={9} className={classes.topGrid}>
          <div>
            <CustomTextField
              placeholder="Cari ID RO, Kode Barang, Nama Klinik"
              value={searchForm.search}
              onChange={(event) =>
                setSearchForm({ ...searchForm, search: event.target.value })
              }
              startIcon={<SearchIcon />}
            />
          </div>
          <div className={classes.marginContainer}>
            <CustomRangeDatePicker
              startDate={searchForm.dateStart}
              endDate={searchForm.dateEnd}
              onChange={([start, end]) =>
                setSearchForm({ ...searchForm, dateStart: start, dateEnd: end })
              }
              placeholder="Berdasarkan Tanggal"
            />
          </div>
        </Grid>
        <Grid item xs={3} className={classes.alignRight}>
          {isFetchingData ? (
            <CircularProgress />
          ) : (
            <CustomDropdownButton
              disableElevation
              variant="contained"
              size="large"
              label={
                cekRole("report.h3") && cekRole("report.h4")
                  ? "Export"
                  : cekRole("report.h3") && !cekRole("report.h4")
                  ? "Export"
                  : "Print"
              }
              endIcon={<ExpandMoreIcon />}
            >
              {cekRole("report.h3") && (
                <MenuItem
                  key="export"
                  value={1}
                  selected={1 === buttonMenuSelect}
                  onClick={handleExport}
                >
                  Export
                </MenuItem>
              )}
              {cekRole("report.h4") && (
                <MenuItem
                  key="print"
                  value={2}
                  selected={2 === buttonMenuSelect}
                  onClick={handlePrint}
                >
                  Print
                </MenuItem>
              )}
            </CustomDropdownButton>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Print>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerLeft}>Tanggal RO</TableCell>
                <TableCell>ID RO</TableCell>
                <TableCell>Tanggal Dikirim</TableCell>
                <TableCell>
                  SLA
                  <InfoOutlinedIcon className={classes.informationIcon} />
                </TableCell>
                <TableCell>Kode Barang</TableCell>
                <TableCell>Nama Barang</TableCell>
                <TableCell>Jumlah Dipesan</TableCell>
                <TableCell>Jumlah Dikirim</TableCell>
                <TableCell>Selisih</TableCell>
                <TableCell>Satuan</TableCell>
                <TableCell>Nama Klinik</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={11} align={"center"}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : !isPrint && tableData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={11} align={"center"}>
                    <p>Tidak ada data yang bisa ditampilkan.</p>
                  </TableCell>
                </TableRow>
              ) : (
                !isPrint &&
                tableData.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{dateFormat(row.approvedDate)}</TableCell>
                    <TableCell>{row.codeRo}</TableCell>
                    <TableCell>
                      {row.shippedDate
                        ? dateFormatShipping(row.shippedDate)
                        : ""}
                    </TableCell>
                    <TableCell>{row.sla}</TableCell>
                    <TableCell>{row.codeItem}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.quantityRequest}</TableCell>
                    <TableCell>{row.quantityDeliver}</TableCell>
                    <TableCell>{row.quantityDifference}</TableCell>
                    <TableCell>{row.bigUnit}</TableCell>
                    <TableCell>{row.hospitalName || row.clientName}</TableCell>
                  </TableRow>
                ))
              )}
              {isPrint &&
                exportData.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{dateFormat(row.approvedDate)}</TableCell>
                    <TableCell>{row.codeRo}</TableCell>
                    <TableCell>
                      {row.shippedDate
                        ? dateFormatShipping(row.shippedDate)
                        : ""}
                    </TableCell>
                    <TableCell>{row.sla}</TableCell>
                    <TableCell>{row.codeItem}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.quantityRequest}</TableCell>
                    <TableCell>{row.quantityDeliver}</TableCell>
                    <TableCell>{row.quantityDifference}</TableCell>
                    <TableCell>{row.bigUnit}</TableCell>
                    <TableCell>{row.hospitalName || row.clientName}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={11} align={"center"}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align={"right"} style={{ fontWeight: "bold" }}>
                    Total
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>{totalEach.totalSLA.toFixed(2)}</TableCell>
                  <TableCell colSpan={2} />
                  <TableCell style={{ fontWeight: "bold" }}>{totalEach.totalJumlahDipesan}</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>{totalEach.totalJumlahDikirim}</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>{totalEach.totalSelisih}</TableCell>
                  <TableCell colSpan={2} />
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </Print>
        <CustomPagination
          page={pagination.page}
          onChangePage={(event, newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          rowsPerPage={pagination.rowsPerPage}
          onChangeRowsPerPage={(event) =>
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value, 10),
            })
          }
          labelDisplayedRows={({ from, to, count }) =>
            from + "-" + to + " dari " + count + " Data"
          }
          count={totalData}
        />
      </Grid>
    </div>
  );
};

LaporanRO.propTypes = {};
export default LaporanRO;
