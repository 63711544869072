import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomBreadcrumbs from "../custom/CustomBreadcrumbs";
import routes from "../../constants/routes";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CustomTextField from "../custom/CustomTextField";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import CustomSelect from "../custom/CustomSelect";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { useHistory, useParams } from "react-router-dom";

import { getDetailSales, paySales } from "../../services/SalesService";
import { currencyFormat, dateFormat } from "../../utils/formatting";
import validations from "../../utils/validations";
import useDisclosure from "../../utils/useDisclosure";
import { setSnackbar } from "../../store/general/actions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  borderBottom: {
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
  },
  title: {
    lineHeight: "60px",
    fontWeight: "bold",
  },
  inputField: {
    width: 140,
  },
  input: {
    width: "90%",
  },
  table: {
    marginBottom: 20,
  },
  table2: {
    marginBottom: 20,
    "& td": {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  headerLeft: {
    borderTopLeftRadius: 10,
  },
  headerRight: {
    borderTopRightRadius: 10,
  },
  obatCell: {
    width: "30%",
    paddingLeft: 20,
  },
  dateCell: {
    paddingLeft: 20,
  },
  select: {
    width: 80,
  },
  headerGrid: {
    height: 80,
    padding: "10px 0px",
    borderBottomColor: theme.palette.normalTextfield,
    borderBottom: "1px solid",
    overflow: 'auto',
  },
  invoiceHead: {
    fontWeight: "normal",
    backgroundColor: "white",
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: 0,
    color: "#666666",
    fontSize: 10,
  },
  invoiceBody: {
    borderBottom: 0,
    padding: 0,
    paddingLeft: 0,
  },
}));

const metodePembayarans = {
  Langsung: ["Tunai"],
  "Kartu Debit": [
    "Bank BCA",
    "Bank Mandiri",
    "Bank BRI",
    "Bank Danamon",
    "Bank BII",
    "Bank BNI",
  ],
  "Kartu Kredit": [
    "Bank BCA",
    "Bank Mandiri",
    "Bank BRI",
    "Bank Danamon",
    "Bank BII",
    "Bank BNI",
  ],
  Transfer: [
    "Bank BCA",
    "Bank Mandiri",
    "Bank BRI",
    "Bank Danamon",
    "Bank BII",
    "Bank BNI",
  ],
};

const units = [
  { value: "FIXED", label: "Rp" },
  { value: "PERCENTAGE", label: "%" },
];

const pembayaranDefault = {
  metode: "Langsung",
  alat: "Tunai",
  dibayarOleh: "",
  bayar: "",
};

const PembayaranSales = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { invoiceId } = useParams();
  const { open, onOpen, onClose } = useDisclosure();

  const [error, setError] = useState({});
  const [invoice, setInvoice] = useState({});
  const [pembayaran, setPembayaran] = useState({ ...pembayaranDefault });
  const [itemDiscountMap, setItemDiscountMap] = useState({});

  useEffect(() => {
    if (!invoiceId) {
      history.push(routes.SALES);
    }

    getDetailSales(invoiceId).then((res) => {
      setInvoice(res.data.item);
      setItemDiscountMap({});
    });
  }, [history, invoiceId]);

  const changeItemDiscountType = (index) => (event) => {
    setItemDiscountMap({
      ...itemDiscountMap,
      [index]: event.target.value,
    });
  };

  const handleChangePembayaran = (name) => (event) => {
    const { value } = event.target;
    if (name === "metode") {
      if (
        (value !== "Langsung" && pembayaran.metode === "Langsung") ||
        (value === "Langsung" && pembayaran.metode !== "Langsung")
      ) {
        return setPembayaran({ ...pembayaran, [name]: value, alat: "" });
      }
    }

    if (name === 'bayar') {
      if (pembayaran.metode !== 'Langsung' && value > invoice.totalFee) {
        return setPembayaran({ ...pembayaran, [name]: invoice.totalFee });
      }
    }
    setPembayaran({ ...pembayaran, [name]: value });
  };

  const checkError = () => {
    const metodeError = validations.isEmpty(pembayaran.metode);
    const alatError = validations.isEmpty(pembayaran.alat);
    const dibayarOlehError = validations.isEmpty(pembayaran.dibayarOleh);
    const bayarError = validations.isEmpty(pembayaran.bayar);
    setError({
      metode: metodeError,
      alat: alatError,
      dibayarOleh: dibayarOlehError,
      bayar: bayarError,
    });
    return metodeError || alatError || dibayarOlehError || bayarError;
  };

  const handleBayar = () => {
    onOpen();
    if (checkError()) {
      return;
    }

    const calculatedBayar =
      parseInt(pembayaran.bayar, 10) - calculateKembalian();
    paySales(
      invoiceId,
      pembayaran.metode,
      pembayaran.alat,
      calculatedBayar,
      pembayaran.dibayarOleh
    ).then((res) => {
      props.setSnackbar("success", "Sukses! Pembayaran berhasil dilakukan");
      onClose();
      history.push(routes.SALES);
    });
  };

  const calculateKembalian = () => {
    const kembalian = pembayaran.bayar - (invoice.totalFee - invoice.paidFee);
    return kembalian < 0 ? 0 : kembalian;
  };

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs
        currentPageName={invoice.status !== "paid off" ? "Pembayaran Sales " + (invoice.codeRo || "") : "Detail Pembayaran Sales " + (invoice.codeRo || "")}
        previousPageName="Sales"
        previousPageLink={routes.SALES}
      />

      <Grid container item xs={12} className={classes.headerGrid}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.invoiceHead}>Invoice</TableCell>
              <TableCell className={classes.invoiceHead}>Client</TableCell>
              <TableCell className={classes.invoiceHead}>PIC Order</TableCell>
              <TableCell className={classes.invoiceHead}>Alamat</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.invoiceBody}>
                {invoice.codeRo}
              </TableCell>
              <TableCell className={classes.invoiceBody}>
                {invoice.Customers?.name}
              </TableCell>
              <TableCell className={classes.invoiceBody}>
                {invoice.namePic}
              </TableCell>
              <TableCell className={classes.invoiceBody}>
                {invoice.Customers?.address}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            className={`${classes.title} ${classes.borderBottom}`}
          >
            Review
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={clsx([classes.headerLeft, classes.obatCell])}
                >
                  Tindakan / Obat / Bahan Habis Pakai
                </TableCell>
                <TableCell>Jumlah (DO)</TableCell>
                <TableCell>Harga</TableCell>
                <TableCell colSpan={2}>Discount ( % | Rp )</TableCell>
                <TableCell className={classes.headerRight}>
                  Total Harga
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoice.TxItems?.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell className={classes.obatCell}>
                    {item.name}
                  </TableCell>
                  <TableCell>
                    <CustomTextField
                      className={classes.inputField}
                      value={item.quantity}
                      helperText=""
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <CustomTextField
                      className={classes.inputField}
                      value={item.baseFee}
                      currency
                      helperText=""
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <CustomSelect
                      className={classes.select}
                      helperText=""
                      value={itemDiscountMap[index] === units[1].value ? units[1].value : units[0].value}
                      onChange={changeItemDiscountType(index)}
                      disabled={invoice.status === "paid off"}
                    >
                      {units.map((data) => (
                        <MenuItem key={data.value} value={data.value}>
                          {data.label}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </TableCell>
                  <TableCell>
                    <CustomTextField
                      className={classes.inputField}
                      value={
                        itemDiscountMap[index] === units[1].value
                          ? item.discountPercent
                          : item.discountRp
                      }
                      helperText=""
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <CustomTextField
                      className={classes.inputField}
                      value={item.totalFee}
                      currency
                      helperText=""
                      disabled
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        {!!invoice.TxPayments?.length && ( // riwayat pembayaran
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className={`${classes.title} ${classes.borderBottom}`}
              >
                Riwayat Pembayaran
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Table className={classes.table2}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={clsx([classes.headerLeft, classes.dateCell])}
                    >
                      Tanggal
                    </TableCell>
                    <TableCell>Nominal Uang (Rp)</TableCell>
                    <TableCell>Metode Pembayaran</TableCell>
                    <TableCell className={classes.headerRight}>PIC</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice.TxPayments?.map((riwayat) => (
                    <TableRow key={riwayat.id}>
                      <TableCell className={classes.dateCell}>
                        {dateFormat(riwayat.createdDate)}
                      </TableCell>
                      <TableCell>{currencyFormat(riwayat.totalFee)}</TableCell>
                      <TableCell>
                        {riwayat.paymentType} - {riwayat.paymentName}
                      </TableCell>
                      <TableCell>{riwayat.paymentBy}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        )}
        {invoice.totalFee - invoice.paidFee > 0 && ( // pembayaran
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className={`${classes.title} ${classes.borderBottom}`}
              >
                Pembayaran
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={3}>
                <CustomSelect
                  value={pembayaran.metode}
                  label="Pilih Metode Pembayaran"
                  className={classes.input}
                  onChange={handleChangePembayaran("metode")}
                  error={error.metode && validations.isEmpty(pembayaran.metode)}
                  helperText={
                    error.metode && validations.isEmpty(pembayaran.metode)
                      ? "Metode pembayaran tidak boleh kosong"
                      : " "
                  }
                >
                  {Object.keys(metodePembayarans).map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item xs={3}>
                <CustomSelect
                  value={pembayaran.alat}
                  label="Alat Pembayaran"
                  className={classes.input}
                  onChange={handleChangePembayaran("alat")}
                  error={error.alat && validations.isEmpty(pembayaran.alat)}
                  helperText={
                    error.alat && validations.isEmpty(pembayaran.alat)
                      ? "Alat pembayaran tidak boleh kosong"
                      : " "
                  }
                >
                  {metodePembayarans[pembayaran.metode]?.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={3}>
                <CustomTextField
                  className={classes.input}
                  label="Dibayar Oleh"
                  value={pembayaran.dibayarOleh}
                  onChange={handleChangePembayaran("dibayarOleh")}
                  error={
                    error.dibayarOleh &&
                    validations.isEmpty(pembayaran.dibayarOleh)
                  }
                  helperText={
                    error.dibayarOleh &&
                    validations.isEmpty(pembayaran.dibayarOleh)
                      ? "Nama pembayar tidak boleh kosong"
                      : " "
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  className={classes.input}
                  label="Total Seluruhnya"
                  value={invoice.totalFee}
                  currency
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  className={classes.input}
                  label="Total yang Sudah Dibayarkan"
                  disabled
                  value={invoice.paidFee}
                  currency
                  helperText=""
                />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  className={classes.input}
                  label="Sisa"
                  value={invoice.totalFee - invoice.paidFee}
                  currency
                  disabled
                  helperText=""
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={3}>
                <CustomTextField
                  className={classes.input}
                  label="Bayar (Rp)"
                  value={pembayaran.bayar}
                  currency
                  allowNegative={false}
                  onChange={handleChangePembayaran("bayar")}
                  error={error.bayar && validations.isEmpty(pembayaran.bayar)}
                  helperText={
                    error.bayar && validations.isEmpty(pembayaran.bayar)
                      ? "Nominal bayar tidak boleh kosong"
                      : " "
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  className={classes.input}
                  label="Kembalian"
                  value={calculateKembalian()}
                  disabled
                  currency
                  helperText=""
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container justify="flex-end">
              <Button
                disableElevation
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => history.push(routes.SALES)}
              >
                Batalkan
              </Button>
              <Button
                disableElevation
                variant="contained"
                size="large"
                onClick={open ? () => {} : handleBayar}
              >
                Bayar Tagihan
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default connect(null, { setSnackbar })(PembayaranSales);
